import React from "react";
import R14, { TextInputField, SelectMenuField, HiddenInputField, FieldGroup, FileInputField } from "../core";
import EditForm from "../components/EditForm";
export default R14.connect(
  class DocumentTemplateEditScreen extends React.Component {
    constructor(props) {
      super(props);
      console.log("USE SECURE IMAGE!");
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='documentTemplate'
          redirect={this.props.redirect || "contactGroups"}
          refreshDataTable={
            this.props.refreshDataTable || "projectDocumentTemplates"
          }
        >
          <HiddenInputField name='projectUid' />
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
              autoFocus
            />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              validator='key'
            />
          </FieldGroup>

          <TextInputField name='description' label='Description' />
          <FileInputField
            name='image'
            label='Document Image'
          />
          <SelectMenuField
            useValuesOnly
            name='userUids'
            multiple
            label='Users'
            items={this.props.formData.userSelections}
          />
        </EditForm>
      );
    }
  }
);
