import React from "react";
import R14, {
  ScreenLayout,
  View,
  Text,
  Colors,
  StyleSheet,
  ActivityIndicator,
  FadeView,
  Icon,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  ScrollView,
  Switch,
  SelectMenu,
  Chip,
  ChipGroup,
} from "../core";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import Dashboard from "../components/Dashboard";
import DashboardCard from "../components/DashboardCard";
import DashboardWidget from "../components/DashboardWidget";
import DashboardCardSection from "../components/DashboardCardSection";
import HeaderLabelSelectMenu from "../components/HeaderLabelSelectMenu";
import ResourceMetricsChart from "../components/ResourceMetricsChart";
import ResourceProcessMetricsChart from "../components/ResourceProcessMetricsChart";
import PipelineBlockMetricsWidget from "../components/PipelineBlockMetricsWidget";
import ResourceDockerTaskDataTable from "../components/ResourceDockerTaskDataTable";

export default R14.connect(
  class ProjectPipelineScreen extends React.Component {
    constructor(props) {
      super(props);
      this.pipelineBlocksItemLoader = this.pipelineBlocksItemLoader.bind(this);
      console.log(props);
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.project.type),
        "pipelineBlock"
      );
    }
    async pipelineBlocksItemLoader(filters) {
      let filter = {
        //projectUid: { eq: this.props.project.uid },
        pipelineUid: { eq: this.props.pipelineBlock.pipeline.uid },
      };
      if (filters.search) {
        filter.search = { like: `%${filters.search}%` };
      }
      let pipelines = await this.props.app.dm.pipelineBlock.find(
        `
        uid
        name
      `,
        {
          page: 1,
          resultsPerPage: 20,
          filter: filter,
          sort: [
            {
              field: "name",
              order: "ASC",
            },
          ],
          totalCount: false,
        }
      );
      return pipelines && pipelines.nodes
        ? pipelines.nodes.map((val) => ({
            value: val.uid,
            label: val.name,
          }))
        : [];
    }
    render() {
      let pipelineBlock = this.props.pipelineBlock;
      let appModule = pipelineBlock.block.appModule;
      let instanceResourceDockerTaskUid = null;

      if (
        pipelineBlock.instanceResourceDockerTaskUids &&
        pipelineBlock.instanceResourceDockerTaskUids.length
      ) {
        instanceResourceDockerTaskUid =
          pipelineBlock.instanceResourceDockerTaskUids[0];
      }
      return (
        <ProjectScreenLayout
          project={this.props.project}
          scrollEnabled
          // headerTitle={this.props.pipelineBlock.name}
          HeaderTitleComponent={[
            <HeaderLabelSelectMenu
              key='headerLabel'
              value={{
                label: this.props.pipelineBlock.name,
                value: this.props.pipelineBlock.uid,
              }}
              searchable
              searchLabel='Pipelines'
              itemLoader={this.pipelineBlocksItemLoader}
              onValueChange={(value) => {
                value.value !== this.props.pipelineBlock.uid &&
                  this.props.app.nav.to("projectPipelineBlock", {
                    uid: this.props.project.uid,
                    key: this.props.app.dm.project.getKeyByType(
                      this.props.project.type
                    ),
                    pipelineUid: this.props.pipelineBlock.pipeline.uid,
                    pipelineBlockUid: value.value,
                  });
              }}
            />,
          ]}
          breadCrumbs={[
            {
              label: this.props.pipelineBlock.pipeline.name,
              to: {
                route: "projectPipeline",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                  pipelineUid: this.props.pipelineBlock.pipeline.uid,
                },
              },
            },
          ]}
        >
          <Dashboard name='projects' minWidth={352}>
            <DashboardCard title='Name'>
              <DashboardCardSection title='Name'>
                <Text>{this.props.pipelineBlock.name}</Text>
              </DashboardCardSection>
              <DashboardCardSection title='Pipeline'>
                <Text>{this.props.pipelineBlock.pipeline.name}</Text>
              </DashboardCardSection>
              <DashboardCardSection title='Instances'>
                <Text>{this.props.pipelineBlock.activeInstanceCount}</Text>
              </DashboardCardSection>
            </DashboardCard>

            {this.props.pipelineBlock.latestPipelineBlockMetrics.nodes
              .length && (
              <DashboardWidget>
                <PipelineBlockMetricsWidget
                  title='Metrics'
                  pipelineBlock={this.props.pipelineBlock}
                />
              </DashboardWidget>
            )}
            <DashboardWidget>
              <ResourceDockerTaskDataTable
                title='Instances'
                pipelineBlockUid={this.props.pipelineBlock.uid}
                permissions={this.perms}
                initialRowsPerPage={5}
              />
            </DashboardWidget>
            {instanceResourceDockerTaskUid && (
              <DashboardWidget>
                <ResourceMetricsChart
                  resourceUid={appModule.resource.uid}
                  resourceDockerTaskUid={instanceResourceDockerTaskUid}
                  resourceType={appModule.resource.type}
                  style={styles.chart}
                />
              </DashboardWidget>
            )}
            {instanceResourceDockerTaskUid && (
              <DashboardWidget>
                <ResourceProcessMetricsChart
                  resourceUid={appModule.resource.uid}
                  resourceDockerTaskUid={instanceResourceDockerTaskUid}
                  resourceType={appModule.resource.type}
                  style={styles.chart}
                />
              </DashboardWidget>
            )}
          </Dashboard>
        </ProjectScreenLayout>
      );
    }
  }
);
let styles = StyleSheet.create({
  screenLayout: {
    ...StyleSheet.margin(0),
    position: "relative",
  },
  pipeline: {
    flex: 1,
    flexDirection: "column",
    position: "relative",
    marginTop: -16,
  },
  scrollView: {
    ...StyleSheet.padding(16),
  },
  cancelMoveButton: {
    backgroundColor: "#B00020",
  },
  gridRow: {
    flex: 1,
    flexDirection: "row",
  },
  gridCellHighlighted: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.2),
  },
  draggableContainer: {
    // transform: [{ scale: .8 }],
  },
  point: {
    width: 5,
    height: 5,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.5),
    borderRadius: 3,
    ...StyleSheet.margin(-3, 0, 0, -3),
  },
  previewBlock: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    zIndex: -1,
  },
  previewBlockCollision: {
    backgroundColor: StyleSheet.color(Colors.error).rgba(0.2),
  },
  placeholderBlock: {
    position: "absolute",
    zIndex: -1,
    borderStyle: "solid",
    borderWidth: 1,
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.4),
  },
  previewBlockIo: {
    position: "absolute",
    borderWidth: 2,
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    zIndex: 1,
  },
  previewBlockIoCollision: {
    zIndex: 1,
    backgroundColor: StyleSheet.color(Colors.error).rgba(0.2),
  },
  placeholderBlockIo: {
    position: "absolute",
    zIndex: 1,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: StyleSheet.color(Colors.onBackground).rgba(0.4),
  },
  switchContainer: {
    ...StyleSheet.padding(0, 8, 0, 8),
  },
});
