import React from "react";
import R14, { Colors, StyleSheet, Text, Image, View } from "../core";
import EntityDataTable from "./EntityDataTable";
export default R14.connect(
  class VirtualWorkspaceImageDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "virtualWorkspaceImage"
      );
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.virtualWorkspaceImage.find(
        `
        uid
        name
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.props.app.dm.virtualWorkspaceImage}
          searchable
          title='Images'
          name='virtualWorkspaceImages'
          onRowPress={false}
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => {
            return <Text style={[styles.cellText]}>{cell}</Text>;
          }}
          pageLoader={this.pageLoader}
          initialSortColumnName='name'
          initialSortDirection='asc'
          perms={this.perms}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    
  }
});
