import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  IconButton,
  Text,
  View,
  Colors,
  Dialog,
  PopUpMenu,
  PopUpMenuItem
} from "../core";
import LabelView from "../components/LabelView";
import StateIndicatorText from "../components/StateIndicatorText";
import ManualEntryUserPaymentDataTable from "../components/ManualEntryUserPaymentDataTable";
import CardTable from "../components/CardTable";

export default R14.connect(
  class ManualEntryUserPaymentBatchesScreen extends React.Component {
    constructor(props) {
      super(props);
      this.showMetricKeyDialog = this.showMetricKeyDialog.bind(this);
      this.hideMetricKeyDialog = this.hideMetricKeyDialog.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUserPaymentBatch"
      );
      this.state = {
        metricKeyDialogVisible: false,
      };
    }
    get manualEntryUserPaymentBatch() {
      return this.props.manualEntryUserPaymentBatch;
    }
    showMetricKeyDialog() {
      this.setState({ metricKeyDialogVisible: true });
    }
    hideMetricKeyDialog() {
      this.setState({ metricKeyDialogVisible: false });
    }
    renderRowControlsRight() {
      let ret = [];
      this.perms.read &&
        this.manualEntryUserPaymentBatch.csvFile &&
        this.manualEntryUserPaymentBatch.csvFile.url &&
        ret.push(
          <PopUpMenu
            controlIcon='dotsVertical'
            // controlIconSize='small'
            key='appModuleMenu'
            direction='downLeft'
          >
            <PopUpMenuItem
              icon='fileDownload'
              label='Download CSV'
              key='fileDownload'
              onPress={() =>
                this.props.app.api.download(
                  this.manualEntryUserPaymentBatch.csvFile.url
                )
              }
            />
            <PopUpMenuItem
              icon='help'
              label='View Metric Keys'
              key='help'
              onPress={() =>
                this.showMetricKeyDialog()
              }
            />
          </PopUpMenu>
        );
      return ret;
    }
    renderMetricKeyDialog() {
      let metricFieldInfo = this.props.app.dm.manualEntry.getMetricFieldInfo();
      let cardTables = metricFieldInfo.map((mode, i) => {
        let cardTableData = mode.fields.map((metricField) => ({
          key: metricField.name,
          metric: metricField.label,
        }));
        return (
          <CardTable
            headerTitle={mode.label}
            style={styles.metricKeyCardTable}
            key={mode.mode}
            columns={{
              key: {
                label: "Key",
              },
              metric: {
                label: "Metric",
              },
            }}
            data={cardTableData}
          />
        );
      });
      return (
        <Dialog
          name='metricKeyDialog'
          title={`Metric Keys`}
          visible={this.state.metricKeyDialogVisible}
          titleControlVariant='close'
          style={styles.metricKeyDialog}
          contentStyle={styles.metricKeyCardDialogContent}
          onClosePress={this.hideMetricKeyDialog}
          onCancelPress={this.hideMetricKeyDialog}
          onBackdropPress={this.hideMetricKeyDialog}
          cancelButtonTitle='Close'
          hideOkButton
        >
          {cardTables}
        </Dialog>
      );
    }
    renderDataTime(date, time, timezone) {
      return `${date} ${time} ${timezone}`;
    }
    render() {
      return (
        <ScreenLayout
          headerTitle={this.manualEntryUserPaymentBatch.name}
          // style={styles.screenLayout}
          // headerStyle={styles.screenLayoutHeader}
          // headerControlsLeft={[
          //   <StateIndicatorIcon
          //     color={this.props.app.dm.resource.getStateIndicatorColor(
          //       resource.state
          //     )}
          //     style={styles.headerStateIndicatorIcon}
          //     key='resourceStateIndicator'
          //   />
          // ]}
          // headerControlsLeft={[
          //   <IconButton icon='arrowBack' onPress={()=>this.props.app.nav.to("manualEntryUserPaymentBatches")} />
          // ]}
          headerControlsRight={this.renderRowControlsRight()}
          scrollEnabled
        >
          <View style={styles.dashboard}>
            <LabelView style={styles.labelView} key='state' label='State'>
              <StateIndicatorText
                label={this.props.app.dm.manualEntryUserPaymentBatch.getStateLabel(
                  this.manualEntryUserPaymentBatch.state
                )}
                color={this.props.app.dm.manualEntryUserPaymentBatch.getStateIndicatorColor(
                  this.manualEntryUserPaymentBatch.state
                )}
              />
            </LabelView>
            <LabelView
              style={styles.labelView}
              key='processAt'
              label='Process At'
            >
              <Text>
                {this.renderDataTime(
                  this.manualEntryUserPaymentBatch.processAtDate,
                  this.manualEntryUserPaymentBatch.processAtTime,
                  "UTC"
                )}
              </Text>
            </LabelView>
            <LabelView style={styles.labelView} key='startAt' label='Start At'>
              <Text>
                {this.renderDataTime(
                  this.manualEntryUserPaymentBatch.startAtDate,
                  this.manualEntryUserPaymentBatch.startAtTime,
                  "UTC"
                )}
              </Text>
            </LabelView>
            <LabelView style={styles.labelView} key='endAt' label='End At'>
              <Text>
                {this.renderDataTime(
                  this.manualEntryUserPaymentBatch.endAtDate,
                  this.manualEntryUserPaymentBatch.endAtTime,
                  "UTC"
                )}
              </Text>
            </LabelView>
          </View>
          <ManualEntryUserPaymentDataTable
            headerStyle={styles.dataTableHeader}
            manualEntryUserPaymentBatchUid={
              this.manualEntryUserPaymentBatch.uid
            }
          />
          {this.renderMetricKeyDialog()}
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  dashboard: {
    flex: 1,
    flexDirection: "row",
    ...StyleSheet.padding(0, 16, 24, 16),
  },
  labelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
  dataTableHeader: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.06),
  },
  metricKeyDialog: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          width: null
        };
    },
  },
  metricKeyCardTable: {
    marginBottom: 12
  },
  metricKeyCardDialogContent: {
    marginBottom: -12
  },
});
