import React from "react";
import R14, {
  TextInputField,
  FieldGroup,
  BackButton,
  SubmitButton,
  SwitchField,
  StyleSheet,
  Form,
  View,
  HiddenInputField,
  Text,
  DownloadButton,
  Button,
  Colors,
  Redirect,
  SelectMenuField,
} from "../core";
// import EditForm from "../components/EditForm";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class R14LicenseEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleDownloadComplete = this.handleDownloadComplete.bind(this);
      this.handleDownloadSubmit = this.handleDownloadSubmit.bind(this);
      this.handleBackPress = this.handleBackPress.bind(this);
      this._submittedFormValues = null;
      this.state = {
        showDownloadForm: false,
        hasDownloadedLicense: false,
      };
      console.log("formdata", this.props.formData);
    }
    get isEditMode() {
      return this.props.formData &&
        this.props.formData.values &&
        this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return {
        ...this.props.formData,
        values: this._submittedFormValues || this.props.formData.values,
      };
    }
    get configFields() {
      return [
        "key",
        "secretKey",
        "adminSecretKey",
        "accessTokenSecret",
        "verifyUrl",
      ];
    }
    async saveLicense(values) {
      this.props.app.ui.progressIndicator.show();
      let ret = false;
      try {
        let isSuccess = await this.props.app.dm.r14License.save(values);
        console.log("IS SUCCESS", isSuccess);
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "r14Licenses";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "r14Licenses"} />;
        }
      } catch (err) {
        this.props.app.ui.snackBar.show({
          message: err.message,
          variant: "error",
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      console.log();
      return ret;
    }
    async handleDownloadSubmit() {
      if (!this.state.hasDownloadedLicense) {
        this.props.app.ui.snackBar.show({
          message: "Please download the license config.",
          variant: "error",
        });
        return false;
      }
      return await this.saveLicense(this._submittedFormValues);
    }
    handleBackPress() {
      this.setState({ showDownloadForm: false, hasDownloadedLicense: false });
    }
    async handleSubmit(form) {
      let ret = false;
      if (this.isEditMode) {
        ret = await this.saveLicense(form.values);
      } else {
        this._submittedFormValues = form.values;
        this.setState({ showDownloadForm: true });
      }
      return ret;
    }
    handleDownloadComplete() {
      this.setState({ hasDownloadedLicense: true });
    }
    createConfigJsonContent() {
      let license = this.props.formData.values;
      let configJson = {};
      this.configFields.map((name) => (configJson[name] = license[name]));
      console.log(license, configJson)
      return JSON.stringify(configJson, undefined, 2);
    }
    renderForm() {
      return (
        <Form
          name='r14LicenseForm'
          onSubmit={this.handleSubmit}
          style={styles.form}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Continue' key='continue' />,
          ]}
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <FieldGroup>
            <SwitchField name='active' label='Is Active' />
            <SelectMenuField
              useValuesOnly
              name='product'
              label='Product'
              items={this.formData.productSelections}
              required='Please select a product.'
            />
          </FieldGroup>
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
          {/* <TextInputField
            name='verifyUrl'
            label='Verification URL'
            required='Please enter a url.'
            helper='Cannot be changed.'
          /> */}
          <FieldGroup>
            <TextInputField
              name='syncTimeInterval'
              label='Sync Interval'
              required='Please enter a sync interval.'
              validator='positiveInt'
              helper='Milliseconds'
            />
            <TextInputField
              name='syncFailureTimeInterval'
              label='Sync Failure Interval'
              validator='positiveInt'
              required='Please enter a sync interval.'
              helper='Milliseconds'
            />
          </FieldGroup>
          {this.isEditMode && <HiddenInputField key='uid' name='uid' />}
          {!this.isEditMode &&
            this.configFields.map((name) => (
              <HiddenInputField key={name} name={name} />
            ))}
        </Form>
      );
    }
    renderDownloadForm() {
      return (
        <Form
          name='r14LicenseDownloadForm'
          onSubmit={this.handleDownloadSubmit}
          style={styles.form}
          validateBeforeSubmit
          // initialValues={this.formData.values}
          controlsBottomRight={[
            <Button
              title='Back'
              key='back'
              variant='text'
              onPress={this.handleBackPress}
            />,
            <SubmitButton
              title='Create'
              key='create'
              disabled={!this.state.hasDownloadedLicense}
            />,
          ]}
        >
          <View style={styles.info}>
            <Text style={styles.infoText}>
              Please download the license config json file below.{" "}
              <Text style={styles.boldText}>
                You will not be able to download this file again after creating
                the license
              </Text>
            </Text>
          </View>
          <DownloadButton
            title='Download License'
            style={styles.downloadButton}
            color={Colors.secondary}
            onComplete={this.handleDownloadComplete}
            source={{
              file: {
                name: "app.license.config.json",
                type: "text/json",
                content: this.createConfigJsonContent(),
              },
            }}
          />
        </Form>
      );
    }
    render() {
      return (
        <View style={styles.testtest}>
          {!this.state.showDownloadForm && this.renderForm()}
          {this.state.showDownloadForm && this.renderDownloadForm()}
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  info: {
    ...StyleSheet.padding(0, 0, 24, 0),
  },
  infoText: {
    // fontSize: 14,
    // fontWeight: "300",
    color: StyleSheet.color(Colors.onSurface).rgba(0.9),
  },
  boldText: {
    fontWeight: 600,
  },
  downloadButton: {
    marginBottom: 24,
  },
  form: {
    maxWidth: 480,
  },
  testtest: {
    flex: 1
  }
});
