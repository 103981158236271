import React from "react";
import R14 from "../core";
import AppModuleIde from "../components/AppModuleIde";
export default R14.connect(
  class ProjectsScreen extends React.Component {
    constructor(props) {
      super(props);

      this.appModuleDomain = this.props.app.dm.appModule;

      this.perms = this.props.app.dm.user.getPermissions("dev", "appModule");

      this.state = {
        state: this.props.appModule.state,
        serverState: this.props.appModule.serverState,
        resourceState: this.props.appModule.resource.state,
      };

      this.appModuleSubscription = null;

      if (!this.perms.read) throw new Error("Permissions Error.");

      this.init();
    }
    componentWillUnmount() {
      this.appModuleSubscription && this.appModuleSubscription.unsubscribe();
    }
    async init() {
      this.appModuleSubscription = await this.props.app.dm.appModule.onUpdate(
        this.props.appModule.uid,
        (res) => {
          if (res)
            this.setState({
              state: res.state,
              serverState: res.serverState,
            });
        }
      );
    }
    render() {
      return (
        <AppModuleIde
          url={this.props.ideUrl}
          appModule={this.props.appModule}
          projectUid={this.props.projectUid}
          projectType={this.props.projectType}
        />
      );
    }
  }
);
