import React from "react";
import R14, {
  StyleSheet,
  Surface,
  View,
  Touchable,
  Colors,
  Icon,
  DraggableContainer,
  DraggableView,
  ControlsView,
  IconButton,
  // NavigationContainer,
  ScrollView,
} from "../core";

export default R14.connect(
  class DocumentTemplateBottomSheet extends React.Component {
    constructor(props) {
      super(props);
      this.handlePress = this.handlePress.bind(this);
      this.handleDragPressMove = this.handleDragPressMove.bind(this);
      this.handleDragPressIn = this.handleDragPressIn.bind(this);
      this.handleDragPressOut = this.handleDragPressOut.bind(this);
      this.handleDragPressCancel = this.handleDragPressCancel.bind(this);
      this._dragResizeStartPageY = null;
      this.state = {
        dragResize: false,
      };
    }
    get documentTemplate() {
      return this.props.documentTemplate;
    }
    handlePress() {
      this.props.onPress && this.props.onPress();
    }
    handleDragPressIn(event) {
      this._dragResizeStartPageY = event.nativeEvent.pageY;
      this.setState({ dragResize: true });
    }
    handleDragPressOut(event) {
      this._dragResizeStartPageY = null;
      this.setState({ dragResize: false });
    }
    handleDragPressCancel(event) {
      this.handleDragPressOut();
    }
    handleDragPressMove(event) {
      if (!this.state.dragResize) return false;
      let height =
        this.documentTemplate.bottomSheet.height +
        this._dragResizeStartPageY -
        event.nativeEvent.pageY;
      if (height < 36) height = 36;
      this._dragResizeStartPageY = event.nativeEvent.pageY;
      this.documentTemplate.setBottomSheetHeight(height);
    }
    createDynamicStyles() {
      let dynamicStyles = { documentTemplateBottomSheet: {} };
      if (this.documentTemplate.bottomSheet.height !== null) {
        dynamicStyles.documentTemplateBottomSheet.height = this.documentTemplate.bottomSheet.height;
      }
      return StyleSheet.create(dynamicStyles);
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      return (
        <Surface
          style={[
            styles.documentTemplateBottomSheet,
            dynamicStyles.documentTemplateBottomSheet,
          ]}
        >
          <View style={styles.toolbar}>
            <View style={styles.toolbarControls} />
            <View style={styles.toolbarControls}>
              <ControlsView align='center'>
                <Touchable
                  onPressIn={this.handleDragPressIn}
                  onPressOut={this.handleDragPressOut}
                  onPressCancel={this.handleDragPressCancel}
                  onPressMove={
                    this.state.dragResize ? this.handleDragPressMove : null
                  }
                  style={styles.dragTouchable}
                >
                  <Icon style={styles.dragHandleIcon} name='dragHandle' />
                </Touchable>
              </ControlsView>
            </View>
            <View style={styles.toolbarControls}>
              <ControlsView style={styles.toolbarControlsRight} align='right'>
                <IconButton size='small' icon='windowMinimize' />
                <IconButton size='small' icon='windowMaximize' />
              </ControlsView>
            </View>
          </View>
          <ScrollView style={styles.navigationContainerScrollView}>
            <Surface style={styles.navigationContainerSurface}>
              {/* <NavigationContainer name='projectDocumentTemplateNavigationContainer' /> */}
            </Surface>
          </ScrollView>
        </Surface>
      );
    }
  }
);

const styles = StyleSheet.create({
  documentTemplateBottomSheet: {
    position: "relative",
    // flex: 1,
    paddingTop: 0,
    // ...StyleSheet.padding(0),
    width: "100%",
    height: 36,
    elevation: 8,
  },
  toolbarControls: {
    flex: 1,
    flexBasis: 1,
    position: "relative",
  },
  toolbarControlsCenter: {
    // ...StyleSheet.margin(0),
    // alignSelf: "center",
  },
  toolbar: {
    flex: 0,
    flexDirection: "row",
    height: 36,
    justifyContent: "flex-end",
    // width: "100%",
    // height: 300,
  },
  draggableContainer: {
    position: "absolute",
    height: 36,
    width: "33%",
  },
  dragHandle: {
    position: "absolute",
    ...StyleSheet.absoluteFill,
    // height: 36,
    // width: "33%",
    backgroundColor: "#FF0000",
  },
  dragHandleIcon: {
    height: 36,
    width: 36,
    ...StyleSheet.padding(6),
  },
  dragTouchable: {
    cursor: "ns-resize",
  },
  navigationContainerScrollView: {
    top: 36,
  },
  navigationContainerSurface:{
    ...StyleSheet.padding(0),
    ...StyleSheet.margin(8,32,0,32),
    overflow: "hidden",
    elevation: 4
  }
});
