import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  DialogFormField,
  FadeView,
  View,
  Text,
  StyleSheet,
} from "../core";
import LabelView from "../components/LabelView";
import { element } from "prop-types";

export default R14.connect(
  class ManualEntryAccuracyTestEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChangeBase64ImageText =
        this.handleChangeBase64ImageText.bind(this);
    }
    // async handlePipelineValueChange(
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryConfigForm");
    }

    handleChangeBase64ImageText(val, elmt) {
      // Parse out the image data prefix
      let dataStr = "data:image/png;base64,";
      let base64ImageText = val ? val.trim() : "";
      base64ImageText.startsWith(dataStr) &&
        elmt.setValue(base64ImageText.substring(dataStr.length));
    }

    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.manualEntryAccuracyTest[
          editMethod
        ](form.values);
        if (isSuccess) {
          let dataTable =
            this.props.refreshDataTable || "manualEntryAccuracyTests";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = (
            <Redirect to={this.props.redirect || "manualEntryAccuracyTests"} />
          );
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='manualEntryConfigForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          validators={{
            singleCharacter: (val) => {
              console.log("validator singleCharacter", val);
              return val && val.length === 1
                ? true
                : { error: "Value must be single character." };
            },
            uniqueActualValue: (val, options, elmt, form) => {
              console.log("validator uniqueActualValue", val);
              return val === form.elmts.value.value
                ? { error: "Values cannot be equal." }
                : true;
            },
          }}
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='mode' />
          <TextInputField
            name='imageBase64'
            label='Image (Base64)'
            required={true}
            multiline
            autoSize
            onChangeText={this.handleChangeBase64ImageText}
          />
          <FieldGroup>
            <TextInputField
              name='actualValue'
              label='Actual Value'
              required={true}
              validators={["singleCharacter", "uniqueActualValue"]}
            />
            <TextInputField
              name='value'
              label='Test Value'
              required={true}
              validators={["singleCharacter"]}
            />
          </FieldGroup>
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  permissionItemRow: {
    flex: 1,
    flexDirection: "row",
  },
  permissionItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  permissionItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
