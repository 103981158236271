import R14 from "../core";
export default class R14LicenseDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "r14License",
    });
    this.PRODUCT_INVENTR = "INVENTR";
    this.PRODUCT_TELETYPE = "TELETYPE";
    this.STATE_OUT_OF_SYNC = "OUT_OF_SYNC";
    this.STATE_ERROR = "ERROR";
    this.STATE_ACTIVE = "ACTIVE";
    this.STATE_INACTIVE = "INACTIVE";
  }
  async authAdminConfig({ key, adminSecretKey }) {
    let res = await this.api.mutate(
      `
      mutation AuthR14LicenseAdminConfig($input: AuthR14LicenseAdminConfigInput!) {
        authR14LicenseAdminConfig(input: $input){
          accessToken
          adminConfig {
            active
            expiresIn
            createdAt
            description
          }
        }
      }`,
      {
        input: { key, adminSecretKey },
      }
    );
    if (res.errors && res.errors.length) {
      throw new Error(res.errors[0]);
    }
    return res.data.authR14LicenseAdminConfig;
  }
  async createAdminConfig(key, accessToken, values) {
    console.log("createAdminConfig", { key, accessToken, values });
    let res = await this.api.mutate(
      `
      mutation CreateR14LicenseAdminConfig($input: CreateR14LicenseAdminConfigInput!) {
        createR14LicenseAdminConfig(input: $input){
          success
          adminConfig {
            active
            expiresIn
            createdAt
            description
          }
        }
      }`,
      {
        input: {
          key,
          description: values.description || null,
          active: values.active || false,
          expiresIn: parseInt(values.expiresIn),
        },
      },
      { accessToken }
    );
    if (res.errors && res.errors.length) {
      throw new Error(res.errors[0]);
    }
    if (
      !res.data.createR14LicenseAdminConfig ||
      !res.data.createR14LicenseAdminConfig.success ||
      !res.data.createR14LicenseAdminConfig.adminConfig
    )
      throw new Error("Unknown error while saving admin config.");
    return res.data.createR14LicenseAdminConfig.adminConfig;
  }
  async deleteAdminConfig(key, accessToken) {
    console.log("deleteAdminConfig", { key, accessToken });
    let res = await this.api.mutate(
      `
      mutation DeleteR14LicenseAdminConfig($key: ID!) {
        deleteR14LicenseAdminConfig(key: $key){
          success
          error
        }
      }`,
      {
        key,
      },
      { accessToken }
    );
    if (res.errors && res.errors.length) {
      throw new Error(res.errors[0]);
    }
    if (
      !res.data.deleteR14LicenseAdminConfig ||
      !res.data.deleteR14LicenseAdminConfig.success
    )
      throw new Error("Unknown error while saving admin config.");
    return true;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let formDataValues = {};
    if (uid) {
      let res = await this.get(
        uid,
        `
        uid
        name
        description
        product
        expiresAt
        active
        syncTimeInterval
        syncFailureTimeInterval
    `
      );
      formDataValues = res;
    } else {
      let config = await this.generateConfig();
      formDataValues = {
        ...config,
        active: true,
      };
    }
    return {
      values: formDataValues,
      productSelections: [
        { value: this.PRODUCT_INVENTR, label: "Inventr" },
        { value: this.PRODUCT_TELETYPE, label: "Teletype" },
      ],
    };
  }
  async generateConfig() {
    let values = {
      clientUid: this.dm.userSession.clientUid,
    };
    let res = null;
    try {
      res = await this.api.mutate(
        `
        mutation GenerateR14LicenseConfig($input: GenerateR14LicenseConfigInput!) {
          generateR14LicenseConfig(input: $input){
            key
            secretKey
            adminSecretKey
            accessTokenSecret
            verifyUrl
          }
        }`,
        {
          input: values,
        }
      );
    } catch (err) {
      return false;
    }
    if (res.errors && res.errors.length) {
      return false;
    }
    return res.data.generateR14LicenseConfig;
  }
  parseSubmitValues(values) {
    let ret = { ...values };
    ret.clientUid = this.dm.userSession.clientUid;
    // ret.config = [];
    if (values.syncTimeInterval)
      ret.syncTimeInterval = parseInt(values.syncTimeInterval);
    if (values.syncFailureTimeInterval)
      ret.syncFailureTimeInterval = parseInt(values.syncFailureTimeInterval);
    return ret;
  }
  calculateState(r14License) {
    return r14License.active ? this.STATE_ACTIVE : this.STATE_INACTIVE;
  }
  getStateLabel(state) {
    let color = "n/a";
    switch (state) {
      case this.STATE_OUT_OF_SYNC:
        color = "Out of Sync";
        break;
      case this.STATE_ERROR:
        color = "Error";
        break;
      case this.STATE_ACTIVE:
        color = "Active";
        break;
      case this.STATE_INACTIVE:
        color = "Inactive";
        break;
    }
    return color;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_OUT_OF_SYNC:
      case this.STATE_ERROR:
        color = "yellow";
        break;
      case this.STATE_ACTIVE:
        color = "green";
        break;
      case this.STATE_INACTIVE:
        color = "red";
        break;
    }
    return color;
  }
}
