import React from "react";
import R14, { Colors, StyleSheet, Text, Image, View } from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class R14DeploymentTable extends React.Component {
    constructor(props) {
      super(props);
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions("admin", "r14Deployment");
      this.r14DeploymentDomain = this.props.app.dm.r14Deployment;
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      // product: types.Enum(["R14", "TELETYPE"]).isRequired,
      // clientUid: types.ForeignID.isRequired,
      // key: types.String.isRequired,
      // secretKey: types.String.encrypt.isRequired,
      // adminSecretKey: types.String.encrypt.isRequired,
      // verifyUrl: types.String.isRequired,
      let res = await this.props.app.dm.r14Deployment.find(
        `
        uid
        uuid
        name
        url
        product
        active
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.props.app.dm.r14Deployment}
          searchable
          onRowPress={false}
          title='R14 Deployments'
          name='r14Deployments'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            url: {
              label: "Url",
              sortable: true,
            },
            uuid: {
              label: "UUID",
              sortable: true,
            },
            // active: {
            //   label: "State",
            //   sortable: true,
            //   renderer: ({ row }) => {
            //     let state = this.r14DeploymentDomain.calculateState(row);
            //     return (
            //       <StateIndicatorText
            //         label={this.r14DeploymentDomain.getStateLabel(state)}
            //         color={this.r14DeploymentDomain.getStateIndicatorColor(
            //           state
            //         )}
            //       />
            //     );
            //   },
            // },
            // key: {
            //   label: "Key",
            //   sortable: true,
            // },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => {
            return <Text style={[styles.cellText]}>{cell}</Text>;
          }}
          pageLoader={this.pageLoader}
          initialSortColumnName='name'
          initialSortDirection='asc'
          perms={this.perms}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {},
});
