import React from "react";
import R14, {
  Colors,
  Calendar,
  View,
  Button,
  DatePickerModal,
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
  Image,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class UserDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.userDomain = this.props.app.dm.user;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.user.find(
        `
          uid
          name
          email
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("users");
    }
    cellRenderer({ cell, columnName, row, columns }) {
      let content = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "profileImage":
          content = cell && cell.key ? (
            <Image
              resizeMode='cover'
              height={50}
              width={50}
              source={{ key: cell.key }}
            />
          ) : null;
          break;
        default:
          content = <Text style={style}>{cell}</Text>;
      }
      return (
        <React.Fragment>
          {content}
          {columns.email.collapsed && (
            <ChipGroup style={styles.cellChipGroup}>
              {columns.email.collapsed && <Chip label={row.email} />}
            </ChipGroup>
          )}
        </React.Fragment>
      );
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    handleRowPress(row) {
      this.props.app.nav.to("user", { uid: row.uid });
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.user.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleSearchPress() {}
    async updateUserState(uid, command) {
      this.props.app.ui.progressIndicator.show();
      let user = await this.userDomain.updateState(uid, command);
      await this.dataTable.refresh();
      this.props.app.ui.progressIndicator.hide();
    }
    render() {
      let t = new Date();
      let startDate = new Date();
      let endDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setMonth(endDate.getMonth() + 1);

      // return (
      //   <Button
      //     title="Test Query"
      //     onPress={()=>this.props.app.dm.dev.test()}
      //   />
      // );
      return (
        <DataTable
          name='users'
          columns={{
            // profileImage: {
            //   label: "image",
            // },
            name: {
              label: "Name",
              sortable: true,
            },
            email: {
              label: "Email",
              sortable: true,
              collapse: ({ width }) => {
                return width <= 640;
              },
            },
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Users"}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='type'
          initialSortDirection='asc'
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              <PopUpMenuItem
                icon='add'
                iconSize='small'
                label='Add User'
                to='userAdd'
                key='add'
              />
            </PopUpMenu>,
          ]}
          rowControlsRight={(row, dataTable) => {
            return [
              <IconButton
                icon='edit'
                size='small'
                key='edit'
                onPress={() =>
                  this.props.app.nav.to("userEdit", {
                    uid: row.uid,
                  })
                }
              />,
              // <PopUpMenu
              //   controlIcon='dotsHorizontal'
              //   controlIconSize='small'
              //   key='userMenu'
              //   direction='downLeft'
              // >
              //   <PopUpMenuItem
              //     label='Permissions'
              //     icon='securityAccount'
              //     key='permissions'
              //     onPress={() => this.props.app.nav.to('userPermissionsEdit',{
              //       uid: row.uid
              //     })}
              //   />
              // </PopUpMenu>
            ];
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
});
