import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  View,
  DataTable,
  Chip,
  ChipGroup,
  Image,
} from "../core";
import HeaderLabelSelectMenu from "./HeaderLabelSelectMenu";

export default R14.connect(
  class ManualEntryPipelineMetricsDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
      this.state = {
        parentType: {
          label: "Document Sets",
          value:
            this.props.app.dm.manualEntryPipeline
              .METRIC_PARENT_TYPE_DOCUMENT_SET,
        },
      };
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let res = await this.props.app.dm.manualEntryPipeline.fetchMetrics(
        this.props.manualEntryPipeline.uid,
        { page, rowsPerPage }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryPipelineMetrics");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      const defaultNamepaceKey = "__r14__default__NONAMESPACE__";
      let getMetricValueByType = (type, metrics) => {
        if (!metrics) return 0;
        let value = 0;
        console.log("CHECK STATE PARENT TYPE", this.state.parentType);
        for (let metric of metrics) {
          if (
            metric.type === type &&
            metric.parentType === this.state.parentType.value
          ) {
            value = metric.value || 0;
            break;
          }
        }
        return value;
      };
      let queueNamespaceMetricsMap = row.queueNamespaceMetrics
        ? row.queueNamespaceMetrics
            .filter((val) => val.parentType === this.state.parentType.value)
            .reduce((acc, val) => {
              const queueNamespace = val.queueNamespace || defaultNamepaceKey;
              if (!acc[queueNamespace]) acc[queueNamespace] = [];
              acc[queueNamespace].push(val);
              return acc;
            }, {})
        : {};

      let queueNamespaceKeys = Object.keys(queueNamespaceMetricsMap);
      // Don't show only default, it is the same as the totals
      if (
        !queueNamespaceKeys.length ||
        (queueNamespaceKeys.length === 1 &&
          queueNamespaceKeys[0] === defaultNamepaceKey)
      )
        queueNamespaceMetricsMap = null;

      console.log("CHECK COL NAME", queueNamespaceMetricsMap);
      let queueNamespaceContent = null;
      switch (columnName) {
        case "totalComplete":
        case "totalTimeout":
          queueNamespaceContent = queueNamespaceMetricsMap ? (
            <View style={styles.queueNamespaceContent}>
              {Object.keys(queueNamespaceMetricsMap).map((key) => (
                <View key={key}>
                  <Text style={[styles.cellText, styles.queueNamespaceText]}>
                    {getMetricValueByType(
                      columnName === "totalTimeout"
                        ? this.props.app.dm.manualEntryPipeline
                            .METRIC_TYPE_TOTAL_TIMEOUT
                        : this.props.app.dm.manualEntryPipeline
                            .METRIC_TYPE_TOTAL_COMPLETE,
                      queueNamespaceMetricsMap[key]
                    )}
                  </Text>
                </View>
              ))}
            </View>
          ) : null;
          ret = (
            <View>
              <Text style={[styles.cellText, styles.callValueText]}>
                {getMetricValueByType(
                  columnName === "totalTimeout"
                    ? this.props.app.dm.manualEntryPipeline
                        .METRIC_TYPE_TOTAL_TIMEOUT
                    : this.props.app.dm.manualEntryPipeline
                        .METRIC_TYPE_TOTAL_COMPLETE,
                  row.metrics
                )}
              </Text>
              {queueNamespaceContent}
            </View>
          );
          break;
        case "dateKey":
          queueNamespaceContent = queueNamespaceMetricsMap ? (
            <View style={styles.queueNamespaceContent}>
              {" "}
              {Object.keys(queueNamespaceMetricsMap).map((key) => (
                <View key={key}>
                  <Text style={[styles.cellText, styles.queueNamespaceText]}>
                    {!key || defaultNamepaceKey === key ? "Default" : key}
                  </Text>
                </View>
              ))}
            </View>
          ) : null;
          ret = (
            <View>
              <View>
                <Text style={[styles.cellText, styles.callValueText]}>{`${
                  content || 0
                }`}</Text>
              </View>
              {queueNamespaceContent}
            </View>
          );
          break;
        default:
          ret = <Text style={style}>{`${content || 0}`}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.dateKey;
    }
    render() {
      return (
        <DataTable
          name={this.props.name || "manualEntryPipelineMetrics"}
          columns={{
            // profileImage: {
            //   label: "image",
            // },
            dateKey: {
              label: "Date",
            },
            totalComplete: {
              label: "Complete",
            },
            totalTimeout: {
              label: "Timeout",
            },
            // total: {
            //   label: "Total",
            // },
          }}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={5}
          headerTitle={this.props.title || "Daily Stats"}
          headerControlsRight={[
            <HeaderLabelSelectMenu
              key="headerLabel"
              value={this.state.parentType}
              style={styles.headerLabelSelectMenu}
              searchable={false}
              headerLabelTextStyle={styles.headerLabelTextStyle}
              items={[
                {
                  label: "Document Sets",
                  value:
                    this.props.app.dm.manualEntryPipeline
                      .METRIC_PARENT_TYPE_DOCUMENT_SET,
                },
                {
                  label: "Documents",
                  value:
                    this.props.app.dm.manualEntryPipeline
                      .METRIC_PARENT_TYPE_DOCUMENT,
                },
                {
                  label: "Fields",
                  value:
                    this.props.app.dm.manualEntryPipeline
                      .METRIC_PARENT_TYPE_FIELD,
                },
                {
                  label: "Field Groups",
                  value:
                    this.props.app.dm.manualEntryPipeline
                      .METRIC_PARENT_TYPE_FIELD_GROUP,
                },
              ]}
              onValueChange={(value) => {
                this.setState({ parentType: value });
              }}
            />,
          ]}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  headerLabelSelectMenu: {},
  headerLabelTextStyle: {
    fontWeight: 400,
    fontSize: 16,
  },
  cellValueText: {
    fontWeight: 500,
  },
  queueNamespaceContent: {
    marginTop: 4,
  },
  queueNamespaceText: {
    fontWeight: 300,
    fontSize: 14,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
});
