import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  FieldGroup,
  StyleSheet,
} from "../core";
import EditForm from "../components/EditForm";
import EntitySelectMenuField from "../components/EntitySelectMenuField";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class VirtualWorkspaceEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.virtualWorkspaceRegionItemLoader =
        this.virtualWorkspaceRegionItemLoader.bind(this);
      this.virtualWorkspaceImageItemLoader =
        this.virtualWorkspaceImageItemLoader.bind(this);
      this.cloudAccessKeyItemLoader = this.cloudAccessKeyItemLoader.bind(this);
      this.projectItemLoader = this.projectItemLoader.bind(this);
      this.handleRegionValueChange = this.handleRegionValueChange.bind(this);
      this.virtualWorkspaceRegionUid =
        this.props.formData.values.virtualWorkspaceRegion &&
        this.props.formData.values.virtualWorkspaceRegion.value
          ? this.props.formData.values.virtualWorkspaceRegion.value
          : null;
    }
    async virtualWorkspaceRegionItemLoader(filters = {}) {
      filters.resourceType =
        this.props.app.dm.virtualWorkspace.RESOURCE_TYPE_AWS_APPSTREAM_2;
      return await this.props.app.dm.virtualWorkspaceRegion.fetchSelections(
        filters,
        {
          resultsPerPage: 50,
        }
      );
    }
    async virtualWorkspaceImageItemLoader(filters = {}) {
      filters.virtualWorkspaceRegionUid = this.virtualWorkspaceRegionUid;
      filters.resourceType =
        this.props.app.dm.virtualWorkspace.RESOURCE_TYPE_AWS_APPSTREAM_2;
      return await this.props.app.dm.virtualWorkspaceImage.fetchSelections(
        filters,
        {
          resultsPerPage: 50,
        }
      );
    }
    async projectItemLoader(filters = {}) {
      filters.type = this.props.app.dm.project.TYPE_VW;
      return await this.props.app.dm.project.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    async cloudAccessKeyItemLoader(filters = {}) {
      filters.type = this.props.app.dm.cloudAccessKey.TYPE_AWS;
      return await this.props.app.dm.cloudAccessKey.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    async handleRegionValueChange({ value }, elmt, form) {
      form.elmts.virtualWorkspaceImage.disabled = false;
      form.elmts.virtualWorkspaceImage.value = null;
      form.elmts.virtualWorkspaceImage.resetAutoLoad();
      this.virtualWorkspaceRegionUid = value;
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='virtualWorkspace'
          redirect={this.props.redirect || "virtualWorkspaces"}
          refreshDataTable='virtualWorkspaces'
          validators={{
            minCapacity: (val) => {
              let error = null;
              if (val < 1) error = "Must be greater than 1";
              return error ? { error } : true;
            },
            maxCapacity: (val, options, elmt, form) => {
              let error = null;
              if (val < form.elmts.minCapacity.value)
                error = "Must be greater or equal to minimum";
              return error ? { error } : true;
            },
          }}
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
          <SelectMenuField
            key='project'
            name='project'
            label='Project'
            searchable
            autoLoad
            itemLoader={this.projectItemLoader}
            required='Please select a project.'
          />
          <SelectMenuField
            key='cloudAccessKey'
            name='cloudAccessKey'
            label='Cloud Access Key'
            searchable
            autoLoad
            itemLoader={this.cloudAccessKeyItemLoader}
            required='Please select a cloud access key.'
          />
          <FieldGroup>
            <SelectMenuField
              key='virtualWorkspaceRegion'
              name='virtualWorkspaceRegion'
              label='Region'
              searchable
              autoLoad
              itemLoader={this.virtualWorkspaceRegionItemLoader}
              onValueChange={this.handleRegionValueChange}
              required='Please select a region.'
            />
            <SelectMenuField
              key='virtualWorkspaceImage'
              name='virtualWorkspaceImage'
              label='Image'
              searchable
              autoLoad
              disabled={this.virtualWorkspaceRegionUid ? false : true}
              itemLoader={this.virtualWorkspaceImageItemLoader}
              required='Please select an image.'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='stackName'
              label='Stack'
              required='Please enter a stack.'
            />
            <TextInputField
              name='fleetName'
              label='Fleet'
              required='Please enter a fleet.'
            />
          </FieldGroup>
          <FieldGroup label='Capacity'>
            <TextInputField
              name='minCapacity'
              label='Minimum'
              required='Please enter a Minimum Capacity.'
              validator={["positiveInt", "minCapacity"]}
            />
            <TextInputField
              name='maxCapacity'
              label='Maximum'
              required='Please enter a Maximum Capacity.'
              validator={["positiveInt", "maxCapacity"]}
            />
            <TextInputField
              name='desiredCapacity'
              label='Desired'
              required='Please enter a Desired Capacity.'
              validator={["positiveInt", "maxCapacity"]}
            />
          </FieldGroup>
          <FieldGroup label='Timeout'>
            <TextInputField
              name='disconnectTimeout'
              label='Disconnect'
              required='Please enter a Disconnect.'
              validator={["positiveInt"]}
              helper='Seconds'
            />
            <TextInputField
              name='idleDisconnectTimeout'
              label='Idle Disconnect'
              required='Please enter a Idle Disconnect.'
              validator={["positiveInt"]}
              helper='Seconds'
            />
          </FieldGroup>
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({});
