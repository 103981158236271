import React from "react";
import R14, {
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  SelectMenuField,
  TextInputField,
  Redirect,
  StyleSheet,
  SectionHeader,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";

export default R14.connect(
  class ProjectPipelineEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.handleAfterSave = this.handleAfterSave.bind(this);
      this.state = {
        connectionType: this.props.formData.values.connectionType,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleAfterSave(isSuccess, form, options = {}) {
      if (
        isSuccess &&
        this.isEditMode &&
        this.props.app.ui.pipeline.exists(this.props.formData.values.uid)
      ) {
        let pipeline = await this.props.app.ui.pipeline.instance(
          this.props.formData.values.uid
        );
        await pipeline.refresh();
      }
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='pipeline'
          redirect={this.props.redirect || "projectPipelines"}
          refreshDataTable={this.props.refreshDataTable || "projectPipelines"}
          onAfterSave={this.handleAfterSave}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectUid' />
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              helper='Required'
              required='Enter Name'
            />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              validator='key'
              helper='Required'
            />
          </FieldGroup>
          <SelectMenuField
            useValuesOnly
            name='userUids'
            multiple
            label='Users'
            items={this.props.formData.userSelections}
            required='Please select at least 1 user.'
            helper='Required'
          />
          <TextInputField name='description' label='Description' />
          <SectionHeader style={[styles.sectionHeader]} level={3}>
            Redis Instance
          </SectionHeader>
          <FieldGroup>
            <TextInputField name='redisHost' label='Host' autoComplete={false}/>
            <TextInputField
              name='redisPassword'
              label='Password'
              secure
              autoComplete={false}
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='redisPort'
              label='Port'
              validator={"positiveInt"}
            />
            <SwitchField name='redisTls' label='Use TLS' />
          </FieldGroup>

          <SectionHeader style={[styles.sectionHeader]} level={3}>
            Appearance
          </SectionHeader>
          <FieldGroup>
            <TextInputField
              name='rows'
              label='Rows'
              validator={"positiveInt"}
            />
            <TextInputField
              name='columns'
              label='Columns'
              validator={"positiveInt"}
            />
          </FieldGroup>
        </EditForm>
      );
    }
  }
);
let styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    paddingBottom: 16,
    fontWeight: 500,
  },
});
