import React from "react";
import R14, {
  ScreenLayout,
  View,
  Text,
  Colors,
  StyleSheet,
  Button,
  ActivityIndicator,
  FadeView,
  Icon,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  ScrollView,
  Switch,
  SelectMenu,
  DraggableContainer,
  AnimatedView,
  SideSheet,
  Image,
  AreaSelectionView,
  ZoomableView,
  FloatingActionButton,
} from "../core";
// import DraggableContainer from "../components/DraggableContainer";
import HeaderLabelSelectMenu from "../components/HeaderLabelSelectMenu";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import DocumentTemplateImage from "../components/DocumentTemplateImage";
import DocumentTemplateAnnotation from "../components/DocumentTemplateAnnotation";
import DocumentTemplateBottomSheet from "../components/DocumentTemplateBottomSheet";

export default R14.connect(
  class ProjectDocumentTemplateScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleScreenLayout = this.handleScreenLayout.bind(this);
      this.handleLayout = this.handleLayout.bind(this);
      this.handleZoomIn = this.handleZoomIn.bind(this);
      this.handleZoomOut = this.handleZoomOut.bind(this);
      this.handleSelectArea = this.handleSelectArea.bind(this);
      this.handleCreateAnnotationPress = this.handleCreateAnnotationPress.bind(
        this
      );
      this.handleCreateFieldPress = this.handleCreateFieldPress.bind(this);
      this.handleCancelCreateFieldPress = this.handleCancelCreateFieldPress.bind(
        this
      );
      this.handleAnnotationPress = this.handleAnnotationPress.bind(this);
      this.documentTemplatesItemLoader = this.documentTemplatesItemLoader.bind(
        this
      );

      this.perms = {
        documentTemplate: this.props.app.dm.user.getPermissions(
          this.projectKey,
          "documentTemplate"
        ),
      };
      this._zoomableRef = React.createRef();
    }
    componentWillUnmount() {
      this.documentTemplate.remove();
    }
    get documentTemplate() {
      return this.props.documentTemplate;
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(
        this.documentTemplate.project.type
      );
    }
    handleScreenLayout(event) {
      this.documentTemplate.updateScreenLayout(event.nativeEvent.layout);
    }
    handleLayout(event) {
      this.documentTemplate.updateLayout(event.nativeEvent.layout);
    }
    handleZoomIn() {
      this.documentTemplate.zoomIn();
    }
    handleZoomOut() {
      this.documentTemplate.zoomOut();
    }
    handleSelectArea(layout) {
      this.documentTemplate.handleSelectAnnotationArea(layout);
    }
    handleCreateAnnotationPress(type) {
      this.documentTemplate.setAnnotationMode(type);
    }
    handleCreateFieldPress() {
      if (!this.documentTemplate.annotationLayout) {
        this.props.app.ui.snackBar.show({
          message: "Please select a field area.",
          variant: "error",
        });
      } else
        this.props.app.nav.to("projectDocumentTemplateAnnotationCreate", {
          uid: this.documentTemplate.project.uid,
          key: this.props.app.dm.project.getKeyByType(
            this.documentTemplate.project.type
          ),
          documentTemplateAnnotationTypeKey: this.props.app.dm.documentTemplateAnnotation.getKeyByType(
            this.documentTemplate.annotationType
          ),
          documentTemplateUid: this.documentTemplate.uid,
        });
    }
    handleCancelCreateFieldPress() {
      this.documentTemplate.disableAnnotationMode();
    }
    handleAnnotationPress(annotation) {
      console.log("handleAnnotationPress", annotation);
    }
    async documentTemplatesItemLoader(filters) {
      let filter = {
        projectUid: { eq: this.documentTemplate.project.uid },
      };
      if (filters.search) {
        filter.search = { like: `%${filters.search}%` };
      }
      let documentTemplates = await this.props.app.dm.documentTemplate.find(
        `
        uid
        name
      `,
        {
          page: 1,
          resultsPerPage: 20,
          filter: filter,
          sort: [
            {
              field: "name",
              order: "ASC",
            },
          ],
          totalCount: false,
        }
      );
      return documentTemplates && documentTemplates.nodes
        ? documentTemplates.nodes.map((val) => ({
            value: val.uid,
            label: val.name,
          }))
        : [];
    }
    createDynamicStyles() {
      let scale = this.documentTemplate.state.scale;
      let imageHeight = this.documentTemplate.image.height;
      let imageWidth = this.documentTemplate.image.width;
      let dynamicStyles = {};
      dynamicStyles.areaSelectionView = {
        height: imageHeight * scale * this.documentTemplate.state.zoomLevel,
        width: imageWidth * scale * this.documentTemplate.state.zoomLevel,
      };
      dynamicStyles.scrollView = {
        ...StyleSheet.padding(this.documentTemplate.padding),
      };
      return StyleSheet.create(dynamicStyles);
    }
    renderZoomControls() {
      return [
        <IconButton
          icon='magnifyPlus'
          key='zoomIn'
          size='small'
          style={[
            styles.zoomButton,
            this.documentTemplate.state.zoomLevel > 1 &&
              styles.zoomButtonActive,
          ]}
          onPress={this.handleZoomIn}
        />,
        <IconButton
          icon='magnifyMinus'
          key='zoomOut'
          size='small'
          style={[
            styles.zoomButton,
            this.documentTemplate.state.zoomLevel < 1 &&
              styles.zoomButtonActive,
          ]}
          onPress={this.handleZoomOut}
        />,
      ];
    }

    renderAnnotations() {
      let ret = [];
      this.documentTemplate.annotations.forEach((annotation) =>
        ret.push(
          <DocumentTemplateAnnotation
            key={`annotation${annotation.uid}`}
            documentTemplate={this.documentTemplate}
            documentTemplateAnnotation={annotation}
            onPress={this.handleAnnotationPress}
          />
        )
      );
      return ret;
    }
    render() {
      let dynamicStyles = this.createDynamicStyles();
      return (
        <ProjectScreenLayout
          project={this.documentTemplate.project}
          onLayout={this.handleScreenLayout}
          // scrollEnabled
          headerStyle={[styles.header]}
          headerContentStyle={[
            ,
            this.documentTemplate.state.annotate &&
              styles.headerContentAnnotate,
          ]}
          headerTitle={
            this.documentTemplate.state.annotate ? "Select an area below" : null
          }
          HeaderTitleComponent={
            this.documentTemplate.state.annotate
              ? null
              : [
                  <HeaderLabelSelectMenu
                    key='headerLabel'
                    value={{
                      label: this.documentTemplate.name,
                      value: this.documentTemplate.uid,
                    }}
                    style={styles.headerLabelSelectMenu}
                    searchable
                    // searchLabel=''
                    itemLoader={this.documentTemplatesItemLoader}
                    onValueChange={(value) => {
                      value.value !== this.documentTemplate.uid &&
                        this.props.app.nav.to("projectDocumentTemplate", {
                          uid: this.documentTemplate.project.uid,
                          key: this.props.app.dm.project.getKeyByType(
                            this.documentTemplate.project.type
                          ),
                          documentTemplateUid: value.value,
                        });
                    }}
                  />,
                ]
          }
          headerControlsRight={
            this.documentTemplate.state.annotate
              ? [
                  ...this.renderZoomControls(),
                  <Button
                    title='Cancel'
                    key='cancel'
                    variant='text'
                    style={styles.cancelButton}
                    onPress={this.handleCancelCreateFieldPress}
                  />,
                  <Button
                    title='Create'
                    key='create'
                    style={styles.createButton}
                    // variant='text'
                    onPress={this.handleCreateFieldPress}
                  />,
                ]
              : [
                  ...this.renderZoomControls(),
                  <PopUpMenu
                    controlIcon='dotsVertical'
                    key='adminMenu'
                    direction='downLeft'
                  >
                    {this.perms.documentTemplate.edit && (
                      <PopUpMenuItem
                        label='Edit'
                        icon='edit'
                        key='edit'
                        onPress={() =>
                          this.props.app.nav.to("projectDocumentTemplateEdit", {
                            uid: this.documentTemplate.project.uid,
                            key: this.props.app.dm.project.getKeyByType(
                              this.documentTemplate.project.type
                            ),
                            documentTemplateUid: this.documentTemplate.uid,
                          })
                        }
                      />
                    )}
                    {this.perms.documentTemplate.edit && (
                      <PopUpMenuItem
                        label='Add Field'
                        icon='add'
                        key='add'
                        onPress={() =>
                          this.handleCreateAnnotationPress(
                            this.props.app.dm.documentTemplateAnnotation
                              .TYPE_FIELD
                          )
                        }
                      />
                    )}
                    {this.perms.documentTemplate.edit && (
                      <PopUpMenuItem
                        label='Add Anchor'
                        icon='add'
                        key='add'
                        onPress={() =>
                          this.handleCreateAnnotationPress(
                            this.props.app.dm.documentTemplateAnnotation
                              .TYPE_ANCHOR
                          )
                        }
                      />
                    )}
                  </PopUpMenu>,
                ]
          }
          style={styles.screenLayout}
          // floatingActionButton={{
          //   onPress: () =>
          //     this.handleCreateAnnotationPress(
          //       this.props.app.dm.documentTemplateAnnotation.TYPE_FIELD
          //     ),
          //   icon: "add",
          //   animate: true,
          //   visible: !this.documentTemplate.state.annotate,
          // }}
          //floatingActionButton={this.renderFloatingActionButton()}
        >
          <View style={styles.content}>
            <ScrollView
              style={styles.scrollView}
              contentStyle={[
                styles.scrollViewContent,
                dynamicStyles.scrollViewContent,
              ]}
              onLayout={this.handleLayout}
              overflow='auto'
            >
              <ZoomableView
                level={this.documentTemplate.state.zoomLevel}
                height={
                  this.documentTemplate.image.height *
                  this.documentTemplate.state.scale
                }
                width={
                  this.documentTemplate.image.width *
                  this.documentTemplate.state.scale
                }
                duration={150}
              >
                <View
                  name='documentTemplate'
                  style={[
                    styles.draggableContainer,
                    //dynamicStyles.draggableContainer,
                  ]}
                >
                  {this.renderAnnotations()}
                  <DocumentTemplateImage
                    documentTemplate={this.documentTemplate}
                  />
                </View>
              </ZoomableView>

              {/* onPress: () =>
              this.handleCreateAnnotationPress(
                this.props.app.dm.documentTemplateAnnotation.TYPE_FIELD
              ),
            icon: "add",
            animate: true,
            visible: !this.documentTemplate.state.annotat */}

              <FloatingActionButton
                onPress={() =>
                  this.handleCreateAnnotationPress(
                    this.props.app.dm.documentTemplateAnnotation.TYPE_FIELD
                  )
                }
                icon='add'
                animate={true}
                visible={!this.documentTemplate.state.annotate}
              />
              {this.documentTemplate.state.annotate && (
                <AreaSelectionView
                  style={[
                    styles.areaSelectionView,
                    dynamicStyles.areaSelectionView,
                  ]}
                  onSelect={this.handleSelectArea}
                />
              )}
            </ScrollView>
          </View>

          <DocumentTemplateBottomSheet
            documentTemplate={this.documentTemplate}
          />
          {/* <View style={styles.zoom}>
            <IconButton
              icon='add'
              size='small'
              style={[
                styles.zoomInButton,
                this.documentTemplate.state.zoomLevel > 1 && styles.zoomButtonActive,
              ]}
              onPress={this.handleZoomIn}
            />
            <IconButton
              icon='minus'
              size='small'
              style={[
                styles.zoomOutButton,
                this.documentTemplate.state.zoomLevel < 1 && styles.zoomButtonActive,
              ]}
              onPress={this.handleZoomOut}
            />
          </View> */}
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenLayout: {
    ...StyleSheet.margin(0),
    position: "relative",
  },
  header: {
    marginBottom: 0,
  },
  headerContentAnnotate: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  content: {
    flex: 1,
    position: "relative",
    overflow: "hidden"
  },
  scrollViewContent: {
    // position: "rel;ative"
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    ...StyleSheet.padding(32),
  },
  scrollView: {
    // marginTop: -16
  },
  draggableContainer: {
    flex: 0,
    elevation: 12,
    // ...StyleSheet.margin(16, 32, 32, 32),
    // overflow: "hidden",
  },
  areaSelectionView: {
    position: "absolute",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: StyleSheet.color(Colors.primaryLight).rgba(0.4),
    //backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.05),
  },
  zoomButton: {
    borderRadius: 16,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  zoomButtonActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  createButton: {
    marginRight: 8
  },
  cancelButton: {
    marginRight: 4
  }
});
