import React from "react";
import R14, { View, SectionHeader, StyleSheet, Colors } from "../core";

export default class DashboardCardSectionRow extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <View style={[styles.dashboardCardSectionRow, this.props.style]}>
        <SectionHeader style={styles.dashboardCardSectionRowHeader} level={2}>
          {this.props.title}
        </SectionHeader>
        <View
          style={[
            styles.dashboardCardSectionRowContent,
            this.props.contentStyle,
          ]}
        >
          {this.props.children}
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  dashboardCardSectionRow: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    borderRadius: 4,
    width: "100%",
    ...StyleSheet.padding(8),
    ...StyleSheet.margin(12, -8, 0, -8),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.margin(8, 0, 0, 0),
        };
    },
  },
  dashboardCardSectionRowContent: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
  },
  dashboardCardSectionRowHeader: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: -12,
    paddingBottom: 0,
  },
});
