import React from "react";
import R14, { StyleSheet, ScreenLayout, Text, IconButton } from "../core";
import Dashboard from "../components/Dashboard";
import DashboardWidget from "../components/DashboardWidget";
import DashboardCard from "../components/DashboardCard";
import DashboardCardSection from "../components/DashboardCardSection";
import DashboardCardSectionRow from "../components/DashboardCardSectionRow";
import ManualEntryUserDataTable from "../components/ManualEntryUserDataTable";
import ManualEntryUserBatchErrorDataTable from "../components/ManualEntryUserBatchErrorDataTable";

import StateIndicatorText from "../components/StateIndicatorText";
export default R14.connect(
  class ManualEntryUserBatchScreen extends React.Component {
    constructor(props) {
      super(props);
      // this.handleRefreshPress = this.handleRefreshPress.bind(this);

      // this.state = {
      //   metrics: this.props.manualEntryConfig.metrics,
      // };
    }
    render() {
      let manualEntryUserBatch = this.props.manualEntryUserBatch;
      return (
        <ScreenLayout headerTitle={manualEntryUserBatch.name} scrollEnabled>
          <Dashboard name='manualEntryUserBatch' minWidth={352}>
            <DashboardCard title='User Batch'>
              <DashboardCardSection
                title='Status'
                style={styles.dashboardCardSection}
              >
                <StateIndicatorText
                  label={this.props.app.dm.manualEntryUserBatch.getStateLabel(
                    manualEntryUserBatch.state
                  )}
                  color={this.props.app.dm.manualEntryUserBatch.getStateIndicatorColor(
                    manualEntryUserBatch.state
                  )}
                />
              </DashboardCardSection>

              <DashboardCardSectionRow title='Stats'>
                <DashboardCardSection
                  title='Total'
                  style={styles.dashboardCardSection}
                >
                  <Text style={styles.dashboardCardSectionText}>
                    {manualEntryUserBatch.total}
                  </Text>
                </DashboardCardSection>
                <DashboardCardSection
                  title='Complete'
                  style={styles.dashboardCardSection}
                >
                  <Text style={styles.dashboardCardSectionText}>
                    {manualEntryUserBatch.totalComplete}
                  </Text>
                </DashboardCardSection>
                <DashboardCardSection
                  title='Error'
                  style={styles.dashboardCardSection}
                >
                  <Text style={styles.dashboardCardSectionText}>
                    {manualEntryUserBatch.totalErrors}
                  </Text>
                </DashboardCardSection>
              </DashboardCardSectionRow>
            </DashboardCard>

            <DashboardWidget>
              <ManualEntryUserDataTable
                title='Users'
                name='users'
                manualEntryUserBatchUid={manualEntryUserBatch.uid}
                initialRowsPerPage={5}
                initialSortColumnName="username"
                initialSortDirection="asc"
                excludeColumns={["name","lastCharacterSetCompleteAt"]}
              />
            </DashboardWidget>

            <DashboardWidget>
              <ManualEntryUserBatchErrorDataTable
                title='Errors'
                name='errors'
                manualEntryUserBatchUid={manualEntryUserBatch.uid}
                initialRowsPerPage={5}
              />
            </DashboardWidget>
          </Dashboard>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  dashboardCardSection: {
    // width: "33.3333%",
    minWidth: "33.3333%",
  },
  dashboardCardSectionText: {
    fontSize: 20,
    fontWeight: 500,
  },
});
