import React from "react";
import R14, { StyleSheet, ScreenLayout, NavigationContainer } from "../core";
import ManualEntryUserBatchDataTable from "../components/ManualEntryUserBatchDataTable";
export default R14.connect(
  class ManualEntryUserBatchesScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
    }
    render() {
      return (
        <ScreenLayout
          scrollEnabled
          floatingActionButton={
            this.perms.create
              ? {
                  to: "manualEntryUserBatchCreate",
                  icon: "add",
                }
              : null
          }
        >
          <ManualEntryUserBatchDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
