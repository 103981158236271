import React from "react";
import R14, {
  FieldGroup,
  SelectMenuField,
  TextInputField,
  StyleSheet,
  View,
  DialogFormField,
  FadeView,
  Text,
  TimePickerField,
  DatePickerField,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class EventScheduleDialogForm extends React.Component {
    constructor(props) {
      super(props);
      // this.blockItemLoader = this.blockItemLoader.bind(this);
      this.handleOccursChange = this.handleOccursChange.bind(this);
      this.handleScheduleDialogClose =
        this.handleScheduleDialogClose.bind(this);
      this.handleItemAddPress = this.handleItemAddPress.bind(this);
      this.handleItemEditPress = this.handleItemEditPress.bind(this);
      this.itemRenderer = this.itemRenderer.bind(this);
      this.itemValueFormatter = this.itemValueFormatter.bind(this);
      this.formValueFormatter = this.formValueFormatter.bind(this);
      // this.pipelineDomain = this.props.app.dm.pipeline;
      this.eventDomain = this.props.app.dm.event;
      this.dateUtils = this.props.app.utils.date;
      this.currDialogFormOccurs = null;
      this.state = {
        dialogFormOccurs: null,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleOccursChange(val) {
      this.setState({
        dialogFormOccurs: val,
      });
    }
    renderTimePickerField(name) {
      return (
        <TimePickerField
          name={name}
          label='Time'
          required='Please select a time.'
        />
      );
    }
    getLabelBySelection(selections, value) {
      let label = "N/A";
      for (let i in selections) {
        if (selections[i].value === value) {
          label = selections[i].label;
          break;
        }
      }
      return label;
    }
    handleScheduleDialogClose() {
      this.setState({
        dialogFormOccurs: null,
      });
    }
    handleItemEditPress(item) {
      this.setState({ dialogFormOccurs: this.getOccursSelectionByItem(item) });
    }
    handleItemAddPress() {
      this.setState({ dialogFormOccurs: null });
    }
    itemValueFormatter(values) {
      return this.props.app.dm.event.createScheduleNode(values);
    }
    formValueFormatter(values = null) {
      let ret = null;
      if (!values) return values;
      switch (values.occurs) {
        case this.eventDomain.OCCURS_FREQUENCY:
          ret = {
            occurs: values.frequency.type,
          };
          switch (values.frequency.type) {
            case this.eventDomain.FREQUENCY_ONCE:
              ret.date = this.dateUtils.toShortDate(
                new Date(values.frequency.at)
              );
              ret.time = this.dateUtils.toTime(new Date(values.frequency.at));
              break;
            case this.eventDomain.FREQUENCY_DAY:
              ret.time = this.dateUtils.toTime(new Date(values.frequency.at));
              ret.days = values.frequency.values;
              break;
            case this.eventDomain.FREQUENCY_DAILY:
              ret.time = this.dateUtils.toTime(new Date(values.frequency.at));
              break;
            case this.eventDomain.FREQUENCY_INTERVAL_HOUR:
            case this.eventDomain.FREQUENCY_INTERVAL_MINUTE:
            case this.eventDomain.FREQUENCY_INTERVAL_SECOND:
              ret.occurs = this.eventDomain.FREQUENCY_INTERVAL;
              ret.intervalType = values.frequency.type;
              ret.time = this.dateUtils.toTime(new Date(values.frequency.at));
              ret.interval = values.frequency.value;
              break;
          }
          break;
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD:
          ret = {
            occurs: values.occurs,
            blockIoUid: values.blockIo.uid,
            threshold: values.blockIo.threshold,
            maxInstances: values.blockIo.maxInstances || 1,
            inputItemsPerInstance: values.blockIo.inputItemsPerInstance || null,
          };
          break;
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD:
          ret = {
            occurs: values.occurs,
            blockDataKey: values.blockData.key,
            threshold: values.blockData.threshold,
            maxInstances: values.blockData.maxInstances || 1,
            inputItemsPerInstance:
              values.blockData.inputItemsPerInstance || null,
          };
          break;
      }
      return ret;
    }
    getOccursSelectionByItem(item) {
      let ret = null;
      switch (item.occurs) {
        case this.eventDomain.OCCURS_FREQUENCY:
          switch (item.frequency.type) {
            case this.eventDomain.FREQUENCY_INTERVAL_HOUR:
            case this.eventDomain.FREQUENCY_INTERVAL_MINUTE:
              ret = this.eventDomain.FREQUENCY_INTERVAL;
              break;
            default:
              ret = item.frequency.type;
              break;
          }
          break;
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD:
          ret = this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD;
          break;
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD:
          ret = this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD;
          break;
      }
      return ret;
    }
    itemRenderer(values) {
      let labelValues = [];
      switch (values.occurs) {
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD:
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD:
          let keyField = null;
          switch (values.occurs) {
            case this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD:
              labelValues.push({
                key: "blockIoUid",
                label: "Block Input",
                value: `${this.getLabelBySelection(
                  this.props.formData.blockIoInputSelections,
                  values.blockIo.uid
                )}`,
              });
              keyField = "blockIo";
              break;
            case this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD:
              labelValues.push({
                key: "blockDataKey",
                label: "Block Data Key",
                value: `${this.getLabelBySelection(
                  this.props.formData.blockDataSelections,
                  values.blockData.key
                )}`,
              });
              keyField = "blockData";
              break;
          }          
          let thresholdValue = `${values[keyField].threshold} Item(s).`;
          if (values[keyField].maxInstances) {
            thresholdValue = `${thresholdValue} Max: ${values[keyField].maxInstances}.`;
          }
          if (values[keyField].inputItemsPerInstance) {
            thresholdValue = `${thresholdValue} Per: ${values[keyField].inputItemsPerInstance}.`;
          }
          labelValues.push({
            key: "threshold",
            label: "Threshold",
            value: thresholdValue,
          });
          break;
        case this.eventDomain.OCCURS_FREQUENCY:
          switch (values.frequency.type) {
            case this.eventDomain.FREQUENCY_ONCE:
              labelValues.push({
                key: "date",
                label: "Date",
                value: this.dateUtils.toShortDate(
                  new Date(values.frequency.at)
                ),
              });
              labelValues.push({
                key: "time",
                label: "Time",
                value: this.dateUtils.toTime(new Date(values.frequency.at)),
              });
              break;
            case this.eventDomain.FREQUENCY_DAY:
              labelValues.push({
                key: "days",
                label: "Days",
                value:
                  values.frequency.values
                    .map((d) => this.props.app.utils.date.getDayLabel(d))
                    .join(", ") || "N/A",
              });
              labelValues.push({
                key: "time",
                label: "Time",
                value: this.dateUtils.toTime(new Date(values.frequency.at)),
              });
              break;
            case this.eventDomain.FREQUENCY_DAILY:
              labelValues.push({
                key: "days",
                label: "Days",
                value: "Daily",
              });
              labelValues.push({
                key: "time",
                label: "Time",
                value: this.dateUtils.toTime(new Date(values.frequency.at)),
              });

              break;
            case this.eventDomain.FREQUENCY_INTERVAL_HOUR:
            case this.eventDomain.FREQUENCY_INTERVAL_MINUTE:
            case this.eventDomain.FREQUENCY_INTERVAL_SECOND:
              labelValues.push({
                key: "interval",
                label: "Interval",
                value: `${values.frequency.value} ${this.getLabelBySelection(
                  this.props.formData.intervalTypeSelections,
                  values.frequency.type
                )}`,
              });
              labelValues.push({
                key: "time",
                label: "Base Time",
                value: this.dateUtils.toTime(new Date(values.frequency.at)),
              });
              break;
          }
          break;
      }
      return (
        <View style={styles.itemRow}>
          {labelValues.map((item) => (
            <LabelView
              style={styles.itemLabelView}
              key={item.key}
              label={item.label}
            >
              <Text>{item.value}</Text>
            </LabelView>
          ))}
        </View>
      );
    }
    getBlockDataSelections() {
      return [];
    }
    getBlockIoInputSelections() {
      let ret = this.formData.blockIoInputSelections.filter((val) => {
        return true;
      });
      return ret;
    }
    get name() {
      return this.props.name || "schedule";
    }
    renderPipelineBlockThresholdKeyField(occurs) {
      let ret = null;
      switch (occurs) {
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD:
          ret = (
            <SelectMenuField
              useValuesOnly
              name='blockIoUid'
              label='Input'
              items={this.getBlockIoInputSelections()}
              required='Please select an input.'
            />
          );
          break;
        case this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD:
          ret = (
            <SelectMenuField
              useValuesOnly
              name='blockDataKey'
              label='Data'
              items={this.formData.blockDataSelections}
              required='Please select data.'
            />
          );
          break;
      }
      return ret;
    }
    render() {
      return (
        <DialogFormField
          name={this.name}
          dialogTitle={this.props.dialogTitle || "Schedule"}
          label={this.props.label || "Schedule"}
          formName={this.props.formName}
          itemRenderer={this.itemRenderer}
          itemValueFormatter={this.itemValueFormatter}
          formValueFormatter={this.formValueFormatter}
          validator={this.props.validator || null}
          onDialogClose={this.handleScheduleDialogClose}
          onItemAddPress={this.handleItemAddPress}
          onItemEditPress={this.handleItemEditPress}
        >
          <SelectMenuField
            useValuesOnly
            name='occurs'
            label='When should this occur?'
            items={this.formData.occursSelections}
            required='Please select occurs.'
            onValueChange={this.handleOccursChange}
          />
          <FadeView visible={this.state.dialogFormOccurs ? true : false}>
            {[
              this.eventDomain.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD,
              this.eventDomain.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD,
            ].includes(this.state.dialogFormOccurs) && (
              <React.Fragment>
                <FieldGroup>
                  {this.renderPipelineBlockThresholdKeyField(
                    this.state.dialogFormOccurs
                  )}
                  <TextInputField
                    name='threshold'
                    key='threshold'
                    label='Threshold'
                    required={true}
                    validator='positiveInt'
                  />
                </FieldGroup>
                <FieldGroup>
                  <TextInputField
                    name='maxInstances'
                    label='Max Instances'
                    helper='Optional'
                    validator={"positiveInt"}
                  />
                  <TextInputField
                    name='inputItemsPerInstance'
                    label='Input Items per Instance'
                    helper='Optional'
                    validator={"positiveInt"}
                  />
                </FieldGroup>
              </React.Fragment>
            )}
            {this.state.dialogFormOccurs ===
              this.eventDomain.FREQUENCY_ONCE && (
              <FieldGroup>
                <DatePickerField
                  name='date'
                  label='Date'
                  mode='modal'
                  required='Please select a date.'
                  minDate={new Date()}
                />
                {this.renderTimePickerField("time")}
              </FieldGroup>
            )}
            {this.state.dialogFormOccurs === this.eventDomain.FREQUENCY_DAILY &&
              this.renderTimePickerField("time")}
            {this.state.dialogFormOccurs === this.eventDomain.FREQUENCY_DAY && (
              <FieldGroup>
                <SelectMenuField
                  useValuesOnly
                  name='days'
                  label='Day'
                  multiple
                  items={this.formData.daySelections}
                  required='Please select a day.'
                />
                {this.renderTimePickerField("time")}
              </FieldGroup>
            )}
            {this.state.dialogFormOccurs ===
              this.eventDomain.FREQUENCY_INTERVAL && (
              <FieldGroup>
                <SelectMenuField
                  useValuesOnly
                  name='intervalType'
                  label='Type'
                  items={this.formData.intervalTypeSelections}
                  required={true}
                />
                <TextInputField
                  name='interval'
                  key='interval'
                  label='Interval'
                  required={true}
                  validator='number'
                />
                {this.renderTimePickerField("time")}
              </FieldGroup>
            )}
          </FadeView>
        </DialogFormField>
      );
    }
  }
);
const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    paddingBottom: 16,
  },
  itemRow: {
    flex: 1,
    flexDirection: "row",
  },
  itemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  itemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
