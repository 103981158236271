import R14 from "../core";

export default class CloudAccessKeyDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "cloudAccessKey",
    });
    this.TYPE_AWS = "AWS";
    this.TYPE_AZURE = "AZURE";
    this.TYPE_GCP = "GCP";
    this.TYPE_R14 = "R14";
    this.state = {};
  }
  async find(fieldsStr, options = {}) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    let res = await this.api.qry(
      `
      query FindCloudAccessKeys($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: CloudAccessKeyFilter) {
        cloudAccessKeys(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.cloudAccessKeys;
  }
  async fetchSelections(filters, options = {}) {
    if (filters.type)
      filters.type = {
        eq: filters.type,
      };
    return await super.fetchSelections(filters, options);
  }
  async generateKeys(type) {
    let values = {
      clientUid: this.dm.userSession.clientUid,
      type: type,
    };
    values.clientUid = this.dm.userSession.clientUid;
    let res = null;
    try {
      res = await this.api.mutate(
        `
        mutation GenerateCloudAccessKeys($input: GenerateCloudAccessKeysInput!) {
          generateCloudAccessKeys(input: $input){
            key
            secretKey
          }
        }`,
        {
          input: values,
        }
      );
    } catch (err) {
      return false;
    }
    if (res.errors && res.errors.length) {
      return false;
    }
    return res.data.generateCloudAccessKeys;
  }
  async fetchEditFormData(uid = null) {
    let res = uid
      ? await this.api.qry(
          `
      query CloudAccessKeyEditFormData($uid: ID!) {
        cloudAccessKey(uid: $uid){
          uid
          name
          description
          active
          type
          key
          secretKey
          regions
          resourceTypes
          clientId
          tenantId
        }
      }`,
          {
            uid: uid,
          }
        )
      : {};
    let formVals = res.cloudAccessKey || {};

    return {
      values: formVals,
      typeSelections: [
        {
          label: "AWS",
          value: this.TYPE_AWS,
        },
        {
          label: "Azure",
          value: this.TYPE_AZURE,
        },
        {
          label: "Google Cloud",
          value: this.TYPE_GCP,
        },
        {
          label: "R14 Agents / API",
          value: this.TYPE_R14,
        },
      ],
      regionSelections: this.getRegionSelections(formVals.type),
      resourceTypeSelections: this.getResourceTypeSelections(formVals.type),
    };
  }
  getResourceTypeSelections(type) {
    let ret = [];
    switch (type) {
      case this.TYPE_AWS:
        ret = [
          {
            label: this.dm.resource.getTypeLabel(
              this.dm.resource.TYPE_EC2_INSTANCE
            ),
            value: this.dm.resource.TYPE_EC2_INSTANCE,
          },
          {
            label: this.dm.resource.getTypeLabel(
              this.dm.resource.TYPE_AWS_FSX_LUSTRE_FILESYSTEM
            ),
            value: this.dm.resource.TYPE_AWS_FSX_LUSTRE_FILESYSTEM,
          },
          {
            label: this.dm.resource.getTypeLabel(
              this.dm.resource.TYPE_AWS_S3_BUCKET
            ),
            value: this.dm.resource.TYPE_AWS_S3_BUCKET,
          },
          {
            label: this.dm.resource.getTypeLabel(
              this.dm.resource.TYPE_EFS_FILESYSTEM
            ),
            value: this.dm.resource.TYPE_EFS_FILESYSTEM,
          },
          {
            label: this.dm.resource.getTypeLabel(
              this.dm.resource.TYPE_ECS_CLUSTER
            ),
            value: this.dm.resource.TYPE_ECS_CLUSTER,
          },
        ];
        break;
      case this.TYPE_AZURE:
        ret = [
          {
            label: this.dm.resource.getTypeLabel(
              this.dm.resource.TYPE_AZURE_VM
            ),
            value: this.dm.resource.TYPE_AZURE_VM,
          },
        ];
        break;
    }
    return ret;
  }
  getRegionSelections(type) {
    let ret = [];
    switch (type) {
      case this.TYPE_AWS:
        ret = [
          {
            label: "US East (N. Virginia): us-east-1",
            value: "us-east-1",
          },
          {
            label: "US East (Ohio): us-east-2",
            value: "us-east-2",
          },
          {
            label: "US West (N. California): us-west-1",
            value: "us-west-1",
          },
          {
            label: "US West (Oregon): us-west-2",
            value: "us-west-2",
          },
          {
            label: "Asia Pacific (Hong Kong): ap-east-1",
            value: "ap-east-1",
          },
          {
            label: "Asia Pacific (Mumbai): ap-south-1",
            value: "ap-south-1",
          },
          {
            label: "Asia Pacific (Osaka-Local): ap-northeast-3",
            value: "ap-northeast-3",
          },
          {
            label: "Asia Pacific (Seoul): ap-northeast-2",
            value: "ap-northeast-2",
          },
          {
            label: "Asia Pacific (Singapore): ap-southeast-1",
            value: "ap-southeast-1",
          },
          {
            label: "Asia Pacific (Sydney): ap-southeast-2",
            value: "ap-southeast-2",
          },
          {
            label: "Asia Pacific (Tokyo): ap-northeast-1",
            value: "ap-northeast-1",
          },
          {
            label: "Canada (Central): ca-central-1",
            value: "ca-central-1",
          },
          {
            label: "China (Beijing): cn-north-1",
            value: "cn-north-1",
          },
          {
            label: "China (Ningxia): cn-northwest-1",
            value: "cn-northwest-1",
          },
          {
            label: "EU (Frankfurt): eu-central-1",
            value: "eu-central-1",
          },
          {
            label: "EU (Ireland): eu-west-1",
            value: "eu-west-1",
          },
          {
            label: "EU (London): eu-west-2",
            value: "eu-west-2",
          },
          {
            label: "EU (Paris): eu-west-3",
            value: "eu-west-3",
          },
          {
            label: "EU (Stockholm): eu-north-1",
            value: "eu-north-1",
          },
          {
            label: "Middle East (Bahrain): me-south-1",
            value: "me-south-1",
          },
          {
            label: "South America (Sao Paulo): sa-east-1",
            value: "sa-east-1",
          },
        ];
        break;
      case this.TYPE_AZURE:
        ret = [
          {
            label: "Central US: CentralUS",
            value: "CentralUS",
          },
          {
            label: "East US: EastUS",
            value: "EastUS",
          },
          {
            label: "East US 2: EastUS2",
            value: "EastUS2",
          },
          {
            label: "North Central US: NorthCentralUS",
            value: "NorthCentralUS",
          },
          {
            label: "South Central US: SouthCentralUS",
            value: "SouthCentralUS",
          },
          {
            label: "West US: WestUS",
            value: "WestUS",
          },
          {
            label: "West US 2: WestUS2",
            value: "WestUS2",
          },
          {
            label: "West Central US: WestCentralUS",
            value: "WestCentralUS",
          },
          {
            label: "North Europe: NorthEurope",
            value: "NorthEurope",
          },
          {
            label: "West Europe: WestEurope",
            value: "WestEurope",
          },
          {
            label: "East Asia: EastAsia",
            value: "EastAsia",
          },
          {
            label: "Southeast Asia: SoutheastAsia",
            value: "SoutheastAsia",
          },
          {
            label: "Brazil South: BrazilSouth",
            value: "BrazilSouth",
          },
          {
            label: "Japan East: JapanEast",
            value: "JapanEast",
          },
          {
            label: "Japan West: JapanWest",
            value: "JapanWest",
          },
          {
            label: "Central India: CentralIndia",
            value: "CentralIndia",
          },
          {
            label: "South India: SouthIndia",
            value: "SouthIndia",
          },
          {
            label: "West India: WestIndia",
            value: "WestIndia",
          },
          {
            label: "UK South 2: UKSouth2",
            value: "UKSouth2",
          },
          {
            label: "UK North: UKNorth",
            value: "UKNorth",
          },
          {
            label: "Canada Central: CanadaCentral",
            value: "CanadaCentral",
          },
          {
            label: "Canada East: CanadaEast",
            value: "CanadaEast",
          },
          {
            label: "Australia East: AustraliaEast",
            value: "AustraliaEast",
          },
          {
            label: "Australia Southeast: AustraliaSoutheast",
            value: "AustraliaSoutheast",
          },
          {
            label: "UK South: UKSouth",
            value: "UKSouth",
          },
          {
            label: "UK West: UKWest",
            value: "UKWest",
          },
          {
            label: "EAST US 2 EUAP: EASTUS2EUAP",
            value: "EASTUS2EUAP",
          },
          {
            label: "Central US EUAP: CentralUSEUAP",
            value: "CentralUSEUAP",
          },
          {
            label: "Korea Central: KoreaCentral",
            value: "KoreaCentral",
          },
          {
            label: "Korea South: KoreaSouth",
            value: "KoreaSouth",
          },
        ];
        break;
    }
    return ret;
  }
  async create(values) {
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateCloudAccessKey($input: CreateCloudAccessKeyInput!) {
        createCloudAccessKey(input: $input){
          cloudAccessKey {
            uid
            name
          }
        }
      }`,
      {
        input: values,
      }
    );
    console.log(res);
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateCloudAccessKey($input: UpdateCloudAccessKeyInput!) {
        updateCloudAccessKey(input: $input){
          cloudAccessKey {
            uid
            name
          }
        }
      }`,
      {
        input: values,
      }
    );
    console.log(res);
    return true;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteCloudAccessKey($uid: ID!) {
        deleteCloudAccessKey(uid: $uid){
          cloudAccessKey {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
}
