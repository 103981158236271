import R14 from "../core";
export default class VirtualWorkspaceImageDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "virtualWorkspaceImage",
    });
  }
  async fetchSelections(filters, options = {}) {
    if (filters.virtualWorkspaceRegionUid)
      filters.virtualWorkspaceRegionUid = {
        eq: filters.virtualWorkspaceRegionUid,
      };
    if (filters.resourceType)
      filters.resourceType = { eq: filters.resourceType };
    return await super.fetchSelections(filters, options);
  }
  async fetchEditFormData(uid = null, options = {}) {
    let fields = `
  uid
  name
  description
  key
  platform
  instanceSize
  virtualWorkspaceRegion {
    uid
    name
  }
  cloudAccessKey {
    uid
    name
  }
`;
    let res = uid ? await this.get(uid, fields) : {};
    if (res.virtualWorkspaceRegion)
      res.virtualWorkspaceRegion = {
        label: res.virtualWorkspaceRegion.name,
        value: res.virtualWorkspaceRegion.uid,
      };
    if (res.cloudAccessKey)
      res.cloudAccessKey = {
        label: res.cloudAccessKey.name,
        value: res.cloudAccessKey.uid,
      };
    let formData = {
      values: res,
      platformSelections: [
        {
          value: this.dm.virtualWorkspace.PLATFORM_WINDOWS_SERVER_2019,
          label: "Windows Server 2019",
        },
        {
          value: this.dm.virtualWorkspace.AMAZON_LINUX_2,
          label: "Amazon Linux 2",
        },
      ],
      instanceSizeSelections: [
        {
          value: this.dm.virtualWorkspace.INSTANCE_SIZE_SMALL,
          label: "Small",
        },
        {
          value: this.dm.virtualWorkspace.INSTANCE_SIZE_MEDIUM,
          label: "Medium",
        },
        {
          value: this.dm.virtualWorkspace.INSTANCE_SIZE_LARGE,
          label: "Large",
        },
        {
          value: this.dm.virtualWorkspace.INSTANCE_SIZE_XLARGE,
          label: "XLarge",
        },
        {
          value: this.dm.virtualWorkspace.INSTANCE_SIZE_XXLARGE,
          label: "XXLarge",
        },
      ],
    };
    return formData;
  }
  parseSubmitValues(values) {
    let ret = { ...values };
    if (values.cloudAccessKey) {
      ret.cloudAccessKeyUid = values.cloudAccessKey.value;
      delete ret.cloudAccessKey;
    }
    if (values.virtualWorkspaceRegion) {
      ret.virtualWorkspaceRegionUid = values.virtualWorkspaceRegion.value;
      delete ret.virtualWorkspaceRegion;
    }
    ret.clientUid = this.dm.userSession.clientUid;
    ret.resourceType = this.dm.virtualWorkspace.RESOURCE_TYPE_AWS_APPSTREAM_2;
    return ret;
  }
}
