import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
  Image,
  View,
  Button,
  FadeView,
} from "../core";

import StateIndicatorText from "./StateIndicatorText";
import EntityDataTable from "./EntityDataTable";
import CardTable from "./CardTable";

export default R14.connect(
  class ManualEntryUserPaymentDataTable extends React.Component {
    constructor(props) {
      super(props);
      // this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUserPayment"
      );
      this.state = {
        rowBottomVisible: [],
      };
    }
    get pricingMetricFields() {
      return this.props.app.dm.manualEntry.getPricingMetricFields();
    }
    get metricFields() {
      return this.props.app.dm.manualEntry.getMetricFields();
    }
    showRowBottom(row) {
      let rowKey = this.rowKeyExtractor(row);
      if (this.isRowBottomVisible(row)) return;
      let rowBottomVisible = this.state.rowBottomVisible;
      rowBottomVisible.push(rowKey);
      this.setState({ rowBottomVisible });
    }
    hideRowBottom(row) {
      let rowKey = this.rowKeyExtractor(row);
      if (!this.isRowBottomVisible(row)) return;
      let rowBottomVisible = this.state.rowBottomVisible;
      rowBottomVisible.splice(rowBottomVisible.indexOf(rowKey), 1);
      this.setState({ rowBottomVisible });
    }
    isRowBottomVisible(row) {
      let rowKey = this.rowKeyExtractor(row);
      return this.state.rowBottomVisible.includes(rowKey);
    }

    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.manualEntryUser = { search: { like: `%${search}%` } };
      if (this.props.manualEntryUserPaymentBatchUid)
        filter.manualEntryUserPaymentBatchUid = {
          eq: this.props.manualEntryUserPaymentBatchUid,
        };
      let res = await this.props.app.dm.manualEntryUserPayment.find(
        `
          manualEntryUserUid
          manualEntryUserPaymentBatchUid
          paymentTotal
          currencyUid
          manualEntryUser {
            uid
            username
          }
          currency {
            code
            symbol
          }
          payments {
            ${this.pricingMetricFields.reduce(
              (prevValue, currValue) => `${prevValue}\n${currValue}`
            )}
          }
          metrics {
            ${this.metricFields.reduce(
              (prevValue, currValue) => `${prevValue}\n${currValue}`
            )}
          }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryUserPayments");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "manualEntryUser":
          ret = <Text style={style}>{cell.username}</Text>;
          break;
        case "paymentTotal":
          ret = (
            <Text style={style}>
              {row.currency.symbol}
              {this.props.app.utils.string.formatCurrency(row.paymentTotal)}
              {` ${row.currency.code}`}
            </Text>
          );
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return `${row.manualEntryUser.uid}-${row.currencyUid}`;
    }
    renderUserPaymentsCardTable(row) {
      let cardTableData = [];
      let metricFieldInfo =
        this.props.app.dm.manualEntry.getPricingMetricFieldInfo();
      metricFieldInfo.forEach((mode) =>
        mode.fields.forEach(
          (metricField) =>
            row.payments[metricField.name] &&
            cardTableData.push({
              mode: mode.label,
              metric: metricField.label,
              key: metricField.name,
              total: `${row.metrics[metricField.name] || 0}`,
              payment: `${
                row.currency.symbol
              }${this.props.app.utils.string.formatCurrency(
                row.payments[metricField.name] || 0
              )} ${row.currency.code}`,
            })
        )
      );
      return (
        <CardTable
          headerTitle='User Payments'
          style={styles.cardTable}
          surfaceStyle={styles.cardTableSurface}
          columns={{
            mode: {
              label: "Mode",
            },
            metric: {
              label: "Metric",
            },
            total: {
              label: "Total",
            },
            payment: {
              label: "Payment",
            },
          }}
          data={cardTableData}
        />
      );
    }
    rowBottomRenderer({ row }) {
      return (
        <FadeView visible={this.isRowBottomVisible(row)} unmountOnExit={true}>
          {this.renderUserPaymentsCardTable(row)}
        </FadeView>
      );
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.props.app.dm.manualEntryUserPaymentBatch}
          name='manualEntryUserPaymentBatches'
          columns={{
            manualEntryUser: {
              label: "User",
              sortable: true,
            },
            paymentTotal: {
              label: "Payment Total",
              sortable: true,
            },
          }}
          searchable
          // onRowPress={(row) =>
          //   this.props.app.nav.to("projectManualEntryConfig", {
          //     uid: this.props.project.uid,
          //     key: this.props.app.dm.project.getKeyByType(this.props.project.type),
          //     manualEntryConfigUid: row.uid,
          //   })
          // }
          onRowPress={false}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          rowBottom={this.rowBottomRenderer}
          rowKeyExtractor={this.rowKeyExtractor}
          headerTitle={this.props.title || "User Payments"}
          headerStyle={this.props.headerStyle}
          initialSortColumnName='createdAt'
          initialSortDirection='asc'
          rowControlsRight={(row, dataTable) => {
            let rowBottomVisible = this.isRowBottomVisible(row);

            let ret = [
              <Button
                title={rowBottomVisible ? "Hide Payments" : "View Payments"}
                color={rowBottomVisible ? Colors.secondary : Colors.primary}
                style={styles.rowBottomExpandButton}
                key='expand'
                variant='outlined'
                uppercase={false}
                size='small'
                onPress={() =>
                  rowBottomVisible
                    ? this.hideRowBottom(row)
                    : this.showRowBottom(row)
                }
              />,
            ];
            // console.log("CHECK URL", row.csvFile.url);
            // this.perms.read && row.csvFile && row.csvFile.url &&
            //   ret.push(
            //     <IconButton
            //       icon='fileDownload'
            //       size='small'
            //       key='fileDownload'
            //       onPress={() =>
            //         this.props.app.api.download(row.csvFile.url)
            //       }
            //     />
            //   );
            // this.perms.execute &&
            //   ret.push(
            //     <PopUpMenu
            //       controlIcon='dotsHorizontal'
            //       controlIconSize='small'
            //       key='projectResourceMenu'
            //       direction='downLeft'
            //     >
            //       <PopUpMenuItem
            //         label='Timeout Documents'
            //         icon='clock'
            //         key='timeout'
            //         onPress={() =>
            //           window.confirm(
            //             "Are you sure? This will timeout all documents currently in the pool."
            //           ) &&
            //           this.handleRunCommandPress(
            //             row.uid,
            //             this.props.app.dm.manualEntryConfig.COMMAND_TIMEOUT
            //           )
            //         }
            //       />
            //       <PopUpMenuItem
            //         label='Restart Services'
            //         icon='refresh'
            //         key='restartServices'
            //         onPress={() =>
            //           window.confirm(
            //             "Are you sure? This will restart all r14 services. Please only use this as a last resort. You will need to refresh the browser after running."
            //           ) &&
            //           this.handleRunCommandPress(
            //             row.uid,
            //             this.props.app.dm.manualEntryConfig
            //               .COMMAND_RESTART_SERVICES
            //           )
            //         }
            //       />
            //     </PopUpMenu>
            //   );

            return ret;
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cardTable: {
    ...StyleSheet.margin(16, -8, -1, -8),
  },
  cardTableSurface: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.05),
  },
  rowBottomExpandButton: {
    marginLeft: "auto",
    width: 160,
    // ...StyleSheet.margin(-8, 0, -20, "auto"),
  },
});
