import React from "react";
import R14, {
  TextInputField,
  StyleSheet,
  FadeView,
  Colors,
  SelectMenuField,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";

export default R14.connect(
  class R14DeploymentEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.r14LicenseLoader = this.r14LicenseLoader.bind(this);
      this.handleProductValueChange = this.handleProductValueChange.bind(this);
      this.handleMasterChange = this.handleMasterChange.bind(this);
      this.state = {
        master: props.formData.values.master ? true : false,
      };
      this._selectedProduct = props.formData.values.product;

      console.log("check formdata", props.formData.values);
    }
    // get isEditMode() {
    //   return this.props.formData &&
    //     this.props.formData.values &&
    //     this.props.formData.values.uid
    //     ? true
    //     : false;
    // }
    get formData() {
      return this.props.formData;
    }
    async handleProductValueChange(val, elmt, form) {
      this.props.app.ui.progressIndicator.show();

      this._selectedProduct = val;

      form.elmts.r14License.setDisabled(val ? false : true);
      form.elmts.r14License.setItems([]);
      form.elmts.r14License.setValue(null);
      form.elmts.r14License.setLoaded(false);

      //form.elmts.subtype.setItems(subtypeSelections);

      this.props.app.ui.progressIndicator.hide({ timeout: 500 });
    }
    async handleMasterChange(master) {
      this.setState({ master });
    }
    async r14LicenseLoader(filters = {}) {
      if (!this._selectedProduct) return [];
      filters.product = { eq: this._selectedProduct };
      return await this.props.app.dm.r14License.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='r14Deployment'
          redirect={this.props.redirect || "r14Deployments"}
          refreshDataTable='r14Deployments'
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
          <TextInputField
            name='url'
            label='Url'
            required='Please enter a url.'
            validator='url'
          />
          <SelectMenuField
            useValuesOnly
            name='product'
            label='Product'
            items={this.formData.productSelections}
            onValueChange={this.handleProductValueChange}
            required='Please select a product.'
          />
          <SwitchField
            key='master'
            name='master'
            label='Is Master'
            onValueChange={this.handleMasterChange}
            value={this.state.master}
          />
          <FadeView visible={!this.state.master}>
            <SelectMenuField
              autoLoad
              name='r14License'
              label='License'
              disabled={this.formData.values.product ? false : true}
              itemLoader={this.r14LicenseLoader}
            />
          </FadeView>
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  info: {
    ...StyleSheet.padding(0, 0, 24, 0),
  },
  infoText: {
    // fontSize: 14,
    // fontWeight: "300",
    color: StyleSheet.color(Colors.onSurface).rgba(0.9),
  },
  boldText: {
    fontWeight: 600,
  },
  form: {
    maxWidth: 480,
  },
});
