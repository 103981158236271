import React from "react";
import R14, { StyleSheet, Image, View } from "../core";
export default R14.connect(
  class ProjectDocumentTemplateScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    get documentTemplate() {
      return this.props.documentTemplate;
    }
    createDynamicStyles() {
      let scale = this.documentTemplate.state.scale;
      let height = this.documentTemplate.state.height;
      let width = this.documentTemplate.state.width;
      let imageHeight = this.documentTemplate.image.height;
      let imageWidth = this.documentTemplate.image.width;
      return StyleSheet.create({
        image: {
          // transform: [
          //   {
          //     scale: scale,
          //     translateX: (imageWidth * scale - imageWidth) / (scale * 2),
          //     translateY: (imageHeight * scale - imageHeight) / (scale * 2),
          //   },
          // ],
        },
        imageWrapper: {
          height: imageHeight * scale,
          width: imageWidth * scale,
          // marginTop:
          //   imageHeight * scale > height
          //     ? 0
          //     : (height - imageHeight * scale) / 2,
          // marginLeft:
          //   imageWidth * scale > width ? 0 : (width - imageWidth * scale) / 2,
          // transform: [
          //   {
          //     // scale: scale,
          //     translateX:
          //       imageWidth * scale > width
          //         ? 0
          //         : (width - imageWidth * scale) / 2,
          //     translateY:
          //       imageHeight * scale > height
          //         ? 0
          //         : (height - imageHeight * scale) / 2,
          //   },
          // ],
        },
      });
    }
    render() {
      if (!this.documentTemplate.state.layout) return false;
      let dynamicStyles = this.createDynamicStyles();
      let scale = this.documentTemplate.state.scale;
      return (
        <View style={[styles.imageWrapper, dynamicStyles.imageWrapper]}>
          <Image
            resizeMode='cover'
            style={[styles.image, dynamicStyles.image]}
            height={this.documentTemplate.image.height * scale}
            width={this.documentTemplate.image.width * scale}
            source={{
              key: this.documentTemplate.image.key,
            }}
          />
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  image: {
    flex: 1,
    backgroundPosition: "center",
  },
  imageWrapper: {
    //flex: 0,
    //position: "absolute",
    //overflow: "hidden",
  },
});
