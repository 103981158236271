import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
} from "../core";
import EntityDataTable from "./EntityDataTable";
export default R14.connect(
  class ManualEntryDocumentDataTable extends React.Component {
    constructor(props) {
      super(props);
      // this.projectDomain = this.props.app.dm.project;
      this.manualEntryDocumentDomain = this.props.app.dm.manualEntryDocument;
      this.pageLoader = this.pageLoader.bind(this);
      // this.cellRenderer = this.cellRenderer.bind(this);
      // this.handleRowPress = this.handleRowPress.bind(this);
      // this.state = {};
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    get perms() {
      return this.props.perms;
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        manualEntryPipelineUid: { eq: this.props.manualEntryPipelineUid },
      };
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.type) filter.type = { eq: this.props.type };
      let res = await this.manualEntryDocumentDomain.find(
        `
        uid
        name
        batchId
        totalLowScoreFields
        totalLowScoreCharacters
        state
        reject
        valid
        updatedAt
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    // cellRenderer({ cell, columnName, row }) {
    //   let content = cell;
    //   let ret = null;
    //   let style = [styles.cellText];
    //   switch (columnName) {
    //     case "name":
    //       ret = (
    //         <React.Fragment>
    //           <Text style={[styles.cellText, styles.nameCellNameText]}>
    //             {row.name}
    //           </Text>
    //           {row.description && (
    //             <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
    //               {row.description}
    //             </Text>
    //           )}
    //         </React.Fragment>
    //       );
    //       break;
    //     default:
    //       ret = <Text style={style}>{content}</Text>;
    //   }
    //   return ret;
    // }
    // handleRowPress(row) {
    //   this.props.app.nav.to("projectDocumentTemplate", {
    //     uid: this.props.projectUid,
    //     key: this.props.app.dm.project.getKeyByType(this.props.projectType),
    //     documentTemplateUid: row.uid,
    //   });
    // }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.manualEntryDocumentDomain}
          searchable
          title='Documents'
          name='manualEntryDocuments'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            batchId: {
              label: "Batch",
              sortable: true,
            },
            updatedAt: {
              label: "Updated",
              sortable: true,
              renderer: ({ cell }) => {
                let updatedAt = new Date(cell);
                return (
                  <Text style={[styles.cellText]}>
                    {this.props.app.utils.date.toShortDate(updatedAt)}{" "}
                    {this.props.app.utils.date.toTime(updatedAt)}
                  </Text>
                );
              },
            },
            totalLowScoreCharacters: {
              label: "LSC",
              sortable: true,
              width: 96,
            },
            totalLowScoreFields: {
              label: "LSF",
              sortable: true,
              width: 96,
            },
            state: {
              label: "State",
              sortable: true,
              renderer: ({ cell }) => {
                return <Text style={[styles.cellText]}>{cell}</Text>;
              },
            },
          }}
          pageLoader={this.pageLoader}
          // onRowPress={this.handleRowPress}
          initialSortColumnName='updatedAt'
          initialSortDirection='desc'
          // projectType={this.props.project.type}
          // projectUid={this.props.project.uid}
          perms={this.perms}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
});
