import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  FieldGroup,
  FadeView,
  Surface,
  SwitchField,
  StyleSheet,
  FileInputField,
} from "../core";
import EditForm from "../components/EditForm";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryUserBatchEditScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    get formData() {
      return this.props.formData;
    }
    get formName() {
      return "manualEntryUserBatchForm";
    }
    get form() {
      return this.props.app.ui.form(this.formName);
    }
    render() {
      return (
        <EditForm
          name={this.formName}
          formData={this.props.formData}
          domainName='manualEntryUserBatch'
          redirect={this.props.redirect || "manualEntryUserBatches"}
          refreshDataTable='manualEntryUserBatches'
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
          {this.props.formData.r14DeploymentInstance &&
          this.props.formData.r14DeploymentInstance.master ? (
            <FieldGroup>
              <SelectMenuField
                useValuesOnly
                name='r14DeploymentUuid'
                label='R14 Deployment'
                items={this.formData.r14DeploymentSelections}
                required='Please select a deployment.'
              />
              <TextInputField
                name='clientKey'
                label='Client Key'
                required='Please enter a client key.'
              />
            </FieldGroup>
          ) : null}
          <FileInputField name='csvFile' label='CSV File' />

          {/* <SelectMenuField
            useValuesOnly
            name='manualEntryConfigUid'
            label='Manual Entry'
            items={this.formData.manualEntryConfigSelections}
            onValueChange={this.handleManualEntryVisibleChange}
            // required={
            //   this.state.manualEntryVisible
            //     ? "Please select a manual entry config."
            //     : false
            // }
          /> */}
          {/* <FadeView
            visible={this.state.manualEntryVisible}
            // unmountOnExit={true}
          >
            <Surface style={styles.sectionSurfaceStyle}>
              <SelectMenuField
                useValuesOnly
                multiple
                name='modes'
                label='Modes'
                items={this.formData.modeSelections}
                labelStyle={styles.surfaceLabel}
                required={
                  this.state.manualEntryVisible
                    ? "Please select a mode."
                    : false
                }
              />
              <FieldGroup>
                <TextInputField
                  name='queuedTimeoutSeconds'
                  label={"Queued Item Timeout"}
                  labelStyle={styles.surfaceLabel}
                  // required={true}
                  helper='Seconds'
                />
              </FieldGroup>
            </Surface>
          </FadeView> */}
          {/* <SwitchField
            name='virtualWorkspaceVisible'
            label='Enable Virtual Workspace(s)'
            value={this.state.virtualWorkspaceVisible}
            onValueChange={this.handleVirtualWorkspaceVisibleChange}
          /> */}
          {/* <SelectMenuField
            useValuesOnly
            name='virtualWorkspaceConfigUid'
            label='Virtual Workspace'
            items={this.formData.virtualWorkspaceConfigSelections}
            required={
              this.state.virtualWorkspaceVisible
                ? "Please select a virtual workspace."
                : false
            }
          /> */}
          {/* <SwitchField name='active' label='Is Active' value={true} /> */}
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({});
