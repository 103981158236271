import React from 'react';
import R14, {
  StyleSheet,
  CardsView,
  Card,
  Colors,
  ScreenLayout,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Button,
  Surface,
  SectionHeader,
  AnimatedView,
  View,
  Text,
  Link
} from '../core';
import DashboardServerWidget from '../components/DashboardServerWidget'
export default R14.connect(
  class DashboardScreen extends React.Component {
    render() {

      let appServers = [];
      for(let key in this.props.project.config.client.servers){
        appServers.push(
          <DashboardServerWidget project={this.props.project} server={key} />
        );
      }
      let serverServers = [];
      for(let key in this.props.project.config.server.servers){
        serverServers.push(
          <DashboardServerWidget project={this.props.project} server={key} />
        );
      }

      return (
        <ScreenLayout
          floatingActionButton={{
            to: "helloWorldUpdate",
            icon: "stop"
          }}
          scrollEnabled
          style={styles.helloWorldScreen}
        >
          <CardsView
            scrollEnabled
          >
            <Card
              title='App'
              style={styles.serverType}
              titleControlsRight={[
                <IconButton
                  icon="settings"
                  key="settings"
                  onPress={() => { alert("filter") }}
                />,
                <PopUpMenu
                  controlIcon="dotsVertical"
                  key="menu"
                  direction="downLeft"
                  onSelect={(value, label) => { }}
                >
                  <PopUpMenuItem
                    icon="add"
                    iconSize="small"
                    label={`Menu Item`}
                    to={`/`}
                    key="add"
                  />
                </PopUpMenu>
              ]}
            >
             {appServers}
            </Card>
            <Card
              title='Server'
              style={styles.serverType}
              titleControlsRight={[
                <IconButton
                  icon="settings"
                  key="settings"
                  onPress={() => { alert("filter") }}
                />,
                <PopUpMenu
                  controlIcon="dotsVertical"
                  key="menu"
                  direction="downLeft"
                  onSelect={(value, label) => { }}
                >
                  <PopUpMenuItem
                    icon="add"
                    iconSize="small"
                    label={`Menu Item`}
                    to={`/`}
                    key="add"
                  />
                </PopUpMenu>
              ]}
            >
              {serverServers}
            </Card>
          </CardsView>

        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  button: {
    marginBottom: 16
  },
  serverType: {
    width: '50%',
    screen: ({ width }) => {
      if (width <= 760) return {
        width: "100%"
      }
    }
  }

});