import R14 from "../core";
export default class ManualEntryPipelineUsageMetricDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryPipelineUsageMetric",
    });
    this.FILTER_PERIOD_HOUR_12 = "HOUR_12";
    this.FILTER_PERIOD_HOUR_24 = "HOUR_24";
    this.FILTER_PERIOD_DAY_3 = "DAY_3";
    this.FILTER_PERIOD_DAY_7 = "DAY_7";
    this.FILTER_PERIOD_DAY_30 = "DAY_30";
    this.FILTER_PERIOD_CUSTOM = "CUSTOM";
  }
  _createMetricMap() {
    let metricMap = {};
    for (let parentType of this.parentTypes) {
      for (let type of this.types) {
        let fieldName = this.getMetricFieldName(parentType, type);
        metricMap[fieldName] = {
          parentType,
          type,
        };
      }
    }
    return metricMap;
  }
  get metricMap() {
    if (!this._metricMap) this._metricMap = this._createMetricMap();
    return this._metricMap;
  }
  async get(uid, fields) {
    if (!fields)
      fields = [
        "uid",
        "startAt",
        "endAt",
        "lastSyncAt",
        ...this.getMetricFields(),
      ];
    return await super.get(uid, fields);
  }
  get metricFields() {
    return this.getMetricFields();
  }

  getMetricFields() {
    // PREFIX TYPES:
    // cm: character mode
    // fm: field mode
    // dsfm: document set field mode
    // d: document
    // ds: document set
    // u: user
    // POSTFIX METRICS:
    // t: total
    // ta: total available
    // tq: total queued
    // tur: total users recomended
    // tts: total timeout seconds
    return [
      ...[].concat.apply(
        [],
        ["cm", "fm", "dsfm", "d", "ds"].map((pre) =>
          ["t", "ta", "tq", "tur", "tts"].map((post) => `${pre}${post}`)
        )
      ),
      "ut",
      "utur",
    ];
  }
  getMetricTimeSeriesFields() {
    return this.metricFields.filter(
      (f) =>
        !f.endsWith("ta") &&
        !f.endsWith("tq") &&
        !["dtur", "dtts", "dstur", "dstts"].includes(f)
    );
  }
  get metricTimeSeriesFields() {
    return this.getMetricTimeSeriesFields();
  }
  getParentTypes() {
    return [
      this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_USER,
      this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT_SET,
      this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT,
      this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD,
      this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD_GROUP,
      this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_CHARACTER,
    ];
  }
  get parentTypes() {
    return this.getParentTypes();
  }
  getTypes() {
    return [
      this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL,
      this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_ACTIVE,
      this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_AVAILABLE,
      this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_QUEUED,
      this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_USERS_RECOMMENDED,
      this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS,
    ];
  }
  get types() {
    return this.getTypes();
  }
  getMetricFieldName(parentType, type) {
    let pre = "";
    let post = "";
    switch (parentType) {
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_USER:
        pre = "u";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT_SET:
        pre = "ds";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT:
        pre = "d";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD:
        pre = "fm";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD_GROUP:
        pre = "dsfm";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_CHARACTER:
        pre = "cm";
        break;
    }
    switch (type) {
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL:
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_ACTIVE:
        post = "t";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_AVAILABLE:
        post = "ta";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_QUEUED:
        post = "tq";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_USERS_RECOMMENDED:
        post = "tur";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS:
        post = "tts";
        break;
    }
    // console.log(
    //   "getPipelineUsageMetricFieldName",
    //   parentType,
    //   type,
    //   `${pre}${post}`
    // );
    return `${pre}${post}`;
  }
  getParentTypeLabel(parentType, options = { plural: true }) {
    let pre = "Unknown";
    switch (parentType) {
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_USER:
        pre = "User";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT_SET:
        pre = "Document Set";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT:
        pre = "Document";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD:
        pre = "Field";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD_GROUP:
        pre = "Field Group";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_CHARACTER:
        pre = "Character";
        break;
    }
    if (options.plural) pre = `${pre}s`;
    return pre;
  }
  getTypeLabel(type) {
    let post = "";
    switch (type) {
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL:
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_ACTIVE:
        post = "Total";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_AVAILABLE:
        post = "Available";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_QUEUED:
        post = "Queued";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_USERS_RECOMMENDED:
        post = "Users Recommended";
        break;
      case this.dm.manualEntryPipeline.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS:
        post = "Timeout Minutes";
        break;
    }
    return post;
  }
  getColorPrimary(parentType, type) {
    let color = null;
    switch (parentType) {
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_USER:
        color = "#ad1457";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT_SET:
        color = "#6a1b9a";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT:
        color = "#283593";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD:
        color = "#0277bd";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD_GROUP:
        color = "#00695c";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_CHARACTER:
        color = "#ef6c00";
        break;
    }
    return color;
  }
  getColorSecondary(parentType, type) {
    let color = null;
    switch (parentType) {
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_USER:
        color = "#ec407a";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT_SET:
        color = "#ab47bc";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_DOCUMENT:
        color = "#5c6bc0";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD:
        color = "#42a5f5";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_FIELD_GROUP:
        color = "#26a69a";
        break;
      case this.dm.manualEntryPipeline.METRIC_PARENT_TYPE_CHARACTER:
        color = "#ff9800";
        break;
    }
    return color;
  }
  async fetchMetrics(uid, filters, options = {}) {
    let filter = {};
    options.filter = {
      manualEntryPipelineUid: { eq: uid },
      clientUid: { eq: this.dm.userSession.clientUid },
    };
    options.sort = [
      {
        field: "startAt",
        order: "DESC",
      },
    ];
    if (!options.page) options.page = 1;
    if (!options.rowsPerPage) {
      options.resultsPerPage = 5;
    } else {
      options.resultsPerPage = options.rowsPerPage;
      delete options.rowsPerPage;
    }
    options.totalCount = true;
    let res = await this.find(
      ["uid", "startAt", "endAt", ...this.getMetricFields()],

      options
    );
    return res;
  }
  getColor(parentType, options = {}) {
    return options.secondary
      ? this.getColorSecondary(parentType)
      : this.getColorPrimary(parentType);
  }
  getLabel(parentType, type, options = {}) {
    let label = this.getParentTypeLabel(parentType);
    if (options.secondary) {
      let typeLabel = this.getTypeLabel(type);
      // Deal with case of users recommended
      if (typeLabel.startsWith(label)) label = typeLabel;
      else label = `${label} ${typeLabel}`;
    }
    return label;
  }
  fetchTimeSeriesFiltersFormData() {
    let startAt = new Date();
    let endAt = new Date();
    startAt.setDate(startAt.getDate() - 10);
    return {
      parentTypeSelections: this.parentTypes.map((parentType) => ({
        label: this.getParentTypeLabel(parentType),
        value: parentType,
      })),
      periodSelections: [
        {
          label: "12 Hours",
          value: this.FILTER_PERIOD_HOUR_12,
        },
        {
          label: "24 Hours",
          value: this.FILTER_PERIOD_HOUR_24,
        },
        {
          label: "3 Days",
          value: this.FILTER_PERIOD_DAY_3,
        },
        {
          label: "7 Days",
          value: this.FILTER_PERIOD_DAY_7,
        },
        {
          label: "30 Days",
          value: this.FILTER_PERIOD_DAY_30,
        },
        {
          label: "Custom Range",
          value: this.FILTER_PERIOD_CUSTOM,
        },
      ],
      values: {
        period: this.FILTER_PERIOD_DAY_3,
        // period: this.FILTER_PERIOD_CUSTOM,
        // startAt,
        // endAt,
        parentTypes: [...this.parentTypes],
      },
    };
  }

  async fetchTimeSeries(manualEntryPipelineUid, options = {}) {
    let period = options.period || this.FILTER_PERIOD_DAY_3;
    let input = {};
    switch (period) {
      case this.FILTER_PERIOD_CUSTOM:
        input = {
          period,
          startAt: options.startAt,
          endAt: options.endAt,
        };
        break;
      default:
        input = { period };
    }
    input.manualEntryPipelineUid = manualEntryPipelineUid;

    let res = await this.api.qry(
      `
      query ManualEntryUsageMetricTimeSeries($input: ManualEntryUsageMetricTimeSeriesInput!) {
        manualEntryUsageMetricTimeSeries(input: $input){
          period
          interval {
            unit
            value
          }
          startAt
          endAt
          nodes {
            startAt
            ${this.metricTimeSeriesFields.join("\n")}
          }
        }
      }`,
      { input }
    );
    // for (let parentType of this.parentTypes) {
    //   for (let type of this.types) {
    //     let fieldName = this.getMetricFieldName(parentType, type);
    //     metricMap[fieldName] = {
    //       parentType,
    //       type,
    //       name: this.getLabel(parentType, type),
    //       data: [],
    //     };
    //   }
    // }

    if (!res.data || !res.data.manualEntryUsageMetricTimeSeries) return null;

    let timeSeries = res.data.manualEntryUsageMetricTimeSeries;
    let ret = {
      period: timeSeries.period,
      interval: timeSeries.interval,
      startAt: timeSeries.startAt ? new Date(timeSeries.startAt) : null,
      endAt: timeSeries.endAt ? new Date(timeSeries.endAt) : null,
      fieldMap: {},
    };
    if (timeSeries.nodes.length) {
      for (let node of res.data.manualEntryUsageMetricTimeSeries.nodes) {
        for (let fieldName of this.metricTimeSeriesFields) {
          if (!ret.fieldMap[fieldName]) {
            let { parentType, type } = this.metricMap[fieldName];
            ret.fieldMap[fieldName] = {
              parentType,
              type,
              // name: this.getParentTypeLabel(parentType, type),
              // stroke: this.getColor(parentType, type),
              data: [],
            };
          }
          if (fieldName in node)
            ret.fieldMap[fieldName].data.push({
              x: new Date(node.startAt),
              y: node[fieldName],
            });
        }
      }
    }

    return ret;
  }
}
