import R14 from "../core";

export default class ManualEntryUserDomain extends R14.Domain {
  constructor(key) {
    super();
    this.PERMISSION_TYPE_PIPELINE_BLOCK = "PIPELINE_BLOCK";
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";
    this.ACTIVE_MINUTES = "5";

    this.METRIC_TYPE_SUBMISSIONS = "SUBMISSIONS";
    this.METRIC_TYPE_KEYSTROKES = "KEYSTROKES";
    this.METRIC_TYPE_FIELD_UPDATES = "FIELD_UPDATES";
    this.METRIC_TYPE_CHARACTER_UPDATES = "CHARACTER_UPDATES";
    this.METRIC_RATE_MINUTE = "MINUTE";

    this.UPDATE_SOURCE_R14 = "R14";
    this.UPDATE_SOURCE_TELETYPE_PORTAL = "TELETYPE_PORTAL";

    this.MFA_TYPE_SMS = "SMS";
    this.MFA_TYPE_EMAIL = "EMAIL";

    this.SOURCE_FREELANCE = "FREELANCE";
    this.SOURCE_SALARY = "SALARY";

    this.STATE_SIGNUP_PENDING_APPROVAL = "SIGNUP_PENDING_APPROVAL";

    this.state = {};
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindManualEntryUsers($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ManualEntryUserFilter) {
        manualEntryUsers(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.manualEntryUsers;
  }
  async fetchEditFormData(uid = null) {
    let qryVals = {
      // pipelineFilter: {
      //   clientUid: { eq: this.dm.userSession.clientUid },
      // },
      // pipelineSort: [
      //   {
      //     field: "name",
      //     order: "ASC",
      //   },
      // ],
    };
    // if (!this.dm.user.hasAdminRole)
    //   qryVals.pipelineFilter["userUids"] = { eq: this.dm.userSession.uid };

    qryVals.manualEntryConfigSort = {
      field: "name",
      order: "ASC",
    };
    qryVals.manualEntryConfigFilter = {
      clientUid: { eq: this.dm.userSession.clientUid },
    };

    qryVals.virtualWorkspaceConfigSort = {
      field: "name",
      order: "ASC",
    };
    qryVals.virtualWorkspaceConfigFilter = {
      clientUid: { eq: this.dm.userSession.clientUid },
    };
    qryVals.r14DeploymentFilter = {
      clientUid: { eq: this.dm.userSession.clientUid },
      product: { eq: this.dm.r14Deployment.PRODUCT_TELETYPE },
    };
    qryVals.r14DeploymentInstanceInput = {
      // clientUid: { eq: this.dm.userSession.clientUid },
      product: this.dm.r14Deployment.PRODUCT_TELETYPE,
    };

    if (uid) {
      qryVals.uid = uid;

      // if (!this.dm.user.hasAdminRole)
      //   qryVals.pipelineBlockFilter = {
      //     userUids: { eq: this.dm.userSession.uid },
      //   };
    }
    let res = uid
      ? await this.api.qry(
          `
          query ManualEntryUserEditFormData(
              $uid: ID!, 
              $manualEntryConfigFilter: ManualEntryConfigFilter, 
              $manualEntryConfigSort: [SortOption!]!,
              $virtualWorkspaceConfigFilter: VirtualWorkspaceConfigFilter, 
              $virtualWorkspaceConfigSort: [SortOption!]!, 
              $r14DeploymentFilter: R14DeploymentFilter, 
              $r14DeploymentInstanceInput: R14DeploymentInstanceInput!
            ) {
            manualEntryUser(uid: $uid){
              uid
              name
              email
              phone
              username
              mfaType
              r14DeploymentConfigs {
                r14DeploymentUuid
                clientKey
                virtualWorkspaceConfigKey
                active
                manualEntryConfigKey
                modes
                queueNamespaces
                queuedTimeoutSeconds
                reviewTimeoutSeconds
                exclusiveQueueNamespaces
              } 
              modes
              queuedTimeoutSeconds
              reviewTimeoutSeconds
              active
              manualEntryConfigUid
              virtualWorkspaceConfigUid
              queueNamespaces
              exclusiveQueueNamespaces
              active
              source
              country {
                uid
                name
                currencyUid
              }
            }
            manualEntryConfigs(filter: $manualEntryConfigFilter, sort: $manualEntryConfigSort){
              nodes {
                uid
                name
              }
            }
            virtualWorkspaceConfigs(filter: $virtualWorkspaceConfigFilter, sort: $virtualWorkspaceConfigSort){
              nodes {
                uid
                name
              }
            }
            r14DeploymentInstance(input: $r14DeploymentInstanceInput){
              uuid
              master
            }
            r14Deployments(filter: $r14DeploymentFilter){
              nodes {
                uuid
                name
              }
            }
          }`,
          qryVals
        )
      : await this.api.qry(
          `
          query ManualEntryUserCreateFormData(
              $manualEntryConfigFilter: ManualEntryConfigFilter, 
              $manualEntryConfigSort: [SortOption!]!, 
              $virtualWorkspaceConfigFilter: VirtualWorkspaceConfigFilter, 
              $virtualWorkspaceConfigSort: [SortOption!]!, 
              $r14DeploymentFilter: R14DeploymentFilter
              $r14DeploymentInstanceInput: R14DeploymentInstanceInput!
            ) {
            manualEntryConfigs(filter: $manualEntryConfigFilter, sort: $manualEntryConfigSort){
              nodes {
                uid
                name
              }
            }
            virtualWorkspaceConfigs(filter: $virtualWorkspaceConfigFilter, sort: $virtualWorkspaceConfigSort){
              nodes {
                uid
                name
              }
            }
            r14DeploymentInstance(input: $r14DeploymentInstanceInput){
              uuid
              master
            }
            r14Deployments(filter: $r14DeploymentFilter){
              nodes {
                uuid
                name
              }
            }
          }`,
          qryVals
        );
    let formData = {
      values: {},
      modeSelections: [
        {
          label: "Characters",
          value: this.MODE_CHARACTERS,
        },
        {
          label: "Field",
          value: this.MODE_FIELD,
        },
        {
          label: "Field Characters",
          value: this.MODE_FIELD_CHARACTERS,
        },
        {
          label: "Document Set Fields",
          value: this.MODE_DOCUMENT_SET_FIELDS,
        },
      ],
      manualEntryConfigSelections:
        res.data.manualEntryConfigs && res.data.manualEntryConfigs.nodes
          ? res.data.manualEntryConfigs.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
      virtualWorkspaceConfigSelections:
        res.data.virtualWorkspaceConfigs &&
        res.data.virtualWorkspaceConfigs.nodes
          ? res.data.virtualWorkspaceConfigs.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
      mfaTypeSelections: [
        {
          label: "No Multi-Factor Auth",
          value: null,
        },
        {
          label: "SMS",
          value: this.MFA_TYPE_SMS,
        },
        // {
        //   label: "Email",
        //   value: this.MFA_TYPE_EMAIL,
        // },
      ],
      sourceSelections: [
        {
          label: "Salary",
          value: this.SOURCE_SALARY,
        },
        {
          label: "Freelance",
          value: this.SOURCE_FREELANCE,
        },
      ],
      r14DeploymentSelections:
        res.data.r14Deployments && res.data.r14Deployments.nodes
          ? res.data.r14Deployments.nodes.map((val) => ({
              label: val.name,
              value: val.uuid,
            }))
          : [],
      r14DeploymentInstance: res.data.r14DeploymentInstance,
    };
    formData.manualEntryConfigSelections.length &&
      formData.manualEntryConfigSelections.unshift({
        label: "No Manual Entry",
        value: null,
      });
    formData.virtualWorkspaceConfigSelections.length &&
      formData.virtualWorkspaceConfigSelections.unshift({
        label: "No Virtual Workspace(s)",
        value: null,
      });

    let formVals = {};

    if (res.data) {
      // if (res.data.pipelines && res.data.pipelines.nodes) {
      //   res.data.pipelines.nodes.forEach((pipeline) => {
      //     formData.pipelineMap[pipeline.uid] = {
      //       uid: pipeline.uid,
      //       name: pipeline.name,
      //       pipelineBlocks: {},
      //     };
      //   });
      // }
      if (res.data.manualEntryUser) {
        let manualEntryUser = res.data.manualEntryUser;
        formVals = {
          username: manualEntryUser.username,
          email: manualEntryUser.email,
          phone: manualEntryUser.phone,
          uid: manualEntryUser.uid,
          name: manualEntryUser.name,
          active: manualEntryUser.active,
          mfaType: manualEntryUser.mfaType,
          source: manualEntryUser.source,
          r14DeploymentConfigs: manualEntryUser.r14DeploymentConfigs,
          modes: manualEntryUser.modes,
          queuedTimeoutSeconds: manualEntryUser.queuedTimeoutSeconds,
          reviewTimeoutSeconds: manualEntryUser.reviewTimeoutSeconds,
          manualEntryConfigUid: manualEntryUser.manualEntryConfigUid,
          virtualWorkspaceConfigUid: manualEntryUser.virtualWorkspaceConfigUid,
          exclusiveQueueNamespaces: manualEntryUser.exclusiveQueueNamespaces,
        };
        if (res.data.manualEntryUser.queueNamespaces) {
          formVals.queueNamespaces =
            res.data.manualEntryUser.queueNamespaces.map((n) => ({
              queueNamespace: n,
            }));
        }
        if (manualEntryUser.country && manualEntryUser.country.uid) {
          formVals.countryUid = {
            value: manualEntryUser.country.uid,
            label: manualEntryUser.country.name,
            currencyUid: manualEntryUser.country.currencyUid || null,
          };
        }

        formData.values = formVals;
        //   if (
        //     manualEntryUser.pipelineBlocks &&
        //     manualEntryUser.pipelineBlocks.nodes
        //   ) {
        //     manualEntryUser.pipelineBlocks.nodes.forEach((pipelineBlock) => {
        //       if (!formData.pipelineMap[pipelineBlock.pipelineUid]) return;
        //       formData.pipelineMap[pipelineBlock.pipelineUid].pipelineBlocks[
        //         pipelineBlock.uid
        //       ] = { uid: pipelineBlock.uid, name: pipelineBlock.name };
        //       formVals.permissions.push({
        //         type: this.PERMISSION_TYPE_PIPELINE_BLOCK,
        //         uid: pipelineBlock.uid,
        //         parentUid: pipelineBlock.pipelineUid,
        //       });
        //     });
        //   }
      }
    }
    return formData;
  }

  async create(values) {
    let res = await this.api.mutate(
      `
      mutation CreateManualEntryUser($input: CreateManualEntryUserInput!) {
        createManualEntryUser(input: $input){
          manualEntryUser {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateManualEntryUser($input: UpdateManualEntryUserInput!) {
        updateManualEntryUser(input: $input){
          manualEntryUser {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );

    return true;
  }
  async approveUsers(uids, values) {
    let input = {
      uids,
      ...values,
      r14DeploymentConfigs: this.parseR14DeploymentConfigFormValues(
        values.r14DeploymentConfigs
      ),
    };
    console.log("APPROVE USER!", input);
    let res = await this.api.mutate(
      `
      mutation ApproveManualEntryUsers($input: ApproveManualEntryUsersInput!) {
        approveManualEntryUsers(input: $input){
          success
          error
        }
      }`,
      {
        input: input,
      }
    );

    if (
      res.data &&
      res.data.approveManualEntryUsers &&
      res.data.approveManualEntryUsers.error
    )
      throw new Error(res.data.approveManualEntryUsers.error);
    else if (res.error && res.errors.length) {
      throw new Error(res.error[1]);
    } else if (!res.data.approveManualEntryUsers) {
      throw new Error("Unknown error");
    }

    return res.data.success;
  }
  parseR14DeploymentConfigFormValues(r14DeploymentConfigs) {
    return r14DeploymentConfigs.map((d) => {
      delete d.virtualWorkspaceVisible;
      delete d.manualEntryVisible;
      d.queuedTimeoutSeconds = d.queuedTimeoutSeconds
        ? parseInt(d.queuedTimeoutSeconds)
        : null;
      return d;
    });
  }
  parseFormValues(values) {
    let updateValues = {
      username: values.username,
      name: values.name,
      mfaType: values.mfaType,
      email: values.email || null,
      phone: values.phone || null,
      source: values.source,
      active: values.active,
      countryUid: values.countryUid ? values.countryUid.value : null,
      currencyUid: values.countryUid ? values.countryUid.currencyUid : null,

      clientUid: this.dm.userSession.clientUid,
      //pipelineBlockUids: [],

      source: values.source || null,
      // r14DeploymentConfigs: values.r14DeploymentConfigs.map((d) => {
      //   delete d.virtualWorkspaceVisible;
      //   delete d.manualEntryVisible;
      //   d.queuedTimeoutSeconds = d.queuedTimeoutSeconds
      //     ? parseInt(d.queuedTimeoutSeconds)
      //     : null;
      //   return d;
      // }),
      // reviewTimeoutSeconds: parseInt(values.reviewTimeoutSeconds),
      // queuedTimeoutSeconds,
      // manualEntryConfigUid,
      // modes,
      // virtualWorkspaceConfigUid,
      // queueNamespaces: values.queueNamespaces
      //   ? values.queueNamespaces.map((n) => n.queueNamespace)
      //   : null,
      // exclusiveQueueNamespaces: values.exclusiveQueueNamespaces,
      lastUpdateSource: this.UPDATE_SOURCE_R14,
    };
    if (values.uid) updateValues.uid = values.uid;
    if (values.password) updateValues.password = values.password;

    if ("r14DeploymentConfigs" in values) {
      updateValues.r14DeploymentConfigs =
        this.parseR14DeploymentConfigFormValues(values.r14DeploymentConfigs);
    } else {
      updateValues = {
        ...updateValues,
        reviewTimeoutSeconds: parseInt(values.reviewTimeoutSeconds),
        queuedTimeoutSeconds: values.manualEntryConfigUid
          ? parseInt(values.queuedTimeoutSeconds)
          : null,
        manualEntryConfigUid: values.manualEntryConfigUid || null,
        modes: values.manualEntryConfigUid ? values.modes : null,
        virtualWorkspaceConfigUid: values.virtualWorkspaceConfigUid || null,
        queueNamespaces: values.queueNamespaces
          ? values.queueNamespaces.map((n) => n.queueNamespace)
          : null,
        exclusiveQueueNamespaces: values.exclusiveQueueNamespaces,
      };
    }

    console.log("check update values", updateValues);
    return updateValues;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteManualEntryUser($uid: ID!) {
        deleteManualEntryUser(uid: $uid){
          user {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  isActive(lastCharacterSetCompleteAt) {
    let diffMinutes =
      (new Date() - lastCharacterSetCompleteAt.getTime()) / 60000;
    return diffMinutes <= this.ACTIVE_MINUTES;
  }
  formatPaymentInfoAddress(address) {
    if (!address) return null;
    let parts = [
      address.address1,
      address.address2,
      address.city,
      address.county,
      address.state,
      address.country,
      address.postalCode,
    ];
    return parts.filter((p) => (p ? true : false)).join(", ");
  }
}
