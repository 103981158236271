import R14 from "../core";
export default class VirtualWorkspaceDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "virtualWorkspace",
    });
    this.RESOURCE_TYPE_AWS_APPSTREAM_2 = "AWS_APPSTREAM_2";
    this.PLATFORM_WINDOWS_SERVER_2019 = "WINDOWS_SERVER_2019";
    this.PLATFORM_AMAZON_LINUX_2 = "AMAZON_LINUX_2";
    this.INSTANCE_SIZE_SMALL = "SMALL";
    this.INSTANCE_SIZE_MEDIUM = "MEDIUM";
    this.INSTANCE_SIZE_LARGE = "LARGE";
    this.INSTANCE_SIZE_XLARGE = "XLARGE";
    this.INSTANCE_SIZE_XXLARGE = "XXLARGE";
    this.STATE_STOPPED = "STOPPED";
    this.STATE_STOPPING = "STOPPING";
    this.STATE_STARTING = "STARTING";
    this.STATE_RUNNING = "RUNNING";
    this.STATE_ERROR = "ERROR";
    this.METRIC_PARENT_TYPE_USER_SESSION = "USER_SESSION";
    this.METRIC_TYPE_TOTAL = "TOTAL";
  }
  async initDashboard(uid) {
    let virtualWorkspace = await this.dm.virtualWorkspace.get(
      uid,
      `
        uid
        name
        metrics {
          type
          parentType
          value
        }
        project {
          name
          type
          uid
        }
        virtualWorkspaceRegion {
          uid
          name
        }
        state
        lastSyncAt
        minCapacity
        maxCapacity
        desiredCapacity
        disconnectTimeout
        idleDisconnectTimeout
      `
    );
    return virtualWorkspace;
  }
  async fetchProjectData(uid=null){
    return await this.get(
      uid,
      `
        uid
        name
        project {
          uid
          name
          type
        }
      `
    );
  }
  async fetchEditFormData(uid = null, options = {}) {
    let fields = `
  uid
  name
  stackName
  fleetName
  minCapacity
  maxCapacity
  desiredCapacity
  disconnectTimeout
  idleDisconnectTimeout
  virtualWorkspaceRegion {
    uid
    name
  }
  virtualWorkspaceImage {
    uid
    name
  }
  cloudAccessKey {
    uid
    name
  }
  project {
    uid
    name
  }
`;
    let res = uid
      ? await this.get(uid, fields)
      : {
          maxCapacity: 5,
          minCapacity: 1,
          desiredCapacity: 1,
          disconnectTimeout: 900,
          idleDisconnectTimeout: 900,
        };
    if (res.virtualWorkspaceRegion)
      res.virtualWorkspaceRegion = {
        label: res.virtualWorkspaceRegion.name,
        value: res.virtualWorkspaceRegion.uid,
      };
    if (res.virtualWorkspaceRegion)
      res.virtualWorkspaceImage = {
        label: res.virtualWorkspaceImage.name,
        value: res.virtualWorkspaceImage.uid,
      };
    if (res.cloudAccessKey)
      res.cloudAccessKey = {
        label: res.cloudAccessKey.name,
        value: res.cloudAccessKey.uid,
      };
    if (res.project)
      res.project = {
        label: res.project.name,
        value: res.project.uid,
      };
    let formData = {
      values: res,
    };
    return formData;
  }
  parseSubmitValues(values) {
    let ret = { ...values };
    if (values.cloudAccessKey) {
      ret.cloudAccessKeyUid = values.cloudAccessKey.value;
      delete ret.cloudAccessKey;
    }
    if (values.project) {
      ret.projectUid = values.project.value;
      delete ret.project;
    }
    if (values.virtualWorkspaceRegion) {
      ret.virtualWorkspaceRegionUid = values.virtualWorkspaceRegion.value;
      delete ret.virtualWorkspaceRegion;
    }
    if (values.virtualWorkspaceImage) {
      ret.virtualWorkspaceImageUid = values.virtualWorkspaceImage.value;
      delete ret.virtualWorkspaceImage;
    }
    ret.clientUid = this.dm.userSession.clientUid;
    ret.resourceType = this.dm.virtualWorkspace.RESOURCE_TYPE_AWS_APPSTREAM_2;
    // Parse int values
    [
      "minCapacity",
      "maxCapacity",
      "desiredCapacity",
      "disconnectTimeout",
      "idleDisconnectTimeout",
    ].map((field) => {
      if (field in values) ret[field] = parseInt(values[field]);
    });
    console.log("check parse", ret);
    return ret;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_RUNNING:
        label = "Running";
        break;
      case this.STATE_STARTING:
        label = "Starting";
        break;
      case this.STATE_STOPPING:
        label = "Stopping";
        break;
      case this.STATE_STOPPED:
        label = "Stopped";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_STARTING:
      case this.STATE_STOPPING:
        color = "yellow";
        break;
      case this.STATE_RUNNING:
        color = "green";
        break;
      case this.STATE_STOPPED:
      case this.STATE_ERROR:
        color = "red";
        break;
    }
    return color;
  }
}
