import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  ShowHideText,
  SwitchField,
  IconButton,
  Dialog,
  Form,
  NavigationContainer,
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";
import AppModuleEditForm from "../components/AppModuleEditForm";

export default R14.connect(
  class BlockEditForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleIoValueFormat = this.handleIoValueFormat.bind(this);
      this.ioItemRenderer = this.ioItemRenderer.bind(this);
      this.dataItemRenderer = this.dataItemRenderer.bind(this);
      this.optionsItemRenderer = this.optionsItemRenderer.bind(this);
      this.appModuleItemLoader = this.appModuleItemLoader.bind(this);
      this.cloudAccessKeyItemLoader = this.cloudAccessKeyItemLoader.bind(this);
      this.handleRemoveBlockIoItem = this.handleRemoveBlockIoItem.bind(this);
      this.handleFieldTypeChange = this.handleFieldTypeChange.bind(this);
      this.handleAppModuleValueChange =
        this.handleAppModuleValueChange.bind(this);
      this.handleCreateAppModulePress =
        this.handleCreateAppModulePress.bind(this);
      this.handleCreateAppModuleDialogClosePress =
        this.handleCreateAppModuleDialogClosePress.bind(this);
      this.handleCreateAppModuleDialogOkPress =
        this.handleCreateAppModuleDialogOkPress.bind(this);
      this.handleCreateAppModuleSubmit =
        this.handleCreateAppModuleSubmit.bind(this);

      this.pipelineDomain = this.props.app.dm.pipeline;
      this.state = {
        createAppModuleDialogVisible: false,
      };
      this.createAppModuleFormData = null;
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get form() {
      return this.props.app.ui.form.blockForm || {};
    }
    get formData() {
      return this.props.formData;
    }
    async appModuleItemLoader(filters) {
      let res = await this.props.app.dm.block.fetchAppModuleSelections(filters);
      return res;
    }
    async cloudAccessKeyItemLoader(filters = {}) {
      return await this.props.app.dm.cloudAccessKey.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    async handleAppModuleValueChange(val) {}
    async handleRemoveBlockIoItem(item) {
      this.props.onRemoveBlockIoItem && this.props.onRemoveBlockIoItem(item);
    }
    async handleCreateAppModuleSubmit(form) {
      try {
        this.props.app.ui.progressIndicator.show();
        let res = await this.props.app.dm.appModule.create(form.values);
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });

        if (res.success) {
          let selectVal = {
            label: res.appModule.name,
            value: res.appModule.uid,
          };
          this.form.elmts("appModule").setValue(selectVal);
          this.handleAppModuleValueChange(selectVal);
          this.setState({ createAppModuleDialogVisible: false });
          this.props.app.ui.snackBar.show({
            message: `Created App Module: ${selectVal.label}.`,
            variant: "success",
          });
        } else if (res.errors && res.errors.length) {
          form.addError(res.errors[0].message);
        } else form.addError("An unknown error has occurred.");
      } catch (err) {
        form.addError(err.message);
      }
    }
    async handleCreateAppModulePress(value) {
      if (!this.createAppModuleFormData) {
        this.props.app.ui.progressIndicator.show();
        // console.log("THIS PROPS", this.props);
        // this.createAppModuleFormData = await this.props.app.dm.appModule
        //   .fetchEditFormData
        //   // null,
        //   // { projectType: this.props.projectType }
        //   ();
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
      }
      this.setState({ createAppModuleDialogVisible: true });
    }
    async handleCreateAppModuleDialogClosePress() {
      this.setState({ createAppModuleDialogVisible: false });
    }
    async handleCreateAppModuleDialogOkPress() {
      this.props.app.ui.form("createAppModuleForm").submit();
    }

    handleFieldTypeChange(type) {
      console.log(type);
    }

    optionsItemRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='label' label='Label'>
              <Text>{values.label}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='type' label='Type'>
              <Text>
                {this.props.app.dm.block.getFieldTypeLabel(values.type)}
              </Text>
            </LabelView>
            {values.required && (
              <LabelView
                style={styles.ioItemLabelView}
                key='required'
                label='Required'
              >
                <Text>{values.required ? "Yes" : "No"}</Text>
              </LabelView>
            )}
          </View>
        </View>
      );
    }

    handleIoValueFormat(values) {
      return values;
    }
    ioItemRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='name' label='Name'>
              <Text>{values.name}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView
              style={styles.ioItemLabelView}
              key='datatype'
              label='Data Type'
            >
              <Text>
                {this.props.app.dm.block.getIoDataTypeLabel(values.dataType)}
              </Text>
            </LabelView>
            <LabelView
              style={styles.ioItemLabelView}
              key='dataStructureType'
              label='Queue Type'
            >
              <Text>
                {this.props.app.dm.block.getIoDataStructureTypeLabel(
                  values.dataStructureType
                )}
              </Text>
            </LabelView>
          </View>
          {values.description && (
            <View style={styles.ioItemRowBottom}>
              <LabelView
                style={styles.ioItemLabelView}
                key='description'
                label='Description'
              >
                <ShowHideText text={values.description} />
              </LabelView>
            </View>
          )}
        </View>
      );
    }
    dataItemRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='name' label='Name'>
              <Text>{values.name}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='type' label='Type'>
              <Text>
                {this.props.app.dm.block.getDataTypeLabel(values.type)}
              </Text>
            </LabelView>
          </View>
          {values.description && (
            <View style={styles.ioItemRowBottom}>
              <LabelView
                style={styles.ioItemLabelView}
                key='description'
                label='Description'
              >
                <ShowHideText text={values.description} />
              </LabelView>
            </View>
          )}
        </View>
      );
    }
    renderIoForm() {
      return (
        <React.Fragment>
          <HiddenInputField name='uid' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='key'
              label='Key'
              validator={{
                key: true,
              }}
              required='Please enter a key'
              helper='Used by App Module'
            />
            <SelectMenuField
              useValuesOnly
              name='dataType'
              label='Data Type'
              required='Select data type'
              items={this.props.formData.blockIoDataTypeSelections}
            />
          </FieldGroup>
          <SelectMenuField
            useValuesOnly
            name='dataStructureType'
            label='Queue Type'
            required='Select queue type'
            items={this.props.formData.blockIoDataStructureTypeSelections}
          />
        </React.Fragment>
      );
    }
    renderDataForm() {
      return (
        <React.Fragment>
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='key'
              label='Key'
              validator={{
                key: true,
              }}
              required='Please enter a key'
              helper='Used by App Module'
            />
            <SelectMenuField
              useValuesOnly
              name='type'
              label='Type'
              required='Select a type'
              items={this.props.formData.blockDataTypeSelections}
            />
          </FieldGroup>
        </React.Fragment>
      );
    }
    render() {
      return (
        <View>
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectType' />
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Enter Name'
              helper='Required, must be unique.'
            />
            <TextInputField name='description' label='Description' />
          </FieldGroup>

          <View style={styles.appModuleField}>
            <SelectMenuField
              name='appModule'
              label='App Module'
              searchable
              autoLoad
              itemLoader={this.appModuleItemLoader}
              items={this.formData.appModuleSelections}
              required='Please select an app module.'
              helper='Required'
            />
            <IconButton
              tooltip='Create App Module'
              onPress={this.handleCreateAppModulePress}
              icon='add'
              variant='circle'
              // disabled
              style={styles.appModuleCreateButton}
            />
          </View>
          <Dialog
            name='createAppModuleDialog'
            title='Create App Module'
            visible={this.state.createAppModuleDialogVisible}
            titleControlVariant='back'
            style={styles.formDialog}
            contentStyle={styles.formDialogContent}
            onClosePress={this.handleCreateAppModuleDialogClosePress}
            onBackPress={this.handleCreateAppModuleDialogClosePress}
            onBackdropPress={this.handleCreateAppModuleDialogClosePress}
            hideOkButton
            hideCancelButton
            // onBackPress={this.handleCreateAppModuleDialogClosePress}
            // onOkPress={this.handleCreateAppModuleDialogOkPress}
            // okButtonTitle={"Create"}
            // OkButtonComponent={<SubmitButton title='Create'}
          >
            <NavigationContainer
              name='appModuleCreateContainer'
              initialParams={{
                onSubmit: this.handleCreateAppModuleSubmit,
                projectType: this.props.projectType,
              }}
            />
            {/* <Form
              name='createAppModuleForm'
              style={styles.createAppModuleForm}
              onSubmit={this.handleCreateAppModuleSubmit}
              validateBeforeSubmit
              // validators={FormValidators}
              initialValues={
                this.createAppModuleFormData &&
                this.createAppModuleFormData.values
              }
            >
              <AppModuleEditForm
                formData={this.createAppModuleFormData}
                // onAfterSave={}
              />
            </Form> */}
          </Dialog>
          {/* <NavigationContainer
              name='appModuleCreateContainer'
              initialParams={{
                onSubmit: this.handleCreateAppModuleSubmit,
                projectType: this.props.projectType,
              }}
            /> */}
          <SelectMenuField
            key='cloudAccessKey'
            name='cloudAccessKey'
            label='Cloud Access Key'
            searchable
            autoLoad
            itemLoader={this.cloudAccessKeyItemLoader}
          />
          <SwitchField
            key='selectableCloudAccessKey'
            name='selectableCloudAccessKey'
            label='Selectable Cloud Access Key'
            onValueChange={this.handleSelectableCloudAccessKeyValueChange}
            helper='Cloud access key selectable in pipeline block.'
            // value={this.state.useDefaultResource}
          />
          {/* {this.props.formData.cloudAccessKeySelections.length ? (
            <SelectMenuField
              useValuesOnly
              name='cloudAccessKeyUid'
              label='Cloud Access Key'
              items={this.props.formData.cloudAccessKeySelections}
            />
          ) : null} */}
          <DialogFormField
            name='inputs'
            dialogTitle='Add Input'
            label='Inputs'
            formName='blockForm'
            itemRenderer={this.ioItemRenderer}
            onRemoveItem={this.handleRemoveBlockIoItem}
            validator='uniqueKey'
            // onValueChange={(val) => {
            //   console.log("INPUT VALUE CHANGE", val);
            // }}
          >
            {this.renderIoForm()}
          </DialogFormField>
          <DialogFormField
            name='outputs'
            dialogTitle='Add Output'
            label='Outputs'
            formName='blockForm'
            validator='uniqueKey'
            itemRenderer={this.ioItemRenderer}
            onRemoveItem={this.handleRemoveBlockIoItem}
          >
            {this.renderIoForm()}
          </DialogFormField>
          <DialogFormField
            name='data'
            dialogTitle='Add Data'
            label='Data'
            formName='blockForm'
            validator='uniqueKey'
            itemRenderer={this.dataItemRenderer}
            // onRemoveItem={this.handleRemoveBlockDataItem}
          >
            {this.renderDataForm()}
          </DialogFormField>
          <DialogFormField
            name='options'
            dialogTitle='Add Option'
            label='Options'
            formName='blockForm'
            itemRenderer={this.optionsItemRenderer}
            validator='uniqueKey'
            sortable
          >
            <TextInputField name='label' label='Label' required='Enter Label' />
            <TextInputField
              name='key'
              label='Key'
              validator='key'
              required='Enter Key'
            />
            <SwitchField
              key='isRequired'
              name='required'
              label='Is Required?'
            />
            <SelectMenuField
              useValuesOnly
              name='type'
              label='Field Type'
              items={this.formData.fieldTypeSelections}
              required='Please select a field type.'
              // onValueChange={this.handleFieldTypeChange}
            />
            <TextInputField
              name='helperText'
              label='Field Helper Text'
              helper='Text displayed under field'
            />
          </DialogFormField>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  formDialogContent: {
    marginTop: -16,
    ...StyleSheet.padding(0)
  },
  formDialog: {
    maxWidth: 640
  },
  ioItemRow: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: -16,
  },
  ioItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 8,
  },
  ioItemLabelView: {
    width: "50%",
    minWidth: 0,
    flex: 1,
    marginBottom: 12,
  },
  appModuleField: {
    flex: 1,
    flexDirection: "row",
  },
  appModuleCreateButton: {
    height: 36,
    width: 36,
    ...StyleSheet.margin(10, 8, 0, 12),
  },
});
