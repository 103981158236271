import React from "react";
import R14, {
  ScreenLayout,
  View,
  SelectMenuField,
  Form,
  SectionHeader,
  StyleSheet
} from "../core";
import LogViewer from "../components/LogViewer";
import AppModuleLogViewer from "../components/AppModuleLogViewer";

export default R14.connect(
  class ProjectsLogsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.project = this.props.project;
      this.appModuleDomain = this.props.app.dm.appModule;
      this.textLoader = this.textLoader.bind(this);
      this.handleLogLayout = this.handleLogLayout.bind(this);
      this.handleAppModuleValueChange = this.handleAppModuleValueChange.bind(
        this
      );
      this.state = {
        appModuleUid: null,
        logType: null,
        logHeight: null
      };
    }
    async textLoader() {
      let log = await this.props.app.dm.appModule.fetchServerLog(
        this.state.appModuleUid,
        this.state.logType
      );
      return log.text;
    }
    async textStreamer(stream) {
      this.appModuleLogSubscription = await this.props.app.dm.appModule.onStreamLog(
        this.props.appModuleUid,
        this.props.logType,
        ({ text }) => stream(text)
      );
    }
    handleLogLayout(event) {
      this.setState({ logHeight: event.nativeEvent.layout.height });
    }
    handleAppModuleValueChange(val) {
      let appModuleUid = null;
      let logType = null;
      if (val !== "default-select") {
        let vals = val.split(",");
        appModuleUid = vals[0];
        logType = vals[1];
      }
      this.setState({
        appModuleUid,
        logType
      });
    }
    // get defaultSelection() {
    //   let defaultSelection = 'default-select';
    //   for(let val of this.project.appModules.nodes)
    //   this.project.appModules.nodes.forEach(val=>{

    //   });
    // }
    get appModuleSelections() {
      let logSelections = [];
      this.project.appModules.nodes.forEach(val => {
        switch (val.type) {
          case this.appModuleDomain.TYPE_SERVER:
            logSelections.push({
              label: `${val.name}: Server`,
              value: `${val.uid},${this.appModuleDomain.SERVER_TYPE_SERVER}`
            });
            break;
          case this.appModuleDomain.TYPE_REACT_APP:
            logSelections.push({
              label: `${val.name}: Web`,
              value: `${val.uid},${this.appModuleDomain.SERVER_TYPE_WEB}`
            });
            logSelections.push({
              label: `${val.name}: Watch`,
              value: `${val.uid},${this.appModuleDomain.SERVER_TYPE_WATCH}`
            });
            break;
        }
      });
      return [
        { label: "Select Log", value: "default-select" },
        ...logSelections
      ];
    }
    render() {
      console.log(this.state.logHeight);
      return (
        <ScreenLayout>
          <View style={styles.appModuleLogs}>
            <View style={styles.header}>
              <Form
                name='appModuleLogForm'
                validateBeforeSubmit
                style={styles.appModuleLogForm}
                initialValues={{
                  appModuleUid: "default-select"
                }}
              >
                <SectionHeader level={2} style={styles.title}>
                  App Module Log
                </SectionHeader>

                <SelectMenuField
                  useValuesOnly
                  name='appModuleUid'
                  items={this.appModuleSelections}
                  onValueChange={this.handleAppModuleValueChange}
                  style={styles.headerSelectMenu}
                  containerStyle={styles.headerSelectMenuContainer}
                />
              </Form>
            </View>
            <View style={styles.log} onLayout={this.handleLogLayout}>
              {this.state.appModuleUid && (
                <AppModuleLogViewer 
                  key={`${this.state.appModuleUid}-${this.state.logType}`}
                  uid={this.state.appModuleUid}
                  logType={this.state.logType}
                  style={styles.logViewer}
                  height={this.state.logHeight}
                />
                // <LogViewer
                 
                //   style={styles.logViewer}
                //   height={this.state.logHeight}
                //   textLoader={this.textLoader}
                //   autoRefresh={10}
                // />
              )}
            </View>
          </View>
        </ScreenLayout>
      );
    }
  }
);

const styles = StyleSheet.create({
  appModuleLogs: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  log: {
    flex: 1,
    position:'relative'
  },
  logViewer: {
    width: "100%",
    // height: "100%",
    // ...StyleSheet.absoluteFill
  },
  appModuleLogForm: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...StyleSheet.padding(0, 12, 0, 16)
  },
  headerSelectMenu: {
    flex: 0,
    // width: 192,
    minWidth: 80,
    marginBottom: 0
    // ...StyleSheet.margin(-12, 0, 0, -12)
  },
  headerSelectMenuContainer: {
    borderColor: "transparent"
    // borderBottomColor: '#FFF'
  },
  title: {
    fontSize: 18,
    fontWeight: "500",
    ...StyleSheet.padding(0),
    marginRight: "auto"
  }
});
