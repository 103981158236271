import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectDocumentTemplateDataTable from "../components/ProjectDocumentTemplateDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectsDocumentTemplateScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    get projectKey() {
      return;
    }
    get perms() {
      return this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.project.type),
        "documentTemplate"
      );
    }
    render() {
      return (
        <ProjectDocumentTemplateDataTable {...this.props} perms={this.perms} />
      );
    }
  }
);
