import React from "react";
import R14, {
  SelectMenuField,
} from "../core";
export default R14.connect(
  class UserSelectMenuField extends React.Component {
    constructor(props) {
      super(props);
      this.userItemLoader = this.userItemLoader.bind(this);
    }
    async userItemLoader(filters) {
      return await this.props.app.dm.project.fetchUserSelections(filters);
    }
    render() {
      return (
        <SelectMenuField
          name='users'
          label='Users'
          multiple
          searchable
          itemLoader={this.userItemLoader}
          autoLoad
          // items={this.formData.userSelections}
        />
      );
    }
  }
);
