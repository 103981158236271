import R14 from "../core";
export default class DocumentTemplateDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "documentTemplate",
    });
    this.state = {};
  }
  async get(uid, fields) {
    if (!fields) fields = [("uid", "name", "description")];
    return await super.get(uid, fields);
  }
  async fetchEditFormData(uid = null, options = {}) {
    let qry = "";
    let qryVals = {
      userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
    };
    if (uid) {
      qry = `
      query DocumentTemplateCreateFormData($uid: ID!, $userFilter: UserFilter) {
        documentTemplate(uid: $uid){
          uid
          key
          name
          description
          image {
            key
            uid
            name
            height
            width
          }
          userUids
        },
        users(filter:$userFilter) {
          nodes {
            uid
            name
          }
        }
      }
    `;
      qryVals.uid = uid;
    } else {
      qry = `
        query DocumentTemplateEditFormData($userFilter: UserFilter) {
          users(filter:$userFilter) {
            nodes {
              uid
              name
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = res.data.documentTemplate || {};
    if (formVals.image) {
      formVals.image = {
        value: formVals.image.uid,
        label: formVals.image.name,
      };
    }
    if (options.projectUid) formVals.projectUid = options.projectUid;
    let formData = {
      values: formVals,
      userSelections: res.data.users.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
    };
    return formData;
  }

  // parseSubmitValues(values){
  //   console.log("GEST VALUES", values);
  //   throw new Error('lskdjflksjdlfkjsdf');
  // }

  parseSubmitValues(values) {
    let updateValues = { ...values };
    console.log("DO SOMEHTING ABOUT IMAGERS SO THAt you DON'T NEED TO PARSE ");
    updateValues.clientUid = this.dm.userSession.clientUid;
    if (values.image && !this.utils.form.isFile(values.image))
      delete updateValues.profileImage;
    return updateValues;
  }
}
