import R14 from "../core";
export default class VirtualWorkspaceRegionDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "virtualWorkspaceRegion",
    });
  }
  async fetchSelections(filters, options = {}) {
    if (filters.resourceType)
      filters.resourceType = { eq: filters.resourceType };
    return await super.fetchSelections(filters, options);
  }
  async fetchEditFormData(uid = null, options = {}) {
    let fields = `
  uid
  name
  description
  key
  subdomain
`;
    let formDataValues = {};
    if (uid) {
      let res = await this.get(uid, fields);
      formDataValues = res;
    }
    return {
      values: formDataValues,
    };
  }
  parseSubmitValues(values) {
    values.clientUid = this.dm.userSession.clientUid;
    values.resourceType = this.dm.virtualWorkspace.RESOURCE_TYPE_AWS_APPSTREAM_2;
    return values;
  }
}
