import React from "react";
import R14, { StyleSheet, ScreenLayout } from "../core";
import R14LicenseDataTable from "../components/R14LicenseDataTable";
export default R14.connect(
  class R14LicenseScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions("admin", "r14License");
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={
            this.perms.create
              ? {
                  to: "r14LicenseCreate",
                  icon: "add",
                }
              : null
          }
          scrollEnabled
        >
          <R14LicenseDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({});
