import React from "react";
import R14, {
  StyleSheet,
  Chart,
  Card,
  ActivityIndicator,
  View,
  IconButton,
  Dialog,
  Form,
  SelectMenuField,
  DatePickerField,
  ChipGroup,
  Chip,
  HiddenInputField,
  Text,
} from "../core";
import HeaderLabelSelectMenu from "../components/HeaderLabelSelectMenu";
import ManualEntryUsageMetricDataTable from "../components/ManualEntryUsageMetricDataTable";
import ManualEntryUsageMetricChart from "../components/ManualEntryUsageMetricChart";

import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectManualEntryUsageMetricsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleFiltersFormDialogClosePress =
        this.handleFiltersFormDialogClosePress.bind(this);
      this.handleFiltersFormDialogShowPress =
        this.handleFiltersFormDialogShowPress.bind(this);
      this.handleFiltersFormDialogOkPress =
        this.handleFiltersFormDialogOkPress.bind(this);
      this.usageMetricsDomain =
        this.props.app.dm.manualEntryPipelineUsageMetric;
      this.handlePeriodValueChange = this.handlePeriodValueChange.bind(this);
      this.handleFiltersFormSubmit = this.handleFiltersFormSubmit.bind(this);
      this.pipelineDomain = this.props.app.dm.manualEntryPipeline;
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.project.type),
        "manualEntryPipeline"
      );

      this._chart = {
        period: null,
        interval: {
          unit: null,
          value: null,
        },
        startAt: null,
        endAt: null,
        //filterValues: {},
      };
      this.state = {
        // chartMetricData: null,
        // chartStartAt: null,
        // chartEndAt: null,
        chartLoaded: false,
        filtersFormDialogVisible: false,
        // filterValues: {
        //   ...this.filtersFormData.values,
        // },
      };
    }
    componentDidMount() {
      this.loadMetricsChart(this.filtersFormData.values);
    }
    async loadMetricsChart(filterValues = null) {
      // Check if change should be loaded
      let currFilterValues = this._chart;
      console.log("COMPARE");

      let shouldLoad =
        currFilterValues.period !== filterValues.period ||
        (currFilterValues.period ===
          this.usageMetricsDomain.FILTER_PERIOD_CUSTOM &&
          (currFilterValues.startAt !== filterValues.startAt ||
            currFilterValues.endAt !== filterValues.endAt));

      this.setState({
        chartLoaded: false,
      });

      if (shouldLoad) {
        let period = filterValues.period;
        let filters = {};
        switch (period) {
          case this.usageMetricsDomain.FILTER_PERIOD_CUSTOM:
            let currAt = new Date();
            let startAt = new Date(filterValues.startAt);
            startAt.setHours(0, 0, 0, 0);
            let endAt = new Date(filterValues.endAt);
            endAt.setHours(23, 59, 59, 999);
            if (endAt > currAt) endAt = currAt;
            filters = {
              period,
              startAt,
              endAt,
            };
            break;
          default:
            filters = { period };
        }
        let res = await this.usageMetricsDomain.fetchTimeSeries(
          this.props.manualEntryPipeline.uid,
          filters
        );
        this._chart = {
          ...this._chart,
          ...res,
        };
      }

      this.setState({
        // chartMetricData: res.fieldMap,
        // chartStartAt: startAt,
        // chartEndAt: endAt,
        chartPeriod: filterValues.period,
        chartLoaded: true,
      });
    }
    get filtersFormData() {
      return this.props.filtersFormData;
    }
    get periodSelections() {
      return this.filtersFormData.periodSelections.map(
        (period) => {
          let ret = period;
          if (
            this._chart.startAt &&
            this._chart.endAt &&
            this.state.chartPeriod ===
              this.usageMetricsDomain.FILTER_PERIOD_CUSTOM &&
            period.value === this.usageMetricsDomain.FILTER_PERIOD_CUSTOM
          ) {
            let startAt = this.props.app.utils.date.toShortDate(
              this._chart.startAt
            );
            let endAt = this.props.app.utils.date.toShortDate(
              this._chart.endAt
            );
            ret = {
              label: startAt === endAt ? startAt : `${startAt} to ${endAt}`,
              value: period.value,
            };
          }

          return ret;
        }
        // this._chart.startAt &&
        // this._chart.endAt &&
        // this.state.chartPeriod ===
        //   this.usageMetricsDomain.FILTER_PERIOD_CUSTOM &&
        // period.value === this.usageMetricsDomain.FILTER_PERIOD_CUSTOM
        //   ? {
        //       label: `${this.props.app.utils.date.toShortDate(
        //         this._chart.startAt
        //       )} to ${this.props.app.utils.date.toShortDate(
        //         this._chart.endAt
        //       )}`,
        //       value: period.value,
        //     }
        //   : period
      );
    }
    handleFiltersFormDialogOkPress() {
      this.setFiltersFormDialogVisible(false);
      this.props.app.ui.form("filtersForm").submit();
    }
    handleFiltersFormDialogClosePress() {
      this.setFiltersFormDialogVisible(false);
    }
    handleFiltersFormDialogShowPress() {
      this.setFiltersFormDialogVisible(true);
    }
    async handleFiltersFormSubmit(form) {
      await this.loadMetricsChart(form.values);
      return false;
    }
    async handlePeriodValueChange({ value }) {
      switch (value) {
        case this.usageMetricsDomain.FILTER_PERIOD_CUSTOM:
          this.setFiltersFormDialogVisible(true);
          break;
        default:
          let filterVals = { ...this._chart };
          filterVals.period = value;
          filterVals.startAt = null;
          filterVals.endAt = null;
          await this.loadMetricsChart(filterVals);
      }
    }
    setFiltersFormDialogVisible(visible) {
      this.setState({ filtersFormDialogVisible: visible });
    }
    // filterMetricChartData(parentTypes, types) {
    //   let ret = [];
    //   parentTypes
    //     .filter((parentType) =>
    //       this._chart.filterValues.parentTypes.includes(parentType)
    //     )
    //     .forEach((parentType) =>
    //       types.forEach((type) => {
    //         let fieldName = this.usageMetricsDomain.getMetricFieldName(
    //           parentType,
    //           type
    //         );
    //         this._chart.fieldMap[
    //           this.usageMetricsDomain.getMetricFieldName(parentType, type)
    //         ] && ret.push(this._chart.fieldMap[fieldName]);
    //       })
    //     );
    //   return {
    //     nodes: ret,
    //   };
    // }

    // renderChart(name, title, parentTypes, types) {
    //   console.log("render charet", { name, title, parentTypes, types });
    //   let data = this.filterMetricChartData(parentTypes, types);
    //   console.log("CHECK DATA", data);
    //   if (!data || !data.nodes || !data.nodes.length) return null;

    //   console.log("CHECK CHART INFO!!!!!!!!!", {
    //     currDate: new Date(),
    //     dateRangeStart: this._chart.startAt,
    //     dateRangeEnd: this._chart.endAt,
    //     intervalUnit: this._chart.interval.unit.toLowerCase(),
    //     intervalValue: this._chart.interval.value,
    //   });
    //   return (
    //     <Card
    //       title={title}
    //       titleTextStyle={styles.cardTitleText}
    //       style={styles.metricsChartSurface}
    //     >
    //       <ChipGroup>
    //         <Chip
    //           key={`metric`}
    //           label={"Test"}
    //           selected={true}
    //           onPress={() => alert("here")}
    //         />
    //       </ChipGroup>

    //       <Chart
    //         name={name}
    //         data={data}
    //         style={styles.metricsChart}
    //         height={300}
    //         type='timeSeries'
    //         dateAxis={{
    //           dateRangeStart: this._chart.startAt,
    //           dateRangeEnd: this._chart.endAt,
    //           intervalUnit: this._chart.interval.unit.toLowerCase(),
    //           intervalValue: this._chart.interval.value,
    //           labelFormats: {
    //             minute: "h:mm a",
    //             hour: "h:mm a",
    //             day: "MMMM d, yyyy",
    //           },
    //         }}
    //       />
    //     </Card>
    //   );
    // }
    renderCharts() {
      if (!this.state.chartLoaded)
        return (
          <ActivityIndicator
            size='large'
            containerStyle={styles.activityIndicator}
          />
        );
      let chartProps = {
        fieldMap: this._chart.fieldMap,
        interval: this._chart.interval,
        period: this._chart.period,
        startAt: this._chart.startAt,
        endAt: this._chart.endAt,
      };
      return (
        <View>
          <ManualEntryUsageMetricChart
            name='modeTotalsChart'
            key='modeTotalsChart'
            title='Active Totals'
            parentTypes={[
              this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
              this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
              this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
              this.pipelineDomain.METRIC_PARENT_TYPE_USER,
              this.pipelineDomain.METRIC_PARENT_TYPE_DOCUMENT,
              this.pipelineDomain.METRIC_PARENT_TYPE_DOCUMENT_SET,
            ]}
            types={[this.pipelineDomain.METRIC_TYPE_TOTAL]}
            {...chartProps}
          />
          <ManualEntryUsageMetricChart
            name='timeoutsChart'
            key='timeoutsChart'
            title='Timeout Minutes'
            parentTypes={[
              this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
              this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
              this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
            ]}
            types={[this.pipelineDomain.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS]}
            {...chartProps}
          />
          <ManualEntryUsageMetricChart
            name='usersRecommendedChart'
            key='usersRecommendedChart'
            title='Recommended Users'
            parentTypes={[
              this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
              this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
              this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
            ]}
            types={[this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED]}
            {...chartProps}
          />
          <ManualEntryUsageMetricChart
            name='usersChart'
            key='usersChart'
            title='Users'
            parentTypes={[this.pipelineDomain.METRIC_PARENT_TYPE_USER]}
            types={[
              this.pipelineDomain.METRIC_TYPE_TOTAL,
              this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED,
            ]}
            {...chartProps}
          />
        </View>
      );
    }
    getInitialFilterFormValues() {
      // let values =  {
      //   ...this._chart,
      //   period: this.usageMetricsDomain.FILTER_PERIOD_CUSTOM,
      // };
      let values = {
        period: this.usageMetricsDomain.FILTER_PERIOD_CUSTOM,
      };
      switch (this._chart.period) {
        case this.usageMetricsDomain.FILTER_PERIOD_CUSTOM:
          values.startAt = this.props.app.utils.date.toShortDate(
            this._chart.startAt
          );
          values.endAt = this.props.app.utils.date.toShortDate(
            this._chart.endAt
          );
          break;
      }
      return values;
    }
    renderFiltersDialog() {
      return (
        <Dialog
          name='filtersFormDialog'
          title='Custom Range'
          visible={this.state.filtersFormDialogVisible}
          titleControlVariant='back'
          onClosePress={this.handleFiltersFormDialogClosePress}
          onCancelPress={this.handleFiltersFormDialogClosePress}
          onBackdropPress={this.handleFiltersFormDialogClosePress}
          onBackPress={this.handleFiltersFormDialogClosePress}
          onOkPress={this.handleFiltersFormDialogOkPress}
          okButtonTitle={"Filter"}
        >
          <Form
            name='filtersForm'
            style={styles.filtersForm}
            onSubmit={this.handleFiltersFormSubmit}
            validateBeforeSubmit
            initialValues={this.getInitialFilterFormValues()}
          >
            {/* <SelectMenuField
              useValuesOnly
              name='parentTypes'
              label='Metrics'
              multiple
              items={this.filtersFormData.parentTypeSelections}
            />
            <SelectMenuField
              useValuesOnly
              name='period'
              label='Period'
              items={this.filtersFormData.periodSelections}
            /> */}
            <HiddenInputField name={`period`} />
            <DatePickerField
              name='startAt'
              label='Start At'
              required='Please select start at date.'
              // minDate={new Date()}
            />
            <DatePickerField
              name='endAt'
              label='End At'
              required='Please select the end at date.'
              maxDate={new Date()}
            />
          </Form>
        </Dialog>
      );
    }
    render() {
      let project = this.props.project;
      return (
        <ProjectScreenLayout
          project={project}
          headerTitle='Usage Metrics'
          headerControlsRight={[
            // <IconButton
            //   icon='filter'
            //   key='filter'
            //   tooltip={`Filters`}
            //   // size='small'
            //   onPress={this.handleFiltersFormDialogShowPress}
            // />,
            <HeaderLabelSelectMenu
              key='headerLabel'
              value={this.state.chartPeriod}
              style={styles.headerLabelSelectMenu}
              searchable={false}
              useValuesOnly
              headerLabelTextStyle={styles.headerLabelTextStyle}
              items={this.periodSelections}
              onValueChange={this.handlePeriodValueChange}
            />,
          ]}
          breadCrumbs={[
            {
              label: "Manual Entry",
              to: {
                route: "projectManualEntryPipelines",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                },
              },
            },
            {
              label: this.props.manualEntryPipeline.name,
              to: {
                route: "projectManualEntryPipeline",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                  manualEntryPipelineUid: this.props.manualEntryPipeline.uid,
                },
              },
            },
          ]}
          // floatingActionButton={
          //   this.perms.create
          //     ? {
          //         to: "manualEntryUserCreate",
          //         icon: "add",
          //       }
          //     : null
          // }
          scrollEnabled
          // DataTableComponent={
          //   <ManualEntryUsageMetricDataTable
          //     manualEntryPipelineUid={this.props.manualEntryPipeline.uid}
          //   />
          // }
        >
          {this.renderFiltersDialog()}
          {this.renderCharts()}
          {/* {this.state.chartLoaded && (
            <Surface style={styles.metricsChartSurface}>
              <Chart
                name='usageMetricsChart'
                data={this._chart.fieldMap}
                style={styles.metricsChart}
                height={300}
                type='timeSeries'
                dateAxis={{
                  dateRangeStart: this.state.chartStartAt,
                  dateRangeEnd: this.state.chartEndAt,
                  intervalUnit: "minute",
                  intervalValue: 15,
                  labelFormats: {
                    minute: "h:mm a",
                    hour: "h:mm a",
                    day: "MMMM d, yyyy",
                  },
                }}
              />
            </Surface>
          )}
          {!this.state.chartLoaded && (
            <ActivityIndicator
              size='large'
              containerStyle={styles.activityIndicator}
              // height={this.currLayout ? this.currLayout.height : null}
              // width={this.currLayout ? this.currLayout.width : null}
            />
          )} */}
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  cardTitleText: {
    fontSize: 18,
    fontWeight: 500,
  },
  filtersForm: {
    ...StyleSheet.padding(0),
  },
  metricsChart: {
    ...StyleSheet.padding(16, 0, 16, 0),
  },
  metricsChartSurface: {
    ...StyleSheet.margin(0, 0, 16, 0),
  },
  headerLabelSelectMenu: { ...StyleSheet.margin(0, 12, 0, 0) },
  headerLabelTextStyle: {
    fontWeight: 400,
    fontSize: 16,
  },
});
