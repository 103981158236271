import { element } from "prop-types";
import React from "react";
import R14, { StyleSheet, SelectMenu } from "../core";
export default R14.connect(
  class HeaderLabelSelectMenu extends React.Component {
    constructor(props) {
      super(props);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleSearchChangeText = this.handleSearchChangeText.bind(this);
      this.state = {
        //value: this.props.value || {},
        items: this.props.items || [],
        searchText: this.props.searchText || "",
      };
    }
    async loadItems() {
      this.setState({
        loading: true,
      });
      let items = await this.props.itemLoader({
        search: this.state.searchText,
      });
      this.setState({
        items: items || [],
        loading: false,
      });
    }
    get items() {
      return this.props.items && this.props.items.length
        ? this.props.items
        : this.state.items;
    }
    async handleOpen() {
      if (!this.state.items.length && !this.state.searchText) {
        await this.loadItems();
      }
    }
    handleValueChange(value) {
      if (this.props.onValueChange) this.props.onValueChange(value);
    }
    handleSearchChangeText(text) {
      this.setState({
        searchText: text,
      });
      this.loadItems();
    }
    render() {
      return (
        <SelectMenu
          label={this.props.searchLabel}
          style={this.props.style}
          inputStyle={styles.selectInputStyle}
          valueLabelTextStyle={[
            styles.headerLabelStyle,
            this.props.headerLabelTextStyle,
          ]}
          popupMenuStyle={styles.selectPopUpMenuStyle}
          value={this.props.value}
          searchable={this.props.searchable === false ? false : true}
          items={this.items}
          onOpen={this.handleOpen}
          useValuesOnly={this.props.useValuesOnly ? true : false}
          onSearchChangeText={this.handleSearchChangeText}
          loading={this.state.loading}
          onValueChange={this.handleValueChange}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  selectInputStyle: {
    ...StyleSheet.padding(0, 0, 0, 0),
    minHeight: 24,
  },
  headerLabelStyle: {
    fontSize: 18,
    fontWeight: 500,
  },
  selectPopUpMenuStyle: {
    minWidth: 224,
  },
});
