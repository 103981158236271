import React from "react";
import R14, {
  StyleSheet,
  Chart,
  Card,
  ActivityIndicator,
  View,
  IconButton,
  Dialog,
  Form,
  SelectMenuField,
  DatePickerField,
  ChipGroup,
  Chip,
  HiddenInputField,
} from "../core";
import HeaderLabelSelectMenu from "../components/HeaderLabelSelectMenu";
import ManualEntryUsageMetricDataTable from "../components/ManualEntryUsageMetricDataTable";
import ManualEntryUsageMetricChart from "../components/ManualEntryUsageMetricChart";

import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectManualEntryUsageMetricsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      // let manualEntryPipeline = await this.dm.manualEntryPipelineUsageMetric.fetchMetrics(
      //   manualEntryPipelineUid
      // );
      let project = this.props.project;
      return (
        <ProjectScreenLayout
          project={project}
          headerTitle='Usage Metrics History'
          breadCrumbs={[
            {
              label: "Manual Entry",
              to: {
                route: "projectManualEntryPipelines",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                },
              },
            },
            {
              label: this.props.manualEntryPipeline.name,
              to: {
                route: "projectManualEntryPipeline",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                  manualEntryPipelineUid: this.props.manualEntryPipeline.uid,
                },
              },
            },
          ]}
          scrollEnabled
          DataTableComponent={
            <ManualEntryUsageMetricDataTable
              manualEntryPipelineUid={this.props.manualEntryPipeline.uid}
            />
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cardTitleText: {
    fontSize: 18,
    fontWeight: 500,
  },
  filtersForm: {
    ...StyleSheet.padding(0),
  },
  metricsChart: {
    ...StyleSheet.padding(16, 0, 16, 0),
  },
  metricsChartSurface: {
    ...StyleSheet.margin(0, 0, 16, 0),
  },
  headerLabelSelectMenu: {},
  headerLabelTextStyle: {
    fontWeight: 400,
    fontSize: 16,
  },
});
