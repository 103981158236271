import React from "react";
import R14, { Colors, StyleSheet, Text, Image, View } from "../core";
import EntityDataTable from "./EntityDataTable";
export default R14.connect(
  class manualEntryAccuracyTestDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.manualEntryAccuracyTest = this.props.app.dm.manualEntryAccuracyTest;
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.projectKey,
        "manualEntryAccuracyTest"
      );
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        // projectUid: { eq: this.props.projectUid },
      };
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.type) filter.type = { eq: this.props.type };
      let res = await this.manualEntryAccuracyTest.find(
        `
        uid
        value
        actualValue
        imageBase64
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.manualEntryAccuracyTest}
          // searchable

          title='Accuracy Tests'
          name='manualEntryAccuracyTests'
          columns={{
            imageBase64: {
              label: "Character",
            },
            actualValue: {
              label: "Actual Value",
              sortable: true,
            },
            value: {
              label: "Test Value",
              sortable: true,
            },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => {
            let ret = null;
            switch (columnName) {
              case "imageBase64":
                ret = (
                  <View style={[styles.characterImage]}>
                    <Image
                      resizeMode='contain'
                      width={24}
                      height={24}
                      style={[styles.image]}
                      source={`data:image/png;base64,${cell}`}
                    />
                  </View>
                );
                break;
              // case "createdAt":
              //   let date = new Date(cell);
              //   ret = `${this.props.app.utils.date.toShortDate(
              //     date
              //   )} ${this.props.app.utils.date.toTime(date)}`;
              //   break;
              // case "state":
              //   ret = (
              //     <StateIndicatorText
              //       label={this.appModuleBuildDomain.getStateLabel(row.state)}
              //       color={this.appModuleBuildDomain.getStateIndicatorColor(
              //         row.state
              //       )}
              //     />
              //   );
              //   break;
              default:
                ret = <Text style={[styles.cellText]}>{cell}</Text>;
            }
            return ret;
          }}
          pageLoader={this.pageLoader}
          initialSortColumnName='createdAt'
          initialSortDirection='desc'
          perms={this.perms}
          projectUid={this.props.projectUid}
          projectType={this.props.projectType}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
    fontSize: 24
  },
  image: {
    flex: 1,
    backgroundPosition: "center",
  },
  characterImage: {
    height: 40,
    width: 40,
    backgroundColor: "#FFFFFF",
    flex: 0,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    borderRadius: 4
  },
});
