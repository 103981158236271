import React from "react";
import R14, {
  StyleSheet,
  Colors,
  FadeView,
  HiddenInputField,
  TextInputField,
  SelectMenuField,
  TimePickerField,
  DatePickerField,
  IconButton,
  ControlsView,
  SectionHeader,
  View,
  Text,
  Button,
  Chip,
  ChipGroup,
  Card,
} from "../core";
export default R14.connect(
  class EventWidget extends React.Component {
    constructor(props) {
      super(props);
      // this.handleTypeChange = this.handleTypeChange.bind(this);
      // this.handleItemAddPress = this.handleItemAddPress.bind(this);
      // this.handleItemRemovePress = this.handleItemRemovePress.bind(this);
      // this.formData = this.props.formData || { values: null };
      // this.formName = this.props.formName || "events";

      // this.isEditMode =
      //   this.formData.values && this.formData.values.uid ? true : false;
      // this.eventDomain = this.props.app.dm.event;

      // this.state = {
      //   type: null,
      //   // items: this.formData.values.triggers,
      //   showForm: this.formData.values.triggers.length ? false : true,
      // };
    }
    // async handleTypeChange(value) {
    //   this.setState({
    //     type: value,
    //   });
    // }
    // async handleItemRemovePress(i) {
    //   let form = this.props.app.ui.form(this.formName);
    //   let items = this.state.items;
    //   items.splice(i, 1);
    //   form.elmts.triggers.value = items;
    //   this.setState({
    //     items: items,
    //     showForm: this.state.showForm || items.length === 0,
    //   });
    // }
    // getScriptName(uid) {
    //   for (let val of this.formData.scriptSelections) {
    //     if (val.value === uid) return val.label;
    //   }
    //   return null;
    // }
    // getContactGroupName(uid) {
    //   console.log(uid, this.formData.contactGroupSelections);
    //   for (let val of this.formData.contactGroupSelections) {
    //     if (val.value === uid) return val.label;
    //   }
    //   return null;
    // }
    // async handleItemAddPress() {
    //   // Get the form from the context
    //   let form = this.props.app.ui.form(this.formName);
    //   let items = this.state.items;

    //   if (!this.state.showForm) {
    //     this.setState({
    //       showForm: true,
    //     });
    //     return true;
    //   }

    //   if (await form.validate()) {
    //     items.push(this.eventDomain.createTriggerNode(form.values));
    //     form.elmts.triggers.value = items;
    //     this.setState({
    //       items: items,
    //       showForm: false,
    //       type: null,
    //     });
    //   }
    // }
    render() {
      return (
        <Card
          surfaceStyle={styles.eventWidget}
          title={this.props.title}
          titleTextStyle={styles.titleTextStyle}
          titleControlsRight={[
            this.props.addMoreButton && (
              <IconButton
                icon='add'
                key='add'
                variant='circle'
                onPress={this.props.onItemAddPress}
                style={styles.addMoreButton}
              />
            ),
          ]}
        >
          {this.props.items.length > 0 && (
            <ChipGroup style={styles.items}>
              {this.props.items.map((val, i) => {
                return (
                  <Chip
                    label={val}
                    key={`item${i}`}
                    iconRight='close'
                    onPress={() => this.props.onItemRemovePress(i)}
                  />
                );
              })}
            </ChipGroup>
          )}

          {this.props.children}

          {!this.props.addMoreButton && (
            <Button
              // icon={"add"}
              title={"Add"}
              onPress={this.props.onItemAddPress}
              style={styles.itemAddButton}
            />
          )}
        </Card>
      );
    }
  }
);
const styles = StyleSheet.create({
  eventWidget: {
    // position: "relative",
    ...StyleSheet.margin(0, -8, 16, -8),
    ...StyleSheet.padding(8),
    elevation: 1,
    // ...StyleSheet.padding(12, 12, 12, 12),
    // borderColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    // borderWidth: 1,
    // borderStyle: "solid",
  },
  sectionHeader: {
    paddingBottom: 16,
  },
  titleTextStyle: {
    fontSize: 16,
    fontWeight: "500",
  },
  itemAddButton: {
    marginTop: -8,
    marginLeft: "auto",
  },
  form: {
    marginTop: 16,
  },
  // headerItemAddButton: {
  //   position: "absolute",
  //   right: 4,
  //   top: 12,
  //   // width: 36
  // },
  addMoreButton: {
    marginRight: 8
  },
  items: {
    ...StyleSheet.margin(4, 0, 0, 0),
  },
  itemTypeText: {
    fontWeight: "300",
  },
  item: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
    borderRadius: 4,
    ...StyleSheet.padding(2, 4, 2, 8),
    marginBottom: 4,
  },
  itemDark: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
  },
});
