import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";
import StateIndicatorIcon from "./StateIndicatorIcon";
import AppModuleWidget from "../components/AppModuleWidget";


export default R14.connect(
  class AppModuleDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.appModuleDomain = this.props.app.dm.appModule;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      // this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.handleDeletePress = this.handleDeletePress.bind(this);
      this.handleReinstallPress = this.handleReinstallPress.bind(this);
      this.handleFixFilePermissionsPress =
        this.handleFixFilePermissionsPress.bind(this);
      this.handleUpdateCorePress = this.handleUpdateCorePress.bind(this);
      this.perms = this.props.app.dm.user.getPermissions("dev", "appModule");
      this.state = {};
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        // userUid: {eq: this.props.app.dm.userSession.uid}
      };

      if (this.props.projectUid)
        filter.projectUid = { eq: this.props.projectUid };
      if (search) filter.search = { like: `%${search}%` };

      // if ("active" in this.props) filter.active = this.props.active;
      let res = await this.props.app.dm.appModule.find(
        `
        uid
        name
        projectUid
        key
        description
        state
        serverState {
          type
          state
        }
        type
        resource {
          state
          uid
          publicIpAddress
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("appModule");
    }
    cellRenderer({ cell, columnName, row, columns }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "state":
          ret = (
            <StateIndicatorText
              label={this.appModuleDomain.getStateLabel(
                row.state,
                row.resource.state
              )}
              color={this.appModuleDomain.getStateIndicatorColor(
                row.state,
                row.resource.state
              )}
            />
          );
          break;
        default:
          ret = (
            <React.Fragment>
              <Text style={style}>{content}</Text>
              {columns.state.collapsed && (
                <ChipGroup style={styles.cellChipGroup}>
                  {columns.state.collapsed && (
                    <Chip
                      iconLeft={
                        <StateIndicatorIcon
                          color={this.appModuleDomain.getStateIndicatorColor(
                            row.state,
                            row.resource.state
                          )}
                        />
                      }
                      label={this.appModuleDomain.getStateLabel(
                        row.state,
                        row.resource.state
                      )}
                    />
                  )}
                </ChipGroup>
              )}
            </React.Fragment>
          );
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleDeletePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        this.props.app.ui.progressIndicator.show();
        await this.props.app.dm.appModule.deleteFull(uid);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        dataTable.refresh({ indicator: true });
      }
    }
    async handleFixFilePermissionsPress(uid, dataTable) {
      if (!window.confirm("Are you sure?")) return false;
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.appModule.fixFilePermissions(uid);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.props.app.ui.snackBar.show({
        message: "Permissions have been updated.",
        variant: "success",
      });
    }
    async handleReinstallPress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        this.props.app.ui.progressIndicator.show();
        await this.props.app.dm.appModule.reinstall(uid);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        dataTable.refresh({ indicator: true });
      }
    }
    async handleUpdateCorePress(uid, dataTable) {
      if (!window.confirm("Are you sure?")) return false;
      this.props.app.ui.progressIndicator.show();
      await this.appModuleDomain.updateCore(uid);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      dataTable.refresh({ indicator: true });
      this.props.app.ui.snackBar.show({
        message: "Updating core.",
      });
    }
    renderHeaderControlsRight() {
      let controls = [];
      this.props.widget &&
        controls.push([
          <IconButton
            icon='add'
            key='add'
            variant='circle'
            onPress={() =>
              this.props.app.nav.to("projectAppModuleCreate", {
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
              })
            }
          />,
        ]);
      this.props.headerControlsRight &&
        this.props.headerControlsRight.forEach((control) =>
          controls.push(control)
        );
      return controls;
    }
    // rowBottomRenderer({ row, columns }) {
    //   console.log("CHECK SERVER XSTATATE", row.serverState);
    //   let ret = null;
    //   if (!row.serverState || !row.serverState.length) return null;
    //   return <AppModuleWidget appModule={row} project={this.props.project} />;
    //   return null;
    // }
    renderRowControlsRight(row, dataTable) {
      let controlsRight = [];

      if (this.perms.edit)
        controlsRight.push(
          <IconButton
            icon='edit'
            size='small'
            key='edit'
            onPress={() =>
              this.props.app.nav.to("projectAppModuleEdit", {
                uid: row.projectUid,
                appModuleUid: row.uid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
              })
            }
          />
        );
      controlsRight.push(
        <IconButton
          icon='console'
          size='small'
          key='console'
          onPress={() =>
            this.props.app.nav.to(`projectAppModuleLog`, {
              uid: row.projectUid,
              appModuleUid: row.uid,
              key: this.props.app.dm.project.getKeyByType(
                this.props.projectType
              ),
            })
          }
        />
      );
      let menuItems = [];
      if (this.perms.create)
        menuItems.push(
          <PopUpMenuItem
            icon='add'
            label='Create Copy'
            key='copy'
            onPress={() =>
              this.props.app.nav.to("projectAppModuleCopy", {
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                copyAppModuleUid: row.uid,
              })
            }
          />
        );
      if (this.perms.edit)
        menuItems.push(
          <PopUpMenuItem
            icon='refresh'
            label='Reinstall'
            key='reinstall'
            onPress={() => this.handleReinstallPress(row.uid, dataTable)}
          />
        );
      if (this.perms.edit)
        menuItems.push(
          <PopUpMenuItem
            icon='securityAccount'
            label='Fix Permissions'
            key='fixFilePermissions'
            onPress={() =>
              this.handleFixFilePermissionsPress(row.uid, dataTable)
            }
          />
        );
      if (this.perms.edit)
        menuItems.push(
          <PopUpMenuItem
            icon='refresh'
            label='Update Core'
            key='updateCore'
            onPress={() => this.handleUpdateCorePress(row.uid, dataTable)}
          />
        );

      if (this.perms.delete)
        menuItems.push(
          <PopUpMenuItem
            icon='delete'
            label='Delete'
            key='delete'
            onPress={() => this.handleDeletePress(row.uid, dataTable)}
          />
        );
      if (menuItems.length)
        controlsRight.push(
          <PopUpMenu
            controlIcon='dotsHorizontal'
            controlIconSize='small'
            key='appModuleMenu'
            direction='downLeft'
          >
            {menuItems}
          </PopUpMenu>
        );
      return !controlsRight.length ? null : controlsRight;
    }
    render() {
      return (
        <DataTable
          name='appModules'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            state: {
              label: "State",
              sortable: true,
              collapse: ({ width }) => {
                return width <= 640;
              },
            },
          }}
          displayMode='cardGrid'
          hideColumnHeaders
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          onRowPress={this.handleRowPress}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          autoRefresh={30}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "App Modules"}
          // rowBottom={this.rowBottomRenderer}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='startAt'
          initialSortDirection='desc'
          headerControlsRight={this.renderHeaderControlsRight()}
          rowControlsRight={(row, dataTable) => {
            return this.renderRowControlsRight(row, dataTable);
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  nameCell: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 0,
  },
  cellText: {
    color: Colors.onSurface,
  },
  cellSelectedText: {
    fontWeight: 800,
    color: Colors.secondary,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
  swatch: {
    position: "relative",
    flexShrink: 0,
    height: 32,
    width: 32,
    marginRight: 8,
    borderRadius: 16,
    overflow: "hidden",
    ...StyleSheet.border(
      1,
      "solid",
      StyleSheet.color(Colors.onBackground).rgba(0.1)
    ),
  },
  swatchTopRight: {
    position: "absolute",
    top: 0,
    right: 0,
    height: 16,
    width: 16,
  },
  swatchBottomRight: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 16,
    width: 16,
  },
  swatchTopLeft: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 16,
    width: 16,
  },
  swatchBottomLeft: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: 16,
    width: 16,
  },
});
