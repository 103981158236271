import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  HiddenInputField,
  FieldGroup,
  Text,
  StyleSheet,
  View,
  SectionHeader,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";
import CountrySelectMenuField from "../components/CountrySelectMenuField";

export default R14.connect(
  class ManualEntryPricingConfigEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleModeChange = this.handleModeChange.bind(this);
      this.handleDefaultChange = this.handleDefaultChange.bind(this);
      this.countryItemLoader = this.countryItemLoader.bind(this);
      this.currencyItemLoader = this.currencyItemLoader.bind(this);
      this.manualEntryConfigItemLoader =
        this.manualEntryConfigItemLoader.bind(this);
      this.handleCountryValueChange = this.handleCountryValueChange.bind(this);
      this.currencyUids = [];

      if (
        this.formData.values &&
        this.formData.values.countryUids &&
        this.formData.values.countryUids.length
      )
        this.formData.values.countryUids.map(
          (country) =>
            !this.currencyUids.includes(country.currencyUid) &&
            this.currencyUids.push(country.currencyUid)
        );

      this.state = {
        pricingDefault:
          this.formData.values && this.formData.values.default === true
            ? true
            : false,
        mode:
          this.formData.values && this.formData.values.mode
            ? this.formData.values.mode
            : null,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryPricingConfigForm");
    }
    handleModeChange(mode) {
      this.setState({ mode });
    }
    async handleDefaultChange(pricingDefault) {
      this.currencyUids = [];
      // this.form.elmts.countryUids.setItems([]);
      // this.form.elmts.countryUids.value = null;
      await this.updateBaseCurrencyUidItems();

      this.setState({ pricingDefault });
    }
    async updateBaseCurrencyUidItems() {
      // Update the baseCurrencyUid element
      let baseCurrencyUidElmt = this.form.elmts.baseCurrencyUid;

      // Filter / Check the current currency items, see if more need to be loaded
      let currencyItems = baseCurrencyUidElmt.items.filter((currencyItem) =>
        this.currencyUids.includes(currencyItem.value)
      );

      // If the filtered count is the same as currencyUids, no need to reload
      if (
        !this.currencyUids.length ||
        currencyItems.length !== this.currencyUids.length
      ) {
        this.props.app.ui.progressIndicator.show();
        // Fetch the currency items based on countries
        currencyItems = await this.currencyItemLoader();
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      }
      baseCurrencyUidElmt.setItems(currencyItems);
      // Check the country currencies and make sure the current values contains it
      // If not reset
      if (
        baseCurrencyUidElmt.value &&
        baseCurrencyUidElmt.value.value &&
        !this.currencyUids.includes(baseCurrencyUidElmt.value.value)
      )
        baseCurrencyUidElmt.value = null;
    }
    async handleCountryValueChange(value, elmt, form) {
      let currencyUids = [];
      elmt.value &&
        elmt.value.forEach((val) => {
          val.currencyUid &&
            !currencyUids.includes(val.currencyUid) &&
            currencyUids.push(val.currencyUid);
        });
      this.currencyUids = currencyUids;
      await this.updateBaseCurrencyUidItems();
    }
    async countryItemLoader(filters = {}) {
      return await this.props.app.dm.country.fetchSelections(filters, {
        resultsPerPage: 25,
        currencyUid: true,
      });
    }
    async currencyItemLoader(filters = {}) {
      if (this.currencyUids && this.currencyUids.length)
        filters.currencyUids = this.currencyUids;
      return await this.props.app.dm.currency.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    async manualEntryConfigItemLoader(filters = {}) {
      return await this.props.app.dm.manualEntryConfig.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    renderPricingRateFields() {
      return this.props.app.dm.manualEntry
        .getMetricFieldInfo()
        .map((mode) => {
          let fieldGroups = [];
          let fieldGroupIdx = 0;
          mode.fields.forEach((field, idx) => {
            if (!fieldGroups[fieldGroupIdx]) fieldGroups[fieldGroupIdx] = [];
            fieldGroups[fieldGroupIdx].push(
              <TextInputField
                key={field.name}
                name={field.name}
                label={field.label}
              />
            );
            idx % 3 == 2 && fieldGroupIdx++;
          });
          return (
            <View key={mode.mode}>
              <SectionHeader style={styles.sectionHeader} level={2}>
                {mode.label}
              </SectionHeader>

              {fieldGroups.map((fieldGroup, idx) => (
                <FieldGroup key={`${mode}${idx}`}>{fieldGroup}</FieldGroup>
              ))}
            </View>
          );
        });
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          name='manualEntryPricingConfigForm'
          domainName='manualEntryPricingConfig'
          redirect={this.props.redirect || "manualEntryPricingConfigs"}
          refreshDataTable='manualEntryPricingConfigs'
          style={styles.editForm}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}

          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name'
              helper='Required'
            />
            <TextInputField name='description' label='Description' />
          </FieldGroup>
          <SwitchField
            key='default'
            name='default'
            label='Default Pricing'
            onValueChange={this.handleDefaultChange}
            value={this.state.pricingDefault}
          />
          {!this.state.pricingDefault && (
            <CountrySelectMenuField
              multiple
              countryUids={true}
              searchable
              autoLoad
              onValueChange={this.handleCountryValueChange}
              required='Please choose at least 1 country.'
              helper='Required'
            />
          )}
          <FieldGroup>
            <SelectMenuField
              // multiple
              key='baseCurrencyUid'
              name='baseCurrencyUid'
              label='Base Currency'
              required='Please select a base currency.'
              helper='Required'
              searchable
              autoLoad
              itemLoader={this.currencyItemLoader}
            />
            <SelectMenuField
              useValuesOnly
              name='mode'
              label='Mode'
              items={this.formData.modeSelections}
              required='Please select a mode.'
              helper='Required'
              onValueChange={this.handleModeChange}
            />
            <TextInputField name='maxRate' label='Max Rate' helper='Per Hour' />
          </FieldGroup>
          {this.state.mode && (
            <FieldGroup>
              {this.state.mode ===
                this.props.app.dm.manualEntryPricingConfig.MODE_AVERAGE && (
                <TextInputField
                  name='rangePastDays'
                  label='Sync Range'
                  helper='Past Day(s) Required'
                  required
                />
              )}
              {this.state.mode ===
                this.props.app.dm.manualEntryPricingConfig.MODE_AVERAGE && (
                <TextInputField
                  name='updateFrequencyMinutes'
                  label='Update Frequency'
                  helper='Minutes Required'
                  required
                />
              )}
            </FieldGroup>
          )}
          <SelectMenuField
            multiple
            key='manualEntryConfigUids'
            name='manualEntryConfigUids'
            label='Manual Entry Configs'
            searchable
            autoLoad
            itemLoader={this.manualEntryConfigItemLoader}
          />
          {this.renderPricingRateFields()}
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  editForm: {
    width: 640,
    screen: ({ width }) =>
      width <= 640
        ? {
            width: null,
          }
        : null,
  },
  sectionHeader: {
    fontSize: 16,
  },
});
