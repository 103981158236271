import R14 from "../core";

export default class PipelineUiDomain extends R14.DomainInstances {
  constructor(key) {
    super();
  }
  async instance(uid, options = {}) {
    if (this.exists(uid)) return this.getInstance(uid);
    let pipeline = new PipelineUiInstanceDomain(uid, options);
    await pipeline.init();
    this.addInstance(uid, pipeline);
    return pipeline;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}

class PipelineUiInstanceDomain extends R14.Domain {
  constructor(uid, options = {}) {
    super();
    this._options = options;
    this._pipelineSubscription = null;
    this.pointMap = [];
    this.ioPointMap = {};
    this.prevLayout = null;
    this.state = {
      uid: uid,
      name: null,
      active: false,
      moveUid: null,
      saving: false,
      dragging: false,
      startX: 0,
      startY: 0,
      x: 0,
      y: 0,
      totalRows: 40,
      totalCols: 40,
      cellSize: 24,
      ioWidgetSize: 26,
      previewBlock: null,
      placeholderBlock: null,
      activeBlock: null,
      activeBlockIo: null,
      previewBlockIo: null,
      placeholderBlockIo: null,
      ioPaths: null,
      blocks: null,
      scale: 1.0,
      prevScale: 1.0,
      layout: null,
    };
  }
  get uid() {
    return this.state.uid;
  }
  get name() {
    return this._pipeline.name;
  }
  get rows() {
    return this._pipeline.rows;
  }
  get columns() {
    return this._pipeline.columns;
  }
  // get zoom() {
  //   return this.state.zoom;
  // }
  get cellSize() {
    return this.state.cellSize;
  }
  setLayout(layout) {
    let hasChanged = this.prevLayout ? false : true;
    this.prevLayout &&
      Object.keys(layout).forEach((key) => {
        if (this.prevLayout[key] !== layout[key]) hasChanged = true;
      });
    if (!hasChanged) return false;
    this.prevLayout = layout;
    this.setState({ layout });
  }
  setScale(scale) {
    this.setState({ scale, prevScale: this.state.scale });
  }
  async init() {
    let { pipeline, pipelines } = await this.dm.pipeline.get(this.state.uid, {
      project: true,
      blocks: true,
      pipelineBlockIo: true,
      pipelines: true,
    });
    this._pipeline = pipeline;
    this._pipelines = pipelines;
    this.setState({
      active: this._pipeline.state === this.dm.pipeline.STATE_RUNNING,
    });
    this.initBlocks();
    this.initIoPaths();
  }

  async refresh() {
    // console.log("[PIPELINE BLOCK UI] Refreshing...", this.state);
    await this.init();
  }

  remove() {
    this.ui.pipeline.removeInstance(this.state.uid);
  }

  reset() {
    this.setState({
      activeBlock: null,
      previewBlock: null,
      placeholderBlock: null,
      activeBlockIo: null,
      previewBlockIo: null,
      placeholderBlockIo: null,
    });
  }

  get project() {
    return this._pipeline.project;
  }

  async initIoPaths() {
    this.setState({
      ioPaths: await this.calculateIoPaths(),
    });
  }

  async initBlocks() {
    let blocks = {};
    let blockIoPosMap = {};
    if (
      this._pipeline &&
      this._pipeline.pipelineBlocks &&
      this._pipeline.pipelineBlocks.nodes
    ) {
      this._pipeline.pipelineBlocks.nodes.forEach((block) => {
        blocks[block.uid] = {
          ...block,
          inputs: {},
          outputs: {},
          boundaryPointMap: this.getBoundaryPointMap(block),
        };
        // Map the inputs
        ["inputs", "outputs"].forEach((type) => {
          if (block[type])
            block[type].forEach((io) => {
              blocks[block.uid][type][io.blockIoUid] = {
                blockIoUid: io.blockIoUid,
                x: io.x,
                y: io.y,
                queueItemCount: io.queueItemCount,
                lastChangedAt: io.lastChangedAt,
                io: false,
              };
            });
        });
        if (this._pipeline.pipelineBlockIo) {
          this._pipeline.pipelineBlockIo.nodes.forEach((val) => {
            ["input", "output"].forEach((type) => {
              if (val[`${type}PipelineBlockUid`] === block.uid) {
                let blockIo = val[`block${this.utils.str.capitalize(type)}`];
                if (!blockIo) return;
                if (!blocks[block.uid][`${type}s`][blockIo.uid])
                  blocks[block.uid][`${type}s`][blockIo.uid] = {
                    // name: blockIo.name,
                    blockIoUid: blockIo.uid,
                    io: null,
                    x: null,
                    y: null,
                    queueItemCount: 0,
                    //backgroundColor: val[`${type}BackgroundColor`] || null,
                  };

                blocks[block.uid][`${type}s`][blockIo.uid].name = blockIo.name;
                blocks[block.uid][`${type}s`][blockIo.uid].backgroundColor =
                  val[`${type}BackgroundColor`] || null;
                blocks[block.uid][`${type}s`][blockIo.uid].hasIo = true;

                // blocks[block.uid][`${type}s`][blockIo.uid].queueItemCount =
                //   currQueueItemCount + queueItemCount;

                if (!blocks[block.uid][`${type}s`][blockIo.uid].io)
                  blocks[block.uid][`${type}s`][blockIo.uid].io = {};
                blocks[block.uid][`${type}s`][blockIo.uid].io[val.uid] = {
                  uid: val.uid,
                  queueItemCount: val.queueItemCount,
                  inputPipelineBlockUid: val.inputPipelineBlockUid,
                  outputPipelineBlockUid: val.outputPipelineBlockUid,
                };
              }
            });
          });
        }
      });
    }

    // Automatically place block;
    for (let uid in blocks) {
      let block = blocks[uid];
      block.height = block.height || 6;
      block.width = block.width || 8;
      if ((!block.x && block.x !== 0) || (!block.y && block.y !== 0)) {
        // if(! block.x && block.x !== 0) block.x = 0;
        // if(! block.y && block.y !== 0) block.y = 0;
        // figure out where to put it
        let x = block.x || 0;
        let y = block.y || 0;
        for (let uid2 in blocks) {
          if (uid2 === uid) continue;
          let block2 = blocks[uid2];
          if ((!block2.x && block2.x !== 0) || (!block2.y && block2.y !== 0))
            continue;
          let isFound = false;
          for (let y2 = 0; y2 <= this.rows - block.height; y2++) {
            for (let x2 = 0; x2 <= this.columns - block.width; x2++) {
              let collisions = this.dm.pipelineBlock.detectCollisions(
                {
                  uid: block.uid,
                  height: block.height * this.state.cellSize,
                  width: block.width * this.state.cellSize,
                  x: x2 * this.state.cellSize,
                  y: y2 * this.state.cellSize,
                },
                blocks,
                this.state.cellSize
              );
              if (!collisions.length) {
                x = x2;
                y = y2;
                isFound = true;
                break;
              }
            }
            if (isFound) break;
          }
        }
        block.x = x;
        block.y = y;
      }
      blocks[uid] = block;
    }

    // Automatically place io
    for (let uid in blocks) {
      let block = blocks[uid];
      ["inputs", "outputs"].forEach((type) => {
        for (let blockIoUid in block[type]) {
          let blockIo = block[type][blockIoUid];
          if (blockIo.x === null || blockIo.y === null) {
            // Default input left, output right
            let x = type === "inputs" ? 0 : block.width;
            let y = Math.floor(block.height / 2);
            if (
              blockIoPosMap[block.uid] &&
              blockIoPosMap[block.uid][`${x},${y}`]
            ) {
              // find the closest input
              let topPointMap = block.boundaryPointMap.slice(1, block.width);
              let rightPointMap = block.boundaryPointMap
                .slice(block.width + 1, block.width + block.height)
                .reverse();
              let bottomPointMap = block.boundaryPointMap.slice(
                block.width + block.height + 1,
                block.width + block.height + block.width
              );
              let leftPointMap = block.boundaryPointMap
                .slice(
                  block.width + block.height + block.width + 1,
                  (block.width + block.height) * 2
                )
                .reverse();

              let pointMaps = [];
              type === "input"
                ? pointMaps.push(
                    leftPointMap,
                    bottomPointMap,
                    rightPointMap,
                    topPointMap
                  )
                : pointMaps.push(
                    rightPointMap,
                    bottomPointMap,
                    leftPointMap,
                    topPointMap
                  );

              let newPointKey = false;
              for (let pointMap of pointMaps) {
                for (let pointKey of pointMap) {
                  if (
                    !blockIoPosMap[block.uid] ||
                    !blockIoPosMap[block.uid][pointKey]
                  ) {
                    newPointKey = pointKey;
                    break;
                  }
                }
                if (newPointKey) break;
              }
              if (newPointKey) {
                let pointParts = newPointKey.split(",");
                x = parseInt(pointParts[0]);
                y = parseInt(pointParts[1]);
              } else
                console.error(
                  `Error placing io. No point found for ${block.name} - ${blockIo.name}`
                );
            }
            block[type][blockIoUid].x = x;
            block[type][blockIoUid].y = y;
          }
          if (!blockIoPosMap[block.uid]) blockIoPosMap[block.uid] = {};
          blockIoPosMap[block.uid][
            `${block[type][blockIoUid].x},${block[type][blockIoUid].y}`
          ] = true;
        }
      });
      blocks[uid] = block;
    }
    this.setState({ blocks });
  }
  getBlockInfo(pipelineBlockUid) {
    return this.state.blocks[pipelineBlockUid];
  }
  getAppModuleUid(pipelineBlockUid) {
    let blockInfo = this.getBlockInfo(pipelineBlockUid);
    return blockInfo.block && blockInfo.block.appModuleUid
      ? blockInfo.block.appModuleUid
      : null;
  }
  getBlockTemplateUid(pipelineBlockUid) {
    let blockInfo = this.getBlockInfo(pipelineBlockUid);
    return blockInfo.block && blockInfo.block.uid
      ? blockInfo.block.uid
      : null;
  }
  hasBlockIoCollision(blockIoKey) {
    return this.state.activeBlockIo &&
      this.state.previewBlockIo &&
      this.state.activeBlockIo.key === blockIoKey &&
      this.state.previewBlockIo.collision
      ? true
      : false;
  }

  detectBlockIoCollisions(blockIo, block) {
    let ret = [];
    let { blockIoUid, pipelineBlockUid, type } = this.decodeBlockIoKey(
      blockIo.key
    );

    // let corners = [
    //   "0,0",
    //   `0,${block.height}`,
    //   `${block.width},0`,
    //   `${block.width},${block.height}`,
    // ];

    ["inputs", "outputs"].forEach((type) => {
      for (let relBlockIoUid in block[type]) {
        if (blockIoUid === relBlockIoUid) continue;
        let relBlock = block[type][relBlockIoUid];
        if (blockIo.blockX == relBlock.x && blockIo.blockY === relBlock.y) {
          ret.push(relBlock);
        }
      }
    });
    return ret;
  }

  getBoundaryPointMap({ height, width }) {
    let ret = [];

    for (let x = 0; x <= width; x++) {
      ret.push(`${x},${0}`);
    }
    for (let y = 1; y <= height; y++) {
      ret.push(`${width},${y}`);
    }
    for (let x = width - 1; x >= 0; x--) {
      ret.push(`${x},${height}`);
    }
    for (let y = height - 1; y >= 1; y--) {
      ret.push(`${0},${y}`);
    }
    return ret;
  }
  getBlockIoSnapInfo(key, event) {
    let { blockIoUid, pipelineBlockUid, type } = this.decodeBlockIoKey(key);
    let ioWidgetOffset = Math.round(this.state.ioWidgetSize / 2);

    // Find the blocks
    if (!this.state.blocks[pipelineBlockUid])
      throw new Error("Block Io Drag Start: Block not found.");

    // Update Block Io
    let blocks = this.state.blocks;
    let block = blocks[pipelineBlockUid];

    if (!block[type] || !block[type][blockIoUid])
      throw new Error("Block Io Drag Start: Block Io not found.");

    let blockIo = block[type][blockIoUid];

    let { pageX, pageY } = event.nativeEvent;
    let xDiff = this.state.activeBlockIo.startX - pageX;
    let yDiff = this.state.activeBlockIo.startY - pageY;
    let x = Math.round(
      (blockIo.x * this.state.cellSize - xDiff) / this.state.cellSize
    );
    let y = Math.round(
      (blockIo.y * this.state.cellSize - yDiff) / this.state.cellSize
    );

    if (x <= 0) x = 0;
    else if (x > block.width) x = block.width;
    if (y <= 0) y = 0;
    else if (y > block.height) y = block.height;

    // let corners = [
    //   "0,0",
    //   `0,${block.height}`,
    //   `${block.width},0`,
    //   `${block.width},${block.height}`,
    // ];
    // Allow corners?
    let corners = [];

    let boundaries = block.boundaryPointMap;
    if (!corners.includes(`${x},${y}`) && boundaries.includes(`${x},${y}`)) {
      return {
        key,
        x: (x + block.x) * this.state.cellSize - ioWidgetOffset,
        y: (y + block.y) * this.state.cellSize - ioWidgetOffset,
        blockX: x,
        blockY: y,
      };
    } else return null;
  }
  async findShortestPath(p1, p2, options = {}) {
    let moves = 0;
    let maxMoves = 100;
    let paths = [];
    let visited = [];
    let shouldContinue = true;
    while (shouldContinue) {
      if (moves >= maxMoves) break;
      let nPaths = [];
      moves++;
      if (!paths.length) {
        paths.push({
          point: p1,
          direction: null,
          distance: 0,
          turns: 0,
          pointMap: [`${p1[0]},${p1[1]}`],
        });
      } else if (paths.length > 50000) break;
      let foundPaths = [];
      paths.forEach((path) => {
        let isNextToPoint =
          (path.point[1] === p2[1] && Math.abs(path.point[0] - p2[0]) === 1) ||
          (path.point[0] === p2[0] && Math.abs(path.point[1] - p2[1]) === 1);
        if (isNextToPoint) {
          foundPaths.push(this.createPath(p1, p2, path, p2, path.collision));
        }
        // Adding visited here gives more path options, but really slow.
        //visited.push(`${path.point[0]},${path.point[1]}`);
      });
      if (foundPaths.length) {
        // Find min least turns, then min distance
        let foundPath = null;
        foundPaths.forEach((p) => {
          if (
            !foundPath ||
            (p.turns <= foundPath.turns && p.distance < foundPath.distance)
          )
            foundPath = p;
        });
        return {
          found: true,
          ...foundPath,
        };
      }

      paths.forEach((path) => {
        let nextPoints = this.getNextPointsByPath(
          p1,
          p2,
          path,
          visited,
          options
        );
        nextPoints.forEach((p) => {
          let pointMap = [...path.pointMap];
          pointMap.push(`${p.point[0]},${p.point[1]}`);
          nPaths.push({ ...p, pointMap: pointMap });
          // Adding visited here much faster, less paths though
          visited.push(`${p.point[0]},${p.point[1]}`);
        });
      });

      // Replace paths with new paths
      paths = nPaths;

      if (!paths.length) break;
    }
    return { found: false };
  }
  getNextPointsByPath(p1, p2, path, visited, options) {
    // Check in each unchecked direction
    let nextPoints = [];
    let p = path.point;

    let points = [
      [p[0] + 1, p[1]],
      [p[0] - 1, p[1]],
      [p[0], p[1] + 1],
      [p[0], p[1] - 1],
    ];
    options.p1 = p1;
    options.p2 = p2;
    points.forEach((point) => {
      if (
        point[0] >= 0 &&
        point[1] >= 0 &&
        point[0] <= this.columns &&
        point[1] <= this.rows &&
        // !this.ioPointMapIncludes(point, p2, options) &&
        !visited.includes(`${point[0]},${point[1]}`) &&
        (!options.pointValidator || options.pointValidator(point)) &&
        //!path.pointMap.includes(`${point[0]},${point[1]}`) &&
        !this.pointMap.includes(`${point[0]},${point[1]}`)
      ) {
        nextPoints.push(this.createPath(p1, p2, path, [point[0], point[1]]));
      }
    });

    return nextPoints;
  }
  createPath(p1, p2, path, p, collision = false) {
    let turns = path.turns || 0;
    let direction = p[0] === path.point[0] ? "y" : "x";
    let history = path.history ? [...path.history] : [];
    let pointMap = [...path.pointMap, `${p[0]},${p[1]}`];
    let distance = (path.distance || 0) + this.getDistanceBetweenPoints(p1, p);
    history.push(direction);
    if (path.direction && path.direction !== direction) turns++;
    return {
      point: p,
      direction,
      turns,
      distance,
      pointMap,
      history: history,
      collision: collision,
    };
  }
  getDistanceBetweenPoints(p1, p2) {
    let xS = p2[0] - p1[0];
    let yS = p2[1] - p1[1];
    xS *= xS;
    yS *= yS;
    return Math.sqrt(xS + yS);
  }
  clonePath(path) {
    let ret = { ...path };
    ret.pointMap = [...path.pointMap];
    return ret;
  }
  initPointMap() {
    let pointMap = [];
    for (let pipelineBlockUid in this.state.blocks) {
      let block = this.state.blocks[pipelineBlockUid];
      for (let y = block.y; y <= block.y + block.height; y++) {
        for (let x = block.x; x <= block.x + block.width; x++) {
          if (
            x === block.x ||
            x === block.x + block.width ||
            y === block.y ||
            y === block.y + block.height
          )
            pointMap.push(`${x},${y}`);
        }
      }
    }
    this.pointMap = pointMap;
    return this.pointMap;
  }
  async calculateIoPaths() {
    let ioPaths = {};
    let collisionMap = {};
    this.initPointMap();
    for (let pipelineBlockUid in this.state.blocks) {
      let block = this.state.blocks[pipelineBlockUid];
      if (!block.outputs) continue;
      for (let blockIoUid in block.outputs) {
        if (!block.outputs[blockIoUid].io) continue;
        let output = block.outputs[blockIoUid];
        let pos = [output.x + block.x, output.y + block.y];
        for (let pipelineBlockIoUid in output.io) {
          let pipelineBlockIo = output.io[pipelineBlockIoUid];
          // Get the related io
          let relBlock =
            this.state.blocks[pipelineBlockIo.inputPipelineBlockUid];
          if (!relBlock || !relBlock.inputs) continue;
          for (let relBlockIoUid in relBlock.inputs) {
            let input = relBlock.inputs[relBlockIoUid];

            // get the output, then find the corresponding inputs
            if (input.io[pipelineBlockIoUid]) {
              let relPos = [input.x + relBlock.x, input.y + relBlock.y];
              let collisionKey = `${pipelineBlockIo.inputPipelineBlockUid},${relBlockIoUid}`;
              // Try with collisions
              let shortestPath = await this.findShortestPath(pos, relPos, {
                pointValidator: (p) => {
                  let point = `${p[0]},${p[1]}`;
                  let ret = true;
                  if (collisionMap[point]) {
                    let offset = collisionMap[point][collisionKey] ? 1 : 0;
                    ret = Object.keys(collisionMap[point]).length - offset > 1;
                  }
                  return ret;
                },
              });
              // Check for an alternate path
              if (!shortestPath.found)
                shortestPath = await this.findShortestPath(pos, relPos);
              if (shortestPath.found) {
                // Add to collision map
                shortestPath.pointMap.forEach((point) => {
                  if (!collisionMap[point]) collisionMap[point] = {};
                  collisionMap[point][collisionKey] = pipelineBlockIoUid;
                });

                ioPaths[pipelineBlockIoUid] = {
                  collision: false,
                  blockIoUid,
                  queueItemCount: output.queueItemCount,
                  path: shortestPath.pointMap.map((val) => {
                    let xy = val.split(",");
                    return [parseInt(xy[0]), parseInt(xy[1])];
                  }),
                };
              } else
                ioPaths[pipelineBlockIoUid] = {
                  collision: false,
                  path: false,
                  blockIoUid,
                };

              continue;
            }
          }
        }
      }
    }
    // Mark Collisions
    for (let p in collisionMap) {
      if (Object.keys(collisionMap[p]).length > 1) {
        for (let key in collisionMap[p]) {
          ioPaths[collisionMap[p][key]].collision = true;
        }
      }
    }
    return ioPaths;
  }
  getBlockSnapInfo(uid, event) {
    if (!this.state.blocks[uid])
      throw new Error("Block Snap Info: Block not found.");

    let blocks = this.state.blocks;
    let block = blocks[uid];

    if (!this.state.activeBlock || this.state.activeBlock.uid !== uid)
      throw new Error("Block Mismatch");

    let { pageX, pageY } = event.nativeEvent;
    let xDiff = this.state.activeBlock.startX - pageX;
    let yDiff = this.state.activeBlock.startY - pageY;
    let x =
      Math.round(
        (block.x * this.state.cellSize - xDiff) / this.state.cellSize
      ) * this.state.cellSize;
    let y =
      Math.round(
        (block.y * this.state.cellSize - yDiff) / this.state.cellSize
      ) * this.state.cellSize;
    let height = block.height * this.state.cellSize;
    let width = block.width * this.state.cellSize;

    if (x < 0) x = 0;
    if (y < 0) y = 0;
    if (x + width > this.width) x = this.width - width;
    if (y + height > this.height) y = this.height - height;

    return {
      uid: uid,
      width: width,
      height: height,
      x: x,
      y: y,
    };
  }
  hasBlockCollision(uid) {
    return this.state.activeBlock &&
      this.state.previewBlock &&
      this.state.activeBlock.uid === uid &&
      this.state.previewBlock.collision
      ? true
      : false;
  }
  blockMoveEnable(uid) {
    this.setState({
      activeBlock: {
        uid: uid,
      },
      activeBlockIo: null,
    });
  }
  blockIoUpdate(uid, values) {
    let ioPaths = this.state.ioPaths;
    if (ioPaths[uid]) {
      ioPaths[uid].queueItemCount = values.queueItemCount;
    }
    this.setState({
      ioPaths: ioPaths,
    });
  }
  blockDragStart(uid, event) {
    if (!this.state.blocks[uid])
      throw new Error("Block Drag Start: Block not found.");
    // Update Block
    let blocks = this.state.blocks;
    let block = blocks[uid];
    let { pageX, pageY } = event.nativeEvent;

    // Create the active block
    let activeBlock = {
      uid: uid,
      startX: pageX,
      startY: pageY,
    };

    // Update placeholder
    let placeholderBlock = {
      uid: uid,
      width: block.width * this.state.cellSize,
      height: block.height * this.state.cellSize,
      x: block.x * this.state.cellSize,
      y: block.y * this.state.cellSize,
    };

    this.setState({
      placeholderBlock: placeholderBlock,
      activeBlock: activeBlock,
    });
  }
  blockDrag(uid, event) {
    if (!this.state.blocks[uid])
      throw new Error("Block Drag: Block not found.");

    let previewBlock = this.getBlockSnapInfo(uid, event);

    if (
      this.state.previewBlock &&
      previewBlock.x === this.state.previewBlock.x &&
      previewBlock.y === this.state.previewBlock.y
    )
      return false;

    let collisions = this.dm.pipelineBlock.detectCollisions(
      previewBlock,
      this.state.blocks,
      this.state.cellSize
    );
    if (collisions.length) {
      previewBlock.collision = true;
    }

    this.setState({
      previewBlock: previewBlock,
    });
  }
  encodeBlockKey(pipelineBlockUid, blockIoUid, ioType) {
    return `${pipelineBlockUid},${blockIoUid},${ioType.toLowerCase()}`;
  }
  async updateBlock(uid) {
    if (!this.state.blocks[uid]) return false;
    let block = this.state.blocks[uid];
    this.setState({ saving: true });
    await this.dm.pipelineBlock.update(block);
    this.setState({ saving: false });
  }
  async updateActive(value) {
    let res = await this.dm.pipeline.update(
      {
        uid: this.uid,
        state: value
          ? this.dm.pipeline.STATE_RUNNING
          : this.dm.pipeline.STATE_STOPPED,
      },
      { parse: false }
    );
    this.setState({
      active: value,
    });
  }

  blockUpdate(uid, values) {
    let blocks = this.state.blocks;
    // Update input values
    if (blocks[uid]) {
      blocks[uid].state = values.state;
      blocks[uid].activeInstanceCount = values.activeInstanceCount;
      // if (!values.inputs || !values.inputs.length) blocks[uid].inputs = {};
      ["inputs", "outputs"].forEach((type) => {
        values[type] &&
          values[type].forEach((io) => {
            if (blocks[uid][type] && blocks[uid][type][io.blockIoUid]) {
              // Manually set items, incase input x / y not set
              blocks[uid][type][io.blockIoUid].queueItemCount =
                io.queueItemCount;
              // blocks[uid][type][io.blockIoUid] = {
              //   ...blocks[uid][type][io.blockIoUid],
              //   ...io,
              // };
            }
          });
      });
    } else blocks[uid] = values;
    this.setState({
      blocks: blocks,
    });
  }
  blockDragEnd(uid, event) {
    if (!this.state.blocks[uid])
      throw new Error("Block Drag End: Block not found.");

    // Get the block and uid
    let blocks = this.state.blocks;
    let snapInfo = this.getBlockSnapInfo(uid, event);

    let collisions = this.dm.pipelineBlock.detectCollisions(
      snapInfo,
      this.state.blocks,
      this.state.cellSize
    );
    if (collisions.length) {
      this.reset();
      return false;
    }

    blocks[uid].x = snapInfo.x / this.state.cellSize;
    blocks[uid].y = snapInfo.y / this.state.cellSize;

    // Clear placeholder and preview
    this.setState({
      previewBlock: null,
      placeholderBlock: null,
      blocks: blocks,
      activeBlock: null,
    });

    this.initIoPaths();

    this.updateBlock(uid);
  }
  blockDragCancel(uid, event) {
    // Clear placeholder and preview
    this.reset();
  }
  // Block Io Methods
  decodeBlockIoKey(key) {
    if (!key) return null;
    let parts = key.split(",");
    if (parts.length !== 3) return null;
    return {
      pipelineBlockUid: parts[0],
      blockIoUid: parts[1],
      type: parts[2],
    };
  }
  blockIoMoveEnable(key) {
    this.setState({
      activeBlockIo: {
        key: key,
      },
      activeBlock: null,
    });
  }

  blockIoDragStart(key, event) {
    let { blockIoUid, pipelineBlockUid, type } = this.decodeBlockIoKey(key);
    let ioWidgetOffset = Math.round(this.state.ioWidgetSize / 2);

    // Find the blocks
    if (!this.state.blocks[pipelineBlockUid])
      throw new Error("Block Io Drag Start: Block not found.");

    // Update Block Io
    let blocks = this.state.blocks;
    let block = blocks[pipelineBlockUid];

    if (!block[type] || !block[type][blockIoUid])
      throw new Error("Block Io Drag Start: Block Io not found.");

    let blockIo = block[type][blockIoUid];

    let { pageX, pageY } = event.nativeEvent;

    // // Create the active block
    let activeBlockIo = {
      key,
      startX: pageX,
      startY: pageY,
    };
    // // Update placeholder
    let placeholderBlockIo = {
      key: key,
      x: (block.x + blockIo.x) * this.state.cellSize - ioWidgetOffset,
      y: (block.y + blockIo.y) * this.state.cellSize - ioWidgetOffset,
    };

    this.setState({
      placeholderBlockIo: placeholderBlockIo,
      activeBlockIo: activeBlockIo,
    });
  }
  blockIoDrag(key, event) {
    let { blockIoUid, pipelineBlockUid, type } = this.decodeBlockIoKey(key);

    if (!this.state.blocks[pipelineBlockUid])
      throw new Error("Block Io Drag: Block not found.");
    let previewBlockIo = this.getBlockIoSnapInfo(key, event);
    if (!previewBlockIo) return false;
    // Check if nothing has changed
    if (
      this.state.previewBlockIo &&
      previewBlockIo.x === this.state.previewBlockIo.x &&
      previewBlockIo.y === this.state.previewBlockIo.y
    )
      return false;

    if (previewBlockIo) {
      let collisions = this.detectBlockIoCollisions(
        previewBlockIo,
        this.state.blocks[pipelineBlockUid]
      );
      if (collisions.length) {
      }
      previewBlockIo.collision = collisions.length ? true : false;
    }
    this.setState({
      previewBlockIo: previewBlockIo,
    });
  }
  async updateBlockIo(key) {
    let { blockIoUid, pipelineBlockUid, type } = this.decodeBlockIoKey(key);
    let blocks = this.state.blocks;

    if (
      !blocks[pipelineBlockUid][type] ||
      !blocks[pipelineBlockUid][type][blockIoUid]
    )
      throw new Error("Block Io Update: Block Io not found.");

    this.setState({ saving: true });
    await this.dm.pipelineBlock.update(blocks[pipelineBlockUid]);
    this.setState({ saving: false });
  }
  blockIoDragEnd(key, event) {
    let { blockIoUid, pipelineBlockUid, type } = this.decodeBlockIoKey(key);

    if (!this.state.blocks[pipelineBlockUid])
      throw new Error("Block Io Drag: Block not found.");

    // if (!this.state.blocks[uid])
    //   throw new Error("Block Drag End: Block not found.");
    // Get the block and uid
    let snapInfo = this.getBlockIoSnapInfo(key, event);
    let blocks = this.state.blocks;

    if (
      !blocks[pipelineBlockUid][type] ||
      !blocks[pipelineBlockUid][type][blockIoUid]
    )
      throw new Error("Block Io Drag End: Block Io not found.");

    let collisions = this.detectBlockIoCollisions(
      snapInfo,
      blocks[pipelineBlockUid]
    );
    if (collisions.length) {
      this.reset();
      return false;
    }
    // blocks[uid].x = snapInfo.x / this.state.cellSize;
    // blocks[uid].y = snapInfo.y / this.state.cellSize;

    blocks[pipelineBlockUid][type][blockIoUid].x = snapInfo.blockX;
    blocks[pipelineBlockUid][type][blockIoUid].y = snapInfo.blockY;

    // Clear placeholder and preview
    this.setState({
      previewBlockIo: null,
      placeholderBlockIo: null,
      blocks: blocks,
      activeBlockIo: null,
    });
    this.initIoPaths();
    this.updateBlockIo(key);
  }
  blockIoDragCancel(key, event) {
    // Clear placeholder and preview
    this.reset();
  }
  async runBlockCommand(uid, command) {
    let res = await this.dm.pipelineBlock.runCommand(
      uid,
      this.dm.pipelineBlock.COMMAND_TYPE_BLOCK,
      command
    );
    if (res.pipelineBlock) {
      let blocks = this.state.blocks;
      blocks[uid] = { ...blocks[uid], ...res.pipelineBlock };
      this.setState({
        blocks: blocks,
      });
    }
  }
}
