import React from "react";
import R14, { Text } from "../core";
export default R14.connect(
  class ProjectsDocumentTemplateDetailsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    get projectKey() {
      return;
    }
    // get perms() {
    //   return this.props.app.dm.user.getPermissions(
    //     this.props.app.dm.project.getKeyByType(this.props.project.type),
    //     "documentTemplate"
    //   );
    // }
    render() {
      return (
        <Text>
          KSLDJF:LKSDJF:LKSJDF:KLJSD:LKF
        </Text>
      );
    }
  }
);