import React from "react";
import R14, { Colors, View, StyleSheet, Text, ScreenLayout } from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class ManualEntryUserBatchDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.pageLoader = this.pageLoader.bind(this);
      this.renderTotalCell = this.renderTotalCell.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.manualEntryUserBatch.find(
        `
        uid
        name
        state
        total
        totalComplete
        totalErrors
        updatedAt
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    renderTotalCell({ cell }) {
      return <Text style={[styles.cellText]}>{cell || 0}</Text>;
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={
            this.perms.create
              ? {
                  to: "manualEntryUserBatchCreate",
                  icon: "add",
                }
              : null
          }
          scrollEnabled
        >
          <EntityDataTable
            entityDomain={this.props.app.dm.manualEntryUserBatch}
            searchable
            title='User Batches'
            rowControlsRight={false}
            name='manualEntryUserBatches'
            onRowPress={(row) =>
              this.props.app.nav.to(`manualEntryUserBatch`, {
                uid: row.uid,
              })
            }
            columns={{
              updatedAt: {
                label: "Updated",
                sortable: true,
                renderer: ({ cell }) => {
                  let date = new Date(cell);
                  return (
                    <View>
                      <View>
                        <Text style={[styles.cellText]}>
                          {this.props.app.utils.date.toShortDate(date)}
                        </Text>
                      </View>
                      <View>
                        <Text style={[styles.cellText, styles.cellTextSmall]}>
                          {this.props.app.utils.date.toTime(date)}
                        </Text>
                      </View>
                    </View>
                  );
                },
              },
              name: {
                label: "Name",
                sortable: true,
              },
              state: {
                label: "Status",
                sortable: true,
                renderer: ({ cell }) => (
                  <StateIndicatorText
                    style={styles.statusCell}
                    label={this.props.app.dm.manualEntryUserBatch.getStateLabel(
                      cell
                    )}
                    color={this.props.app.dm.manualEntryUserBatch.getStateIndicatorColor(
                      cell
                    )}
                  />
                ),
              },
              total: {
                label: "Total",
                sortable: true,
                width: 128,
                renderer: this.renderTotalCell,
              },
              totalComplete: {
                label: "Complete",
                sortable: true,
                width: 128,
                renderer: this.renderTotalCell,
              },
              totalErrors: {
                label: "Errors",
                sortable: true,
                width: 128,
                renderer: this.renderTotalCell,
              },
            }}
            cellRenderer={({ cell, columnName, row, columns }) => (
              <Text style={[styles.cellText]}>{cell}</Text>
            )}
            pageLoader={this.pageLoader}
            initialSortColumnName='updatedAt'
            initialSortDirection='desc'
            perms={this.perms}
            autoRefresh={30}
          />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {},
  cellTextSmall: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
});
