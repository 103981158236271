import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  FieldGroup,
  StyleSheet,
} from "../core";
import EditForm from "../components/EditForm";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class VirtualWorkspaceImageEditScreen extends React.Component {
    constructor(props) {
      super(props);

      this.virtualWorkspaceRegionItemLoader =
        this.virtualWorkspaceRegionItemLoader.bind(this);
      this.cloudAccessKeyItemLoader = this.cloudAccessKeyItemLoader.bind(this);
    }
    async virtualWorkspaceRegionItemLoader(filters = {}) {
      return await this.props.app.dm.virtualWorkspaceRegion.fetchSelections(
        filters,
        {
          resultsPerPage: 50,
        }
      );
    }
    async cloudAccessKeyItemLoader(filters = {}) {
      return await this.props.app.dm.cloudAccessKey.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='virtualWorkspaceImage'
          redirect={this.props.redirect || "virtualWorkspaceImages"}
          refreshDataTable='virtualWorkspaceImages'
        >
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
              autoFocus
            />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              helper='Provider image key'
            />
          </FieldGroup>

          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />

          <FieldGroup>
            <SelectMenuField
              key='cloudAccessKey'
              name='cloudAccessKey'
              label='Cloud Access Key'
              searchable
              autoLoad
              itemLoader={this.cloudAccessKeyItemLoader}
              required='Please select a cloud access key.'
            />
            <SelectMenuField
              key='virtualWorkspaceRegion'
              name='virtualWorkspaceRegion'
              label='Region'
              searchable
              autoLoad
              itemLoader={this.virtualWorkspaceRegionItemLoader}
              required='Please select a region.'
            />
          </FieldGroup>

          <FieldGroup>
            <SelectMenuField
              key='platform'
              name='platform'
              label='Platform'
              useValuesOnly
              items={this.props.formData.platformSelections}
              required='Please select a platform.'
            />
            <SelectMenuField
              key='instanceSize'
              name='instanceSize'
              label='Instance Size'
              useValuesOnly
              items={this.props.formData.instanceSizeSelections}
              required='Please select an instance size.'
            />
          </FieldGroup>
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({});
