import React from "react";
import R14, { StyleSheet } from "../core";
import ManualEntryUserDataTable from "../components/ManualEntryUserDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ManualEntryUsersScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
    }
    render() {
      let project = this.props.project;
      return (
        <ProjectScreenLayout
          project={project}
          headerTitle='Users'
          breadCrumbs={[
            {
              label: "Manual Entry",
              to: {
                route: "projectManualEntryPipelines",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                },
              },
            },
            {
              label: this.props.manualEntryPipeline.name,
              to: {
                route: "projectManualEntryPipeline",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                  manualEntryPipelineUid: this.props.manualEntryPipeline.uid,
                },
              },
            },
          ]}
          // floatingActionButton={
          //   this.perms.create
          //     ? {
          //         to: "manualEntryUserCreate",
          //         icon: "add",
          //       }
          //     : null
          // }
          scrollEnabled
          DataTableComponent={
            <ManualEntryUserDataTable
              manualEntryPipeline={this.props.manualEntryPipeline}
              activity
            />
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({});
