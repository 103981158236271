import React from "react";
import R14, {
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  SwitchField,
  Colors,
  Surface,
  FadeView,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryUserDeploymentsDialogFormField extends React.Component {
    constructor(props) {
      super(props);
      this.handleDeploymentsDialogOpen =
        this.handleDeploymentsDialogOpen.bind(this);
      this.handleManualEntryVisibleChange =
        this.handleManualEntryVisibleChange.bind(this);
      this.handleVirtualWorkspaceVisibleChange =
        this.handleVirtualWorkspaceVisibleChange.bind(this);
      this.deploymentItemRenderer = this.deploymentItemRenderer.bind(this);
      this.state = {
        manualEntryVisible:
          !this._useR14Deployments &&
          this.props.formData &&
          this.props.formData.values &&
          this.props.formData.values.manualEntryConfigUid
            ? true
            : false,
        virtualWorkspaceVisible: true,
        mfaType:
          this.props.formData &&
          this.props.formData.values &&
          this.props.formData.values.mfaType
            ? this.props.formData.values.mfaType
            : null,
        r14DeploymentSelections: [],
        r14DeploymentUids: [],
        showClientKey: false,
      };
      this._r14DeploymentSelections =
        this.props.formData && this.props.formData.r14DeploymentSelections
          ? this.props.formData.r14DeploymentSelections
          : [];

      this._r14DeploymentInstance =
        this.props.formData && this.props.formData.r14DeploymentInstance
          ? this.props.formData.r14DeploymentInstance
          : null;
    }
    handleDeploymentsDialogOpen(item = null) {
      if (item)
        this.setState({
          showClientKey: false,
          r14DeploymentSelections: [],
        });
      else
        this.setState({
          showClientKey: true,
          r14DeploymentSelections: this._r14DeploymentSelections,
        });
    }

    handleManualEntryVisibleChange(value) {
      this.setState({ manualEntryVisible: value });
    }
    handleVirtualWorkspaceVisibleChange(value) {
      this.setState({ virtualWorkspaceVisible: value });
    }
    getR14DeploymentSelections() {
      return this.props.formData.r14DeploymentSelections;
    }
    getDeploymentLabel(uuid) {
      let r14Deployments = this.props.formData.r14DeploymentSelections.filter(
        (d) => (d.value === uuid ? true : false)
      );
      return r14Deployments.length ? r14Deployments[0].label : "N/A";
    }

    deploymentItemRenderer(values) {
      return (
        <View>
          <View style={styles.itemRow}>
            <LabelView style={styles.itemLabelView} key='name' label='Name'>
              <Text>{this.getDeploymentLabel(values.r14DeploymentUuid)}</Text>
            </LabelView>
          </View>
        </View>
      );
    }
    handleDeploymentsDialogOpen(item = null) {
      if (item) {
        console.log({
          manualEntryVisible: item.manualEntryConfigKey ? true : false,
          virtualWorkspaceVisible: item.virtualWorkspaceConfigKey
            ? true
            : false,
        });
        this.setState({
          showClientKey: false,
          manualEntryVisible: item.manualEntryConfigKey ? true : false,
          virtualWorkspaceVisible: item.virtualWorkspaceConfigKey
            ? true
            : false,
        });
      } else {
        this.setState({
          showClientKey: true,
          manualEntryVisible: false,
          virtualWorkspaceVisible: false,
        });
      }
    }
    render() {
      return (
        <DialogFormField
          name='r14DeploymentConfigs'
          dialogTitle='Add Deployment'
          label='Deployments'
          formName='deploymentForm'
          itemRenderer={this.deploymentItemRenderer}
          // onRemoveItem={this.handleRemoveBlockIoItem}
          // validator='uniqueKey'
          onValueChange={this.handleDeploymentsChange}
          style={[styles.r14DeploymentsDialogFormField, this.props.style]}
          onDialogOpen={this.handleDeploymentsDialogOpen}
          required={this.props.required || false}
        >
          <SelectMenuField
            useValuesOnly
            name='r14DeploymentUuid'
            label='Deployment'
            required='Select Deployment'
            items={this.getR14DeploymentSelections()}
          />
          {this.state.showClientKey && (
            <TextInputField
              name='clientKey'
              label='Client Key'
              required='Enter Client Key'
            />
          )}
          <SwitchField
            name='manualEntryVisible'
            label='Enable Manual Entry'
            value={this.state.manualEntryVisible}
            onValueChange={this.handleManualEntryVisibleChange}
          />
          <FadeView visible={this.state.manualEntryVisible}>
            <Surface style={styles.sectionSurfaceStyle}>
              <TextInputField
                name='manualEntryConfigKey'
                label='Manual Entry Config Key'
                labelStyle={styles.surfaceLabel}
                required={true}
              />
              <SelectMenuField
                useValuesOnly
                multiple
                name='modes'
                label='Modes'
                items={this.props.formData.modeSelections}
                labelStyle={styles.surfaceLabel}
                required={
                  this.state.manualEntryVisible
                    ? "Please select a mode."
                    : false
                }
              />
              <DialogFormField
                name='queueNamespaces'
                dialogTitle='Add Namespace'
                label='Queue Namespaces'
                formName='manualEntryConfigForm'
                itemRenderer={(values) => (
                  <View style={styles.fieldValueItem}>
                    <Text>{values.queueNamespace}</Text>
                  </View>
                )}
                helper='Tag Value Namespace. Example: "cust1/prj1"'
              >
                <TextInputField
                  name='queueNamespace'
                  label='Tag Value Namespace'
                  required='Please enter a field.'
                />
              </DialogFormField>
              <SwitchField
                name='exclusiveQueueNamespaces'
                label='Exclusive Queue Namespaces'
                helper='Results only returned by namespace'
                value={false}
              />
              <FieldGroup>
                <TextInputField
                  name='queuedTimeoutSeconds'
                  label={"Queued Item Timeout"}
                  labelStyle={styles.surfaceLabel}
                  // required={true}
                  helper='Seconds'
                />
              </FieldGroup>
            </Surface>
          </FadeView>
          <SwitchField
            name='virtualWorkspaceVisible'
            label='Enable Virtual Workspace'
            value={this.state.virtualWorkspaceVisible}
            onValueChange={this.handleVirtualWorkspaceVisibleChange}
          />
          <FadeView visible={this.state.virtualWorkspaceVisible}>
            <Surface style={styles.sectionSurfaceStyle}>
              <TextInputField
                name='virtualWorkspaceConfigKey'
                label='Virtual Workspace Config Key'
                labelStyle={styles.surfaceLabel}
                required={true}
              />
            </Surface>
          </FadeView>
          <SwitchField name='active' label='Is Active' value={true} />
        </DialogFormField>
      );
    }
  }
);
const styles = StyleSheet.create({
  sectionSurfaceStyle: {
    ...StyleSheet.padding(16, 8, 0, 8),
    ...StyleSheet.margin(-16, -8, 24, -8),
  },
  surfaceLabel: {
    backgroundColor: Colors.surface,
  },
  r14DeploymentsDialogFormField: {
    width: 480,
  },
});
