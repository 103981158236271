import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  ShowHideText,
  SwitchField,
} from "../core";
import EditForm from "../components/EditForm";
import BlockEditFormFields from "../components/BlockEditFormFields";
import FormValidators from "../config/FormValidators";
import LabelView from "../components/LabelView";

export default R14.connect(
  class BlockEditScreen extends React.Component {
    constructor(props){
      super(props);
      this.handleRemoveBlockIoItem = this.handleRemoveBlockIoItem.bind(this);
      this.removedBlockIoUids = [];
    }
    async handleRemoveBlockIoItem(item) {
      if (item && item.uid && !this.removedBlockIoUids.includes(item.uid))
        this.removedBlockIoUids.push(item.uid);
    }
    render() {
      return (
        <EditForm
          name='blockForm'
          formData={this.props.formData}
          domainName='block'
          redirect={this.props.redirect || "blocks"}
          refreshDataTable={this.props.refreshDataTable || "blocks"}
          saveOptions={{
            removedBlockIoUids: this.removedBlockIoUids,
          }}
          validators={FormValidators}
        >
          <BlockEditFormFields onRemoveBlockIoItem={this.handleRemoveBlockIoItem} {...this.props} />
        </EditForm>
      );
    }
  }
);