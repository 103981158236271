import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  DialogFormField,
  FadeView,
  View,
  Text,
  StyleSheet,
  SectionHeader,
  Colors,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryRouteEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTimeoutTagValuesRenderer =
        this.handleTimeoutTagValuesRenderer.bind(this);
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      // return {
      //   ...this.props.formData,
      //   values: {
      //     inputType: "S3",
      //     outputType: "S3",
      //     name: "Healthcare PROD",
      //     key: "healthcare-prod",
      //     description: null,
      //     manualEntryPipelineUids: ["JdHDdx2Gr"],
      //     inputBucketName: "exela-uat-hc",
      //     inputFolderName: "TeletypeProdInput/",
      //     inputCopyToFolderName: "TeletypeProdInProgress",
      //     outputBucketName: "exela-uat-hc",
      //     outputFolderName: "TeletypeProdOutput",
      //     timeoutMinutes: "60",
      //     timeoutTagValues: [
      //       {
      //         name: "customerid",
      //         value: "test",
      //         minutes: "30",
      //       },
      //     ],
      //   },
      // };
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryRouteForm");
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.manualEntryRoute[editMethod](
          form.values
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "manualEntryRoutes";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "manualEntryRoutes"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    handleTimeoutTagValuesRenderer(values) {
      return (
        <View>
          <View style={styles.tagValueItemRow}>
            <LabelView
              style={styles.tagValueItemLabelView}
              key='name'
              label='Name'
            >
              <Text>{values.name}</Text>
            </LabelView>
            <LabelView
              style={styles.tagValueItemLabelView}
              key='value'
              label='Value'
            >
              <Text>{values.value}</Text>
            </LabelView>
            <LabelView
              style={styles.tagValueItemLabelView}
              key='minutes'
              label='Timeout'
            >
              <Text>{values.minutes}</Text>
            </LabelView>
          </View>
        </View>
      );
    }
    render() {
      return (
        <Form
          name='manualEntryRouteForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='inputType' />
          <HiddenInputField name='outputType' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required={true} />
            <TextInputField name='key' label='Key' required={true} />
          </FieldGroup>
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
          />
          <SelectMenuField
            useValuesOnly
            multiple
            name='manualEntryPipelineUids'
            label='Manual Entry Projects'
            items={this.formData.manualEntryPipelineSelections}
            required='Please select a manual entry project.'
          />
          <SectionHeader level={2} style={styles.sectionHeader}>
            Input
          </SectionHeader>
          <FieldGroup>
            <TextInputField name='inputBucketName' label='Bucket Name' />
            <TextInputField name='inputFolderName' label='Folder Name' />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='inputCopyToFolderName'
              label='Copy to Folder Name'
            />
            <View></View>
          </FieldGroup>
          <SectionHeader level={2} style={styles.sectionHeader}>
            Output
          </SectionHeader>
          <FieldGroup>
            <TextInputField name='outputBucketName' label='Bucket Name' />
            <TextInputField name='outputFolderName' label='Folder Name' />
          </FieldGroup>
          <SectionHeader level={2} style={styles.sectionHeader}>
            Timeout Settings
          </SectionHeader>
          <TextInputField
            name='timeoutMinutes'
            label='Default Timeout'
            helper='Minutes. Optional.'
          />
          <DialogFormField
            name='timeoutTagValues'
            dialogTitle='Timeout Tag Values'
            label='Timeout Tag Values'
            formName='manualEntryRouteForm'
            itemRenderer={this.handleTimeoutTagValuesRenderer}
            // onSubmit={this.handlePipelineBlocksDialogSubmit}
            // onDialogClose={this.handlePipelineBlocksDialogClose}
            // onItemEditPress={this.handlePipelineBlocksItemEditPress}
          >
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
            />
            <TextInputField
              name='value'
              label='Value'
              required='Please enter a value.'
            />
            <TextInputField
              name='minutes'
              label='Timeout'
              required='Please enter a timeout.'
              helper='Minutes'
            />
          </DialogFormField>
          {/* <SwitchField name='active' label='Is Active' value={true} /> */}
        </Form>
      );
    }
  }
);

const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    ...StyleSheet.padding(0, 0, 16, 0),
    ...StyleSheet.margin(0, 0, 0, 0),
  },
  tagValueItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
  tagValueItem: {
    marginBottom: -8,
  },
  tagValueItemRow: {
    flex: 1,
    flexDirection: "row",
    paddingBottom: 8,
  },
  tagValueItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  tagValueItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
