import React from "react";
import R14, { useApp, StyleSheet } from "../core";
import ManualEntryPipelineUserMetricsDataTable from "../components/ManualEntryPipelineUserMetricsDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
function ProjectManualEntryUserMetricsScreen({ manualEntryPipeline, project }) {
  const app = useApp();
  const perms = app.dm.user.getPermissions("admin", "manualEntryUser");
  return (
    <ProjectScreenLayout
      project={project}
      headerTitle="User Metrics"
      breadCrumbs={[
        {
          label: "Manual Entry",
          to: {
            route: "projectManualEntryPipelines",
            params: {
              uid: project.uid,
              key: app.dm.project.getKeyByType(project.type),
            },
          },
        },
        {
          label: manualEntryPipeline.name,
          to: {
            route: "projectManualEntryPipeline",
            params: {
              uid: project.uid,
              key: app.dm.project.getKeyByType(project.type),
              manualEntryPipelineUid: manualEntryPipeline.uid,
            },
          },
        },
      ]}
      scrollEnabled
      DataTableComponent={
        <ManualEntryPipelineUserMetricsDataTable
          manualEntryPipeline={manualEntryPipeline}
        />
      }
    />
  );
}
export default ProjectManualEntryUserMetricsScreen;