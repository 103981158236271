import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
} from "../core";

export default R14.connect(
  class ClientEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.client[editMethod](form.values);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "clients";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "clients"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='clientForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <TextInputField
            autoFocus
            name='name'
            label='Name'
            required='Enter Name'
          />
          <TextInputField
            autoFocus
            name='key'
            label='Key'
            required='Enter Key'
            validator="key"
          />
          <TextInputField name='description' label='Description' />
          <SwitchField name='active' label='Is Active' value={true} />
          <FieldGroup label='Core Repository'>
            <TextInputField
              autoComplete={false}
              name='repoCoreUsername'
              label='Username'
            />
            <TextInputField
              autoComplete={false}
              name='repoCorePassword'
              secure
              label={this.isEditMode ? "Change Password" : "Password"}
            />
          </FieldGroup>
        </Form>
      );
    }
  }
);
