import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  ColorInputField,
  SectionHeader,
  StyleSheet,
  View,
} from "../core";
import EditForm from "../components/EditForm";
import EventScheduleDialogForm from "../components/EventScheduleDialogForm";

export default R14.connect(
  class ProjectPipelineBlockEventEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.blockItemLoader = this.blockItemLoader.bind(this);
      //this.handleBlockValueChange = this.handleBlockValueChange.bind(this);
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.state = {
        connectionType: this.props.formData.values.connectionType,
        optionFields: this.props.formData.optionFields || null,
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async blockItemLoader(filters = {}) {
      filters.projectType = this.props.formData.values.projectType;
      let t = await this.props.app.dm.pipelineBlock.fetchBlockSelections(
        filters
      );
    }
    // async handleBlockValueChange(val) {
    //   this.props.app.ui.progressIndicator.show();
    //   let blockOptions = await this.props.app.dm.block.fetchOptions(val.value);
    //   this.setState({
    //     optionFields: blockOptions && blockOptions.length ? blockOptions : null,
    //   });
    //   this.props.app.ui.progressIndicator.hide({ timeout: 500 });
    // }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          name='pipelineBlockEventForm'
          domainName='pipelineBlock'
          redirect={this.props.redirect || "projectPipeline"}
          updateMethod='updateEvent'
          createMethod='updateEvent'
        >
          <HiddenInputField name='uid' />
          <HiddenInputField name='pipelineUid' />
          {this.formData.values.eventTaskUid && (
            <HiddenInputField name='eventTaskUid' />
          )}
          {this.formData.values.eventUid && (
            <HiddenInputField name='eventUid' />
          )}
          <EventScheduleDialogForm
            label='Run Triggers'
            dialogTitle='Add Run Trigger'
            formName='pipelineBlockEventForm'
            name='eventSchedule'
            formData={this.props.formData}
            targetType={this.props.app.dm.event.TARGET_TYPE_PIPELINE_BLOCK}
          />
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    paddingBottom: 16,
  },
});
