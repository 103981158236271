import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  DialogFormField,
  FadeView,
  View,
  Text,
  StyleSheet,
  SectionHeader,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryPipelineEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleTimeoutFieldValuesRenderer =
        this.handleTimeoutFieldValuesRenderer.bind(this);
      // this.handlePipelineBlocksDialogClose = this.handlePipelineBlocksDialogClose.bind(
      //   this
      // );
      this.handlePipelineValueChange =
        this.handlePipelineValueChange.bind(this);
      // this.handlePipelineBlocksDialogSubmit = this.handlePipelineBlocksDialogSubmit.bind(
      //   this
      // );
      // this.handlePipelineBlocksItemEditPress = this.handlePipelineBlocksItemEditPress.bind(
      //   this
      // );
      this.state = {
        pipelineBlockSelections: this.props.formData.pipelineBlockSelections,
      };
    }
    async handlePipelineValueChange(
      value,
      label = null,
      elmt = null,
      form = null
    ) {
      this.props.app.ui.progressIndicator.show();

      if (!value) {
        this.setState({ pipelineBlockSelections: [] });
        return false;
      }
      let pipelineBlockSelections =
        await this.props.app.dm.pipeline.fetchBlockSelections({
          pipelineUid: value,
        });
      if (form && form.elmts.controllerPipelineBlockUid) {
        form.elmts.controllerPipelineBlockUid.setItems(pipelineBlockSelections);
        form.elmts.controllerPipelineBlockUid.setValue(null);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.setState({ pipelineBlockSelections });
    }
    handlePipelineBlocksItemEditPress(values) {
      this.handlePipelineValueChange(values.pipelineUid);
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryPipelineForm");
    }

    handleTimeoutFieldValuesRenderer(values) {
      return (
        <View>
          <View style={styles.fieldValueItemRow}>
            <LabelView
              style={styles.fieldValueItemLabelView}
              key='tag'
              label='Tag'
            >
              <Text>{values.field}</Text>
            </LabelView>
            <LabelView
              style={styles.fieldValueItemLabelView}
              key='value'
              label='Value'
            >
              <Text>{values.value}</Text>
            </LabelView>
            <LabelView
              style={styles.fieldValueItemLabelView}
              key='minutes'
              label='Timeout'
            >
              <Text>{values.minutes}</Text>
            </LabelView>
          </View>
        </View>
      );
    }

    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.manualEntryPipeline[editMethod](
          form.values
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "manualEntryPipelines";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = (
            <Redirect
              to={this.props.redirect || "projectManualEntryPipelines"}
            />
          );
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='manualEntryPipelineForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectUid' />
          <TextInputField name='name' label='Name' required={true} />
          <SelectMenuField
            useValuesOnly
            name='pipelineUid'
            label='Pipeline'
            items={this.formData.pipelineSelections}
            onValueChange={this.handlePipelineValueChange}
            required='Please select a pipeline.'
          />
          <FadeView
            unmountOnExit
            visible={this.state.pipelineBlockSelections.length ? true : false}
          >
            {this.state.pipelineBlockSelections.length ? (
              <SelectMenuField
                useValuesOnly
                name='controllerPipelineBlockUid'
                label='Controller Pipeline Block'
                items={this.state.pipelineBlockSelections}
                required='Please select a pipeline block.'
              />
            ) : null}
          </FadeView>
          <TextInputField name='description' label='Description' />

          <SelectMenuField
            useValuesOnly
            multiple
            name='modes'
            label='Modes'
            items={this.formData.modeSelections}
            required='Please select a mode.'
          />

          <FieldGroup>
            <TextInputField name='scoreThreshold' label='Score Threshold' />
            <TextInputField
              name='timeoutMinutes'
              label='Default Timeout'
              helper='Minutes'
            />
          </FieldGroup>
          <TextInputField
            name='queueTagNamespace'
            label='Queue Tag Namespace'
            helper='Tag Namespace separated by "/". Example: "customerId/projectId"'
          />
          <DialogFormField
            name='timeoutFieldValues'
            dialogTitle='Timeout Tag Values'
            label='Timeout Tag Values'
            formName='manualEntryPipelineForm'
            itemRenderer={this.handleTimeoutFieldValuesRenderer}
            // onSubmit={this.handlePipelineBlocksDialogSubmit}
            // onDialogClose={this.handlePipelineBlocksDialogClose}
            // onItemEditPress={this.handlePipelineBlocksItemEditPress}
          >
            <TextInputField
              name='field'
              label='Field'
              required='Please enter a field.'
            />
            <TextInputField
              name='value'
              label='Value'
              required='Please enter a value.'
            />
            <TextInputField
              name='minutes'
              label='Timeout'
              required='Please enter a timeout.'
              helper='Minutes'
            />
          </DialogFormField>
          {/* <FieldGroup>
            <TextInputField
              name='queuedTimeoutSeconds'
              label={"Queued Item Timeout"}
              required={true}
              helper='Seconds'
            />
            <TextInputField
              name='reviewTimeoutSeconds'
              label={"Review Timeout"}
              required={true}
              helper='Seconds'
            />
          </FieldGroup> */}
          <SwitchField name='active' label='Is Active' value={true} />
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  fieldValueItem: {
    marginBottom: -8,
  },
  fieldValueItemRow: {
    flex: 1,
    flexDirection: "row",
    paddingBottom: 8,
  },
  fieldValueItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 12,
  },
  fieldValueItemLabelView: {
    width: "25%",
    minWidth: 0,
    flex: 1,
  },
});
