import React from "react";
import R14, {
  Colors,
  View,
  StyleSheet,
  Icon,
  SelectMenuField,
  PopUpMenu,
  PopUpMenuItem,
  Chip,
  ChipGroup,
  Text,
  ShowHideText,
  DataTable,
  DatePickerField,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";
import StateIndicatorIcon from "./StateIndicatorIcon";
export default R14.connect(
  class HealthCheckDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.healthCheckDomain = this.props.app.dm.healthCheck;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
    }
    async getPageData(
      {
        page,
        rowsPerPage,
        sortColumnName,
        sortDirection,
        search = null,
        filters = null,
      },
      options = {}
    ) {
      let filter = {};
      if (this.props.projectUid)
        filter.projectUid = { eq: this.props.projectUid };
      if (this.props.projectHealthCheckUid)
        filter.projectHealthCheckUid = { eq: this.props.projectHealthCheckUid };
      // Add Search Filter
      if (search) filter.search = { like: `%${search}%` };
      if (filters) {
        let startDate =
          filters.startDateFilter &&
          new Date(filters.startDateFilter + " 12:01 AM");
        let endDate =
          filters.endDateFilter &&
          new Date(filters.endDateFilter + " 11:59 PM");
        if (filters.stateFilter) filter.state = { eq: filters.stateFilter };
        if (startDate && endDate)
          filter.endAt = {
            between: [startDate, endDate],
          };
        else if (endDate) filter.endAt = { lte: endDate };
        else if (startDate) filter.endAt = { gte: startDate };
      }
      let res = await this.props.app.dm.healthCheck.find(
        `
          uid
          name
          message
          state
          description
          environment
          browser
          url
          endAt
          duration
          location {
            city
            state
            country
            zipCode
          }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("healthChecks");
    }
    rowBottomRenderer({ row, columns }) {
      return (
        <View>
          {row.name && columns.endAt.collapsed && (
            <View style={styles.nameCellDescription}>
              <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                {this.renderEndAt(row.endAt)}
              </Text>
            </View>
          )}
          {row.message && (
            <View style={styles.messageRow}>
              <Icon
                color={Colors.error}
                name='error'
                style={styles.messageIcon}
              />
              <ShowHideText text={row.message} maxCharacters={60} />
            </View>
          )}
          <ChipGroup style={styles.cellChipGroup}>
            {columns.state.collapsed && (
              <Chip
                iconLeft={
                  <StateIndicatorIcon
                    color={this.healthCheckDomain.getStateIndicatorColor(
                      row.state
                    )}
                  />
                }
                label={this.healthCheckDomain.getStateLabel(row.state)}
              />
            )}
            {row.name && row.name !== row.description && (
              <Chip label={row.name} />
            )}
            {row.environment && <Chip label={row.environment} />}
            {row.url && <Chip label={row.url} />}
            {row.browser && <Chip label={row.browser} />}
            {row.duration && <Chip label={`${row.duration}s`} />}
            {row.location && row.location.city && <Chip label={row.location.city} />}
            {row.location && row.location.state && <Chip label={row.location.state} />}
            {row.location && row.location.country && <Chip label={row.location.country} />}
            {row.location && row.location.zipCode && <Chip label={row.location.zipCode} />}
          </ChipGroup>
        </View>
      );
    }
    cellRenderer({ cell, columnName, row, columns }) {
      return <Text>{cell}</Text>;
    }
    cellRenderer({ cell, columnName, row, columns }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "description":
          ret = (
            <React.Fragment>
              <View>
                <Text style={[styles.cellText, styles.nameCellNameText]}>
                  {row.description}
                </Text>
              </View>
            </React.Fragment>
          );
          break;
        case "endAt":
          ret = <Text style={style}>{this.renderEndAt(row.endAt)}</Text>;
          break;
        case "duration":
          ret = <Text style={style}>{row.duration}s</Text>;
          break;
        case "state":
          ret = (
            <StateIndicatorText
              label={this.healthCheckDomain.getStateLabel(cell)}
              color={this.healthCheckDomain.getStateIndicatorColor(cell)}
            />
          );
          break;
        // case "type":
        //   ret = (
        //     <Text style={style}>
        //       {this.props.app.dm.resource.getTypeLabel(cell)}
        //     </Text>
        //   );
        //   break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    renderEndAt(endAt) {
      let endAtDate = this.props.app.utils.date.toShortDate(new Date(endAt));
      let endAtTime = this.props.app.utils.date.toTime(new Date(endAt));
      return `${endAtDate} ${endAtTime}`;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleSearchPress() {}
    render() {
      let startDate = new Date();
      let endDate = new Date();
      let permType = "project";
      let hasEditPerm = this.props.app.dm.user.checkPermissions(
        permType,
        "healthCheck",
        this.props.app.dm.user.PERMISSION_ACCESS_EDIT
      );
      let hasExecPerm = this.props.app.dm.user.checkPermissions(
        permType,
        "healthCheck",
        this.props.app.dm.user.PERMISSION_ACCESS_EXECUTE
      );
      startDate.setMonth(startDate.getMonth() - 1);
      endDate.setMonth(endDate.getMonth() + 1);
      return (
        <DataTable
          name='healthChecks'
          columns={{
            // name: {
            //   label: "Name",
            //   collapse: ({ width }) => {
            //     return width <= 1040;
            //   },
            // },
            description: {
              label: "Description",
              sortable: true
              // collapse: ({ width }) => {
              //   return width <= 1040;
              // },
            },
            // environment: {
            //   label: "Env",
            //   width: 96,
            //   collapse: ({ width }) => {
            //     return width <= 1040;
            //   },
            // },
            // url: {
            //   label: "Url",
            //   // width: 128,
            //   collapse: ({ width }) => {
            //     return width <= 1040;
            //   },
            // },
            // browser: {
            //   label: "Browser",
            //   width: 128,
            //   collapse: ({ width }) => {
            //     return width <= 1040;
            //   },
            // },
            endAt: {
              label: "Ended At",
              width: 192,
              collapse: ({ width }) => {
                return width <= 640;
              },
              align: "right",
              sortable: true,
            },
            // duration: {
            //   label: "Duration",
            //   width: 76,
            //   sortable: true,
            //   collapse: ({ width }) => {
            //     return width <= 1040;
            //   },
            // },
            state: {
              label: "State",
              sortable: true,
              width: 128,
              // collapse: ({ width }) => {
              //   return width <= 480;
              // },
              // cellStyle: styles.stateCell,
              // collapse: ({ width }) => {
              //   return width <= 480;
              // },
            },
          }}
          maxHeight={300}
          autoRefresh={30}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          rowBottom={this.rowBottomRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Health Checks"}
          // checkboxes
          isSelectedRow={this.isSelectedRow}
          onRowSelect={this.handleRowSelect}
          onRowsSelect={this.handleRowsSelect}
          onRowPress={this.handleRowPress}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='endAt'
          initialSortDirection='desc'
          headerFilterFields={[
            <SelectMenuField
              useValuesOnly
              key='stateFilter'
              name='stateFilter'
              label='State'
              items={this.props.app.dm.healthCheck.getStateSelections()}
            />,
            <DatePickerField
              key='startDate'
              name='startDateFilter'
              label='Start Date'
              mode='modal'
            />,
            <DatePickerField
              key='endDate'
              name='endDateFilter'
              label='End Date'
              mode='modal'
            />,
          ]}
          headerControlsRight={[
            <PopUpMenu
              controlIcon='dotsVertical'
              key='adminMenu'
              direction='downLeft'
              onSelect={(value, label) => {}}
            >
              {this.props.projectHealthCheckUid && (
                <PopUpMenuItem
                  icon='edit'
                  iconSize='small'
                  label='Edit Health Check'
                  value='add'
                  onPress={() =>
                    this.props.app.nav.to("projectHealthCheckEdit", {
                      uid: this.props.projectUid,
                      key: this.props.app.dm.project.getKeyByType(
                        this.props.projectType
                      ),
                      projectHealthCheckUid: this.props.projectHealthCheckUid,
                    })
                  }
                  key='add'
                />
              )}
              {!this.props.projectHealthCheckUid && (
                <PopUpMenuItem
                  icon='add'
                  iconSize='small'
                  label='Create Health Check'
                  value='add'
                  onPress={() =>
                    this.props.app.nav.to("projectHealthCheckCreate", {
                      uid: this.props.projectUid,
                      key: this.props.app.dm.project.getKeyByType(
                        this.props.projectType
                      ),
                    })
                  }
                  key='add'
                />
              )}
            </PopUpMenu>,
          ]}
          //rowControlsRightWidth={140}
          // rowControlsRight={(row, dataTable) => {
          //   return [];
          // }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  nameCell: {
    marginRight: "auto",
  },
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
  nameCellDescription: {
    marginTop: 4,
  },
  messageIcon: {
    marginRight: 8,
  },
  messageRow: {
    flex: 1,
    flexDirection: "row",
    ...StyleSheet.margin(8, 0, 12, 0),
  },
});
