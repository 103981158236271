import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  HiddenInputField,
  FieldGroup,
  Text,
  DatePickerField,
  TimePickerField,
  StyleSheet,
} from "../core";
import EditForm from "../components/EditForm";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class VirtualWorkspaceConfigEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.virtualWorkspaceItemLoader =
        this.virtualWorkspaceItemLoader.bind(this);
    }
    async virtualWorkspaceItemLoader(filters = {}) {
      return await this.props.app.dm.virtualWorkspace.fetchSelections(
        filters,
        {
          resultsPerPage: 50,
        }
      );
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='virtualWorkspaceConfig'
          redirect={this.props.redirect || "virtualWorkspaceConfigs"}
          refreshDataTable='virtualWorkspaceConfigs'
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField
            name='key'
            label='Key'
            required='Please enter a key.'
            autoFocus
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
          <SelectMenuField
            multiple
            key='virtualWorkspaces'
            name='virtualWorkspaces'
            label='Virtual Workspace(s)'
            searchable
            autoLoad
            itemLoader={this.virtualWorkspaceItemLoader}
            required='Please select at least one config.'
          />
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({});
