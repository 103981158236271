import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
  Image,
} from "../core";

export default R14.connect(
  class ManualEntryPipelineDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.manualEntryPipelinePerms = this.props.app.dm.user.getPermissions(
        "idp",
        "manualEntryPipeline"
      );
      this.manualEntryPipelineTimeoutPerms =
        this.props.app.dm.user.getPermissions(
          "idp",
          "manualEntryPipelineTimeout"
        );
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.project)
        filter.projectUid = { eq: this.props.project.uid };
      let res = await this.props.app.dm.manualEntryPipeline.find(
        `
          uid
          name
          description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryPipelines");
    }
    cellRenderer({ cell, columnName, row, columns }) {
      return <Text style={[styles.cellText]}>{cell}</Text>;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.manualEntryPipeline.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleRunCommandPress(uid, command) {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.manualEntryPipeline.runCommand(uid, command);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    async handleSearchPress() {}
    render() {
      return (
        <DataTable
          name='manualEntryPipelines'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            description: {
              label: "Description",
              sortable: true,
            },
          }}
          searchable
          scrollable
          onRowPress={(row) =>
            this.props.app.nav.to("projectManualEntryPipeline", {
              uid: this.props.project.uid,
              key: this.props.app.dm.project.getKeyByType(
                this.props.project.type
              ),
              manualEntryPipelineUid: row.uid,
            })
          }
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Manual Entry"}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='type'
          initialSortDirection='asc'
          rowControlsRight={(row, dataTable) => {
            let ret = [];
            this.manualEntryPipelinePerms.edit &&
              ret.push(
                <IconButton
                  icon='edit'
                  size='small'
                  key='edit'
                  onPress={() =>
                    this.props.app.nav.to("projectManualEntryPipelineEdit", {
                      uid: this.props.project.uid,
                      key: this.props.app.dm.project.getKeyByType(
                        this.props.project.type
                      ),
                      manualEntryPipelineUid: row.uid,
                    })
                  }
                />
              );
            this.manualEntryPipelineTimeoutPerms.execute &&
              ret.push(
                <PopUpMenu
                  controlIcon='dotsHorizontal'
                  controlIconSize='small'
                  key='projectResourceMenu'
                  direction='downLeft'
                >
                  <PopUpMenuItem
                    label='Timeout Document Sets'
                    icon='clock'
                    key='timeout'
                    onPress={() =>
                      window.confirm(
                        "Are you sure? This will timeout all documents currently in the pool."
                      ) &&
                      this.handleRunCommandPress(
                        row.uid,
                        this.props.app.dm.manualEntryPipeline.COMMAND_TIMEOUT
                      )
                    }
                  />
                  {/* <PopUpMenuItem
                    label='Restart Services'
                    icon='refresh'
                    key='restartServices'
                    onPress={() =>
                      window.confirm(
                        "Are you sure? This will restart all r14 services. Please only use this as a last resort. You will need to refresh the browser after running."
                      ) &&
                      this.handleRunCommandPress(
                        row.uid,
                        this.props.app.dm.manualEntryPipeline
                          .COMMAND_RESTART_SERVICES
                      )
                    }
                  /> */}
                </PopUpMenu>
              );

            return ret;
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
});
