import React from "react";
import PropTypes from "prop-types";
import R14, { StyleSheet, Colors, View, Text, Card } from "../core";
import StateIndicatorIcon from "./StateIndicatorIcon";

export default class CardTable extends React.PureComponent {
  static propTypes = {
    /** The label for the indicator */
    headerTitle: PropTypes.string,
    /** Key value column object */
    columns: PropTypes.object.isRequired,
    /** Array of key value row values */
    data: PropTypes.array.isRequired,
    /** Gets the key for a row, required if no key given */
    rowKeyExtractor: PropTypes.func,
    /** A StyleSheet object, or array of StyleSheet objects to apply to the card table */
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };
  static defaultProps = {
    color: "green",
  };
  render() {
    let columns = Object.keys(this.props.columns).map((columnKey) => ({
      key: columnKey,
      ...this.props.columns[columnKey],
    }));
    return (
      <Card
        title={this.props.headerTitle || null}
        titleTextStyle={styles.cardTableTitleStyle}
        style={[styles.cardTable, this.props.style]}
        surfaceStyle={[styles.cardTableSurface, this.props.surfaceStyle]}
      >
        <View style={styles.cardTableHeader}>
          {columns.map((column) => (
            <View key={column.key} style={styles.cardTableHeaderColumn}>
              <Text style={styles.cardTableHeaderColumnText}>
                {column.label}
              </Text>
            </View>
          ))}
        </View>
        {this.props.data.map((row) => (
          <View
            style={styles.cardTableRow}
            key={
              row.key ||
              (this.props.rowKeyExtractor && this.props.rowKeyExtractor(row))
            }
          >
            {columns.map((column) => (
              <View key={column.key} style={styles.cardTableNameColumn}>
                <Text style={styles.cardTableColumnText}>
                  {row[column.key] || ""}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  cardTable: {
    // flex: 0,
    // width: "33.3333%",
    // minWidth: "33.3333%",
    // marginRight: "auto"
  },
  cardTableSurface: {
    ...StyleSheet.margin(0),
  },
  cardTableTitleStyle: {
    fontSize: 18,
    fontWeight: 500,
  },
  cardTableHeader: {
    flex: 1,
    flexDirection: "row",
  },

  cardTableHeaderColumn: {
    width: "50%",
  },
  cardTableHeaderColumnText: {
    fontSize: 14,
    fontWidth: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  cardTableNameColumn: {
    width: "50%",
  },
  cardTableTotalColumn: {
    width: "15%",
    textAlign: "right",
  },
  cardTableRateColumn: {
    width: "35%",
    textAlign: "right",
  },
  cardTableColumnText: {},
  cardTableRow: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    ...StyleSheet.padding(4, 8, 4, 8),
    ...StyleSheet.margin(8, -8, 8, -8),
    borderRadius: 4,
  },
});
