import React from 'react';
import R14, { StyleSheet, ScreenLayout, Text, View} from '../core';
export default R14.connect(
  class ComingSoonScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    componentDidMount(){
     
    }
    render() {
      return (
        <ScreenLayout
          scrollEnabled
          style={styles.screenLayout}
        >
          <Text>{this.props.error || 'Oops. Something went wrong.'}</Text>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  screenLayout: {
   ...StyleSheet.margin(16)
 }
});