import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import VirtualWorkspaceDataTable from '../components/VirtualWorkspaceDataTable';
export default R14.connect(
  class VirtualWorkspacesScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "virtualWorkspace"
      );
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={this.perms.create ? {
            to: "virtualWorkspaceCreate",
            icon: "add"
          } : null}
          scrollEnabled
        >
          <VirtualWorkspaceDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
});