import R14 from "../core";
export default class DocumentTemplateAnnotationDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "documentTemplateAnnotation",
    });
    this.TYPE_FIELD = "FIELD";
    this.TYPE_FIELD_CHILD = "FIELD_CHILD";
    this.TYPE_FIELD_LABEL = "FIELD_LABEL";
    this.TYPE_ANCHOR = "ANCHOR";
    this.state = {};
  }
  async get(uid, fields) {
    if (!fields)
      fields = [("uid", "name", "height", "width", "x", "y", "description")];
    return await super.get(uid, fields);
  }
  async fetchEditFormData(
    uid = null,
    { documentTemplate = null, type = null }
  ) {
    let formVals = {
      documentTemplateUid: documentTemplate.uid,
    };
    let qry = "";
    let qryVals = {
      userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
    };
    if (uid) {
      qry = `
      query DocumentTemplateAnnotationCreateFormData($uid: ID!) {
        documentAnnotationTemplate(uid: $uid){
          uid
          key
          name
          description
        }
      }
    `;
      qryVals.uid = uid;
      let res = await this.api.qry(qry, qryVals);
      console.log("RES DATA ANNOTATION", res.data);
    } else {
      formVals.type = type;
      formVals.x = documentTemplate.annotationLayout.x;
      formVals.y = documentTemplate.annotationLayout.y;
      formVals.height = documentTemplate.annotationLayout.height;
      formVals.width = documentTemplate.annotationLayout.width;
    }

    let formData = {
      values: formVals,
    };
    return formData;
  }

  parseSubmitValues(values) {
    let updateValues = { ...values };
    console.log("CHECK UPDATE VALUES", updateValues);
    console.log("DO SOMEHTING ABOUT IMAGERS SO THAt you DON'T NEED TO PARSE ");
    updateValues.clientUid = this.dm.userSession.clientUid;
    return updateValues;
  }

  getTypeByKey(key) {
    let ret = null;
    switch (key) {
      case "field":
        ret = this.TYPE_FIELD;
        break;
      case "subfield":
        ret = this.TYPE_FIELD_CHILD;
        break;
      case "label":
        ret = this.TYPE_FIELD_LABEL;
        break;
      case "anchor":
        ret = this.TYPE_ANCHOR;
        break;
      default:
    }
    return ret;
  }
  getKeyByType(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_FIELD:
        ret = "field";
        break;
      case this.TYPE_FIELD_CHILD:
        ret = "subfield";
        break;
      case this.TYPE_FIELD_LABEL:
        ret = "label";
        break;
      case this.TYPE_ANCHOR:
        ret = "anchor";
        break;
      default:
    }
    return ret;
  }
  getColorByType(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_FIELD:
        ret = "#26a69a";
        break;
      case this.TYPE_FIELD_CHILD:
        ret = "#80cbc4";
        break;
      case this.TYPE_FIELD_LABEL:
        ret = "#26c6da";
        break;
      case this.TYPE_ANCHOR:
        ret = "#5c6bc0";
        break;
      default:
    }
    return ret;
  }
}
