import R14 from "../core";
export default class VirtualWorkspaceUserSessionDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "virtualWorkspaceUserSession",
    });
    this.ACTIVE_MINUTES = "5";
  }
  isActive(lastActiveAt) {
    let diffMinutes =
      (new Date() - lastActiveAt.getTime()) / 60000;
    return diffMinutes <= this.ACTIVE_MINUTES;
  }
}