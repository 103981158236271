import { Platform } from "../core/";

const getBaseUrl = () => {
  if (!window || !window.location) return "";
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
const getR14LoginUrl = () => {
  let ret = "https://login.inventr.ai";
  if (!window || !window.location) return ret;
  const hostname = window.location.hostname;
  if (!hostname) return ret;
  switch (hostname) {
    case "xbp.inventr.ai":
      ret = "https://login.xbp.inventr.ai";
      break;
    case "dev.inventr.ai":
    case "exeladev.inventr.ai":
      ret = "http://logindev.inventr.ai";
      break;
    default:
    // Do nothing
  }
  return ret;
};
let baseUrl = getBaseUrl();
// let devBaseUrl = "http://r14dev.com";
// let devBaseUrl = "http://3.15.132.137:3009";
//let devBaseUrl = "http://3.12.119.142:3009";
let devBaseUrl = baseUrl;

const Config = {
  name: "r14-dev-app",
  api: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
    // onAuthError: async (app) => await app.dm.userSession.handleAuthError(),
    onClientError: async (code, app) =>
      await app.dm.userSession.handleClientError(code),
  },
  io: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  storage: {
    url: {
      file:
        process.env.NODE_ENV === "development"
          ? `${devBaseUrl}/files`
          : `${baseUrl}/files`,
      image:
        process.env.NODE_ENV === "development"
          ? `${devBaseUrl}/images`
          : `${baseUrl}/images`,
    },
  },
  metadata: {
    // "NQUBE",
    // "DEV_STUDIO",
    // "LISTEN",
    // "CLOUD_OPS",
    // "IDP",
    // "VIRTUAL_WORKSPACES",
    // "AGENT",
    r14Login: {
      url: getR14LoginUrl(),
    },
    products: [
      {
        title: "nQube",
        description: "AI-driven platform for large-scale intelligent data processing.",
        key: "NQUBE",
        urlKey: "ai",
        icon: "cube",
      },
      {
        title: "Dev Studio",
        description: "Enterprise cloud-based development environment.",
        key: "DEV_STUDIO",
        urlKey: "dev",
        icon: "codeBraces",
      },
      {
        title: "Listen",
        description: "Platform for large-scale data processing and migration.",
        key: "LISTEN",
        urlKey: "listen",
        icon: "database",
      },
      {
        title: "Cloud Ops",
        description: "Centralized cloud platform management.",
        key: "CLOUD_OPS",
        urlKey: "cloud",
        icon: "cloud",
      },
      {
        title: "IDP",
        description: "Intelligent document processing with Teletype management and metrics.",
        key: "IDP",
        urlKey: "idp",
        icon: "documents",
      },
      {
        title: "Virtual Workspaces",
        description: "Managed OS for secure and efficient remote work.",
        key: "VIRTUAL_WORKSPACES",
        urlKey: "workspaces",
        icon: "desktopMac",
      },
      {
        title: "Agent",
        description: "AI-enhanced conversational and telephony solutions.",
        key: "AGENT",
        urlKey: "agent",
        icon: "headset",
      }
    ]
  },
};
export default Config;
