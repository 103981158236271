import React from "react";
import R14, { ScreenLayout } from "../core";
import BlockDataTable from "../components/BlockDataTable";
export default R14.connect(
  class BlocksScreen extends React.Component {
    render() {
      return (
        <ScreenLayout
          floatingActionButton={
            this.props.app.dm.user.checkPermissions(
              this.props.app.dm.project.getKeyByType(this.props.projectType),
              "block",
              "create"
            ) && {
              onPress: () =>
                this.props.app.nav.to("blockCreate", {
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.projectType
                  ),
                }),
              icon: "add",
            }
          }
          scrollEnabled
        >
          <BlockDataTable projectType={this.props.projectType} />
        </ScreenLayout>
      );
    }
  }
);
