import React from "react";
import R14, { StyleSheet, Touchable, View, Colors } from "../core";

export default class DocumentTemplateAnnotation extends React.Component {
  constructor(props) {
    super(props);
    this.handlePress = this.handlePress.bind(this);
  }
  get documentTemplate() {
    return this.props.documentTemplate;
  }
  get documentTemplateAnnotation() {
    return this.props.documentTemplateAnnotation;
  }
  handlePress() {
    this.props.onPress && this.props.onPress(this.documentTemplateAnnotation);
  }
  createDynamicStyles() {
    let imageHeight = this.documentTemplate.image.height;
    let imageWidth = this.documentTemplate.image.width;
    let dynamicStyles = {
      documentTemplateAnnotation: {
        top:
          this.documentTemplateAnnotation.y *
          this.documentTemplate.state.scale *
          imageHeight,
        left:
          this.documentTemplateAnnotation.x *
          this.documentTemplate.state.scale *
          imageWidth,
        height:
          this.documentTemplateAnnotation.height *
          this.documentTemplate.state.scale *
          imageHeight,
        width:
          this.documentTemplateAnnotation.width *
          this.documentTemplate.state.scale *
          imageWidth,
        backgroundColor: StyleSheet.color(
          this.documentTemplate.getAnnotationColorByType(
            this.documentTemplateAnnotation.type
          )
        ).rgba(0.25),
        borderColor: StyleSheet.color(
          this.documentTemplate.getAnnotationColorByType(
            this.documentTemplateAnnotation.type
          )
        ).rgba(0.5),
      },
    };
    return StyleSheet.create(dynamicStyles);
  }
  render() {
    let dynamicStyles = this.createDynamicStyles();
    return (
      <Touchable
        style={[
          styles.documentTemplateAnnotation,
          dynamicStyles.documentTemplateAnnotation,
        ]}
        tooltip={this.documentTemplateAnnotation.name}
        onPress={this.props.onPress || this.handlePress}
      >
        <View>{this.props.children}</View>
      </Touchable>
    );
  }
}

const styles = StyleSheet.create({
  documentTemplateAnnotation: {
    position: "absolute",
    backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.25),
    borderWidth: 2,
    borderStyle: "dotted",
  },
});
