import React from "react";
import R14, { Colors, StyleSheet, Text, Image, View } from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";
import TimeDurationLabel from "./TimeDurationLabel";

export default R14.connect(
  class VirtualWorkspaceUserSessionDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "virtualWorkspaceUserSession"
      );
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.projectUid)
        filter.projectUid = { eq: this.props.projectUid };
      let res = await this.props.app.dm.virtualWorkspaceUserSession.find(
        `
        uid
        startAt
        endAt
        lastActiveAt
        manualEntryUser {
          uid
          username
        }
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    renderColumns() {
      // let toShortDateTime = (date) => {
      //   let formattedDate = "N/A";
      //   if (date) {
      //     let d = new Date(date);
      //     formattedDate = `${this.props.app.utils.date.toShortDate(
      //       d
      //     )} ${this.props.app.utils.date.toTime(d)}`;
      //   }
      //   return formattedDate;
      // };
      let renderDateCell = (cell, indicator = false) => {
        if (!cell) return "";
        let date = new Date(cell);
        let active = indicator
          ? this.props.app.dm.virtualWorkspaceUserSession.isActive(date)
          : false;
        return (
          <View>
            <View>
              {indicator && (
                <StateIndicatorText
                  label={this.props.app.utils.date.toShortDate(date)}
                  color={active ? "green" : null}
                />
              )}
              {!indicator && (
                <Text style={[styles.cellText]}>
                  {this.props.app.utils.date.toShortDate(date)}
                </Text>
              )}
            </View>
            <View>
              <Text
                style={[
                  styles.cellText,
                  styles.cellTextSmall,
                  indicator && styles.indicatorTimeText,
                ]}
              >
                {this.props.app.utils.date.toTime(date)}
              </Text>
            </View>
          </View>
        );
      };
      let extraColumns = {};
      if (!this.props.widget) {
        extraColumns = {
          startAt: {
            label: "Started",
            sortable: true,
            width: 144,
            renderer: ({ cell }) => renderDateCell(cell),
          },
          endAt: {
            label: "Ended",
            width: 144,
            sortable: true,
            renderer: ({ cell }) => renderDateCell(cell),
          },
        };
      }
      return {
        manualEntryUser: {
          label: "Username",
          renderer: ({ cell }) => (
            <Text style={[styles.cellText]}>{cell.username}</Text>
          ),
        },
        duration: {
          label: "Duration",
          width: 144,
          renderer: ({ row }) => {
            if (!row.startAt || !row.lastActiveAt) return null;
            let startAt = new Date(row.startAt);
            let lastActiveAt = new Date(row.lastActiveAt);
            return <TimeDurationLabel startAt={startAt} endAt={lastActiveAt} />;
          },
        },
        ...extraColumns,
        lastActiveAt: {
          label: "Active",
          width: 144,
          sortable: true,
          renderer: ({ cell }) => renderDateCell(cell, true),
        },
      };
    }
    render() {
      console.log(this.props.app.dm.virtualWorkspaceUserSession);
      return (
        <EntityDataTable
          entityDomain={this.props.app.dm.virtualWorkspaceUserSession}
          searchable
          title='User Sessions'
          name='virtualWorkspaceUserSessions'
          columns={this.renderColumns()}
          rowControlsRight={false}
          onRowPress={false}
          // onRowPress={({ uid }) =>
          //   this.props.app.nav.to("virtualWorkspace", { uid })
          // }
          cellRenderer={({ cell, columnName, row, columns }) => (
            <Text style={[styles.cellText]}>{cell}</Text>
          )}
          pageLoader={this.pageLoader}
          initialSortColumnName='lastActiveAt'
          initialSortDirection='desc'
          perms={this.perms}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {},
  cellTextSmall: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  indicatorTimeText: {
    paddingLeft: 20,
  },
});
