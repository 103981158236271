import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";

import View from "./View";
import Text from "./Text";
import ScrollView from "./ScrollView";
import StyleSheet from "./StyleSheet";
import AnimatedView from "./AnimatedView";
import { Colors } from "./Theme";

export default forwardRef(function InfiniteList(
  {
    name,
    itemsPerPage,
    totalItems,
    // size,
    itemSize,
    dataLoader,
    itemRenderer,
    initialPage = 1,
    initialData = null,
    itemLoadingRenderer = null,
    maxCachedPages = 2,
    ItemLoadingComponent = null,
    pageLoadDelay = 500,
    animateLoading = true,
    initialItemIndex = null,
    horizontal = false,
    onScroll = null,
    onContentSizeChange = null,
    onRemoveItem = null,
    style = null,
  },
  ref
) {
  const [refreshToken, setRefreshToken] = useState(false);
  const [visiblePages, setVisiblePages] = useState(
    initialData && initialPage ? [initialPage] : []
  );
  const [loadedPages, setLoadedPages] = useState(
    initialData && initialPage ? [initialPage] : []
  );
  const pageMapRef = useRef(
    initialData && initialPage
      ? {
          [initialPage]: {
            loaded: true,
            data: initialData,
            at: new Date(),
            page: initialPage,
          },
        }
      : {}
  );
  // const totalItemsRef = useRef(totalItems || 0);
  const pageLoaderRef = useRef();
  const addPageDataRef = useRef();
  const lastScrollRef = useRef();
  const forceSetVisiblePagesRef = useRef(false);
  const sizeField = horizontal ? "width" : "height";
  const axis = horizontal ? "x" : "y";
  const scrollRef = useRef(null);
  // const forceRefresh = () => setRefreshToken(!refreshToken);
  const forceRefresh = () => setRefreshToken(prevRefreshToken => !prevRefreshToken);

  useEffect(() => {
    if (!scrollRef.current)
      throw new Error("Unable to initialize infinite list.");
    let scrollTo = { x: 0, y: 0, animated: false };
    if (initialPage && initialPage !== 1)
      scrollTo[axis] = (initialPage - 1) * itemsPerPage * itemSize;
    else if (initialItemIndex) scrollTo[axis] = initialItemIndex * itemSize;
    else scrollTo = null;
    scrollTo ? scrollRef.current.scrollTo(scrollTo) : triggerOnScroll();
  }, []);

  useEffect(() => {
    visiblePageLoader();
    return () => {
      clearPageLoaderRefTimeout();
      //clearCleanupPageMapRefTimeout();
    };
  }, [refreshToken, loadedPages, visiblePages]);

  useEffect(() => {
    addPageDataRef.current &&
      visiblePages.includes(addPageDataRef.current.page) &&
      loadedPages.includes(addPageDataRef.current.page) &&
      addPageDataRef.current.callback();
  }, [refreshToken, loadedPages, visiblePages]);

  // Share scroll methods with the component
  useImperativeHandle(
    ref,
    () => {
      return {
        forceRefresh() {
          forceRefresh();
        },
        scrollTo(params) {
          scrollRef.current.scrollTo(params);
        },
        scrollToIndex(index, options = {}) {
          const autoScroll = options.autoScroll || false;
          const autoAnimate = options.autoAnimate || false;
          const itemStart = index * itemSize;
          const itemEnd = itemStart + itemSize;
          const currentOffset = lastScrollRef.current
            ? lastScrollRef.current.contentOffset[axis]
            : 0;
          let params = {
            [axis]: itemStart > 64 ? itemStart - 64 : 0,
            animated: options.animated !== false,
          };
          if (autoAnimate) {
            const scrollDiff = Math.abs(params[axis] - currentOffset);
            if (scrollDiff > itemSize * 15) params.animated = false;
          }
          if (autoScroll && lastScrollRef.current) {
            const { contentOffset, layout } = lastScrollRef.current;
            scrollRef.current.measure((x, y, width, height) => {
              let layoutSize = horizontal ? width : height;
              if (itemStart < contentOffset[axis]) {
                params[axis] = itemStart - 64;
              } else if (itemEnd > contentOffset[axis] + layoutSize) {
                params[axis] = itemEnd - layoutSize + 64;
              } else params = null;
              params && scrollRef.current.scrollTo(params);
            });
          } else scrollRef.current.scrollTo(params);
        },
        scrollToEnd(params) {
          scrollRef.current.scrollToEnd(params);
        },
        triggerOnScroll,
        measureInWindow(callback) {
          scrollRef.current.measureInWindow(callback);
        },
        measure(callback) {
          scrollRef.current.measure(callback);
        },
        // reset(options = {}) {
        //   clearPageLoaderRefTimeout();
        //   //setLoadedPages([]);
        //   const page = options.page || initialPage;
        //   const data = options.data || initialData;
        //   if (options.totalItems) totalItems = options.totalItems;
        //   console.log("THIS IS SO HACKY REWRITE", page, data);
        //   pageMapRef.current =
        //     data && page
        //       ? {
        //           [page]: {
        //             loaded: true,
        //             data,
        //             at: new Date(),
        //             page,
        //           },
        //         }
        //       : {};
        //   setLoadedPages([page]);
        //   setVisiblePages([page]);
        //   scrollRef.current.scrollTo({
        //     [axis]: (page - 1) * itemsPerPage * itemSize,
        //     animated: false,
        //   });
        //   scrollRef.current.triggerOnScroll();
        //   forceRefresh();
        // },
        reload(options = {}) {
          const page = options.page || null;
          const data = options.data || null;
          clearPageLoaderRefTimeout();
          Object.keys(pageMapRef.current).forEach((key) => {
            onRemoveItem &&
              pageMapRef.current[key].data.forEach((item) =>
                onRemoveItem(item)
              );
            delete pageMapRef.current[key];
          });
          if (page && data) {
            pageMapRef.current[page] = {
              loaded: true,
              data,
              at: new Date(),
              page,
            };
          }
          setLoadedPages(page && data ? [page] : []);
          setVisiblePages(page ? [page] : []);
          forceSetVisiblePagesRef.current = true;
          if (options.scrollToPage || page) {
            scrollRef.current.scrollTo({
              [axis]:
                ((options.scrollToPage || page) - 1) * itemsPerPage * itemSize,
              animated: false,
            });
          } else triggerOnScroll();
        },
        addPageData(page, items = [], options = {}) {
          return new Promise((resolve, reject) => {
            options.reset &&
              Object.keys(pageMapRef.current)
                .filter((key) => parseInt(key) !== parseInt(page))
                .forEach((key) => {
                  onRemoveItem &&
                    pageMapRef.current[key].data.forEach((item) => {
                      onRemoveItem(item);
                    });
                  delete pageMapRef.current[key];
                });
            pageMapRef.current[page] = {
              loaded: true,
              data: Array.isArray(items) ? items : [],
              at: new Date(),
              page,
            };

            const callbackTimeout = setTimeout(() => {
              addPageDataRef.current = null;
              reject("addPageData has timed out");
            }, 10000);
            addPageDataRef.current = {
              page,
              callback: () => {
                callbackTimeout && clearTimeout(callbackTimeout);
                addPageDataRef.current = null;
                resolve();
              },
            };

            // if (options.reset) {
            //   console.log("addPageData loaded reset", loadedPages, [page]);
            // } else if (!loadedPages.includes(page))
            //   console.log("addPageData loaded", loadedPages, [
            //     ...loadedPages,
            //     page,
            //   ]);
            // else console.log("loaded pages not changed", loadedPages);
            if (options.reset) {
              setLoadedPages([page]);
            } else if (!loadedPages.includes(page))
              setLoadedPages([...loadedPages, page]);

            scrollRef.current.scrollTo({
              [axis]: (page - 1) * itemsPerPage * itemSize,
              animated: false,
            });
            // scrollRef.current.triggerOnScroll();
            //console.log("Force refresh?");
            forceRefresh();
          });
        },
      };
    },
    []
  );

  // calculateHeight() {
  //   let height = this.props.height || null;
  //   if (
  //     this.props.maxHeight &&
  //     this.props.direction === "vertical" &&
  //     this.scroller.state.layout &&
  //     this.scroller.state.layout.height
  //   ) {
  //     let newHeight = null;
  //     let maxHeight = this.props.maxHeight;
  //     if (
  //       !this._height ||
  //       this.scroller.state.layout.height !== this._height
  //     ) {
  //       newHeight =
  //         this.scroller.state.layout.height > maxHeight
  //           ? maxHeight
  //           : this.scroller.state.layout.height;
  //       if (newHeight !== this._height) height = newHeight;
  //     }
  //   }
  //   return height;
  // }
  const triggerOnScroll = () => {
    scrollRef.current.triggerOnScroll();
  };
  const handleScroll = (e) => {
    const { nativeEvent } = e;
    const { contentOffset, layout } = nativeEvent;

    const pageSize = itemSize * itemsPerPage;
    const visiblePageStart = Math.ceil(contentOffset[axis] / pageSize) || 1;
    const visiblePageEnd =
      Math.ceil((contentOffset[axis] + layout[sizeField]) / pageSize) || 1;
    let newVisiblePages = [];

    for (let i = visiblePageStart; i <= visiblePageEnd; i++) {
      newVisiblePages.push(i);
    }
    // If there is only 1 visible page, and it is already loaded, don't update
    // forceSetVisiblePagesRef is kind of a hacky fix.
    (forceSetVisiblePagesRef.current ||
      !compareArrays(visiblePages, newVisiblePages)) &&
      setVisiblePages(newVisiblePages);

    forceSetVisiblePagesRef.current = false;

    // Update the last scroll Ref
    lastScrollRef.current = { contentOffset, layout };
    onScroll && onScroll(e);
  };

  const visiblePageLoader = () => {
    clearPageLoaderRefTimeout();
    let loaded = visiblePages.every(
      (page) => pageMapRef.current[page] && pageMapRef.current[page].loaded
    );
    if (loaded) {
      // Check if it addPageData is scrolling
      if (
        addPageDataRef.current &&
        !visiblePages.includes(addPageDataRef.current.page)
      ) {
        // Do nothing, wait for scroll
        return false;
      } else if (!compareArrays(visiblePages, loadedPages)) {
        setLoadedPages(visiblePages);
        cleanupPageMap();
      }
    } else
      pageLoaderRef.current = {
        visiblePages,
        timeout: setTimeout(() => {
          // Figure out pages to load
          let promises = visiblePages
            .filter((page) => !pageMapRef.current[page])
            .map((page) => {
              pageMapRef.current[page] = {
                loaded: false,
                data: [],
                at: new Date(),
                page,
              };
              return new Promise((resolve, reject) => {
                dataLoader(page)
                  .then((loadedData) => {
                    if (!Array.isArray(loadedData))
                      console.error(
                        `InfiniteList ignoring loaded data of type ${typeof loadedData}.`
                      );
                    pageMapRef.current[page] = {
                      loaded: true,
                      data: Array.isArray(loadedData) ? loadedData : [],
                      at: new Date(),
                      page,
                    };
                    resolve(true);
                  })
                  .catch((err) => reject(err));
              });
            });

          promises.length &&
            Promise.all(promises).then(() => {
              const newLoadedPages = visiblePages.filter(
                (page) =>
                  pageMapRef.current[page] && pageMapRef.current[page].loaded
              );
              !compareArrays(loadedPages, newLoadedPages) &&
                setLoadedPages(newLoadedPages);
              cleanupPageMap();
            });
        }, pageLoadDelay),
      };
  };

  const cleanupPageMap = () => {
    // Timeout before cleanup incase they scroll back to a page that exists
    // cleanupPageMapTimeoutRef.current = setTimeout(() => {

    if (Object.keys(pageMapRef.current).length <= maxCachedPages) return;

    // Remove cached pageMaps
    Object.keys(pageMapRef.current)
      .map((key) => pageMapRef.current[key])
      .filter((p) => !visiblePages.includes(p.page))
      .sort((a, b) => a.at - b.at)
      .forEach((p) => {
        if (Object.keys(pageMapRef.current).length > maxCachedPages) {
          //console.log("REMOVING CACHED PAGE MAP", p.page);
          onRemoveItem &&
            pageMapRef.current[p.page].data.forEach((item) =>
              onRemoveItem(item)
            );
          delete pageMapRef.current[p.page];
        }
      });
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const dynamicStyles = useMemo(() => {
    let startPage = visiblePages.length ? visiblePages[0] : 1;
    let endPage = visiblePages.length
      ? visiblePages[visiblePages.length - 1]
      : 1;
    // console.log("chedck vals", {
    //   placeHolderBefore: {
    //     [sizeField]:
    //       endPage > 1 ? itemSize * itemsPerPage * (startPage - 1) : 0,
    //   },
    //   placeHolderAfter: {
    //     [sizeField]:
    //       page < totalPages
    //         ? itemSize * itemsPerPage * (totalPages - endPage)
    //         : 0,
    //   },
    //   page: {
    //     [sizeField]: itemSize * itemsPerPage,
    //   },
    //   item: {
    //     [sizeField]: itemSize,
    //     flex: 0,
    //   },
    // });
    // console.log("CHECK SIZE: ", {
    //   startPage,
    //   endPage,
    //   totalItems: totalItems,
    //   totalPages,
    //   size: totalItems * itemSize,
    //   after:
    //     totalItems * itemSize - endPage * itemsPerPage * itemSize,
    // });
    return StyleSheet.create({
      placeHolderBefore: {
        flexShrink: 0,
        flexGrow: 0,
        [sizeField]:
          endPage > 1 ? itemSize * itemsPerPage * (startPage - 1) : 0,
      },
      placeHolderAfter: {
        flexShrink: 0,
        flexGrow: 0,
        [sizeField]:
          endPage < totalPages
            ? totalItems * itemSize - endPage * itemsPerPage * itemSize
            : 0,
      },
      page: {
        [sizeField]: totalItems * itemSize,
      },
      endPage: {
        [sizeField]: totalItems * itemSize,
      },
      item: {
        [sizeField]: itemSize,
        flex: 0,
      },
      scrollViewContent: {
        [sizeField]: totalItems * itemSize,
        flex: 1,
        flexShrink: 0,
        flexDirection: horizontal ? "row" : "column",
        // flexGrow: 0,
        justifyContent: "flex-start",
      },
      visiblePages: {
        // [sizeField]: totalPages * itemsPerPage * itemSize,
        flex: 0,
        flexGrow: 0,
        flexDirection: horizontal ? "row" : "column",
        justifyContent: "flex-start",
      },
      itemLoading: {
        flex: 1,
        [sizeField]: itemSize - 8,
        ...StyleSheet.margin(4),
        backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.02),
        borderRadius: 4,
      },
    });
  }, [visiblePages]);

  const keyExtractor = (item, index) => item.label;
  const compareArrays = (a1, a2) =>
    a1.length === a2.length && a1.every((elmt, idx) => elmt === a2[idx]);

  const clearPageLoaderRefTimeout = () => {
    pageLoaderRef.current &&
      pageLoaderRef.current.timeout &&
      clearTimeout(pageLoaderRef.current.timeout);
  };

  const renderPages = () => {
    let ret = [];
    for (let page of visiblePages) {
      if (loadedPages.includes(page)) {
        let loadedItems = [];
        pageMapRef.current[page] &&
          loadedItems.push(
            ...pageMapRef.current[page].data.map((item, idx) => (
              <View
                // key={keyExtractor(item, page * itemsPerPage + idx)}
                key={`_p${page}-i${idx}`}
                style={dynamicStyles.item}
              >
                {itemRenderer(item, (page - 1) * itemsPerPage + idx)}
              </View>
            ))
          );
        ret.push(...loadedItems);
      } else {
        let loadingItems = [];
        for (let i = 0; i < itemsPerPage; i++) {
          const itemNum = (page - 1) * itemsPerPage + i;
          if (itemNum >= totalItems) break;
          loadingItems.push(
            itemLoadingRenderer ? (
              itemLoadingRenderer(itemNum, page)
            ) : (
              <View key={`_pl_p${page}-i${i}`} style={dynamicStyles.item}>
                {ItemLoadingComponent || (
                  <View style={dynamicStyles.itemLoading} />
                )}
              </View>
            )
          );
        }
        if (animateLoading) {
          ret.push(
            <AnimatedView
              key={`_pl_p${page}-loading`}
              animation={{
                from: {
                  opacity: 0.8,
                },
                to: {
                  opacity: 0.4,
                },
              }}
              // in={true}
              style={dynamicStyles.visiblePages}
              // enter={{
              //   opacity: 1,
              // }}
              // exit={{
              //   opacity: 0.2,
              // }}
              iterationCount='infinite'
              duration={2000}
            >
              {loadingItems}
            </AnimatedView>
          );
        } else ret.push(...loadingItems);
      }
    }
    return ret;
  };
  const renderedPages = renderPages();
  return (
    <ScrollView
      // {...{ [sizeField]: size }}
      name={name}
      horizontal={horizontal}
      ref={scrollRef}
      contentStyle={dynamicStyles.scrollViewContent}
      onContentSizeChange={onContentSizeChange}
      onScroll={handleScroll}
      style={style}
    >
      <View style={dynamicStyles.placeHolderBefore} />
      <View style={dynamicStyles.visiblePages}>{renderedPages}</View>
      <View style={dynamicStyles.placeHolderAfter} />
    </ScrollView>
  );
});
