import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  View,
  Surface,
  SectionHeader,
  Text,
  Button,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Colors,
} from "../core";
import Dashboard from "../components/Dashboard";
import DashboardWidget from "../components/DashboardWidget";
import DashboardCard from "../components/DashboardCard";
import DashboardCardSection from "../components/DashboardCardSection";
import DashboardCardSectionRow from "../components/DashboardCardSectionRow";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import StateIndicatorText from "../components/StateIndicatorText";
import VirtualWorkspaceUserSessionDataTable from "../components/VirtualWorkspaceUserSessionDataTable";

export default R14.connect(
  class ProjectVirtualWorkspaceScreen extends React.Component {
    constructor(props) {
      super(props);
      // this.handleRefreshPress = this.handleRefreshPress.bind(this);
      // this.userDomain = this.props.app.dm.user;
      this.state = {
        metrics: this.props.virtualWorkspace.metrics,
        lastSyncAt: this.props.virtualWorkspace.lastSyncAt,
      };
      this.projectKey = this.props.app.dm.project.getKeyByType(
        this.props.project.type
      );
      this.perms = {
        virtualWorkspaceUserSession: this.props.app.dm.user.getPermissions(
          this.projectKey,
          "virtualWorkspaceUserSession"
        ),
        virtualWorkspace: this.props.app.dm.user.getPermissions(
          this.projectKey,
          "virtualWorkspace"
        ),
      };
      console.log(
        "perms?",
        this.perms.virtualWorkspaceUserSession.read,
        this.perms.virtualWorkspace.read
      );
    }
    async handleRefreshPress() {
      this.props.app.ui.progressIndicator.show();
      // Auto-batch the fetch by resolving asynchronously
      let promiseRes = await Promise.all([
        this.props.app.dm.virtualWorkspace.initDashboard(
          this.props.virtualWorkspace.uid
        ),
        this.props.app.ui.dataTable("manualEntryDocumentMetrics").refresh(),
      ]);
      let virtualWorkspace = promiseRes.length ? promiseRes[0] : null;
      this.props.app.ui.snackBar.show({
        message: virtualWorkspace
          ? "Refreshed metrics."
          : "Unable to refresh metrics.",
        variant: virtualWorkspace ? "success" : "error",
      });
      virtualWorkspace &&
        this.setState({
          metrics: virtualWorkspace.metrics,
          lastSyncAt: virtualWorkspace.lastSyncAt,
        });
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    get virtualWorkspaceDomain() {
      return this.props.app.dm.virtualWorkspace;
    }
    getMetric(parentType, type) {
      if (!this.state.metrics) return 0;
      return (
        this.state.metrics
          .filter(
            (metric) => metric.parentType === parentType && metric.type === type
          )
          .map((metric) => metric.value || 0)
          .at(0) || 0
      );
    }
    get dashboardSectionRows() {
      return [
        {
          title: "User Sessions",
          metricParentType:
            this.virtualWorkspaceDomain.METRIC_PARENT_TYPE_USER_SESSION,
          sections: [
            {
              title: "Active",
              metricType: this.virtualWorkspaceDomain.METRIC_TYPE_TOTAL,
            },
          ],
        },
      ];
    }
    renderLastMetricsSyncAt() {
      let formattedDate = "N/A";
      if (this.state.lastSyncAt) {
        let lastSyncAt = new Date(this.state.lastSyncAt);
        formattedDate = `${this.props.app.utils.date.toShortDate(
          lastSyncAt
        )} ${this.props.app.utils.date.toTime(lastSyncAt)}`;
      }
      return formattedDate;
    }
    render() {
      return (
        <ProjectScreenLayout
          headerTitle={this.props.virtualWorkspace.name}
          scrollEnabled
          project={this.props.project}
          headerControlsRight={[
            <View key='lastSyncAt' style={styles.lastSyncAt}>
              <Text style={styles.lastSyncAtText}>
                Last Sync: {this.renderLastMetricsSyncAt()}
              </Text>
            </View>,
            <IconButton
              icon='refresh'
              key='refresh'
              onPress={this.handleRefreshPress}
            />,
          ]}
          breadCrumbs={[
            {
              label: this.props.virtualWorkspace.name,
              to: {
                route: "project",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                },
              },
            },
          ]}
        >
          <Dashboard name='virtualWorkspace' minWidth={352}>
            <DashboardCard title='Virtual Workspace'>
              <DashboardCardSection
                key='name'
                title='Name'
                style={styles.dashboardCardSection}
              >
                <Text>{this.props.virtualWorkspace.name}</Text>
              </DashboardCardSection>
              <DashboardCardSection
                key='status'
                title='Status'
                style={styles.dashboardCardSection}
              >
                <StateIndicatorText
                  label={this.props.app.dm.virtualWorkspace.getStateLabel(
                    this.props.virtualWorkspace.state
                  )}
                  color={this.props.app.dm.virtualWorkspace.getStateIndicatorColor(
                    this.props.virtualWorkspace.state
                  )}
                />
              </DashboardCardSection>
              {/* <DashboardCardSection
                key='status'
                title='Status'
                style={styles.dashboardCardSection}
              >
                <Text>Test</Text>
              </DashboardCardSection> */}

              <DashboardCardSectionRow key='capacity' title='Capacity'>
                <DashboardCardSection
                  key='minCapacity'
                  title='Minimum'
                  style={styles.dashboardCardSection}
                >
                  <Text>{this.props.virtualWorkspace.minCapacity}</Text>
                </DashboardCardSection>
                <DashboardCardSection
                  key='maxCapacity'
                  title='Maximum'
                  style={styles.dashboardCardSection}
                >
                  <Text>{this.props.virtualWorkspace.maxCapacity}</Text>
                </DashboardCardSection>
                <DashboardCardSection
                  key='desiredCapacity'
                  title='Desired'
                  style={styles.dashboardCardSection}
                >
                  <Text>{this.props.virtualWorkspace.desiredCapacity}</Text>
                </DashboardCardSection>
              </DashboardCardSectionRow>
              <DashboardCardSectionRow key='timeout' title='Timeout'>
                <DashboardCardSection
                  key='idle Disconnect'
                  title='Idle'
                  style={styles.dashboardCardSection}
                >
                  <Text>
                    {this.props.virtualWorkspace.idleDisconnectTimeout}
                  </Text>
                </DashboardCardSection>
                <DashboardCardSection
                  key='disconnect'
                  title='Disconnect'
                  style={styles.dashboardCardSection}
                >
                  <Text>{this.props.virtualWorkspace.disconnectTimeout}</Text>
                </DashboardCardSection>
              </DashboardCardSectionRow>
              {this.dashboardSectionRows.map((sectionRow) => (
                <DashboardCardSectionRow
                  key={sectionRow.metricParentType}
                  title={sectionRow.title}
                >
                  {sectionRow.sections.map((section) => (
                    <DashboardCardSection
                      key={section.metricType}
                      title={section.title}
                      style={styles.dashboardCardSection}
                    >
                      <Text style={styles.dashboardCardSectionText}>
                        {this.getMetric(
                          sectionRow.metricParentType,
                          section.metricType
                        )}
                        {/* {this.state.metrics.documents.totalAvailable} */}
                      </Text>
                    </DashboardCardSection>
                  ))}
                </DashboardCardSectionRow>
              ))}
            </DashboardCard>

            {this.perms.virtualWorkspaceUserSession.read && (
              <DashboardWidget>
                <VirtualWorkspaceUserSessionDataTable
                  title='User Sessions'
                  name='virtualWorkspaceUserSessions'
                  virtualWorkspace={this.props.virtualWorkspace}
                  widget
                />
              </DashboardWidget>
            )}
          </Dashboard>
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  dashboardCardSection: {
    // width: "33.3333%",
    minWidth: "33.3333%",
  },
  dashboardCardSectionText: {
    fontSize: 20,
    fontWeight: 500,
  },
  lastSyncAt: {
    paddingTop: 4,
  },
  lastSyncAtText: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
});
