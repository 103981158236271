import React from "react";
import R14, {
  StyleSheet,
  ControlsView,
  Card,
  Colors,
  ScreenLayout,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Button,
  Surface,
  SectionHeader,
  AnimatedView,
  View,
  ScrollView,
  Text,
  Link,
  FadeView,
  ExternalLink,
} from "../core";
import StateIndicatorText from "./StateIndicatorText";
import LabelView from "./LabelView";
import ResourceControls from "./ResourceControls";

export default R14.connect(
  class AppModuleWidget extends React.Component {
    constructor(props) {
      super(props);

      this.appModuleDomain = this.props.app.dm.appModule;
      this.resourceDomain = this.props.app.dm.resource;
      this.projectDomain = this.props.app.dm.project;
      this.appModuleDetails = null;

      this.state = {
        state: this.props.appModule.state,
        serverState: this.props.appModule.serverState,
        resourceState: this.props.appModule.resource.state,
        showDetails: false,
        //hasDetailsLoaded: false,
      };

      this.hasLoadedDetails = false;
      this.handleToggleDetailsPress = this.handleToggleDetailsPress.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.projectKey,
        "appModule"
      );
      this.appModuleSubscription = null;
      this.resourceSubscription = null;

      if (!this.perms.read) throw new Error("Permissions Error.");

      this.init();
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.props.type);
    }
    async init() {
      this.appModuleSubscription = await this.props.app.dm.appModule.onUpdate(
        this.props.appModule.uid,
        (res) => {
          if (res)
            this.setState({
              state: res.state,
              serverState: res.serverState,
            });
        }
      );
      this.resourceSubscription = await this.props.app.dm.resource.onUpdate(
        this.props.appModule.resource.uid,
        (res) => {
          if (res)
            this.setState({
              resourceState: res.state,
            });
        }
      );
    }
    async handleUpdateCorePress() {
      this.props.app.ui.progressIndicator.show();
      await this.appModuleDomain.updateCore(this.props.appModule.uid);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    componentWillUnmount() {
      this.appModuleSubscription && this.appModuleSubscription.unsubscribe();
      this.resourceSubscription && this.resourceSubscription.unsubscribe();
    }
    // get projectTypePrefix() {
    //   return this.props.project.type.toLowerCase();
    // }
    get projectKey() {
      return this.projectDomain.getKeyByType(this.props.project.type);
    }
    get projectUid() {
      return this.props.project.uid;
    }
    get serverState() {
      return this.state.serverState;
    }
    get idePath() {
      return this.props.app.nav.getPath({
        route: "projectAppModuleIde",
        params: {
          uid: this.projectUid,
          key: this.projectKey,
          appModuleUid: this.props.appModule.uid,
        },
      });
    }
    async runServerCommand(uid, type, command) {
      this.props.app.ui.progressIndicator.show();
      let serverState = await this.appModuleDomain.runServerCommand(
        uid,
        type,
        command
      );
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      if (serverState && serverState.length)
        this.setState({
          serverState: serverState,
        });
    }
    async handleShowServerLogPress(serverType) {
      this.props.app.nav.to(`projectAppModuleServerLog`, {
        uid: this.projectUid,
        key: this.projectKey,
        appModuleUid: this.props.appModule.uid,
        serverType: serverType.toLowerCase(),
      });
    }
    async handleShowServerMonitorPress(serverType) {
      this.props.app.nav.to(`projectAppModuleMonitor`, {
        uid: this.projectUid,
        key: this.projectKey,
        appModuleUid: this.props.appModule.uid,
        serverType: serverType.toLowerCase(),
      });
    }
    async handleShowLogPress() {
      this.props.app.nav.to(`projectAppModuleLog`, {
        uid: this.projectUid,
        key: this.projectKey,
        appModuleUid: this.props.appModule.uid,
      });
    }
    async handleToggleDetailsPress() {
      this.setState({
        showDetails: !this.state.showDetails,
      });
    }
    addDetailsIdeInfoSections(infoSections, appModule, server) {
      if (
        server &&
        server.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
        server.port &&
        server.publicIpAddress
      ) {
        infoSections.push(
          <LabelView style={styles.labelView} key='ide' label='IDE'>
            <ExternalLink url={this.idePath} style={styles.externalLink}>
              <Text>Open IDE</Text>
            </ExternalLink>
          </LabelView>
        );
        // infoSections.push(
        //   <LabelView style={styles.labelView} key='ide' label='IDE'>
        //     <ExternalLink
        //       url={` http://${server.publicIpAddress}:${server.port}`}
        //       style={styles.externalLink}
        //     />
        //   </LabelView>
        // );
        // infoSections.push(
        //   <LabelView style={styles.labelView} key='ideBeta' label='IDE'>
        //     <ExternalLink
        //       url={this.appModuleDomain.createUrl(
        //         appModule.uid,
        //         this.appModuleDomain.SERVER_TYPE_IDE
        //       )}
        //       style={styles.externalLink}
        //     />
        //   </LabelView>
        // );
      }
      return infoSections;
    }
    renderDetails() {
      let appModule = this.props.appModule;
      let infoSections = [
        <LabelView style={styles.labelView} key='resource' label='Resource'>
          <Link
            to={{
              route: "resource",
              params: { uid: appModule.resource.uid },
            }}
          >
            <Text>{appModule.resource.name}</Text>
          </Link>
        </LabelView>,
      ];

      if (
        this.state.state !== this.appModuleDomain.STATE_READY ||
        (this.state.resourceState !== "running" &&
          this.state.resourceState !== "active")
      ) {
        return <View style={styles.details}>{infoSections}</View>;
      }

      // if (!this.state.hasDetailsLoaded && !this.state.showDetails) return null;

      // if (!this.state.hasDetailsLoaded) {
      //   return (
      //     <ActivityIndicator
      //       size='large'
      //       containerStyle={styles.activityIndicator}
      //     />
      //   );
      // }

      // let ports = {};
      // appModule.appModulePorts.nodes.forEach(
      //   (node) => (ports[node.type] = node.port)
      // );

      let serverStateMap = {};
      this.state.serverState.forEach(
        (serverState) => (serverStateMap[serverState.type] = serverState)
      );

      switch (appModule.type) {
        case this.appModuleDomain.TYPE_REACT_APP:
          let serverWeb = serverStateMap[this.appModuleDomain.SERVER_TYPE_WEB];
          if (
            serverWeb &&
            serverWeb.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
            serverWeb.port &&
            serverWeb.publicIpAddress
          ) {
            infoSections.push(
              <LabelView style={styles.labelView} key='web' label='Web'>
                <ExternalLink
                  url={` http://${serverWeb.publicIpAddress}:${serverWeb.port}`}
                  style={styles.externalLink}
                />
              </LabelView>
            );
            infoSections.push(
              <LabelView
                style={styles.labelView}
                key='webBeta'
                label='Web (Beta)'
              >
                <ExternalLink
                  url={this.appModuleDomain.createUrl(
                    appModule.uid,
                    this.appModuleDomain.SERVER_TYPE_WEB
                  )}
                  style={styles.externalLink}
                />
              </LabelView>
            );
          }

          let serverNative =
            serverStateMap[this.appModuleDomain.SERVER_TYPE_NATIVE];
          if (
            serverNative &&
            serverNative.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
            serverNative.port &&
            serverNative.publicIpAddress
          ) {
            infoSections.push(
              <LabelView style={styles.labelView} key='native' label='Native'>
                <ExternalLink
                  url={` http://${serverNative.publicIpAddress}:${serverNative.port}`}
                  style={styles.externalLink}
                />
              </LabelView>
            );
          }

          // if (
          //   serverIde &&
          //   serverIde.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
          //   serverIde.port &&
          //   serverIde.publicIpAddress
          // ) {
          //   infoSections.push(
          //     <LabelView style={styles.labelView} key='ide' label='IDE'>
          //       <ExternalLink
          //         url={` http://${serverIde.publicIpAddress}:${serverIde.port}`}
          //         style={styles.externalLink}
          //       />
          //     </LabelView>
          //   );
          //   infoSections.push(
          //     <LabelView
          //       style={styles.labelView}
          //       key='ideBeta'
          //       label='IDE (Beta)'
          //     >
          //       <ExternalLink
          //         url={this.appModuleDomain.createUrl(
          //           appModule.uid,
          //           this.appModuleDomain.SERVER_TYPE_IDE
          //         )}
          //         style={styles.externalLink}
          //       />
          //     </LabelView>
          //   );
          // }

          break;

        case this.appModuleDomain.TYPE_SERVER:
          let serverServer =
            serverStateMap[this.appModuleDomain.SERVER_TYPE_SERVER];
          if (
            serverServer &&
            serverServer.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
            serverServer.port &&
            serverServer.publicIpAddress
          ) {
            let serverUrl = `http://${serverServer.publicIpAddress}:${serverServer.port}`;

            infoSections.push(
              <LabelView
                style={styles.labelView}
                key='graphiQl'
                label='GraphiQL'
              >
                <ExternalLink
                  url={` ${serverUrl}/graphql`}
                  style={styles.externalLink}
                />
              </LabelView>
            );
            infoSections.push(
              <LabelView style={styles.labelView} key='server' label='Server'>
                <Text>{serverUrl}</Text>
              </LabelView>
            );
            infoSections.push(
              <LabelView
                style={styles.labelView}
                key='serverBeta'
                label='Server (Beta)'
              >
                <Text>
                  {this.appModuleDomain.createUrl(
                    appModule.uid,
                    this.appModuleDomain.SERVER_TYPE_SERVER
                  )}
                </Text>
              </LabelView>
            );
          }
          break;
        case this.appModuleDomain.TYPE_PYTHON_APP:
          break;
        case this.appModuleDomain.TYPE_REDIS_SERVER:
          let redisServer =
            serverStateMap[this.appModuleDomain.SERVER_TYPE_SERVER];
          if (
            redisServer &&
            redisServer.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
            redisServer.port &&
            redisServer.publicIpAddress
          ) {
            let redisRoute = this.appModuleDomain.getRoute(
              appModule,
              this.appModuleDomain.SERVER_TYPE_SERVER
            );
            infoSections.push(
              <LabelView style={styles.labelView} key='server' label='Redis'>
                <Text>{`${redisServer.publicIpAddress}:${redisServer.port}`}</Text>
              </LabelView>
            );
            infoSections.push(
              <LabelView
                style={styles.labelView}
                key='redisBeta'
                label='Redis (Beta)'
              >
                <Text>{`${redisRoute.host}:${redisRoute.port}`}</Text>
              </LabelView>
            );
          }

          break;
      }

      let serverIde = serverStateMap[this.appModuleDomain.SERVER_TYPE_IDE];

      if (serverIde)
        this.addDetailsIdeInfoSections(infoSections, appModule, serverIde);

      switch (appModule.resource.type) {
        // let projectKey = this.projectDomain.getKeyByType(project.type)
        case this.resourceDomain.TYPE_ECS_CLUSTER:
          if (
            ![this.appModuleDomain.TYPE_REDIS_SERVER].includes(appModule.type)
          )
            infoSections.push(
              <LabelView style={styles.labelView} key='sftp' label='SFTP'>
                <Text>
                  sftp://sftp.{this.appModuleDomain.getHostname()}:2222
                </Text>
              </LabelView>
            );
          break;
        default:
          infoSections.push(
            <LabelView style={styles.labelView} key='sftp' label='SFTP Folder'>
              <Text>
                sftp://sftpuser@{appModule.resource.publicIpAddress}/
                {appModule.key}
              </Text>
            </LabelView>
          );
      }

      // return null;
      return (
        <View>
          <FadeView style={styles.details} visible={this.state.showDetails}>
            {infoSections}
          </FadeView>
          {/* <Button
            title={this.state.showDetails ? "Hide Details" : "Show Details"}
            style={styles.detailsButton}
            variant='outlined'
            onPress={this.handleToggleDetailsPress}
          /> */}
          <View style={styles.detailsButtonView}>
            <AnimatedView
              in={this.state.showDetails}
              delay={100}
              enter={{
                transform: [{ rotate: "180deg" }],
              }}
              exit={{
                transform: [{ rotate: "0deg" }],
              }}
              style={styles.detailsButtonAnimatedView}
            >
              <IconButton
                icon='keyboardArrowDown'
                // title={this.state.showDetails ? "Hide Details" : "Show Details"}
                style={styles.detailsButton}
                variant='circle'
                onPress={this.handleToggleDetailsPress}
              />
            </AnimatedView>
          </View>
        </View>
      );
      // return (
      //   // <AnimatedView
      //   //   in={this.state.showDetails}
      //   //   enter={{
      //   //     transform: [{ translateX: 0 }, { translateY: 0 }, { scale: 1 }],
      //   //     opacity: 1
      //   //   }}
      //   //   exit={{
      //   //     transform: [{ translateX: 0 }, { translateY: -35 }, { scale: 0.8 }],
      //   //     opacity: 0
      //   //   }}
      //   //   duration={200}
      //   //   onEnter
      //   //   unmountOnExit
      //   // >
      //   //  <View style={styles.details}>{infoSections}</View>
      //   // </AnimatedView>
      // );
    }
    renderStatusRows() {
      let appModule = this.props.appModule;
      let statusRows = [];
      let stateControlsRight = [
        <IconButton
          icon='console'
          key='log'
          tooltip={`View Log`}
          size='small'
          onPress={() => this.handleShowLogPress()}
        />,
      ];

      let rows = [];
      if (
        appModule.resource.type !== this.resourceDomain.TYPE_ECS_CLUSTER ||
        this.state.state !== this.appModuleDomain.STATE_READY
      ) {
        rows.push(
          <AppModuleWidgetRow
            name='State'
            key='state'
            stateIndicator={{
              label: this.appModuleDomain.getStateLabel(
                this.state.state,
                this.state.resourceState
              ),
              color: this.appModuleDomain.getStateIndicatorColor(
                this.state.state,
                this.state.resourceState
              ),
            }}
            controlsRight={stateControlsRight}
          />
        );
      }
      if (appModule.resource.type !== this.resourceDomain.TYPE_ECS_CLUSTER) {
        rows.push(
          <AppModuleWidgetRow
            name='Resource'
            key='resource'
            stateIndicator={{
              label: this.resourceDomain.getStateLabel(
                this.state.resourceState
              ),
              color: this.resourceDomain.getStateIndicatorColor(
                this.state.resourceState
              ),
            }}
            controlsRight={[
              <ResourceControls
                key='resourceControls'
                resourceType={appModule.resource.type}
                resourceUid={appModule.resource.uid}
                resourceState={this.state.resourceState}
              />,
            ]}
          />
        );
      }
      if (!rows.length) return null;
      return <View style={styles.serverDetails}>{rows}</View>;
    }

    renderServerRows() {
      let appModule = this.props.appModule;
      let content = null;
      let servers = [];
      let serverState = {};

      this.state.serverState.forEach(
        (node) => (serverState[node.type] = node.state)
      );
      let serverStateMap = {};
      this.state.serverState.forEach(
        (serverState) => (serverStateMap[serverState.type] = serverState)
      );

      let ideServer = {
        name: "IDE",
        type: this.appModuleDomain.SERVER_TYPE_IDE,
        state: serverState[this.appModuleDomain.SERVER_TYPE_IDE] || null,
      };

      switch (appModule.type) {
        case this.appModuleDomain.TYPE_REACT_APP:
          servers = [
            {
              name: "Web",
              type: this.appModuleDomain.SERVER_TYPE_WEB,
              state: serverState[this.appModuleDomain.SERVER_TYPE_WEB] || null,
            },
            {
              name: "Native",
              type: this.appModuleDomain.SERVER_TYPE_NATIVE,
              state:
                serverState[this.appModuleDomain.SERVER_TYPE_NATIVE] || null,
            },
          ];
          if (
            appModule.resource.type === this.resourceDomain.TYPE_ECS_CLUSTER
          ) {
            servers.push(ideServer);
          } else {
            servers.push({
              name: "Watch",
              type: this.appModuleDomain.SERVER_TYPE_WATCH,
              state:
                serverState[this.appModuleDomain.SERVER_TYPE_WATCH] || null,
            });
          }

          break;
        case this.appModuleDomain.TYPE_SERVER:
          servers = [
            {
              name: "Server",
              type: this.appModuleDomain.SERVER_TYPE_SERVER,
              state:
                serverState[this.appModuleDomain.SERVER_TYPE_SERVER] || null,
            },
          ];
          if (appModule.resource.type === this.resourceDomain.TYPE_ECS_CLUSTER)
            servers.push(ideServer);

          break;
        case this.appModuleDomain.TYPE_TASK:
          servers = [
            {
              name: "Task",
              type: this.appModuleDomain.SERVER_TYPE_TASK,
              state: serverState[this.appModuleDomain.SERVER_TYPE_TASK] || null,
            },
          ];
          if (appModule.resource.type === this.resourceDomain.TYPE_ECS_CLUSTER)
            servers.push(ideServer);

          break;
        case this.appModuleDomain.TYPE_NODE_APP:
        case this.appModuleDomain.TYPE_PYTHON_APP:
          servers = [
            {
              name: "App",
              type: this.appModuleDomain.SERVER_TYPE_APP,
              state: serverState[this.appModuleDomain.SERVER_TYPE_APP] || null,
            },
          ];
          if (appModule.resource.type === this.resourceDomain.TYPE_ECS_CLUSTER)
            servers.push(ideServer);
          break;
        case this.appModuleDomain.TYPE_REDIS_SERVER:
          servers = [
            {
              name: "Server",
              type: this.appModuleDomain.SERVER_TYPE_SERVER,
              state:
                serverState[this.appModuleDomain.SERVER_TYPE_SERVER] || null,
            },
          ];
          break;
      }
      let serverRows = servers.map((server) => {
        let controlsRight = [];
        let menuItems = [];
        let hasLog = [
          this.appModuleDomain.TYPE_REACT_APP,
          this.appModuleDomain.TYPE_SERVER,
          this.appModuleDomain.TYPE_PYTHON_APP,
          this.appModuleDomain.TYPE_TASK,
          this.appModuleDomain.TYPE_NODE_APP,
        ].includes(appModule.type);

        let url = null;
        if (
          appModule.resource.type === this.resourceDomain.TYPE_ECS_CLUSTER &&
          server.type !== this.appModuleDomain.SERVER_TYPE_NATIVE
        )
          url = this.appModuleDomain.createUrl(appModule.uid, server.type);
        else if (
          serverStateMap[server.type] &&
          serverStateMap[server.type].port &&
          serverStateMap[server.type].publicIpAddress
        )
          url = `http://${serverStateMap[server.type].publicIpAddress}:${
            serverStateMap[server.type].port
          }`;

        server.state !== this.appModuleDomain.SERVER_STATE_RUNNING &&
          hasLog &&
          menuItems.push(
            <PopUpMenuItem
              icon='console'
              key='log'
              label='View Log'
              tooltip={`View ${server.name} Log`}
              size='small'
              onPress={() => this.handleShowServerLogPress(server.type)}
            />
          );

        server.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
          url &&
          [
            this.appModuleDomain.SERVER_TYPE_APP,
            this.appModuleDomain.SERVER_TYPE_NATIVE,
            this.appModuleDomain.SERVER_TYPE_WEB,
          ].includes(server.type) &&
          controlsRight.push(
            <IconButton
              icon='openInNew'
              key='open'
              tooltip={`Open ${server.name} in new tab.`}
              size='small'
              externalUrl={url}
            />
          );
        url &&
          [this.appModuleDomain.SERVER_TYPE_IDE].includes(server.type) &&
          controlsRight.push(
            <IconButton
              icon='openInNew'
              key='open'
              tooltip={`Open ${server.name} ide in new tab.`}
              size='small'
              externalUrl={this.idePath}
            />
          );

        if (this.perms.execute) {
          server.state !== this.appModuleDomain.SERVER_STATE_RUNNING &&
            server.state !== this.appModuleDomain.SERVER_STATE_STARTING &&
            controlsRight.push(
              <IconButton
                icon='play'
                key='run'
                tooltip={`Run ${server.name}`}
                size='small'
                onPress={() =>
                  this.runServerCommand(
                    appModule.uid,
                    server.type,
                    this.appModuleDomain.COMMAND_RUN
                  )
                }
              />
            );
          server.state !== this.appModuleDomain.SERVER_STATE_STOPPED &&
            menuItems.push(
              <PopUpMenuItem
                icon='refresh'
                key='reload'
                tooltip={`Reload ${server.name}`}
                size='small'
                label='Reload'
                onPress={() =>
                  window.confirm("Are you sure?") &&
                  this.runServerCommand(
                    appModule.uid,
                    server.type,
                    this.appModuleDomain.COMMAND_RELOAD
                  )
                }
              />
            );
          (server.state === this.appModuleDomain.SERVER_STATE_RUNNING ||
            server.state === this.appModuleDomain.SERVER_STATE_STARTING) &&
            menuItems.push(
              <PopUpMenuItem
                icon='stop'
                key='stop'
                label='Stop'
                tooltip={`Stop ${server.name}`}
                onPress={() =>
                  window.confirm("Are you sure?") &&
                  this.runServerCommand(
                    appModule.uid,
                    server.type,
                    this.appModuleDomain.COMMAND_STOP
                  )
                }
                size='small'
              />
            );
        }
        appModule.resource.type === this.resourceDomain.TYPE_ECS_CLUSTER &&
          [
            this.appModuleDomain.SERVER_TYPE_APP,
            this.appModuleDomain.SERVER_TYPE_NATIVE,
            this.appModuleDomain.SERVER_TYPE_SERVER,
            this.appModuleDomain.SERVER_TYPE_WEB,
          ].includes(server.type) &&
          menuItems.push(
            <PopUpMenuItem
              icon='chartLine'
              key='monitor'
              label='Monitor'
              tooltip={`Monitor ${server.name}`}
              size='small'
              onPress={() => this.handleShowServerMonitorPress(server.type)}
            />
          );
        server.state === this.appModuleDomain.SERVER_STATE_RUNNING &&
          hasLog &&
          controlsRight.push(
            <IconButton
              icon='console'
              key='log'
              // label='View Log'
              tooltip={`View ${server.name} Log`}
              size='small'
              onPress={() => this.handleShowServerLogPress(server.type)}
            />
          );
        if (menuItems.length)
          controlsRight.push(
            <PopUpMenu
              controlIcon='dotsVertical'
              key='appModuleServerMenu'
              direction='downLeft'
            >
              {menuItems}
            </PopUpMenu>
          );
        return (
          <AppModuleWidgetRow
            key={server.type}
            name={server.name}
            stateIndicator={{
              label: this.appModuleDomain.getServerStateLabel(server.state),
              color: this.appModuleDomain.getServerStateIndicatorColor(
                server.state
              ),
            }}
            controlsRight={controlsRight}
          />
        );
      });
      return <View style={styles.serverDetails}>{serverRows}</View>;
    }
    renderTitleControlsRight() {
      let menuItems = [];
      let appModule = this.props.appModule;
      let project = this.props.project;
      let projectUid = project.uid;
      let projectKey = this.projectDomain.getKeyByType(project.type);

      if (this.perms.edit) {
        menuItems.push(
          <PopUpMenuItem
            icon='edit'
            label='Edit'
            size='small'
            key='edit'
            onPress={() =>
              this.props.app.nav.to(`projectAppModuleEdit`, {
                uid: projectUid,
                key: projectKey,
                appModuleUid: appModule.uid,
              })
            }
          />
        );
      }
      if (this.perms.edit) {
        if (
          this.state.state === this.appModuleDomain.STATE_READY &&
          [
            this.appModuleDomain.TYPE_REACT_APP,
            this.appModuleDomain.TYPE_SERVER,
            this.appModuleDomain.TYPE_TASK,
            this.appModuleDomain.TYPE_NODE_APP,
          ].includes(appModule.type)
        )
          menuItems.push(
            <PopUpMenuItem
              icon='npm'
              label='NPM Packages'
              size='small'
              key='manageNpm'
              onPress={() =>
                this.props.app.nav.to("projectAppModuleManagePackages", {
                  uid: projectUid,
                  key: projectKey,
                  appModuleUid: appModule.uid,
                })
              }
            />
          );

        if (
          this.state.state === this.appModuleDomain.STATE_READY &&
          appModule.type === this.appModuleDomain.TYPE_PYTHON_APP
        )
          menuItems.push(
            <PopUpMenuItem
              icon='languagePython'
              label='PIP Packages'
              size='small'
              key='manageNpm'
              onPress={() =>
                this.props.app.nav.to("projectAppModuleManagePackages", {
                  uid: projectUid,
                  key: projectKey,
                  appModuleUid: appModule.uid,
                })
              }
            />
          );

        if (
          appModule.appModuleRepos.nodes.length &&
          this.state.state === this.appModuleDomain.STATE_READY
        )
          menuItems.push(
            <PopUpMenuItem
              icon='git'
              label='Git Repositories'
              size='small'
              key='gitRepos'
              onPress={() =>
                this.props.app.nav.to("projectAppModuleManageRepos", {
                  uid: projectUid,
                  key: projectKey,
                  appModuleUid: appModule.uid,
                })
              }
            />
          );

        if (
          this.state.state === this.appModuleDomain.STATE_READY &&
          [
            this.appModuleDomain.TYPE_REACT_APP,
            this.appModuleDomain.TYPE_SERVER,
            this.appModuleDomain.TYPE_PYTHON_APP,
            this.appModuleDomain.TYPE_TASK,
            this.appModuleDomain.TYPE_NODE_APP,
          ].includes(appModule.type)
        )
          menuItems.push(
            <PopUpMenuItem
              icon='refresh'
              label='Update Core'
              size='small'
              key='updateCore'
              onPress={() =>
                window.confirm("Are you sure?") && this.handleUpdateCorePress()
              }
            />
          );
        let controlsRight = [];
        switch (appModule.resource.type) {
          case this.resourceDomain.TYPE_ECS_CLUSTER:
            controlsRight.push(
              <IconButton
                icon='console'
                key='log'
                tooltip={`View Log`}
                size='small'
                onPress={() => this.handleShowLogPress()}
              />
            );
            break;
        }
        if (menuItems.length)
          controlsRight.push(
            <PopUpMenu
              controlIcon='dotsVertical'
              key='appModuleMenu'
              direction='downLeft'
            >
              {menuItems}
            </PopUpMenu>
          );
        return controlsRight.length ? controlsRight : null;
      }
    }
    render() {
      let appModule = this.props.appModule;
      return (
        <Card
          title={appModule.name}
          titleTextStyle={styles.titleTextStyle}
          surfaceStyle={styles.appModuleCard}
          titleControlsRight={this.renderTitleControlsRight()}
        >
          <View style={styles.content}>
            {this.renderStatusRows()}
            {this.state.state === this.appModuleDomain.STATE_READY &&
              (this.state.resourceState === "running" ||
                this.state.resourceState === "active") &&
              this.renderServerRows()}
            {this.renderDetails()}
          </View>
        </Card>
      );
    }
  }
);
class AppModuleWidgetRow extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <View style={styles.rowControls}>
        <View style={styles.rowName}>
          <Text>{this.props.name}</Text>
        </View>
        {this.props.stateIndicator && (
          <View style={styles.rowState}>
            <StateIndicatorText
              label={this.props.stateIndicator.label}
              color={this.props.stateIndicator.color}
            />
          </View>
        )}
        <ControlsView align='right'>{this.props.controlsRight}</ControlsView>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  appModuleCard: {
    paddingBottom: 12,
    ...StyleSheet.margin(-16),
  },
  content: {
    marginTop: 8,
    // marginBottom: -24,
  },
  header: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  // detailsButton: {
  //   marginRight: -8
  // },
  // details: {
  //   ...StyleSheet.padding(0, 8, 8, 8),
  //   ...StyleSheet.margin(0, -8, 8, -8)
  // },
  externalLink: {
    color: Colors.onBackground,
  },
  details: {
    flex: 0,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  labelView: {
    ...StyleSheet.padding(0, 0, 24, 0),
  },
  titleTextStyle: {
    fontSize: 16,
    fontWeight: "500",
  },
  rowName: {
    width: 96,
  },
  rowState: {
    marginRight: "auto",
  },
  rowControls: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.05),
    height: 40,
    ...StyleSheet.padding(0, 8, 0, 8),
    ...StyleSheet.margin(0, -8, 8, -8),
    borderRadius: 4,
  },
  detailsButtonView: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "center",
  },
  detailsButtonAnimatedView: {
    width: 36,
    //marginBottom: -24
  },
  detailsButton: {
    ...StyleSheet.margin(0),
  },
  serverDetails: {
    ...StyleSheet.padding(0, 0, 8, 0),
  },
});
