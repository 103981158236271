import React from "react";
import R14, {
  WebView,
  StyleSheet,
  Button,
  ScreenLayout,
  ActivityIndicator,
  View,
  Text,
  IconButton,
  ControlsView,
  PopUpMenu,
  PopUpMenuItem,
  AnimatedView,
  FadeView,
  Colors,
} from "../core";
import StateIndicatorIcon from "../components/StateIndicatorIcon";
import AppModuleLogViewer from "../components/AppModuleLogViewer";
import PipelineBlockLogViewer from "../components/PipelineBlockLogViewer";
import AppModuleIdeControls from "../components/AppModuleIdeControls";
export default R14.connect(
  class AppModuleIde extends React.Component {
    constructor(props) {
      super(props);
      this.handleStartIdePress = this.handleStartIdePress.bind(this);
      this.handleRunCommandPress = this.handleRunCommandPress.bind(this);
      this.handleFooterExpandTogglePress = this.handleFooterExpandTogglePress.bind(
        this
      );
      this.handleFooterLargeTogglePress = this.handleFooterLargeTogglePress.bind(
        this
      );
      http://ide-pb-6d6d4d516b7350596d.am.os.r14.dev/154ccd182313c345d3b9dd8571931eb5.wasm
      // document.cookie = "param=test;sameSite=Lax;domain=.r14.dev";
      //this.props.app.api.createAccessTokenRequestUri(this.uri);
      this.appModuleSubscription = null;
      this.pipelineBlockSubscription = null;
      this.perms = this.props.app.dm.user.getPermissions("dev", "appModule");
      this.state = {
        pipelineBlockState: this.props.pipelineBlock
          ? this.props.pipelineBlock.state
          : null,
        pipelineBlockIdeState: this.props.pipelineBlock
          ? this.props.pipelineBlock.ideState
          : null,
        serverStateMap: this.createServerStateMap(
          this.appModule.serverState,
          this.pipelineBlock
        ),
        state: this.appModule.state,
        footerExpand: false,
        footerLarge: false,
        //defaultServer: this.getDefaultAppServer(),
      };
      this.init();
    }
    componentDidMount() {
      //this.props.app.ui.navigator("homeNavigator").hide();
      //this.props.app.ui.navigator("homeNavigator").setOption('type','modal');
    }
    componentWillUnmount() {
      this.appModuleSubscription && this.appModuleSubscription.unsubscribe();
      this.pipelineBlockSubscription &&
        this.pipelineBlockSubscription.unsubscribe();
    }
    createServerStateMap(serverState = [], pipelineBlock = null) {
      let serverStateMap = {};
      serverState.forEach((server) => (serverStateMap[server.type] = server));
      return serverStateMap;
    }
    async init() {
      this.appModuleSubscription = await this.props.app.dm.appModule.onUpdate(
        this.appModule.uid,
        (res) => {
          let serverStateMap = this.createServerStateMap(res.serverState);
          if (res)
            this.setState({
              state: res.state,
              serverStateMap: serverStateMap,
            });
        }
      );
      if (this.pipelineBlock)
        this.pipelineBlockSubscription = await this.pipelineBlockDm.onUpdate(
          this.pipelineBlock.uid,
          (res) => {
            this.setState({
              pipelineBlockState: res.state,
              pipelineBlockIdeState: res.ideState,
            });
          },
          { ideState: true }
        );
    }
    async handleStartIdePress() {
      this.handleRunCommandPress(
        this.appModuleDm.SERVER_TYPE_IDE,
        this.appModuleDm.COMMAND_RUN
      );
    }
    async handleStopIdePress() {
      this.handleRunCommandPress(
        this.appModuleDm.SERVER_TYPE_IDE,
        this.appModuleDm.COMMAND_STOP
      );
    }
    async handleFooterExpandTogglePress() {
      this.setState({ footerExpand: !this.state.footerExpand });
    }
    async handleFooterLargeTogglePress() {
      this.setState({ footerLarge: !this.state.footerLarge });
    }
    async handleRunCommandPress(serverType, command) {
      this.props.app.ui.progressIndicator.show();
      if (this.pipelineBlock) {
        let res = await this.pipelineBlockDm.runCommand(
          this.pipelineBlock.uid,
          serverType === this.appModuleDm.SERVER_TYPE_IDE
            ? this.pipelineBlockDm.COMMAND_TYPE_IDE
            : this.pipelineBlockDm.COMMAND_TYPE_BLOCK,
          command
        );
        if (res.pipelineBlock) {
          this.setState({
            pipelineBlockState: res.pipelineBlock.state,
            pipelineBlockIdeState: res.pipelineBlock.ideState,
          });
        }
      } else {
        let serverState = await this.appModuleDm.runServerCommand(
          this.appModule.uid,
          serverType,
          command
        );
        if (serverState && serverState.length)
          this.setState({
            serverStateMap: this.createServerStateMap(serverState),
          });
      }

      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    getServerStateByType(serverType) {
      let ret = null;
      if (this.pipelineBlock) {
        if (serverType === this.appModuleDm.SERVER_TYPE_IDE) {
          ret = this.state.pipelineBlockIdeState;
        }
      } else ret = this.state.serverStateMap[serverType] || null;
      return ret;
    }
    get appModule() {
      return this.props.appModule;
    }
    get block() {
      return this.props.block;
    }
    get pipelineBlock() {
      return this.props.pipelineBlock;
    }
    get appModuleDm() {
      return this.props.app.dm.appModule;
    }
    get pipelineBlockState() {
      return this.state.pipelineBlockState;
    }
    get pipelineBlockDm() {
      return this.props.app.dm.pipelineBlock;
    }
    get appServerState() {
      let ret = null;
      for (let type in this.state.serverStateMap) {
        if (
          [
            this.appModuleDm.SERVER_TYPE_WEB,
            this.appModuleDm.SERVER_TYPE_SERVER,
            this.appModuleDm.SERVER_TYPE_APP,
            this.appModuleDm.SERVER_TYPE_TASK,
          ].includes(type)
        )
          return this.state.serverStateMap[type].state;
      }
      return ret;
    }
    get ideServer() {
      return this.getServerStateByType(this.appModuleDm.SERVER_TYPE_IDE);
    }
    get defaultServer() {
      let ret = {};
      for (let type in this.state.serverStateMap) {
        if (
          [
            this.appModuleDm.SERVER_TYPE_WEB,
            this.appModuleDm.SERVER_TYPE_SERVER,
            this.appModuleDm.SERVER_TYPE_APP,
            this.appModuleDm.SERVER_TYPE_TASK,
          ].includes(type)
        )
          return this.state.serverStateMap[type];
      }
      return ret;
    }
    get uri() {
      if(! this.props.url) throw new Error("Url not found");
      return this.props.url;
      // if (!this.ideServer) return null;
      // if (process.env.NODE_ENV === "development")
      //   return `http://${this.ideServer.publicIpAddress}:${this.ideServer.port}`;
      // let uri = null;

      // if (this.pipelineBlock) {
      //   uri = this.pipelineBlockDm.createWebUrl(
      //     this.pipelineBlock.uid,
      //     this.appModuleDm.SERVER_TYPE_IDE
      //   );
      // } else
      //   uri = this.appModuleDm.createUrl(
      //     this.appModule.uid,
      //     this.appModuleDm.SERVER_TYPE_IDE
      //   );

      // if (process.env.NODE_ENV === "development")
      //   uri = uri.replace("https:", "http:");
      // return uri;
    }
    renderServerControls() {
      return (
        <ControlsView style={styles.headerControlsRight} align='right'>
          <AppModuleIdeControls
            appModule={this.props.appModule}
            pipelineBlock={this.props.pipelineBlock}
            projectType={this.props.projectType}
            projectUid={this.props.projectUid}
            permissions={this.perms}
            server={this.defaultServer}
            ideServer={this.ideServer}
            state={this.state.state}
            pipelineBlockState={this.state.pipelineBlockState}
            onRunCommandPress={this.handleRunCommandPress}
          />
        </ControlsView>
      );
    }
    renderTitle() {
      return this.pipelineBlock ? this.pipelineBlock.name : this.appModule.name;
    }
    renderStateIndicator() {
      return this.pipelineBlock ? (
        <StateIndicatorIcon
          tooltip={this.pipelineBlockDm.getStateLabel(this.pipelineBlockState)}
          color={this.pipelineBlockDm.getStateIndicatorColor(
            this.pipelineBlockState
          )}
        />
      ) : (
        <StateIndicatorIcon
          tooltip={this.appModuleDm.getServerStateLabel(this.appServerState)}
          color={this.appModuleDm.getServerStateIndicatorColor(
            this.appServerState
          )}
        />
      );
    }
    renderHeader() {
      return (
        <View style={styles.header}>
          <View style={styles.headerTitle}>
            {this.renderStateIndicator()}
            <Text style={styles.headerTitleText}>{this.renderTitle()}</Text>
          </View>
          {this.renderServerControls()}
        </View>
      );
    }
    renderLogViewer() {
      return this.pipelineBlock ? (
        <PipelineBlockLogViewer
          style={styles.logViewer}
          uid={this.pipelineBlock.uid}
          height={this.state.footerLarge ? 600 : 300}
        />
      ) : (
        <AppModuleLogViewer
          style={styles.logViewer}
          uid={this.appModule.uid}
          logType={this.defaultServer.type}
          height={this.state.footerLarge ? 600 : 300}
        />
      );
    }
    renderLogName() {
      return this.pipelineBlock
        ? "logs/block.log"
        : `logs/${this.defaultServer.type.toLowerCase()}.log`;
    }
    renderFooter() {
      let logViewer = null;
      return (
        <View
          style={[
            styles.footer,
            this.state.footerExpand && styles.footerExpanded,
            this.state.footerLarge && styles.footerLarge,
          ]}
        >
          <View style={styles.footerHeader}>
            <Text>{this.renderLogName()}</Text>
            <FadeView visible={this.state.footerExpand}>
              <AnimatedView
                enter={{
                  transform: [{ rotate: "180deg" }],
                }}
                exit={{
                  transform: [{ rotate: "0deg" }],
                }}
                in={this.state.footerLarge}
                onExit={this.handleAnimationExit}
                duration={400}
                style={[
                  this.props.collision || this.props.queueItemCount
                    ? styles.ioPathRaised
                    : null,
                ]}
              >
                {/* <IconButton
                  icon='keyboardArrowDown'
                  size='small'
                  color={StyleSheet.color(Colors.onSurface).rgba(0.5)}
                  onPress={this.handleFooterLargeTogglePress}
                /> */}
              </AnimatedView>
            </FadeView>

            <ControlsView style={styles.footerControlsRight} align='right'>
              <IconButton
                icon='console'
                size='small'
                color={
                  this.state.footerExpand ? Colors.primary : Colors.onSurface
                }
                onPress={this.handleFooterExpandTogglePress}
              />
            </ControlsView>
          </View>
          {this.state.footerExpand && this.renderLogViewer()}
        </View>
      );
    }
    render() {
      return (
        <ScreenLayout>
          {this.renderHeader()}
          {(!this.ideServer ||
            this.ideServer.state === this.appModuleDm.SERVER_STATE_STOPPED) && (
            <View style={styles.centered}>
              <Button
                title='Start Ide'
                icon='play'
                onPress={this.handleStartIdePress}
              />
            </View>
          )}
          {this.ideServer &&
            this.ideServer.state !== this.appModuleDm.SERVER_STATE_STOPPED &&
            this.ideServer.state !== this.appModuleDm.SERVER_STATE_RUNNING && (
              <View style={styles.centered}>
                <ActivityIndicator
                  size='large'
                  containerStyle={styles.activityIndicator}
                />
              </View>
            )}
          {this.ideServer &&
            this.ideServer.state === this.appModuleDm.SERVER_STATE_RUNNING && (
              <WebView
                source={{
                  uri: this.uri,
                }}
                style={styles.webView}
              />
            )}
          {this.renderFooter()}
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  header: {
    flex: 0,
    height: 56,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    elevation: 4,
    ...StyleSheet.padding(0, 12, 0, 16),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.06),
  },
  headerTitle: {
    flex: 0,
    height: 56,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerTitleText: {
    fontSize: 18,
    fontWeight: 600,
    paddingLeft: 16,
  },
  footer: {
    flex: 0,
    height: 36,
    elevation: 4,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    //
  },
  footerHeader: {
    flex: 1,
    height: 36,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...StyleSheet.padding(0, 12, 0, 12),
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.06),
  },
  footerExpanded: {
    flex: 0,
    height: 336,
    // screen: ({ height }) => ({
    //   height: height / 3,
    // }),
  },
  footerLarge: {
    flex: 0,
    height: 636,
    // screen: ({ height }) => ({
    //   height: height / 3,
    // }),
  },
  footerControlsRight: {
    marginLeft: "auto",
  },
  webView: {
    flex: 1,
  },
  centered: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logViewer: {
    flex: 1,
    width: "100%",
    height: 300,
  },
});
