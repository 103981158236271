import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import ClientDataTable from '../components/ClientDataTable';
export default R14.connect(
  class ClientsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={{
            to: "clientCreate",
            icon: "add"
          }}
          // scrollEnabled
        >
          <ClientDataTable />
        </ScreenLayout>
      );
    }
  }
);