import React from "react";
import R14, {
  StyleSheet,
  Chart,
  Card,
  ActivityIndicator,
  View,
  IconButton,
  Dialog,
  Form,
  SelectMenuField,
  ChipGroup,
  Chip,
  Colors,
} from "../core";
import ManualEntryUsageMetricDataTable from "../components/ManualEntryUsageMetricDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectManualEntryUsageMetricsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.usageMetricsDomain =
        this.props.app.dm.manualEntryPipelineUsageMetric;
      this.state = {
        activeChipMap: this.getInitialActiveChipMap(),
      };
      if (this.props.types.length > 2)
        throw new Error("Only 2 types supported per chart.");
      // this.handleFiltersFormDialogClosePress =
      //   this.handleFiltersFormDialogClosePress.bind(this);
      // this.handleFiltersFormDialogShowPress =
      //   this.handleFiltersFormDialogShowPress.bind(this);
      // this.handleFiltersFormDialogOkPress =
      //   this.handleFiltersFormDialogOkPress.bind(this);
      // this.usageMetricsDomain =
      //   this.props.app.dm.manualEntryPipelineUsageMetric;
      // this.handleFiltersFormSubmit = this.handleFiltersFormSubmit.bind(this);
      // this.pipelineDomain = this.props.app.dm.manualEntryPipeline;
      // this.perms = this.props.app.dm.user.getPermissions(
      //   "admin",
      //   "manualEntryUser"
      // );
      // this._chart = {
      //   period: this.filtersFormData.values.period,
      //   interval: {
      //     unit: null,
      //     value: null,
      //   },
      //   startAt: null,
      //   endAt: null,
      //   filterValues: {},
      // };
      // this.state = {
      //   // chartMetricData: null,
      //   // chartStartAt: null,
      //   // chartEndAt: null,
      //   chartLoaded: false,
      //   filtersFormDialogVisible: false,
      //   // filterValues: {
      //   //   ...this.filtersFormData.values,
      //   // },
      // };
    }
    isActiveChip(parentType, type) {
      return this.state.activeChipMap[this.createChipKey(parentType, type)]
        ? true
        : false;
    }
    toggleActiveChip(parentType, type) {
      let activeChipMap = { ...this.state.activeChipMap };
      let key = this.createChipKey(parentType, type);
      activeChipMap[key] = activeChipMap[key] === true ? false : true;
      this.setState({ activeChipMap });
    }
    createChipKey(parentType, type) {
      return `${parentType}-${type}`;
    }
    getInitialActiveChipMap() {
      let activeChipMap = {};
      this.props.parentTypes.forEach((parentType) =>
        this.props.types.forEach((type) => {
          activeChipMap[this.createChipKey(parentType, type)] = true;
        })
      );
      return activeChipMap;
    }
    filterMetricChartData() {
      let ret = [];
      if (!this.props.fieldMap) return [];
      for (let parentType of this.props.parentTypes) {
        let hasDefaultTypeProps = false;
        for (let type of this.props.types) {
          if (!this.isActiveChip(parentType, type)) continue;
          let fieldName = this.usageMetricsDomain.getMetricFieldName(
            parentType,
            type
          );
          if (!this.props.fieldMap[fieldName]) continue;
          let data = {
            ...this.props.fieldMap[fieldName],
            name: this.usageMetricsDomain.getLabel(parentType, type, {
              secondary: hasDefaultTypeProps,
            }),
            color: this.usageMetricsDomain.getColor(parentType, {
              secondary: hasDefaultTypeProps,
            }),
          };
          hasDefaultTypeProps = true;
          ret.push(data);
        }
      }
      // this.props.parentTypes
      //   //.filter((parentType) => this.state.parentTypes.includes(parentType))
      //   .forEach((parentType) =>
      //     this.props.types
      //       .filter((type) => this.isActiveChip(parentType, type))
      //       .forEach((type) => {
      //         let fieldName = this.usageMetricsDomain.getMetricFieldName(
      //           parentType,
      //           type
      //         );
      //         this.props.fieldMap[
      //           this.usageMetricsDomain.getMetricFieldName(parentType, type)
      //         ] && ret.push(this.props.fieldMap[fieldName]);
      //       })
      //   );
      return {
        nodes: ret,
      };
    }
    // getColor(parentType, options = {}) {
    //   return options.secondary
    //     ? this.usageMetricsDomain.getColorSecondary(parentType)
    //     : this.usageMetricsDomain.getColorPrimary(parentType);
    // }
    // getLabel(parentType, type, options = {}) {
    //   let label = this.usageMetricsDomain.getParentTypeLabel(parentType);
    //   if (options.secondary) {
    //     let typeLabel = this.usageMetricsDomain.getTypeLabel(type);
    //     // Deal with case of users recommended
    //     if (typeLabel.startsWith(label)) label = typeLabel;
    //     else label = `${label} ${typeLabel}`;
    //   }
    //   return label;
    // }
    renderFilterChipGroup() {
      let chips = [];
      for (let parentType of this.props.parentTypes) {
        let hasDefaultTypeProps = false;
        for (let type of this.props.types) {
          let label = this.usageMetricsDomain.getLabel(parentType, type, {
            secondary: hasDefaultTypeProps,
          });
          let key = this.createChipKey(parentType, type);
          let selectedColor = this.usageMetricsDomain.getColor(parentType, {
            secondary: hasDefaultTypeProps,
          });
          hasDefaultTypeProps = true;

          chips.push(
            <Chip
              key={key}
              label={label}
              selected={this.isActiveChip(parentType, type)}
              onPress={() => this.toggleActiveChip(parentType, type)}
              selectedColor={selectedColor}
              selectedLabelColor={Colors.onPrimary}
            />
          );
        }
      }

      return <ChipGroup>{chips}</ChipGroup>;
    }
    render() {
      let data = this.filterMetricChartData(
        this.props.parentTypes,
        this.props.types
      );
      if (!data || !data.nodes || !data.nodes.length) return null;

      // console.log("CHECK CHART INFO!!!!!!!!!", {
      //   currDate: new Date(),
      //   dateRangeStart: this._chart.startAt,
      //   dateRangeEnd: this._chart.endAt,
      //   intervalUnit: this._chart.interval.unit.toLowerCase(),
      //   intervalValue: this._chart.interval.value,
      // });

      return (
        <Card
          title={this.props.title}
          titleTextStyle={styles.cardTitleText}
          style={styles.metricsChartSurface}
        >
          {this.renderFilterChipGroup()}
          <Chart
            name={this.props.name}
            key={this.props.name}
            data={data}
            style={styles.metricsChart}
            height={300}
            type='timeSeries'
            dateAxis={{
              dateRangeStart: this.props.startAt,
              dateRangeEnd: this.props.endAt,
              intervalUnit: this.props.interval.unit.toLowerCase(),
              intervalValue: this.props.interval.value,
              labelFormats: {
                minute: "h:mm a",
                hour: "h:mm a",
                day: "MMMM d, yyyy",
              },
            }}
          />
        </Card>
      );
    }
  }
);
const styles = StyleSheet.create({
  cardTitleText: {
    fontSize: 18,
    fontWeight: 500,
  },
  filtersForm: {
    ...StyleSheet.padding(0),
  },
  metricsChart: {
    ...StyleSheet.padding(16, 0, 16, 0),
  },
  metricsChartSurface: {
    ...StyleSheet.margin(0, 0, 16, 0),
  },
});
