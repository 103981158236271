import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  PopUpMenu,
  PopUpMenuItem,
  Button,
  Dialog,
  Form,
  SelectMenuField,
} from "../core";
import ManualEntryUserDataTable from "../components/ManualEntryUserDataTable";
import ManualEntryUserDeploymentsDialogFormField from "../components/ManualEntryUserDeploymentsDialogFormField";
export default R14.connect(
  class ManualEntryUsersScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleApproveSelectedPress =
        this.handleApproveSelectedPress.bind(this);
      this.handleApproveAllPress = this.handleApproveAllPress.bind(this);
      this.handleApprovePress = this.handleApprovePress.bind(this);
      this.handleApprovalDialogClosePress =
        this.handleApprovalDialogClosePress.bind(this);
      this.handleApprovalDialogOkPress =
        this.handleApprovalDialogOkPress.bind(this);
      this.handleApprovalFormSubmit = this.handleApprovalFormSubmit.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
      this.state = {
        approvalDialogVisible: false,
      };
      this._approvalSubmitData = null;
    }
    get dataTableName() {
      return "manualEntryUserSignUps";
    }
    get dataTable() {
      return this.props.app.ui.dataTable(this.dataTableName);
    }
    showApprovalFormDialog() {
      this.setState({ approvalDialogVisible: true });
    }
    hideApprovalFormDialog() {
      this.setState({ approvalDialogVisible: false });
    }
    async approveByUids(uids) {
      // this.props.app.ui.progressIndicator.show();

      // console.log("Approve uids", uids);
      // this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this._approvalUids = uids;
      this.showApprovalFormDialog();
    }
    async handleApproveSelectedPress() {
      await this.approveByUids(this.dataTable.selectedRows);
    }
    async handleApprovePress(uid) {
      await this.approveByUids([uid]);
    }
    async handleApproveAllPress() {
      throw new Error("N/A");
    }
    async handleApprovalDialogClosePress() {
      this.hideApprovalFormDialog();
    }
    async handleApprovalDialogOkPress() {
      this.props.app.ui.form("approvalForm").submit();
    }
    async handleApprovalFormSubmit(form) {

      try{
        let res = await this.props.app.dm.manualEntryUser.approveUsers(this._approvalUids, form.values);
      }
      catch(err){
         console.error(err)
      }

      return true;
      
    }
    getInitialApprovalFormValues() {
      return {};
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={
            this.perms.create
              ? {
                  to: "manualEntryUserCreate",
                  icon: "add",
                }
              : null
          }
          scrollEnabled
        >
          <ManualEntryUserDataTable
            state={
              this.props.app.dm.manualEntryUser.STATE_SIGNUP_PENDING_APPROVAL
            }
            name={this.dataTableName}
            title='Manual Entry User Sign Ups'
            signupDetails
            checkboxes
            excludeColumns={["name", "lastCharacterSetCompleteAt"]}
            includeColumns={["createdAt"]}
            headerControlsRight={[
              <PopUpMenu
                controlIcon='dotsVertical'
                key='adminMenu'
                direction='downLeft'
                onSelect={(value, label) => {}}
              >
                <PopUpMenuItem
                  key='approveSelected'
                  icon='checkboxMarked'
                  iconSize='small'
                  label='Approve Selected'
                  onPress={this.handleApproveSelectedPress}
                />
                {/* <PopUpMenuItem
                  key='approveAll'
                  icon='checkCircle'
                  iconSize='small'
                  label='Approve All'
                  onPress={this.handleApproveAllPress}
                /> */}
              </PopUpMenu>,
            ]}
            rowControlsRight={(row, dataTable) => (
              <Button
                title='Approve'
                onPress={() => this.handleApprovePress(row.uid)}
                variant='outlined'
                style={styles.approveButton}
              />
            )}
          />
          <Dialog
            name='approvalDialog'
            title='Deployment Settings'
            visible={this.state.approvalDialogVisible}
            titleControlVariant='back'
            onClosePress={this.handleApprovalDialogClosePress}
            onCancelPress={this.handleApprovalDialogClosePress}
            onBackdropPress={this.handleApprovalDialogClosePress}
            onBackPress={this.handleApprovalDialogClosePress}
            onOkPress={this.handleApprovalDialogOkPress}
            okButtonTitle={"Save"}
          >
            <Form
              name='approvalForm'
              style={styles.approvalForm}
              onSubmit={this.handleApprovalFormSubmit}
              validateBeforeSubmit
              // initialValues={this.getInitialApprovalFormValues()}
            >
              <SelectMenuField
                useValuesOnly
                name='mfaType'
                label='Multi-Factor Auth'
                items={this.props.formData.mfaTypeSelections}
                onValueChange={this.handleMfaTypeChange}
              />
              <ManualEntryUserDeploymentsDialogFormField
                formData={this.props.formData}
                style={styles.r14DeploymentsDialogFormField}
                required='Please select deployment(s)'
              />
            </Form>
          </Dialog>
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  r14DeploymentsDialogFormField: {
    width: 320,
  },
  approvalForm: {
    ...StyleSheet.padding(0),
  },
  approveButton: {
    marginRight: 4,
  },
});
