import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
} from "../core";

import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class DatasetDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.datasetDomain = this.props.app.dm.dataset;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRemovePress = this.handleRemovePress.bind(this);
      this.handleClearDataPress = this.handleClearDataPress.bind(this);
      this.handleStopSyncPress = this.handleStopSyncPress.bind(this);
      this.handleRunSyncPress = this.handleRunSyncPress.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
      this.state = {};
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.projectType),
        "dataset"
      );
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.projectType)
        filter.projectType = { eq: this.props.projectType };
      let res = await this.props.app.dm.dataset.find(
        `
        uid
        name
        type
        state
        totalSyncedItems
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("datasets");
    }
    get title() {
      return "Datasets";
      // return this.props.type
      //   ? `${this.props.app.dm.dataset.getTypeLabel(this.props.type)} Datasets`
      //   : "Datasets";
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      switch (columnName) {
        case "name":
          ret = (
            <React.Fragment>
              <Text style={[styles.cellText, styles.nameCellNameText]}>
                {row.name}
              </Text>
              {row.description && (
                <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
                  {row.description}
                </Text>
              )}
            </React.Fragment>
          );
          break;
        case "type":
          ret = (
            <Text style={style}>
              {this.datasetDomain.getTypeLabel(row.type)}
            </Text>
          );
          break;
        case "state":
          ret = (
            <StateIndicatorText
              label={this.datasetDomain.getStateIndicatorLabel(row.state)}
              color={this.datasetDomain.getStateIndicatorColor(row.state)}
            />
          );
          break;
        case "totalSyncedItems":
          ret = <Text style={style}>{`${content || 0}`}</Text>;
          break;
        default:
          ret = <Text style={style}>{content}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.datasetDomain.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleClearDataPress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.datasetDomain.clearData(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleStopSyncPress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.datasetDomain.stopSync(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleRunSyncPress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.datasetDomain.runSync(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    handleRowPress(row) {
      // this.props.app.nav.to("dataset", {
      //   uid: row.uid,
      //   key: this.datasetDomain.getKeyByType(row.type)
      // });
    }
    renderRowControlsRight(row, dataTable) {
      let rowControlsRight = [];
      this.perms.edit &&
        rowControlsRight.push(
          <IconButton
            icon='edit'
            size='small'
            key='edit'
            onPress={() =>
              this.props.app.nav.to(this.props.editAction || "datasetEdit", {
                uid: row.uid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
              })
            }
          />
        );
      if (this.perms.edit || this.perms.delete) {
        let menuItems = [];
        if (this.perms.edit) {
          [
            this.datasetDomain.STATE_SYNC,
            this.datasetDomain.STATE_SYNCING,
          ].includes(row.state) &&
            menuItems.push(
              <PopUpMenuItem
                icon='stop'
                label='Stop Sync'
                key='stopSync'
                onPress={() => this.handleStopSyncPress(row.uid, dataTable)}
              />
            );
          [this.datasetDomain.STATE_READY].includes(row.state) &&
            menuItems.push(
              <PopUpMenuItem
                icon='play'
                label='Sync Data'
                key='runSync'
                onPress={() => this.handleRunSyncPress(row.uid, dataTable)}
              />
            );
          [
            this.datasetDomain.STATE_READY,
            this.datasetDomain.STATE_ERROR,
          ].includes(row.state) &&
            menuItems.push(
              <PopUpMenuItem
                icon='deleteSweep'
                label='Clear Data'
                key='clear'
                onPress={() => this.handleClearDataPress(row.uid, dataTable)}
              />
            );
        }
        this.perms.delete &&
          menuItems.push(
            <PopUpMenuItem
              icon='close'
              label='Remove'
              key='remove'
              onPress={() => this.handleRemovePress(row.uid, dataTable)}
            />
          );
        rowControlsRight.push(
          <PopUpMenu
            controlIcon='dotsHorizontal'
            controlIconSize='small'
            key='datasetMenu'
            direction='downLeft'
          >
            {menuItems}
          </PopUpMenu>
        );
      }
      return rowControlsRight;
    }
    render() {
      return (
        <DataTable
          name='datasets'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            type: {
              label: "Type",
              sortable: true,
            },
            state: {
              label: "State",
              sortable: true,
            },
            totalSyncedItems: {
              label: "Synced",
              sortable: true,
            },
          }}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          //onRowPress={this.handleRowPress}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || this.title}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='name'
          initialSortDirection='asc'
          autoRefresh={30}
          // headerControlsRight={[
          //   <PopUpMenu
          //     controlIcon='dotsVertical'
          //     key='adminMenu'
          //     direction='downLeft'
          //     onSelect={(value, label) => {}}
          //   >
          //     <PopUpMenuItem
          //       icon='add'
          //       iconSize='small'
          //       label='Create Dataset'
          //       to='datasetCreate'
          //       key='add'
          //     />
          //   </PopUpMenu>
          // ]}
          rowControlsRight={(row, dataTable) => {
            return this.renderRowControlsRight(row, dataTable);
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  nameCellDescriptionText: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: "300",
  },
});
