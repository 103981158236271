import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  HiddenInputField,
  FieldGroup,
  Text,
  DatePickerField,
  TimePickerField,
  StyleSheet,
} from "../core";
import EditForm from "../components/EditForm";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class VirtualWorkspaceRegionEditScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='virtualWorkspaceRegion'
          redirect={this.props.redirect || "virtualWorkspaceRegions"}
          refreshDataTable='virtualWorkspaceRegions'
        >
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
           <TextInputField
            name='key'
            label='Key'
            required='Please enter a key.'
            autoFocus
            helper="Provider region key"
          />
           <TextInputField
            name='subdomain'
            label='Subdomain'
            required='Please enter a subdomain.'
            autoFocus
          />
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({});
