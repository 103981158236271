export default {
  uniqueKey: (val) => {
    let keys = {};
    let isValid = true;
    if (val) {
      for (let i in val) {
        if (keys[val[i].key]) {
          isValid = false;
          break;
        }
        keys[val[i].key] = true;
      }
    }
    return isValid ? true : { error: "All keys must be unique." };
  },
  semanticVersion: (val) => {
    let error = null;
    try {
      if (typeof val !== "string")
        throw new Error("Version should be a string.");
      let vArr = val.split(".");
      if (vArr.length !== 3)
        throw new Error("Version should in the format '1.2.3'.");
      vArr.forEach((n) => {
        let num = Number(n);
        if (!Number.isInteger(num) || num < 0)
          throw new Error(`'${n}' should be a positive integer`);
      });
    } catch (err) {
      error = err.message;
    }
    return error ? { error } : true;
  },
};
