import React, { useMemo, useState, useRef } from "react";
import R14, {
  useApp,
  Colors,
  StyleSheet,
  Text,
  View,
  DataTable,
  Dialog,
  Form,
  DatePickerField,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  TextInputField,
} from "../core";

function ManualEntryPipelineUserMetricsDataTable({
  manualEntryPipeline,
  name = null,
  title = null,
}) {
  const app = useApp();
  const [isDownloadDialogVisible, setIsDownloadDialogVisible] = useState(false);
  const currFiltersRef = useRef(null);
  const initializer = async (gridState) => {
    return await getPageData(gridState, {
      totalCount: true,
    });
  };
  const pageLoader = async (gridState) => {
    let res = await getPageData(gridState);
    return res.pageData;
  };
  const getPageData = async (
    {
      page,
      rowsPerPage,
      sortColumnName,
      sortDirection,
      search = null,
      filters = null,
    },
    options = {}
  ) => {
    currFiltersRef.current = filters;
    let res = await app.dm.manualEntryPipeline.fetchUserMetrics(
      manualEntryPipeline.uid,
      { page, rowsPerPage, filters, search }
    );
    return {
      pageData: res.nodes.map((node) => ({
        ...node,
        metrics: app.dm.manualEntry.parseUserMetrics(node.metrics),
      })),
      totalRows: res.totalCount || null,
    };
  };
  const getMetricsByColumnName = (columnName, metrics) => {
    const modes = manualEntryPipeline.modes;
    const metricTypeMap = {
      [app.dm.manualEntry.MODE_CHARACTERS]: {
        label: "CLC",
        keyValue: "CHARACTERS",
      },
      [app.dm.manualEntry.MODE_FIELD]: {
        label: "FLC",
        keyValue: "FIELDS",
      },
      [app.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS]: {
        label: "PLC",
        keyValue: "FIELDS",
      },
    };
    Object.keys(metricTypeMap).forEach((mode) => {
      metricTypeMap[mode].columns = {
        accepted: [
          app.dm.manualEntry[
            `METRIC_TYPE_${metricTypeMap[mode].keyValue}_ACCEPTED`
          ],
        ],
        updated: [
          app.dm.manualEntry[
            `METRIC_TYPE_${metricTypeMap[mode].keyValue}_UPDATED`
          ],
        ],
        rejected: [
          app.dm.manualEntry[
            `METRIC_TYPE_${metricTypeMap[mode].keyValue}_REJECTED`
          ],
        ],
        total: [
          app.dm.manualEntry[
            `METRIC_TYPE_${metricTypeMap[mode].keyValue}_TOTAL`
          ],
        ],
        seconds: [app.dm.manualEntry.METRIC_TYPE_SECONDS],
        keystrokes: [app.dm.manualEntry.METRIC_TYPE_KEYSTROKES],
      };
    });
    const ret = modes
      .filter((mode) => metricTypeMap[mode])
      .map((mode) => {
        const metricType = metricTypeMap[mode];
        const metricNodes = metricType.columns[columnName]
          .map((type) => {
            return metrics.find(
              (metric) => metric.mode === mode && metric.type === type
            );
          })
          .filter((metric) => metric)
          .map((metric) => ({
            label: metricType.label,
            ...metric,
          }));
        return metricNodes;
      })
      .reduce((acc, val) => {
        acc.push(...val);
        return acc;
      }, []);
    return ret;
  };
  const metricsCellRenderer = (columnName, metrics) => {
    const cellMetrics = getMetricsByColumnName(columnName, metrics);
    return cellMetrics.length ? (
      <View>
        {cellMetrics.map((metric) => (
          <View key={metric.key} style={styles.cellMetric}>
            <View style={styles.cellMetricLabel}>
              <Text style={styles.cellMetricLabelText}>{metric.label}</Text>
            </View>
            <View style={styles.cellMetricValue}>
              <Text style={styles.cellMetricValueText}>{metric.value}</Text>
            </View>
          </View>
        ))}
      </View>
    ) : null;
  };
  const cellRenderer = ({ cell, columnName, row }) => {
    let text = cell;
    let content = null;
    // console.log("CHECK ROW", columnName, row);
    switch (columnName) {
      case "completedAt":
        text = row.dateKey;
        break;
      case "username":
        text = row.username;
        break;
      default:
        // render the metrics
        content = metricsCellRenderer(columnName, row.metrics);
    }
    return content || <Text>{text}</Text>;
  };
  const rowKeyExtractor = (row) => {
    return `${row.username}-${row.dateKey}`;
  };
  const hideDownloadDialog = () => setIsDownloadDialogVisible(false);
  const showDownloadDialog = () => setIsDownloadDialogVisible(true);
  const handleDownloadSubmit = async (form) => {
    const { startDate, endDate, username } = form.values;
    const filters = startDate || endDate || username ? {} : null;
    if (startDate) filters.startDate = startDate;
    if (endDate) filters.endDate = endDate;
    if (username) filters.username = username;
    app.api.download(
      `/downloads/pipelineUserMetricsCsv/${manualEntryPipeline.uid}`,
      filters
    );
  };
  const filterFields = [
    <TextInputField
      name="username"
      key="username"
      label="Username"
      helper="Use % for search, like %sernam%"
    />,
    <DatePickerField name="startDate" key="startDate" label="Start Date" />,
    <DatePickerField name="endDate" key="endDate" label="End Date" />,
  ];

  const renderDownloadDialog = () => (
    <Dialog
      name="downloadDialog"
      title="Download User Metrics"
      visible={isDownloadDialogVisible}
      titleControlVariant="back"
      onClosePress={hideDownloadDialog}
      onCancelPress={hideDownloadDialog}
      onBackdropPress={hideDownloadDialog}
      onBackPress={hideDownloadDialog}
      onOkPress={() => app.ui.form.filterForm.submit()}
      //onOkPress={() => console.log("CHECK HCEKC")}
      okButtonTitle={"Download"}
      // OkButtonComponent={<SubmitButton title='Create'}
    >
      <Form
        name="filterForm"
        validateBeforeSubmit
        style={styles.filterForm}
        initialValues={currFiltersRef.current}
        onSubmit={handleDownloadSubmit}
      >
        {filterFields}
      </Form>
    </Dialog>
  );
  return (
    <View>
      {renderDownloadDialog()}
      <DataTable
        name={name || "manualEntryPipelineUserMetrics"}
        columns={{
          completedAt: {
            label: "Date",
            collapsable: false,
            sortable: true,
            width: 128,
          },
          username: {
            label: "Username",
            collapsable: false,
            sortable: true,
            width: 192,
          },
          accepted: {
            label: "Accepted",
          },
          rejected: {
            label: "Rejected",
          },
          updated: {
            label: "Updated",
          },
          total: {
            label: "Total",
          },
          keystrokes: {
            label: "Keystrokes",
            collapsed: true,
          },
          seconds: {
            label: "Seconds",
            collapsed: true,
          },
        }}
        initializer={initializer}
        rowKeyExtractor={rowKeyExtractor}
        pageLoader={pageLoader}
        cellRenderer={cellRenderer}
        initialRowsPerPage={10}
        headerTitle={title || "User Metrics"}
        filterFields={filterFields}
        headerControlsRight={[
          <PopUpMenu controlIcon="dotsVertical" key="menu" direction="downLeft">
            <PopUpMenuItem
              icon="fileDownload"
              iconSize="small"
              label="Download CSV"
              onPress={showDownloadDialog}
              key="add"
            />
          </PopUpMenu>,
        ]}
        collapsable={true}
      />
    </View>
  );
}
export default ManualEntryPipelineUserMetricsDataTable;

//   class ManualEntryPipelineUserMetricsDataTable extends React.Component {
//     constructor(props) {
//       super(props);
//       this.initializer = this.initializer.bind(this);
//       this.pageLoader = this.pageLoader.bind(this);
//       this.cellRenderer = this.cellRenderer.bind(this);
//       this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
//       this.perms = this.props.app.dm.user.getPermissions(
//         "admin",
//         "manualEntryUser"
//       );
//       this.state = {
//         parentType: {
//           label: "Document Sets",
//           value:
//             this.props.app.dm.manualEntryPipeline
//               .METRIC_PARENT_TYPE_DOCUMENT_SET,
//         },
//       };
//     }
//     async getPageData(
//       { page, rowsPerPage, sortColumnName, sortDirection, search = null },
//       options = {}
//     ) {
//       let res = await this.props.app.dm.manualEntryPipeline.fetchMetrics(
//         this.props.manualEntryPipeline.uid,
//         { page, rowsPerPage }
//       );
//       return {
//         pageData: res.nodes,
//         totalRows: res.totalCount || null,
//       };
//     }
//     async initializer(gridState) {
//       return await this.getPageData(gridState, {
//         totalCount: true,
//       });
//     }
//     async pageLoader(gridState) {
//       let res = await this.getPageData(gridState);
//       return res.pageData;
//     }
//     get dataTable() {
//       return this.props.app.ui.dataTable("manualEntryPipelineMetrics");
//     }
//     cellRenderer({ cell, columnName, row }) {
//       let content = cell;
//       let ret = null;
//       let style = [styles.cellText];
//       const defaultNamepaceKey = "__r14__default__NONAMESPACE__";
//       let getMetricValueByType = (type, metrics) => {
//         if (!metrics) return 0;
//         let value = 0;
//         console.log("CHECK STATE PARENT TYPE", this.state.parentType);
//         for (let metric of metrics) {
//           if (
//             metric.type === type &&
//             metric.parentType === this.state.parentType.value
//           ) {
//             value = metric.value || 0;
//             break;
//           }
//         }
//         return value;
//       };
//       let queueNamespaceMetricsMap = row.queueNamespaceMetrics
//         ? row.queueNamespaceMetrics
//             .filter((val) => val.parentType === this.state.parentType.value)
//             .reduce((acc, val) => {
//               const queueNamespace = val.queueNamespace || defaultNamepaceKey;
//               if (!acc[queueNamespace]) acc[queueNamespace] = [];
//               acc[queueNamespace].push(val);
//               return acc;
//             }, {})
//         : null;
//       let queueNamespaceKeys = Object.keys(queueNamespaceMetricsMap);
//       // Don't show only default, it is the same as the totals
//       if (
//         !queueNamespaceKeys.length ||
//         (queueNamespaceKeys.length === 1 &&
//           queueNamespaceKeys[0] === defaultNamepaceKey)
//       )
//         queueNamespaceMetricsMap = null;

//       console.log("CHECK COL NAME", queueNamespaceMetricsMap);
//       let queueNamespaceContent = null;
//       switch (columnName) {
//         case "totalComplete":
//         case "totalTimeout":
//           queueNamespaceContent = queueNamespaceMetricsMap ? (
//             <View style={styles.queueNamespaceContent}>
//               {Object.keys(queueNamespaceMetricsMap).map((key) => (
//                 <View key={key}>
//                   <Text style={[styles.cellText, styles.queueNamespaceText]}>
//                     {getMetricValueByType(
//                       columnName === "totalTimeout"
//                         ? this.props.app.dm.manualEntryPipeline
//                             .METRIC_TYPE_TOTAL_TIMEOUT
//                         : this.props.app.dm.manualEntryPipeline
//                             .METRIC_TYPE_TOTAL_COMPLETE,
//                       queueNamespaceMetricsMap[key]
//                     )}
//                   </Text>
//                 </View>
//               ))}
//             </View>
//           ) : null;
//           ret = (
//             <View>
//               <Text style={[styles.cellText, styles.callValueText]}>
//                 {getMetricValueByType(
//                   columnName === "totalTimeout"
//                     ? this.props.app.dm.manualEntryPipeline
//                         .METRIC_TYPE_TOTAL_TIMEOUT
//                     : this.props.app.dm.manualEntryPipeline
//                         .METRIC_TYPE_TOTAL_COMPLETE,
//                   row.metrics
//                 )}
//               </Text>
//               {queueNamespaceContent}
//             </View>
//           );
//           break;
//         case "dateKey":
//           queueNamespaceContent = queueNamespaceMetricsMap ? (
//             <View style={styles.queueNamespaceContent}>
//               {" "}
//               {Object.keys(queueNamespaceMetricsMap).map((key) => (
//                 <View key={key}>
//                   <Text style={[styles.cellText, styles.queueNamespaceText]}>
//                     {!key || defaultNamepaceKey === key ? "Default" : key}
//                   </Text>
//                 </View>
//               ))}
//             </View>
//           ) : null;
//           ret = (
//             <View>
//               <View>
//                 <Text style={[styles.cellText, styles.callValueText]}>{`${
//                   content || 0
//                 }`}</Text>
//               </View>
//               {queueNamespaceContent}
//             </View>
//           );
//           break;
//         default:
//           ret = <Text style={style}>{`${content || 0}`}</Text>;
//       }
//       return ret;
//     }
//     rowKeyExtractor(row) {
//       return row.dateKey;
//     }
//     render() {
//       return (
//         <DataTable
//           name={this.props.name || "manualEntryPipelineMetrics"}
//           columns={{
//             // profileImage: {
//             //   label: "image",
//             // },
//             dateKey: {
//               label: "Date",
//             },
//             totalComplete: {
//               label: "Complete",
//             },
//             totalTimeout: {
//               label: "Timeout",
//             },
//             // total: {
//             //   label: "Total",
//             // },
//           }}
//           initializer={this.initializer}
//           rowKeyExtractor={this.rowKeyExtractor}
//           pageLoader={this.pageLoader}
//           cellRenderer={this.cellRenderer}
//           initialRowsPerPage={5}
//           headerTitle={this.props.title || "Daily Stats"}
//           headerControlsRight={[
//             <HeaderLabelSelectMenu
//               key="headerLabel"
//               value={this.state.parentType}
//               style={styles.headerLabelSelectMenu}
//               searchable={false}
//               headerLabelTextStyle={styles.headerLabelTextStyle}
//               items={[
//                 {
//                   label: "Document Sets",
//                   value:
//                     this.props.app.dm.manualEntryPipeline
//                       .METRIC_PARENT_TYPE_DOCUMENT_SET,
//                 },
//                 {
//                   label: "Documents",
//                   value:
//                     this.props.app.dm.manualEntryPipeline
//                       .METRIC_PARENT_TYPE_DOCUMENT,
//                 },
//                 {
//                   label: "Fields",
//                   value:
//                     this.props.app.dm.manualEntryPipeline
//                       .METRIC_PARENT_TYPE_FIELD,
//                 },
//                 {
//                   label: "Field Groups",
//                   value:
//                     this.props.app.dm.manualEntryPipeline
//                       .METRIC_PARENT_TYPE_FIELD_GROUP,
//                 },
//               ]}
//               onValueChange={(value) => {
//                 this.setState({ parentType: value });
//               }}
//             />,
//           ]}
//         />
//       );
//     }
//   }
// );
const styles = StyleSheet.create({
  cellMetrics: {},
  cellMetric: {
    flex: 1,
    flexDirection: "row",
  },
  cellMetricLabel: {
    width: 48,
  },
  cellMetricValue: {
    fontWeight: 500,
    textAlign: "right",
  },
  cellMetricLabelText: {
    fontWeight: 300,
    fontSize: 14,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
  cellMetricValueText: {
    fontWeight: 500,
  },
  filterForm: {
    ...StyleSheet.padding(8, 0, 0, 0),
  },
  //   cellText: {
  //     color: Colors.onSurface,
  //   },
  //   cellChipGroup: {
  //     marginTop: 8,
  //   },
  //   headerLabelSelectMenu: {},
  //   headerLabelTextStyle: {
  //     fontWeight: 400,
  //     fontSize: 16,
  //   },
  //   cellValueText: {
  //     fontWeight: 500,
  //   },
  //   queueNamespaceContent: {
  //     marginTop: 4,
  //   },
  //   queueNamespaceText: {
  //     fontWeight: 300,
  //     fontSize: 14,
  //     color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  //   },
});
