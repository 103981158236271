import React from "react";
import R14, { ScreenLayout } from "../core";
import ResourceDataTable from "../components/ResourceDataTable";
export default R14.connect(
  class ResourcesScreen extends React.Component {
    render() {
      return (
        <ScreenLayout scrollEnabled>
          <ResourceDataTable
            dataGridFiltersFormData={this.props.dataGridFiltersFormData || null}
          />
        </ScreenLayout>
      );
    }
  }
);
