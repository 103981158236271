import R14 from "../core";
export default class CurrencyDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "currency",
      pluralName: "currencies",
    });
    this.state = {};
  }
  async fetchSelections(filters, options = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (filters.currencyUids) {
      filter.uid = { in: filters.currencyUids };
    }
    let res = await this.find(
      `
      uid
      name
      code
      `,
      {
        page: 1,
        resultsPerPage: options.resultsPerPage || 25,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "code",
            order: "ASC",
          },
        ],
        clientUid: false,
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: `${val.code} - ${val.name} `,
            value: val.uid,
          }))
        : [];
    return ret;
  }
}
