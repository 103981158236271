let theme = {
  default: {
    colors: {
      primary: "#0097a7",
      onPrimary: "#FFFFFF",
      primaryLight: "#56c8d8",
      onPrimaryLight: "#FFFFFF",
      primaryDark: "#006978",
      onPrimaryDark: "#FFFFFF",
      secondary: "#c62828",
      onSecondary: "#FFFFFF",
      secondaryLight: "#ff5f52",
      onSecondaryLight: "#FFFFFF",
      secondaryDark: "#8e0000",
      onSecondaryDark: "#FFFFFF",
      background: "#1b1b1b",
      onBackground: "#FFFFFF",
      surface: "#212121",
      onSurface: "#FFFFFF",
      error: "#e53935",
      onError: "#FFFFFF",
    },
    components: {
      header: {
        colors: {
          // background: "#00FFFF",
          // onBackground: "#00FFFF"
        },
      },
      sidebar: {
        colors: {
          // background: "#00FFFF",
          // onBackground: "#00FFFF"
        },
      },
    },
  },
  light: {
    colors: {
      primary: "#0097a7",
      onPrimary: "#FFFFFF",
      primaryLight: "#56c8d8",
      onPrimaryLight: "#FFFFFF",
      primaryDark: "#006978",
      onPrimaryDark: "#FFFFFF",
      secondary: "#c62828",
      onSecondary: "#FFFFFF",
      SecondaryLight: "#ff5f52",
      onSecondaryLight: "#FFFFFF",
      secondaryDark: "#8e0000",
      onSecondaryDark: "#FFFFFF",
      background: "#F6F6F6",
      onBackground: "#1c1c1c",
      surface: "#FFFFFF",
      onSurface: "#1c1c1c",
      surfaceLight: "#F6F6F6",
      error: "#e53935",
      onError: "#FFFFFF",
    },
  },
};
// if (true || window.location.hostname.indexOf("exela.") !== -1) {
//   theme = {
//     default: {
//       colors: {
//         primary: "#00acc1",
//         onPrimary: "#FFFFFF",
//         primaryLight: "#5ddef4",
//         onPrimaryLight: "#FFFFFF",
//         primaryDark: "#005662",
//         onPrimaryDark: "#FFFFFF",
//         secondary: "#bc1683",
//         onSecondary: "#FFFFFF",
//         secondaryLight: "#f256b3",
//         onSecondaryLight: "#FFFFFF",
//         secondaryDark: "#870056",
//         onSecondaryDark: "#FFFFFF",
//         background: "#202020",
//         onBackground: "#FFFFFF",
//         surface: "#262626",
//         onSurface: "#FFFFFF",
//         error: "#B00020",
//         onError: "#FFFFFF",
//         header: "#313131",
//         onHeader: "#FFFFFF",
//       },
//       components: {
//         logo: {
//           options: {
//             company: "exela",
//           },
//         },
//       },
//     },
//     light: {
//       colors: {
//         primary: "#397ea0",
//         onPrimary: "#FFFFFF",
//         primaryLight: "#4dc5f2",
//         onPrimaryLight: "#FFFFFF",
//         primaryDark: "#397ea0",
//         onPrimaryDark: "#FFFFFF",
//         secondary: "#bc1683",
//         onSecondary: "#FFFFFF",
//         secondaryLight: "#870056",
//         onSecondaryLight: "#FFFFFF",
//         secondaryDark: "#f256b3",
//         onSecondaryDark: "#FFFFFF",
//         background: "#f4f4f4",
//         onBackground: "#151b26",
//         surface: "#FFFFFF",
//         onSurface: "#151b26",
//         error: "#B00020",
//         onError: "#FFFFFF",
//         header: "#FFFFFF",
//         onHeader: "#1c1c1c",
//       },
//       components: {
//         logo: {
//           options: {
//             company: "exela",
//           },
//         },
//       },
//     },
//   };
// }

export default theme;
