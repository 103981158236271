import R14 from "../core";

export default class DocumentTemplateUiDomain extends R14.DomainInstances {
  constructor(key) {
    super();
    this.ANNOTATION_TYPE_FIELD = "FIELD";
    this.BOTTOM_SHEET_SIZE_MODE_RESTORE = "BOTTOM_SHEET_SIZE_MODE_RESTORE";
    this.BOTTOM_SHEET_SIZE_MODE_MAXIMIZE = "BOTTOM_SHEET_SIZE_MODE_MAXIMIZE";
    this.BOTTOM_SHEET_SIZE_MODE_MINIMIZE = "BOTTOM_SHEET_SIZE_MODE_MINIMIZE";
  }
  async instance(uid, options = {}) {
    if (this.exists(uid)) return this.getInstance(uid);
    let documentTemplate = new DocumentTemplateUiInstanceDomain(
      this,
      uid,
      options
    );
    await documentTemplate.init();
    this.addInstance(uid, documentTemplate);
    return documentTemplate;
  }
  clearInstances() {
    this.forEach((inst) => {
      inst.remove();
    });
  }
}

class DocumentTemplateUiInstanceDomain extends R14.Domain {
  constructor(documentTemplates, uid, options = {}) {
    super();
    this._uid = uid;
    this._documentTemplate = null;
    this._documentTemplates = documentTemplates;
    this._annotationLayout = null;
    this._padding = 32;
    this.state = {
      layout: false,
      height: 0,
      width: 0,
      scale: 1,
      zoomLevel: 1,
      annotate: false,
      annotationType: null,
      screenLayoutHeight: null,
      screenLayoutWidth: null,
      bottomSheetHeight: null,
      bottomSheetMode: this.dm.documentTemplateAnnotation
        .BOTTOM_SHEET_SIZE_MODE_RESTORE,
    };
  }
  remove() {
    this._documentTemplates.removeInstance(this._uid);
  }
  get uid() {
    return this._uid;
  }
  get scale() {
    return this.state.scale;
  }
  get layout() {
    return this.state.layout;
  }
  get annotationType() {
    return this.state.annotationType;
  }
  get name() {
    return this._documentTemplate.name;
  }
  get project() {
    return this._documentTemplate ? this._documentTemplate.project : null;
  }
  get image() {
    return this._documentTemplate ? this._documentTemplate.image : null;
  }
  get bottomSheet() {
    return {
      height: this.state.bottomSheetHeight,
      mode: this.state.bottomSheetMode,
    };
  }
  get padding() {
    return this._padding;
  }
  get annotationLayout() {
    return this._annotationLayout;
  }
  get annotations() {
    return this._documentTemplate &&
      this._documentTemplate.annotations &&
      this._documentTemplate.annotations.nodes
      ? this._documentTemplate.annotations.nodes
      : [];
  }
  async init() {
    console.log("HERE?");
    let documentTemplate = await this.dm.documentTemplate.get(
      this._uid,
      `
    uid
    key
    name
    description
    image {
      key
      height
      width
    }
    project {
      uid
      type
      name
    }
    annotations {
      nodes {
        uid
        name
        description
        type
        x
        y
        height
        width
      }
    }
    `
    );
    this._documentTemplate = documentTemplate;
  }
  calculateScale() {
    let scale = 1;
    let imageHeight = this._documentTemplate.image.height;
    let imageWidth = this._documentTemplate.image.width;
    if (this.state.height && this.state.width && imageWidth && imageHeight) {
      scale = this.state.width / imageWidth;
      // if (imageHeight > imageWidth) {
      //   scale = this.state.height / imageHeight;
      //   if (imageWidth * scale > this.state.width)
      //     scale = this.state.width / imageWidth;
      // } else {
      //   scale = this.state.width / imageWidth;
      //   if (imageHeight * scale > this.state.height)
      //     scale = this.state.height / imageHeight;
      // }
    }
    return scale;
  }
  setAnnotationLayout(annotationLayout) {
    this._annotationLayout = annotationLayout;
  }
  getAnnotationLayout() {
    return this._annotationLayout;
  }
  setAnnotationMode(annotationType) {
    console.log("annotationType", annotationType);
    this.setState({
      annotate: annotationType ? true : false,
      annotationType: annotationType || null,
    });
    this._annotationLayout = null;
  }
  disableAnnotationMode() {
    console.log("disableAnnotationMode here here here");
    this.setAnnotationMode(null);
  }
  updateLayout({ height, width }) {
    this.setState({
      layout: true,
      height: height - this.padding * 2,
      width: width - this.padding * 2,
      scale: this.calculateScale(),
      //prevScale: this.state.scale
    });
  }
  updateScreenLayout({ height, width }) {
    let nState = {
      screenLayoutHeight: height,
      screenLayoutWidth: width,
    };
    if (!this.state.bottomSheetMode) {
      // Init the bottom sheet
      screenLayoutHeight: {
      }
      nState.bottomSheetMode = this.dm.documentTemplateAnnotation.BOTTOM_SHEET_SIZE_MODE_RESTORE;
      nState.bottomSheetHeight = height / 3;
    }
    this.setState(nState);
  }
  setBottomSheetHeight(height) {
    this.setState({
      bottomSheetHeight: height,
    });
  }

  setZoomLevel(zoomLevel) {
    this.setState({
      zoomLevel,
    });
  }
  zoomIn() {
    let zoomLevel = this.state.zoomLevel;
    zoomLevel += 0.1;
    if (zoomLevel < 2) this.setZoomLevel(zoomLevel);
  }
  zoomOut() {
    let zoomLevel = this.state.zoomLevel;
    zoomLevel -= 0.1;
    if (zoomLevel > -2) this.setZoomLevel(zoomLevel);
  }
  handleSelectAnnotationArea(selectArea) {
    let layout = null;
    if (selectArea) {
      let { x, y, height, width } = selectArea;
      let imageScaleHeight =
        this.image.height * this.state.scale * this.state.zoomLevel;
      let imageScaleWidth =
        this.image.width * this.state.scale * this.state.zoomLevel;
      layout = {
        x: x / imageScaleWidth,
        y: y / imageScaleHeight,
        height: height / imageScaleHeight,
        width: width / imageScaleWidth,
      };
    }
    this.setAnnotationLayout(layout);
  }
  getAnnotationColorByType(type) {
    return this.dm.documentTemplateAnnotation.getColorByType(type);
  }
}
