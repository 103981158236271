import R14 from "../core";
export default class ManualEntryUserPaymentDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryUserPayment",
      pluralName: "manualEntryUserPayments",
    });
    this.state = {};
   
  }

}
