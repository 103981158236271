import R14 from "../core";
export default class ManualEntryAccuracyTestDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryAccuracyTest",
    });
    this.state = {};
  }
  async fetchEditFormData(uid = null, options = {}) {
    let res = uid
      ? await this.get(uid, ["uid", "imageBase64", "value", "actualValue"])
      : {};
    res.mode = this.dm.manualEntry.MODE_CHARACTERS;
    let formData = { values: res };
    console.log(formData);
    return formData;
  }
  parseSubmitValues(values) {
    let updateValues = { ...values };
    updateValues.clientUid = this.dm.userSession.clientUid;
    return updateValues;
  }
}
