import R14 from "../core";

export default class EventDomain extends R14.Domain {
  constructor(key) {
    super();
    // this.STATE_INIT = "INIT";
    // this.STATE_PAUSED = "PAUSED";
    // this.STATE_QUEUED = "QUEUED";
    // this.STATE_COMPLETE = "COMPLETE";
    // this.STATE_ERROR = "ERROR";

    this.TARGET_TYPE_RESOURCE = "RESOURCE";
    this.TARGET_TYPE_PROJECT = "PROJECT";
    this.TARGET_TYPE_PIPELINE_BLOCK = "PIPELINE_BLOCK";

    this.TYPE_STOP = "STOP";
    this.TYPE_START = "START";
    this.TYPE_REBOOT = "REBOOT";
    this.TYPE_SCRIPT = "SCRIPT";
    this.TYPE_ALERT = "ALERT";

    this.OCCURS_METRIC = "METRIC";
    this.OCCURS_FREQUENCY = "FREQUENCY";
    this.OCCURS_EVENT = "EVENT";
    this.OCCURS_STATUS_CHECK = "STATUS_CHECK";
    this.OCCURS_PROJECT_HEALTH_CHECK = "PROJECT_HEALTH_CHECK";
    this.OCCURS_PROCESS_METRIC = "PROCESS_METRIC";
    this.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD =
      "PIPELINE_BLOCK_INPUT_THRESHOLD";
    this.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD = "PIPELINE_BLOCK_DATA_THRESHOLD";

    this.EVENT_RESOURCE_BEFORE_STOP = "BEFORE_STOP";
    this.EVENT_RESOURCE_BEFORE_REBOOT = "BEFORE_REBOOT";
    this.EVENT_RESOURCE_AFTER_START = "AFTER_START";
    this.EVENT_RESOURCE_AFTER_REBOOT = "AFTER_REBOOT";

    this.FREQUENCY_ONCE = "ONCE";
    this.FREQUENCY_DAILY = "DAILY";
    this.FREQUENCY_DAY = "DAY";
    this.FREQUENCY_INTERVAL = "INTERVAL";
    this.FREQUENCY_INTERVAL_SECOND = "INTERVAL_SECOND";
    this.FREQUENCY_INTERVAL_MINUTE = "INTERVAL_MINUTE";
    this.FREQUENCY_INTERVAL_HOUR = "INTERVAL_HOUR";
    this.FREQUENCY_INTERVAL_DAY = "INTERVAL_DAY";

    this.METRIC_CPU_UTILIZATION = "CPU_UTILIZATION";
    this.METRIC_DISK_READ_OPS = "DISK_READ_OPS";
    this.METRIC_DISK_WRITE_OPS = "DISK_WRITE_OPS";
    this.METRIC_DISK_READ_BYTES = "DISK_READ_BYTES";
    this.METRIC_DISK_WRITE_BYTES = "DISK_WRITE_BYTES";
    this.METRIC_NETWORK_IN = "NETWORK_IN";
    this.METRIC_NETWORK_OUT = "NETWORK_OUT";
  }
  async find(fieldsStr, options = null) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindLogs($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: EventFilter) {
        events(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.events;
  }

  async create(
    values,
    { type, resourceUid = null, projectUid = null, pipelineBlockUid = null }
  ) {
    // Check for resourceUid. If exists, create eventTask
    if (resourceUid || projectUid || pipelineBlockUid) {
      values.eventTasks = {
        create: [
          {
            type: type,
            projectUid: projectUid,
            resourceUid: resourceUid,
            pipelineBlockUid: pipelineBlockUid,
            clientUid: this.dm.userSession.clientUid,
          },
        ],
      };
    }
    values.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation CreateEvent($input: CreateEventInput!) {
        createEvent(input: $input){
          event {
            uid
            name
            description
          }
        }
      }`,
      {
        input: this._parseEditValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateEvent($input: UpdateEventInput!, $eventUid: ID!) {
        updateEvent(input: $input){
          event {
            uid
            name
            description
          }
        }
        initEventTasks(eventUid: $eventUid){
          success
        }
      }`,
      {
        input: this._parseEditValues(values),
        eventUid: values.uid,
      }
    );
    return !res.errors.length || false;
  }
  createScheduleNode({
    occurs,
    timeOnce = null,
    timeDay = null,
    timeDaily = null,
    timeInterval = null,
    time = null,
    date = null,
    days = null,
    interval = null,
    intervalType = null,
    metric = null,
    value = null,
    operator = null,
    period = null,
    event = null,
    statusCheck = null,
    projectHealthCheck = null,
    blockIoUid = null,
    blockDataKey = null,
    threshold = null,
    maxInstances = null,
    inputItemsPerInstance = null,
    state = null,
    name = null,
  }) {
    let currDate = new Date();
    let scheduleNode = {};

    console.log("CREATE SCHDULE NODE", occurs);

    switch (occurs) {
      case this.FREQUENCY_ONCE:
        scheduleNode.occurs = this.OCCURS_FREQUENCY;
        scheduleNode.frequency = {
          type: this.FREQUENCY_ONCE,
          at: new Date(`${date} ${time || timeOnce}`),
        };
        break;
      case this.FREQUENCY_DAILY:
        scheduleNode.occurs = this.OCCURS_FREQUENCY;
        scheduleNode.frequency = {
          type: this.FREQUENCY_DAILY,
          at: new Date(
            `${this.utils.date.toShortDate(currDate)} ${time || timeDaily}`
          ),
        };
        break;
      case this.FREQUENCY_INTERVAL:
        scheduleNode.occurs = this.OCCURS_FREQUENCY;
        scheduleNode.frequency = {
          type: intervalType,
          value: interval,
          at: new Date(
            `${this.utils.date.toShortDate(currDate)} ${time || timeInterval}`
          ),
        };
        break;
      case this.FREQUENCY_DAY:
        scheduleNode.occurs = this.OCCURS_FREQUENCY;
        scheduleNode.frequency = {
          type: this.FREQUENCY_DAY,
          at: new Date(
            `${this.utils.date.toShortDate(currDate)} ${time || timeDay}`
          ),
          values: days,
        };
        break;
      case this.OCCURS_METRIC:
        scheduleNode.occurs = this.OCCURS_METRIC;
        scheduleNode.metric = {
          type: metric,
          operator: operator,
          value: value,
          period: parseInt(period),
        };
        break;
      case this.OCCURS_PROCESS_METRIC:
        scheduleNode.occurs = this.OCCURS_PROCESS_METRIC;
        scheduleNode.processMetric = {
          name: name,
          type: metric,
          operator: operator,
          value: value,
          period: parseInt(period),
        };
        break;
      case this.OCCURS_STATUS_CHECK:
        scheduleNode.occurs = this.OCCURS_STATUS_CHECK;
        scheduleNode.statusCheck = {
          uid: statusCheck,
          state: state,
        };
        break;
      case this.OCCURS_PROJECT_HEALTH_CHECK:
        scheduleNode.occurs = this.OCCURS_PROJECT_HEALTH_CHECK;
        scheduleNode.projectHealthCheck = {
          uid: projectHealthCheck,
          state: state,
        };
        break;
      case this.OCCURS_EVENT:
        scheduleNode.occurs = this.OCCURS_EVENT;
        scheduleNode.event = {
          type: event,
        };
        break;
      case this.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD:
        scheduleNode.occurs = this.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD;
        scheduleNode.blockIo = {
          uid: blockIoUid,
          threshold: parseInt(threshold),
          maxInstances: maxInstances ? parseInt(maxInstances) : 1,
          inputItemsPerInstance: inputItemsPerInstance
            ? parseInt(inputItemsPerInstance)
            : null,
        };
        break;
      case this.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD:
        scheduleNode.occurs = this.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD;
        scheduleNode.blockData = {
          key: blockDataKey,
          threshold: parseInt(threshold),
          maxInstances: maxInstances ? parseInt(maxInstances) : 1,
          inputItemsPerInstance: inputItemsPerInstance
            ? parseInt(inputItemsPerInstance)
            : null,
        };
        break;
    }
    return scheduleNode;
  }
  createTriggerNode({ type, scriptUid = null, contactGroupUid = null }) {
    let triggerNode = { type: type };
    switch (type) {
      case "ALERT":
        triggerNode.contactGroupUid = contactGroupUid;
        break;
      case "SCRIPT":
        triggerNode.scriptUid = scriptUid;
        break;
    }

    return triggerNode;
  }
  _parseEditValues(values) {
    let ret = {
      name: values.name,
      description: values.description || null,
      triggers: [],
      schedule: [],
      //resourceUids: values.resourceUids || null,
      //state: values.state || this.STATE_INIT,
    };
    if (values.uid) ret.uid = values.uid;
    if (values.clientUid) ret.clientUid = values.clientUid;
    if (values.eventTasks) ret.eventTasks = values.eventTasks;

    let scheduleNodes = values.schedule.length
      ? values.schedule
      : [this.createScheduleNode(values)];

    // Remove any null value
    ret.schedule = scheduleNodes.map((node) => {
      let nNode = {};
      for (let i in node) {
        if (node[i] !== null) nNode[i] = node[i];
      }
      return nNode;
    });
    let triggerNodes = values.triggers.length
      ? values.triggers
      : [this.createTriggerNode(values)];
    ret.triggers = triggerNodes;

    return ret;
  }
  async get(uid) {
    let res = await this.api.qry(
      `
      query GetEvent($uid: ID!) {
       event(uid: $uid){
          uid
          name
          description
        }
      }`,
      {
        uid: uid,
      }
    );
    return res.data.event;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteEvent($uid: ID!) {
        deleteEvent(uid: $uid){
          event {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  getFrequencyDaySelections() {
    return [1, 2, 3, 4, 5, 6, 0].map((d) => ({
      label: this.utils.date.getDayLabel(d),
      value: `${d}`,
    }));
  }
  getFrequencyDaySelections() {
    return [1, 2, 3, 4, 5, 6, 0].map((d) => ({
      label: this.utils.date.getDayLabel(d),
      value: `${d}`,
    }));
  }
  getIntervalTypeSelections() {
    return [
      {
        label: "Second(s)",
        value: this.FREQUENCY_INTERVAL_SECOND,
      },
      {
        label: "Minute(s)",
        value: this.FREQUENCY_INTERVAL_MINUTE,
      },
      {
        label: "Hour(s)",
        value: this.FREQUENCY_INTERVAL_HOUR,
      },
      // {
      //   label: "Day(s)",
      //   value: this.FREQUENCY_INTERVAL_DAY,
      // },
    ];
  }
  getOccursSelections(targetType) {
    let ret = [
      {
        label: "Once",
        value: this.FREQUENCY_ONCE,
      },
      {
        label: "Daily",
        value: this.FREQUENCY_DAILY,
      },
      {
        label: "By Day",
        value: this.FREQUENCY_DAY,
      },
      {
        label: "By Interval",
        value: this.FREQUENCY_INTERVAL,
      },
    ];
    switch (targetType) {
      case this.TARGET_TYPE_PIPELINE_BLOCK:
        ret = [
          {
            label: "By Input Threshold",
            value: this.OCCURS_PIPELINE_BLOCK_INPUT_THRESHOLD,
          },
          {
            label: "By Data Threshold",
            value: this.OCCURS_PIPELINE_BLOCK_DATA_THRESHOLD,
          },
          ...ret,
        ];
        break;
      case this.TARGET_TYPE_PROJECT:
        ret = [
          ...ret,
          {
            label: "By Metric",
            value: this.OCCURS_METRIC,
          },
          {
            label: "By Process Metric",
            value: this.OCCURS_PROCESS_METRIC,
          },
          {
            label: "By Status Check",
            value: this.OCCURS_STATUS_CHECK,
          },
          {
            label: "By Health Check",
            value: this.OCCURS_PROJECT_HEALTH_CHECK,
          },
          {
            label: "On Event",
            value: this.OCCURS_EVENT,
          },
        ];
        break;
    }
    return ret;
  }

  async fetchEditFormData(uid = null, resourceUid = null) {
    let qry = "";
    let qryVals = {
      resourceFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      scriptFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      alertFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      statusCheckFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      projectHealthCheckFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      contactGroupFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
    };
    let resourceQry = "";
    let resourceParams = "";
    if (resourceUid) {
      qryVals.resourceUid = resourceUid;
      resourceParams = ", $resourceUid: ID!";
      resourceQry = `
        resource(uid: $resourceUid){
          uid
          type
        }
      `;
    }

    if (uid) {
      qry = `
        query EventEditFormData($uid: ID!, $resourceFilter: ResourceFilter, $scriptFilter: ScriptFilter, $alertFilter: AlertFilter, $statusCheckFilter: StatusCheckFilter, $projectHealthCheckFilter: ProjectHealthCheckFilter, $contactGroupFilter: ContactGroupFilter${resourceParams}) {
          event(uid: $uid){
            uid
            name
            description
            triggers {
              type
              scriptUid
              contactGroupUid
            }
            schedule {
              occurs
              frequency {
                type
                value
                values
                at
              }
              metric {
                type
                operator
                value
                period
              }
              processMetric {
                name
                type
                operator
                value
                period
              }
              event {
                type
              }
              statusCheck {
                uid
                state
              }
              projectHealthCheck {
                uid
                state
              }
            }
          },
          ${resourceQry}
          resources(filter:$resourceFilter) {
            nodes {
              uid
              name
            }
          }
          scripts(filter:$scriptFilter) {
            nodes {
              uid
              name
            }
          }
          alerts(filter:$alertFilter) {
            nodes {
              uid
              name
            }
          }
          statusChecks(filter:$statusCheckFilter) {
            nodes {
              uid
              name
            }
          }
          projectHealthChecks(filter:$projectHealthCheckFilter) {
            nodes {
              uid
              name
            }
          }
          contactGroups(filter:$contactGroupFilter) {
            nodes {
              uid
              name
            }
          }
        }
      `;
      qryVals.uid = uid;
    } else {
      qry = `
        query EventCreateFormData($resourceFilter: ResourceFilter, $scriptFilter: ScriptFilter, $alertFilter: AlertFilter, $statusCheckFilter: StatusCheckFilter, $projectHealthCheckFilter: ProjectHealthCheckFilter, $contactGroupFilter: ContactGroupFilter${resourceParams}) {
          resources(filter:$resourceFilter) {
            nodes {
              uid
              name
            }
          }
          scripts(filter:$scriptFilter) {
            nodes {
              uid
              name
            }
          }
          alerts(filter:$alertFilter) {
            nodes {
              uid
              name
            }
          }
          statusChecks(filter:$statusCheckFilter) {
            nodes {
              uid
              name
            }
          }
          projectHealthChecks(filter:$projectHealthCheckFilter) {
            nodes {
              uid
              name
            }
          }
          contactGroups(filter:$contactGroupFilter) {
            nodes {
              uid
              name
            }
          }
          ${resourceQry}
        }
      `;
    }
    let res = await this.api.qry(qry, qryVals);

    let formVals = res.data.event || {};
    if (!formVals.schedule) formVals.schedule = [];
    if (!formVals.triggers) formVals.triggers = [];

    let periodSelections = [];
    for (let i = 5; i <= 60; i += 5) {
      periodSelections.push({
        label: `${i}`,
        value: `${i}`,
      });
    }
    let resourceType = res.data.resource ? res.data.resource.type : null;
    let formData = {
      values: formVals,
      typeSelections: this.getTypeSelections(),
      occursSelections: [
        {
          label: "Once",
          value: this.FREQUENCY_ONCE,
        },
        {
          label: "Daily",
          value: this.FREQUENCY_DAILY,
        },
        {
          label: "By Day",
          value: this.FREQUENCY_DAY,
        },
        {
          label: "By Metric",
          value: this.OCCURS_METRIC,
        },
        {
          label: "By Process Metric",
          value: this.OCCURS_PROCESS_METRIC,
        },
        {
          label: "By Status Check",
          value: this.OCCURS_STATUS_CHECK,
        },
        {
          label: "By Health Check",
          value: this.OCCURS_PROJECT_HEALTH_CHECK,
        },
        {
          label: "On Event",
          value: this.OCCURS_EVENT,
        },
      ],
      eventSelections: [
        {
          label: this.getEventLabel(this.EVENT_RESOURCE_AFTER_START),
          value: this.EVENT_RESOURCE_AFTER_START,
        },
        {
          label: this.getEventLabel(this.EVENT_RESOURCE_AFTER_REBOOT),
          value: this.EVENT_RESOURCE_AFTER_REBOOT,
        },
        {
          label: this.getEventLabel(this.EVENT_RESOURCE_BEFORE_STOP),
          value: this.EVENT_RESOURCE_BEFORE_STOP,
        },
        {
          label: this.getEventLabel(this.EVENT_RESOURCE_BEFORE_REBOOT),
          value: this.EVENT_RESOURCE_BEFORE_REBOOT,
        },
      ],
      metricSelections: this.dm.resource.getMetricSelections(resourceType),
      processMetricSelections: this.dm.resource.getProcessMetricSelections(),
      operatorSelections: [
        {
          label: "Greater Than",
          value: ">",
        },
        {
          label: "Greater Than or Equal",
          value: ">=",
        },
        {
          label: "Less Than",
          value: "<",
        },
        {
          label: "Less Than or Equal",
          value: "<=",
        },
        {
          label: "Equal",
          value: "=",
        },
      ],
      periodSelections: periodSelections,
      schedule: formVals.schedule,
      daySelections: this.getFrequencyDaySelections(),
      resourceSelections: res.data.resources.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      scriptSelections: res.data.scripts.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      contactGroupSelections: res.data.contactGroups.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      statusCheckSelections: res.data.statusChecks.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      statusCheckStateSelections: this.dm.statusCheck.getStateSelections(),
      projectHealthCheckSelections: res.data.projectHealthChecks.nodes.map(
        (val) => ({
          label: val.name,
          value: val.uid,
        })
      ),
      projectHealthCheckStateSelections:
        this.dm.healthCheck.getStateSelections(),
    };
    return formData;
  }
  getTypeSelections() {
    return [
      {
        label: this.getTypeLabel(this.TYPE_STOP),
        value: this.TYPE_STOP,
      },
      {
        label: this.getTypeLabel(this.TYPE_START),
        value: this.TYPE_START,
      },
      {
        label: this.getTypeLabel(this.TYPE_REBOOT),
        value: this.TYPE_REBOOT,
      },
      {
        label: this.getTypeLabel(this.TYPE_SCRIPT),
        value: this.TYPE_SCRIPT,
      },
      {
        label: this.getTypeLabel(this.TYPE_ALERT),
        value: this.TYPE_ALERT,
      },
    ];
  }
  getStateSelections() {
    return [
      { value: this.STATE_INIT, label: "Initializing" },
      { value: this.STATE_QUEUED, label: "Queued" },
      { value: this.STATE_ERROR, label: "Error" },
      { value: this.STATE_COMPLETE, label: "Complete" },
      { value: this.STATE_PAUSED, label: "Paused" },
    ];
  }
  getTypeLabel(occurs) {
    let ret = "";
    switch (occurs) {
      case this.TYPE_START:
        ret = "Start";
        break;
      case this.TYPE_STOP:
        ret = "Stop";
        break;
      case this.TYPE_REBOOT:
        ret = "Reboot";
        break;
      case this.TYPE_SCRIPT:
        ret = "Script";
        break;
      case this.TYPE_ALERT:
        ret = "Alert";
        break;
    }
    return ret;
  }
  getEventLabel(event) {
    let ret = "";
    switch (event) {
      case this.EVENT_RESOURCE_AFTER_START:
        ret = "After Start";
        break;
      case this.EVENT_RESOURCE_AFTER_REBOOT:
        ret = "After Reboot";
        break;
      case this.EVENT_RESOURCE_BEFORE_STOP:
        ret = "Before Stop";
        break;
      case this.EVENT_RESOURCE_BEFORE_REBOOT:
        ret = "Before Reboot";
        break;
    }
    return ret;
  }

  // getStateIndicatorColor(state) {
  //   let color = "red";
  //   switch (state) {
  //     case this.STATE_INIT:
  //     case this.STATE_PAUSED:
  //       color = "yellow";
  //       break;
  //     case this.STATE_QUEUED:
  //       color = "green";
  //       break;
  //   }
  //   return color;
  // }
  // getStateLabel(state) {
  //   let label = null;
  //   switch (state) {
  //     case this.STATE_INIT:
  //       label = "Initializing";
  //       break;
  //     case this.STATE_QUEUED:
  //       label = "Queued";
  //       break;
  //     case this.STATE_ERROR:
  //       label = "Error";
  //       break;
  //     case this.STATE_COMPLETE:
  //       label = "Complete";
  //       break;
  //     case this.STATE_PAUSED:
  //       label = "Paused";
  //       break;
  //   }
  //   return label;
  // }
}
