import R14 from "../core";
export default class ManualEntryPaymentBatchDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryUserPaymentBatch",
      pluralName: "manualEntryUserPaymentBatches",
    });
    this.state = {};
    // this.UPDATE_FREQUENCY_TYPE_INTERVAL_MINUTES = "INTERVAL_MINUTES";
    // "ONCE", "DAILY", "DAY", "INTERVAL_HOUR"
    this.STATE_INIT = "INIT";
    this.STATE_QUEUED = "QUEUED";
    this.STATE_PROCESSING = "PROCESSING";
    this.STATE_ERROR = "ERROR";
    this.STATE_COMPLETE = "COMPLETE";
  }

  async get(uid) {
    return await super.get(
      uid,
      `
    uid
    name
    description
    startAtDate
    endAtDate
    processAtDate
    startAtTime
    endAtTime
    processAtTime
    state
    manualEntryConfigs {
      nodes {
        name
        uid
      }
    }
    csvFile {
      url
    }
`
    );
  }

  async fetchEditFormData(uid = null, options = {}) {
    let fields = `
  uid
  name
  description
  startAtDate
  endAtDate
  processAtDate
  startAtTime
  endAtTime
  processAtTime
  manualEntryConfigs {
    nodes {
      name
      uid
    }
  }
  
`;
    let res = uid ? await this.get(uid, fields) : {};
    console.log("CHECK RES?", res);
    let values = {};
    if (uid && res && res.uid) {
      values = {
        ...res,
        manualEntryConfigs:
          res.manualEntryConfigs && res.manualEntryConfigs.nodes
            ? res.manualEntryConfigs.nodes.map((config) => ({
                label: config.name,
                value: config.uid,
              }))
            : null,
      };
    }
    // values = {
    //   name: "test",
    //   description: "asdf",
    //   manualEntryConfigs: [{ label: "IDP UAT", value: "V9scdbcub" }],
    //   processAtDate: "1/4/2022",
    //   processAtTime: "1:00 PM",
    //   startAtDate: "1/3/2022",
    //   startAtTime: "12:00 AM",
    //   endAtDate: "1/4/2022",
    //   endAtTime: "12:00 AM",
    // };
    let formData = {
      values,
      // modeSelections: [
      //   {
      //     label: this.getModeLabel(this.MODE_AVERAGE),
      //     value: this.MODE_AVERAGE,
      //   },
      //   {
      //     label: this.getModeLabel(this.MODE_FIXED),
      //     value: this.MODE_FIXED,
      //   },
      // ],
    };
    return formData;
  }
  parseSubmitValues(values) {
    // Figure out pricing
    let ret = { ...values, clientUid: this.dm.userSession.clientUid };
    ret.manualEntryConfigUids = values.manualEntryConfigs.map(
      (config) => config.value
    );
    delete ret.manualEntryConfigs;
    return ret;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_INIT:
        label = "Initializing";
        break;
      case this.STATE_QUEUED:
        label = "Queued";
        break;
      case this.STATE_PROCESSING:
        label = "Processing";
        break;
      case this.STATE_COMPLETE:
        label = "Complete";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_INIT:
      case this.STATE_QUEUED:
      case this.PROCESSING:
        color = "yellow";
        break;
      case this.STATE_COMPLETE:
        color = "green";
        break;
      case this.STATE_ERROR:
        color = "red";
        break;
    }
    return color;
  }
}
