import R14 from "../core";
export default class VirtualWorkspaceConfigDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "virtualWorkspaceConfig",
    });
  }
  async fetchEditFormData(uid = null, options = {}) {
    let fields = `
  uid
  name
  key
  description
  virtualWorkspaces {
    nodes {
      uid
      name
    }
  }
`;
    let formDataValues = {};
    if (uid) {
      let res = await this.get(uid, fields);
      formDataValues = {
        uid: res.uid,
        name: res.name,
        description: res.description,
        virtualWorkspaces:
          res.virtualWorkspaces && res.virtualWorkspaces.nodes
            ? res.virtualWorkspaces.nodes.map((virtualWorkspace) => ({
                label: virtualWorkspace.name,
                value: virtualWorkspace.uid,
              }))
            : [],
      };
    }
    return {
      values: formDataValues,
    };
  }
  parseSubmitValues(values) {
    if (values.virtualWorkspaces) {
      values.virtualWorkspaceUids = values.virtualWorkspaces.map(
        (virtualWorkspace) => virtualWorkspace.value
      );
      delete values.virtualWorkspaces;
    }
    values.clientUid = this.dm.userSession.clientUid;
    return values;
  }
}
