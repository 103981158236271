import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  Button,
  Form,
  SubmitButton,
  StyleSheet,
} from "../core";
export default R14.connect(
  class EditForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      if (!this.props.domainName)
        throw new Error("Domain name required for EditForm.");
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formName(){
      return this.props.name || `${this.props.domainName}Form`
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form(this.formName);
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode
        ? this.props.updateMethod || "update"
        : this.props.createMethod || "create";
      let ret = null;
      let opts = this.props.saveOptions || {};
      // if (this.props.resourceUid) opts.resourceUid = this.props.resourceUid;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = this.props.onSave
          ? await this.props.onSave(form, opts)
          : await this.props.app.dm[this.props.domainName][editMethod](
              form.values,
              opts
            );
        if (this.props.onAfterSave)
          await this.props.onAfterSave(isSuccess, form, opts);
        if (isSuccess) {
          if (this.props.refreshDataTable) {
            let dataTable = this.props.refreshDataTable;
            this.props.app.ui.dataTable.exists(dataTable) &&
              (await this.props.app.ui.dataTable(dataTable).refresh());
          }
          if (this.props.redirect) ret = <Redirect to={this.props.redirect} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name={this.formName}
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          validators={this.props.validators || null}
          initialValues={this.formData.values}
          style={[styles.editForm, this.props.style]}
          controlsBottomRight={[
            <Button
              title='Cancel'
              key='cancel'
              variant='text'
              onPress={() =>
                this.props.redirect
                  ? this.props.app.nav.to(this.props.redirect)
                  : this.props.app.nav.back()
              }
            />,
            <SubmitButton
              title={
                this.props.submitButtonLabel ||
                (this.isEditMode ? "Update" : "Create")
              }
              style={styles.submitButton}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          {this.props.children}
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  editForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          width: null,
        };
      }
    },
  },
  submitButton: {
    marginLeft: 4,
  },
});
