import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  HiddenInputField,
  FieldGroup,
  Text,
  StyleSheet
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";

export default R14.connect(
  class DocumentTemplateEditScreen extends React.Component {
    constructor(props) {
      super(props);
      console.log("USE SECURE IMAGE!");
    }
    get isEditMode() {
      return this.props.formData &&
        this.props.formData.values &&
        this.props.formData.values.uid
        ? true
        : false;
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='documentTemplateAnnotation'
          redirect={this.props.redirect}
        >
          {!this.isEditMode && <HiddenInputField name='documentTemplateUid' />}
          {!this.isEditMode && <HiddenInputField name='type' />}
          {!this.isEditMode && <HiddenInputField name='x' />}
          {!this.isEditMode && <HiddenInputField name='y' />}
          {!this.isEditMode && <HiddenInputField name='height' />}
          {!this.isEditMode && <HiddenInputField name='width' />}
          

          {/* <LabelView
            style={styles.labelView}
            key='label'
            label='Type'
          >
            <Text>Field</Text>
          </LabelView> */}
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
              autoFocus
            />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              validator='key'
            />
          </FieldGroup>

          <TextInputField name='description' label='Description' helper="Optional"/>
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  // labelView: {
  //   width: "25%",
  //   minWidth: 0,
  //   flex: 1,
  // },
});
