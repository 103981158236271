import R14 from "../core";
export default class ManualEntryDomain extends R14.Domain {
  constructor(key) {
    super();
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";
    this.METRIC_TYPE_TOTAL = "TOTAL";
    this.METRIC_TYPE_FIELDS_ACCEPTED = "FIELDS_ACCEPTED";
    this.METRIC_TYPE_FIELDS_REJECTED = "FIELDS_REJECTED";
    this.METRIC_TYPE_FIELDS_UPDATED = "FIELDS_UPDATED";
    this.METRIC_TYPE_FIELDS_TOTAL = "FIELDS_TOTAL";
    this.METRIC_TYPE_CHARACTERS_ACCEPTED = "CHARACTERS_ACCEPTED";
    this.METRIC_TYPE_CHARACTERS_REJECTED = "CHARACTERS_REJECTED";
    this.METRIC_TYPE_CHARACTERS_TOTAL = "CHARACTERS_TOTAL";
    this.METRIC_TYPE_FIELDS_UPDATED = "FIELDS_UPDATED";
    this.METRIC_TYPE_KEYSTROKES = "KEYSTROKES";
    this.METRIC_TYPE_SECONDS = "SECONDS";
    this.state = {};
  }
  get metricFields() {
    return this.getMetricFields();
  }
  getMetricFields() {
    return [
      "cmt",
      "cmts",
      "cmtks",
      "cmtc",
      "cmtcu",
      "cmtcr",
      "cmtca",
      "cmtat",
      "cmtatp",
      "fcmt",
      "fcmts",
      "fcmtks",
      "fcmtc",
      "fcmtcu",
      "fcmtcr",
      "fcmtca",
      "fcmtfu",
      "fcmtfr",
      "fcmtfa",
      "fcmtat",
      "fcmtatp",
      "fmt",
      "fmts",
      "fmtks",
      "fmtfu",
      "fmtfr",
      "fmtfa",
      "fmtat",
      "fmtatp",
      "dsfmt",
      "dsfmts",
      "dsfmtks",
      "dsfmtfu",
      "dsfmtfr",
      "dsfmtfa",
      "dsfmtf",
      "dsfmtat",
      "dsfmtatp",
    ];
  }
  parseUserMetrics(metrics) {
    const prefixMap = {
      cm: this.MODE_CHARACTERS,
      fcm: this.MODE_FIELD_CHARACTERS,
      fm: this.MODE_FIELD,
      dsfm: this.MODE_DOCUMENT_SET_FIELDS,
    };
    const postfixMap = {
      t: this.METRIC_TYPE_TOTAL,
      tc: this.METRIC_TYPE_CHARACTERS_TOTAL,
      tca: this.METRIC_TYPE_CHARACTERS_ACCEPTED,
      tcr: this.METRIC_TYPE_CHARACTERS_REJECTED,
      tcu: this.METRIC_TYPE_CHARACTERS_UPDATED,
      tf: this.METRIC_TYPE_FIELDS_TOTAL,
      tfa: this.METRIC_TYPE_FIELDS_ACCEPTED,
      tfr: this.METRIC_TYPE_FIELDS_REJECTED,
      tfu: this.METRIC_TYPE_FIELDS_UPDATED,
      tks: this.METRIC_TYPE_KEYSTROKES,
      ts: this.METRIC_TYPE_SECONDS,
    };
    const modesWithValue = {};
    const ret = Object.keys(metrics)
      .filter((key) => this.metricFields.includes(key))
      .map((key) => {
        const value = metrics[key];
        const modeKey = Object.keys(prefixMap).find((prefix) =>
          key.startsWith(prefix)
        );
        const mode = prefixMap[modeKey] || null;
        const keyPostfix = key.substring(modeKey.length);
        const typeKey = Object.keys(postfixMap).find(
          (postfix) => postfix === keyPostfix
        );
        if (value && parseInt(value) > 0) modesWithValue[mode] = true;
        const type = postfixMap[typeKey] || null;
        return { key, mode, type, value };
      })
      .filter(
        (metric) => metric.mode && metric.type && modesWithValue[metric.mode]
      );
    return ret;
  }
  getMetricFieldInfo() {
    return [
      this.dm.manualEntry.MODE_CHARACTERS,
      this.dm.manualEntry.MODE_FIELD_CHARACTERS,
      this.dm.manualEntry.MODE_FIELD,
      this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS,
    ].map((mode) => {
      let prefix = null;
      let label = null;
      switch (mode) {
        case this.dm.manualEntry.MODE_CHARACTERS:
          prefix = "cm";
          label = "Characters";
          break;
        case this.dm.manualEntry.MODE_FIELD_CHARACTERS:
          prefix = "fcm";
          label = "Field Characters";
          break;
        case this.dm.manualEntry.MODE_FIELD:
          prefix = "fm";
          label = "Fields";
          break;
        case this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS:
          prefix = "dsfm";
          label = "Document Set Fields";
          break;
      }
      let fields = this.metricFields
        .filter((field) => field.startsWith(prefix))
        .map((name) => {
          let type = name.substring(prefix.length);
          let label = null;
          switch (type) {
            case "tc":
              label = "Total Characters";
              break;
            case "tcu":
              label = "Updated Characters";
              break;
            case "tcr":
              label = "Rejected Characters";
              break;
            case "tf":
              label = "Total Fields";
              break;
            case "tfu":
              label = "Updated Fields";
              break;
            case "tfr":
              label = "Rejected Field";
              break;
          }
          return {
            name,
            label,
          };
        });
      return { mode, label, fields };
    });
  }
  get pricingMetricFields() {
    return this.getPricingMetricFields();
  }
  getPricingMetricFields() {
    return [
      "cmtc",
      "cmtcu",
      "cmtcr",
      "fcmtc",
      "fcmtcu",
      "fcmtcr",
      "fcmt",
      "fcmtfu",
      "fcmtfr",
      "fmt",
      "fmtfu",
      "fmtfr",
      "dsfmt",
      "dsfmtfu",
      "dsfmtfr",
    ];
  }
  getPricingMetricFieldInfo() {
    return [
      this.MODE_CHARACTERS,
      this.MODE_FIELD_CHARACTERS,
      this.MODE_FIELD,
    ].map((mode) => {
      let prefix = null;
      let label = null;
      switch (mode) {
        case this.MODE_CHARACTERS:
          prefix = "cm";
          label = "Characters";
          break;
        case this.MODE_FIELD_CHARACTERS:
          prefix = "fcm";
          label = "Field Characters";
          break;
        case this.MODE_FIELD:
          prefix = "fm";
          label = "Fields";
          break;
        case this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS:
          prefix = "dsfm";
          label = "Document Set Fields";
          break;
      }
      let fields = this.getPricingMetricFields()
        .filter((field) => field.startsWith(prefix))
        .map((name) => {
          let type = name.substring(prefix.length);
          let label = null;
          switch (type) {
            case "tc":
              label = "Total Characters";
              break;
            case "tcu":
              label = "Updated Characters";
              break;
            case "tcr":
              label = "Rejected Characters";
              break;
            case "t":
              label = "Total Fields";
              break;
            case "tfu":
              label = "Updated Fields";
              break;
            case "tfr":
              label = "Rejected Field";
              break;
          }
          return {
            name,
            label,
          };
        });
      return { mode, label, fields };
    });
  }
}
