import R14 from "../core";

export default class BlockDomain extends R14.Domain {
  constructor(key) {
    super();
    this.TYPE_STORAGE = "STORAGE";
    this.IO_DATA_TYPE_OBJECT = "OBJECT";
    this.IO_DATA_TYPE_FILE = "FILE";
    this.IO_DATA_STRUCTURE_TYPE_LIST = "LIST";
    this.IO_DATA_STRUCTURE_TYPE_SORTED_SET = "SORTED_SET";
    this.IO_DATA_STRUCTURE_TYPE_GROUPED_SORTED_SET = "GROUPED_SORTED_SET";
    this.IO_TYPE_INPUT = "INPUT";
    this.IO_TYPE_OUTPUT = "OUTPUT";

    this.DATA_TYPE_DATA = "DATA";
    this.DATA_TYPE_QUEUE = "QUEUE";
    this.DATA_TYPE_DATA_QUEUE = "DATA_QUEUE";

    this.OPTION_TYPE_TEXT = "TEXT";
    this.OPTION_TYPE_TEXT_MULTI = "TEXT_MULTI";
    this.OPTION_TYPE_TEXT_SECURE = "TEXT_SECURE";
    this.OPTION_TYPE_SWITCH = "SWITCH";
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let res = await this.api.qry(
      `
      query FindBlocks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: BlockFilter) {
        blocks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.blocks;
  }

  async create(values, options = {}) {
    let input = this.parseFormValues(values, options);
    let res = await this.api.mutate(
      `
      mutation CreateBlock($input: CreateBlockInput!) {
        createBlock(input: $input){
          block {
            uid
            name
            description
          }
          success
        }
      }`,
      {
        input: input,
      }
    );
    return res.errors.length
      ? { success: false, errors: res.errors }
      : { success: true, block: res.data.createBlock.block };
  }
  async update(values, options = {}) {
    let input = this.parseFormValues(values, options);
    let res = await this.api.mutate(
      `
      mutation UpdateBlock($input: UpdateBlockInput!) {
        updateBlock(input: $input){
          block {
            uid
            name
            description
          }
        }
      }`,
      {
        input: input,
      }
    );
    return res.errors.length
      ? { success: false, errors: res.errors }
      : { success: true, block: res.data.updateBlock.block };
  }
  async copy(input) {
    let res = await this.api.mutate(
      `
      mutation CopyBlock($input: CopyBlockInput!) {
        copyBlock(input: $input){
          success
          block {
            uid
            name
          }
        }
      }`,
      {
        input: input,
      }
    );
    if (res.errors && res.errors.length) return res;
    else return res.data.copyBlock;
  }

  parseFormValues(values, options = {}) {
    let ret = { ...values };
    ret.clientUid = this.dm.userSession.clientUid;
    if ("appModule" in values) {
      ret.appModuleUid = values.appModule ? values.appModule.value : null;
      delete ret.appModule;
    }
    if ("cloudAccessKey" in values) {
      ret.cloudAccessKeyUid = values.cloudAccessKey
        ? values.cloudAccessKey.value
        : null;
      delete ret.cloudAccessKey;
    }
    let blockIoVals = {};
    let createBlock = (val, type) => {
      let block = {
        name: val.name,
        key: val.key,
        description: val.description,
        type: type,
        dataType: val.dataType,
        dataStructureType: val.dataStructureType,
        clientUid: this.dm.userSession.clientUid,
      };
      if (val.uid) block.uid = val.uid;
      return block;
    };
    let types = {
      inputs: this.IO_TYPE_INPUT,
      outputs: this.IO_TYPE_OUTPUT,
    };
    for (let key in types) {
      if (ret[key]) {
        ret[key].forEach((val) => {
          let block = createBlock(val, types[key]);
          if (block.uid) {
            if (!blockIoVals.update) blockIoVals.update = [];
            blockIoVals.update.push(block);
          } else {
            if (!blockIoVals.create) blockIoVals.create = [];
            blockIoVals.create.push(block);
          }
        });
      }
      if (key in ret) delete ret[key];
    }
    if (options.removedBlockIoUids)
      blockIoVals.delete = options.removedBlockIoUids;

    ret.blockIo = blockIoVals;

    return ret;
  }

  async get(uid, options = {}) {
    let projectQry = "";
    let optionsField = "";
    let dataField = "";
    let appModuleField = "";
    let selectableCloudAccessKeyField = "";
    if (options.project)
      projectQry = `
    project {
      uid
      name
      type
    }
`;
    if (options.options)
      optionsField = `
    options {
      key
      label
      required
      type
      helperText
    }
`;
    if (options.data)
      dataField = `
    data {
      key
      name
      description
      type
    }
`;

    let appModuleResourceFields = options.appModuleResource
      ? `
    appModuleResourceInstanceTypeKey
    appModuleResource {
      uid
      name
      gpu
      instanceTypes {
        key
        name
        description
      }
    }
    `
      : "";
    if (options.appModule)
      appModuleField = `
    appModule {
      uid
      type
      gpuAccelerated
      ${appModuleResourceFields}
    }
`;
    if (options.selectableCloudAccessKey)
      selectableCloudAccessKeyField = `
  selectableCloudAccessKey
`;

    let res = await this.api.qry(
      `
      query GetBlock($uid: ID!) {
        block(uid: $uid){
          uid
          name
          description
          ${appModuleField}
          ${projectQry}
          ${optionsField}
          ${selectableCloudAccessKeyField}
        }
      }`,
      {
        uid: uid,
      }
    );
    if (res.errors && res.errors.length)
      throw new Error("Error getting block.");
    return res.data.block;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteBlock($uid: ID!) {
        deleteBlock(uid: $uid){
          block {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  getAppModuleTypesByProjectType(projectType) {
    let ret = [];
    switch (projectType) {
      case this.dm.project.TYPE_AI:
      case this.dm.project.TYPE_DEV:
        ret = [
          this.dm.appModule.TYPE_PYTHON_APP,
          this.dm.appModule.TYPE_NODE_APP,
          this.dm.appModule.TYPE_TASK,
          this.dm.appModule.TYPE_REACT_APP,
          this.dm.appModule.TYPE_SERVER,
          // this.dm.appModule.TYPE_AWS_S3_BUCKET,
          // this.dm.appModule.TYPE_REDIS_SERVER,
        ];
        break;
      default:
      // Do Nothing
    }
    return ret;
  }
  async fetchOptions(uid) {
    let block = await this.get(uid, { options: true });
    return block.options || [];
  }
  async fetchPipelineBlockEditFormData(uid, options = {}) {
    let block = await this.get(uid, {
      appModule: true,
      options: true,
      cloudAccessKey: true,
      appModuleResource: options.appModuleResource ? true : false,
    });
    return block || null;
  }
  async fetchCopyFormData(uid = null, options = {}) {
    let qry = "";
    qry = `
    query BlockCopyFormData($uid: ID!) {
      block(uid: $uid){
        uid
        name
        appModule {
          uid
          name
          key
        }
      }
    }
  `;
    let res = await this.api.qry(qry, { uid });
    if (res.errors.length)
      throw new Error("Error getting block copy form values.");
    let blockData = res.data.block;
    return {
      values: {
        uid: blockData.uid,
        name: `${blockData.name} COPY`,
        appModuleUid: blockData.appModule.uid,
        appModuleName: `${blockData.appModule.name} COPY`,
        appModuleKey: `${blockData.appModule.key}-copy`,
      },
    };
  }
  async fetchEditFormData(uid = null, options = {}) {
    let formVals = {
      // pipelineUid: options.pipelineUid || null,
      // projectUid: options.projectUid || null,
    };
    if (uid) {
      let qry = "";
      let qryVals = {};
      if (uid) {
        // let appModuleFilter = {
        //   clientUid: { eq: this.dm.userSession.clientUid },
        // };
        // let cloudAccessKeyFilter = {
        //   clientUid: { eq: this.dm.userSession.clientUid },
        // };

        // if (!this.dm.user.hasAdminRole)
        //   appModuleFilter.userUids = { eq: this.dm.userSession.uid };

        // if (options.projectType) {
        //   let appModuleTypes = this.getAppModuleTypesByProjectType(
        //     options.projectType
        //   );
        //   appModuleFilter.project = { type: { eq: options.projectType } };
        //   if (appModuleTypes.length)
        //     appModuleFilter.type = { in: appModuleTypes };
        // }

        qryVals.blockIoFilter = {
          clientUid: { eq: this.dm.userSession.clientUid },
        };
        qry = `
      query BlockCreateFormData($uid: ID!, $blockIoFilter: BlockIoFilter) {
        block(uid: $uid){
          uid
          name
          description
          projectType
          selectableCloudAccessKey
          cloudAccessKeyUid
          cloudAccessKey {
            uid
            name
          }
          options {
            key
            label
            required
            type
            helperText
          }
          data {
            key
            name
            description
            type
          }
          appModule {
            uid
            name
            project {
              uid
              type
            }
          }
          blockIo(filter:$blockIoFilter) {
            nodes {
              type
              dataType
              dataStructureType
              uid
              name
              key
              description
            }
          }
        },
      }
    `;
        qryVals.uid = uid;
      }

      let res = await this.api.qry(qry, qryVals);

      if (res.errors.length) throw new Error("Error getting form values.");
      if (!res.data.block) throw new Error("Error getting form values.");
      formVals = { ...formVals, ...res.data.block };
      if (formVals.appModule)
        formVals.appModule = formVals.appModule.uid
          ? {
              label: formVals.appModule.name,
              value: formVals.appModule.uid,
            }
          : null;
      else formVals.appModule = null;
      if (formVals.cloudAccessKey)
        formVals.cloudAccessKey = formVals.cloudAccessKey.uid
          ? {
              label: formVals.cloudAccessKey.name,
              value: formVals.cloudAccessKey.uid,
            }
          : null;

      if (formVals.blockIo && formVals.blockIo.nodes.length) {
        let inputs = [];
        let outputs = [];
        let parseBlockIo = (val) => {
          let blockIo = { ...val };
          return blockIo;
        };
        formVals.blockIo.nodes.forEach((val) => {
          let blockIo = parseBlockIo(val);
          switch (val.type) {
            case this.IO_TYPE_INPUT:
              inputs.push(blockIo);
              break;
            case this.IO_TYPE_OUTPUT:
              outputs.push(blockIo);
              break;
          }

          if (inputs.length) formVals.inputs = inputs;
          if (outputs.length) formVals.outputs = outputs;
        });
        delete formVals.blockIo;
      }
      // formVals = { ...formVals, ...res.data.block };
    }
    // Make sure to set projectType
    if (!formVals.projectType)
      formVals.projectType = options.projectType || null;
    let formData = {
      values: formVals,
      // appModuleSelections: res.data.appModules
      //   ? res.data.appModules.nodes.map((val) => ({
      //       label: val.name,
      //       value: val.uid,
      //     }))
      //   : [],
      // cloudAccessKeySelections: res.data.cloudAccessKeys
      //   ? [
      //       { label: "" },
      //       ...res.data.cloudAccessKeys.nodes.map((val) => ({
      //         label: val.name,
      //         value: val.uid,
      //       })),
      //     ]
      //   : [],
      blockIoDataTypeSelections: [
        {
          label: this.getIoDataTypeLabel(this.IO_DATA_TYPE_OBJECT),
          value: this.IO_DATA_TYPE_OBJECT,
        },
        {
          label: this.getIoDataTypeLabel(this.IO_DATA_TYPE_FILE),
          value: this.IO_DATA_TYPE_FILE,
        },
      ],
      blockIoDataStructureTypeSelections: [
        {
          label: this.getIoDataStructureTypeLabel(
            this.IO_DATA_STRUCTURE_TYPE_LIST
          ),
          value: this.IO_DATA_STRUCTURE_TYPE_LIST,
        },
        {
          label: this.getIoDataStructureTypeLabel(
            this.IO_DATA_STRUCTURE_TYPE_SORTED_SET
          ),
          value: this.IO_DATA_STRUCTURE_TYPE_SORTED_SET,
        },
        {
          label: this.getIoDataStructureTypeLabel(
            this.IO_DATA_STRUCTURE_TYPE_GROUPED_SORTED_SET
          ),
          value: this.IO_DATA_STRUCTURE_TYPE_GROUPED_SORTED_SET,
        },
      ],
      blockDataTypeSelections: [
        {
          label: this.getDataTypeLabel(this.DATA_TYPE_DATA),
          value: this.DATA_TYPE_DATA,
        },
        {
          label: this.getDataTypeLabel(this.DATA_TYPE_QUEUE),
          value: this.DATA_TYPE_QUEUE,
        },
        {
          label: this.getDataTypeLabel(this.DATA_TYPE_DATA_QUEUE),
          value: this.DATA_TYPE_DATA_QUEUE,
        },
      ],
      fieldTypeSelections: [
        {
          label: this.getFieldTypeLabel(this.OPTION_TYPE_TEXT),
          value: this.OPTION_TYPE_TEXT,
        },
        {
          label: this.getFieldTypeLabel(this.OPTION_TYPE_TEXT_MULTI),
          value: this.OPTION_TYPE_TEXT_MULTI,
        },
        {
          label: this.getFieldTypeLabel(this.OPTION_TYPE_TEXT_SECURE),
          value: this.OPTION_TYPE_TEXT_SECURE,
        },
        {
          label: this.getFieldTypeLabel(this.OPTION_TYPE_SWITCH),
          value: this.OPTION_TYPE_SWITCH,
        },
      ],
    };
    return formData;
  }
  getFieldTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.OPTION_TYPE_TEXT:
        ret = "Text";
        break;
      case this.OPTION_TYPE_TEXT_MULTI:
        ret = "Multi-line Text";
        break;
      case this.OPTION_TYPE_TEXT_SECURE:
        ret = "Secure";
        break;
      case this.OPTION_TYPE_SWITCH:
        ret = "Switch";
        break;
    }
    return ret;
  }
  async fetchAddFormData(
    pipelineUid,
    { projectUid = null, projectType = null }
  ) {
    return {
      values: { pipelineUid },
      blockSelections: await this.fetchSelections(),
    };
  }

  async fetchSelections(filters = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (!this.dm.user.hasAdminRole)
      filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );

    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchAppModuleSelections(filters) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (!this.dm.user.hasAdminRole)
      filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.dm.appModule.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }

  getIoDataTypeLabel(dataType) {
    let ret = null;
    switch (dataType) {
      case this.IO_DATA_TYPE_FILE:
        ret = "File";
        break;
      case this.IO_DATA_TYPE_OBJECT:
        ret = "Object";
        break;
    }
    return ret;
  }

  getIoDataStructureTypeLabel(dataStructureType) {
    let ret = null;
    switch (dataStructureType) {
      case this.IO_DATA_STRUCTURE_TYPE_LIST:
        ret = "Queue";
        break;
      case this.IO_DATA_STRUCTURE_TYPE_SORTED_SET:
        ret = "Sorted Set";
        break;
      case this.IO_DATA_STRUCTURE_TYPE_GROUPED_SORTED_SET:
        ret = "Grouped Sorted Set";
        break;
    }
    return ret;
  }

  getDataTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.DATA_TYPE_DATA:
        ret = "Data (Set)";
        break;
      case this.DATA_TYPE_QUEUE:
        ret = "Queue";
        break;
      case this.DATA_TYPE_DATA_QUEUE:
        ret = "Data Queue";
        break;
    }
    return ret;
  }
}
