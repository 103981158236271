import R14 from "./R14";
// import { R14UiDialogDomain } from './domains/R14UiDialogDomain';
// import { R14UiLayoutDomain } from './domains/R14UiLayoutDomain';

import R14Navigation from "./R14Navigation";
import R14AppNavigation from "./R14AppNavigation";
import R14Api from "./R14Api";
import R14Io from "./R14Io";
import FormUiDomain from "./domains/FormUiDomain";
import ScrollerUiDomain from "./domains/ScrollerUiDomain";
import KeyMapperUiDomain from "./domains/KeyMapperUiDomain";
import DraggableUiDomain from "./domains/DraggableUiDomain";
import ChartUiDomain from "./domains/ChartUiDomain";
import DataTableUiDomain from "./domains/DataTableUiDomain";
import InfiniteListUiDomain from "./domains/InfiniteListUiDomain";
import ViewportUiDomain from "./domains/ViewportUiDomain";
import NavigatorUiDomain from "./domains/NavigatorUiDomain";
import Theme from "./components/Theme";
import ProgressIndicatorUiDomain from "./domains/ProgressIndicatorUiDomain";
import SnackBarUiDomain from "./domains/SnackBarUiDomain";
import ThemeUiDomain from "./domains/ThemeUiDomain";

export default class R14App {
  constructor(options) {
    if (!options.config) throw "R14 Error: No Config.";
    this._r14 = R14.getInstance();
    this._isInitialized = false;
    let config = options.config;
    if (window._r14AppConfigPublic)
      config.public =
        window && window._r14AppConfigPublic ? window._r14AppConfigPublic : {};
    console.log("HERE HERE HERE");
    this._metadata = {
      model: new R14AppModel(this._r14, this, options),
      navigation: new R14AppNavigation(this._r14, this, options),
      api: new R14Api(this._r14, this, options),
      io: new R14Io(this._r14, this, options),
      actions: new options.actions(),
      config: options.config,
      routes: options.routes,
      icons: options.icons,
      theme: options.theme,
      defaultThemeKey: options.defaultThemeKey || null,
      defaultLayout: options.defaultLayout || null,
      utilities: this._r14.utils,
      scrollBehavior: options.scrollBehavior || null,
      isInitialized: false,
    };
    console.log("INIT r14 app");
  }
  async initialize() {
    // this._metadata.actions
    if (this._isInitialized) return true;

    if (typeof this._metadata.theme === "function")
      this._metadata.theme = this._metadata.theme(this);
    if (typeof this._metadata.defaultThemeKey === "function")
      this._metadata.defaultThemeKey = this._metadata.defaultThemeKey(this);

    await Theme.initialize(this._metadata.theme, {
      defaultThemeKey: this._metadata.defaultThemeKey,
    });
    this._r14.app = this;
    this._r14.navigation = new R14Navigation(this._r14);
    this._r14.navigation.initializeRoutes(this._metadata.routes);

    // Initialize the domain
    await this.model.initialize();

    this._isInitialized = true;
    return true;
  }
  get navigation() {
    return this._metadata.navigation;
  }
  get nav() {
    return this._metadata.navigation;
  }
  get action() {
    return this._metadata.actions;
  }
  get act() {
    return this._metadata.act;
  }
  get api() {
    return this._metadata.api;
  }
  get io() {
    return this._metadata.io;
  }
  get router() {
    return this._metadata.router;
  }
  get rtr() {
    return this._metadata.router;
  }
  get model() {
    return this._metadata.model;
  }
  get mdl() {
    return this.model;
  }
  get domain() {
    return this.mdl.domain;
  }
  get dm() {
    return this.domain;
  }
  get entity() {
    return this.mdl.entity;
  }
  get ent() {
    return this.entity;
  }
  get database() {
    return this.mdl.database;
  }
  get db() {
    return this.database;
  }
  get query() {
    return this.db.qry;
  }
  get qry() {
    return this.query;
  }
  get ui() {
    return this.mdl.ui;
  }
  get routes() {
    return this._metadata.routes;
  }
  get theme() {
    return this._metadata.theme;
  }
  get icons() {
    return this._metadata.icons;
  }
  get utilities() {
    return this._metadata.utilities;
  }
  get utils() {
    return this._metadata.utilities;
  }
  get api() {
    return this._metadata.api;
  }
  get defaultLayout() {
    return this._metadata.defaultLayout;
  }
  get config() {
    return this._metadata.config;
  }
  get scrollBehavior() {
    return this._metadata.scrollBehavior;
  }
  get r14Instance() {
    return this._r14;
  }
}

export class R14AppModel {
  constructor(r14, app, options) {
    if (!options.domains) throw "R14 Error: No Domains config found.";
    if (!options.domains.domain)
      throw "R14 Error: No domain found in domains config.";
    if (!options.domains.ui) throw "R14 Error: No Ui found in domains config.";
    this._app = app;
    this._r14 = r14;
    /** @todo Create a factory for creating domains as needed? */
    let domains = {};
    for (let k in options.domains.domain) {
      domains[k] = new options.domains.domain[k]();
    }

    if (options.domains.ui.form) console.warn("Replacing UI Form ignored.");
    options.domains.ui.form = FormUiDomain;

    if (options.domains.ui.keyMapper)
      console.warn("Replacing UI Key Mapper ignored.");
    options.domains.ui.keyMapper = KeyMapperUiDomain;

    if (options.domains.ui.snackBar)
      console.warn("Replacing UI SnackBar ignored.");
    options.domains.ui.snackBar = SnackBarUiDomain;

    if (options.domains.ui.draggable)
      console.warn("Replacing UI Draggable ignored.");
    options.domains.ui.draggable = DraggableUiDomain;

    if (options.domains.ui.navigator)
      console.warn("Replacing UI Navigator ignored.");
    options.domains.ui.navigator = NavigatorUiDomain;

    if (options.domains.ui.dataTable)
      console.warn("Replacing UI DataTable ignored.");
    options.domains.ui.dataTable = DataTableUiDomain;

    if (options.domains.ui.scroller)
      console.warn("Replacing UI Scroller ignored.");
    options.domains.ui.scroller = ScrollerUiDomain;

    if (options.domains.ui.theme) console.warn("Replacing UI Theme ignored.");
    options.domains.ui.theme = ThemeUiDomain;

    if (options.domains.ui.infiniteList)
      console.warn("Replacing UI Infinite List ignored.");
    options.domains.ui.infiniteList = InfiniteListUiDomain;

    if (options.domains.ui.viewport)
      console.warn("Replacing UI Viewport ignored.");
    options.domains.ui.viewport = ViewportUiDomain;

    if (options.domains.ui.chart) console.warn("Replacing UI Chart ignored.");
    options.domains.ui.chart = ChartUiDomain;

    if (options.domains.ui.progressIndicator)
      console.warn("Replacing UI Progress indicator ignored.");
    options.domains.ui.progressIndicator = ProgressIndicatorUiDomain;

    let uiDomains = {};
    for (let k in options.domains.ui) {
      uiDomains[k] = new options.domains.ui[k](r14);
    }

    this._domain = domains;
    this._ui = uiDomains;

    // if (options.domains.ui.layout) console.warn("Replacing UI layout domain may cause issues.");
    // else this._metadata.ui.layout = this._metadata.ui.lyt = new R14UiLayoutDomain(r14);
  }
  async initialize() {
    let promises = [];
    for (let i in this._domain) {
      if (this._domain[i].setup) promises.push(this._domain[i].setup());
      if (this._domain[i].domainDidLoad)
        promises.push(this._domain[i].domainDidLoad());
    }
    for (let i in this._ui) {
      if (this._ui[i].setup) promises.push(this._ui[i].setup());
      if (this._ui[i].domainDidLoad) promises.push(this._ui[i].domainDidLoad());
    }
    await Promise.all(promises);

    return true;
  }
  get domain() {
    return this._domain;
  }
  get dm() {
    return this.domain;
  }
  get ui() {
    return this._ui;
  }
}
