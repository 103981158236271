import R14 from "../core";
export default class ManualEntryPricingConfigDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryPricingConfig",
    });
    this.state = {};
    this.MODE_FIXED = "FIXED";
    this.MODE_AVERAGE = "AVERAGE";
    this.UPDATE_FREQUENCY_TYPE_INTERVAL_MINUTES = "INTERVAL_MINUTES";
    this.RANGE_TYPE_PAST_DAYS = "PAST_DAYS";
    this.STATE_UPDATE = "UPDATE";
    this.STATE_QUEUED = "QUEUED";
    this.STATE_SYNC = "SYNC";
  }
  get metricFields() {
    return this.dm.manualEntry.getMetricFields();
  }
  // getMetricFields() {
  //   return [
  //     "cmtc",
  //     "cmtcu",
  //     "cmtcr",
  //     "fcmtc",
  //     "fcmtcu",
  //     "fcmtcr",
  //     "fcmt",
  //     "fcmtfu",
  //     "fcmtfr",
  //     "fmt",
  //     "fmtfu",
  //     "fmtfr",
  //   ];
  // }

  // getMetricFieldInfo() {
  //   return [
  //     this.dm.manualEntry.MODE_CHARACTERS,
  //     this.dm.manualEntry.MODE_FIELD_CHARACTERS,
  //     this.dm.manualEntry.MODE_FIELD,
  //   ].map((mode) => {
  //     let prefix = null;
  //     let label = null;
  //     switch (mode) {
  //       case this.dm.manualEntry.MODE_CHARACTERS:
  //         prefix = "cm";
  //         label = "Characters";
  //         break;
  //       case this.dm.manualEntry.MODE_FIELD_CHARACTERS:
  //         prefix = "fcm";
  //         label = "Field Characters";
  //         break;
  //       case this.dm.manualEntry.MODE_FIELD:
  //         prefix = "fm";
  //         label = "Fields";
  //         break;
  //     }
  //     let fields = this.metricFields
  //       .filter((field) => field.startsWith(prefix))
  //       .map((name) => {
  //         let type = name.substring(prefix.length);
  //         let label = null;
  //         switch (type) {
  //           case "tc":
  //             label = "Total Characters";
  //             break;
  //           case "tcu":
  //             label = "Updated Characters";
  //             break;
  //           case "tcr":
  //             label = "Rejected Characters";
  //             break;
  //           case "t":
  //             label = "Total Fields";
  //             break;
  //           case "tfu":
  //             label = "Updated Fields";
  //             break;
  //           case "tfr":
  //             label = "Rejected Field";
  //             break;
  //         }
  //         return {
  //           name,
  //           label,
  //         };
  //       });
  //     return { mode, label, fields };
  //   });
  // }
  async fetchEditFormData(uid = null, options = {}) {
    let pricingMetricFieldStr = this.metricFields.reduce(
      (prevValue, currValue) =>
        prevValue ? `${prevValue}\n${currValue}` : currValue
    );
    let fields = `
  uid
  name
  description
  default
  mode
  range {
    type
    value
  }
  updateFrequency {
    type
    value
  }
  maxRate
  baseCurrencyUid
  baseCurrency {
    uid
    name
    code
  }
  manualEntryConfigs {
    nodes {
      uid
      name
    }
  }
  countries {
    nodes {
      uid
      name
      currencyUid
    }
  }

  ${pricingMetricFieldStr}
`;
    let res = uid ? await this.get(uid, fields) : {};
    let values = {};
    if (uid && res && res.uid) {
      values = { ...res };
      if (values.range) {
        values.rangePastDays = values.range.value;
        delete values.range;
      }
      if (values.updateFrequency) {
        values.updateFrequencyMinutes = values.updateFrequency.value;
        delete values.updateFrequency;
      }
      if (values.baseCurrency && values.baseCurrency.uid) {
        values.baseCurrencyUid = {
          label: `${values.baseCurrency.code} - ${values.baseCurrency.name}`,
          value: values.baseCurrency.uid,
        };
        delete values.baseCurrency;
      }
      if (values.manualEntryConfigs && values.manualEntryConfigs.nodes) {
        values.manualEntryConfigUids = values.manualEntryConfigs.nodes.map(
          (manualEntryConfig) => ({
            value: manualEntryConfig.uid,
            label: manualEntryConfig.name,
          })
        );
        delete values.manualEntryConfigs;
      }
      if (values.countries && values.countries.nodes) {
        values.countryUids = values.countries.nodes.map((country) => ({
          value: country.uid,
          label: country.name,
          currencyUid: country.currencyUid,
        }));
        delete values.countries;
      }
    } else values.default = true;

    // res = {
    //   name: "Test Pricing",
    //   description: "Test Description",
    //   default: true,
    //   baseCurrencyUid: {
    //     label: "USD - Dollar ",
    //     value: "5MxL2AwDo",
    //   },
    //   mode: "AVERAGE",
    //   maxRate: "6.00",
    //   cmtc: "1",
    //   cmtcu: "2",
    //   cmtcr: "3",
    //   fcmtc: "4",
    //   fcmtcu: "5",
    //   fcmtcr: "6",
    //   fcmt: null,
    //   fcmtfu: null,
    //   fcmtfr: null,
    //   fmt: null,
    //   fmtfu: null,
    //   fmtfr: null,
    //   rangePastDays: "3",
    //   updateFrequencyMinutes: "5",
    // };

    let formData = {
      values,
      modeSelections: [
        {
          label: this.getModeLabel(this.MODE_AVERAGE),
          value: this.MODE_AVERAGE,
        },
        {
          label: this.getModeLabel(this.MODE_FIXED),
          value: this.MODE_FIXED,
        },
      ],
    };
    return formData;
  }
  parseSubmitValues(values) {
    // Figure out pricing
    let metricRates = {};
    this.metricFields
      .filter((field) => values[field])
      .forEach((field) => {
        metricRates[field] =
          field in values &&
          values[field] !== null &&
          values[field] !== "" &&
          !isNaN(values[field])
            ? parseFloat(values[field])
            : null;
      });
    let updateValues = {
      name: values.name,
      description: values.description,
      default: values.default || false,
      clientUid: this.dm.userSession.clientUid,
      countryUids:
        values.countryUids && values.countryUids.length
          ? values.countryUids.map((country) => country.value)
          : null,
      manualEntryConfigUids:
        values.manualEntryConfigUids && values.manualEntryConfigUids.length
          ? values.manualEntryConfigUids.map(
              (manualEntryConfig) => manualEntryConfig.value
            )
          : null,
      baseCurrencyUid:
        values.baseCurrencyUid && values.baseCurrencyUid.value
          ? values.baseCurrencyUid.value
          : null,
      mode: values.mode,
      maxRate:
        values.maxRate && !isNaN(values.maxRate)
          ? parseFloat(values.maxRate)
          : null,
      range:
        values.mode === this.MODE_AVERAGE
          ? {
              type: this.RANGE_TYPE_PAST_DAYS,
              value: parseInt(values.rangePastDays),
            }
          : null,
      updateFrequency:
        values.mode === this.MODE_AVERAGE
          ? {
              type: this.UPDATE_FREQUENCY_TYPE_INTERVAL_MINUTES,
              value: parseInt(values.updateFrequencyMinutes),
            }
          : null,
      syncState: this.STATE_UPDATE,
      ...metricRates,
    };
    if (values.uid) updateValues.uid = values.uid;
    return updateValues;
  }
  getModeLabel(mode) {
    let ret = null;
    switch (mode) {
      case this.MODE_AVERAGE:
        ret = "Dynamic (per hour)";
        break;
      case this.MODE_FIXED:
        ret = "Fixed (per unit)";
        break;
    }
    return ret;
  }
}
