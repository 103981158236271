import React from "react";
import R14, {
  Colors,
  StyleSheet,
  Text,
  View,
  ShowHideText,
  Surface,
} from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class ManualEntryUserBatchErrorDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.pageLoader = this.pageLoader.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.renderTotalCell = this.renderTotalCell.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      filter.manualEntryUserBatchUid = {
        eq: this.props.manualEntryUserBatchUid,
      };
      let res = await this.props.app.dm.manualEntryUserBatchError.find(
        `
        uid
        message
        createdAt
        row
        rowNumber
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    rowBottomRenderer({ row, columns }) {
      return (
        <Surface style={styles.rowBottomSurface}>
          <Text style={[styles.cellText, styles.cellTextSmall]}>{row.row}</Text>
        </Surface>
      );
    }
    renderTotalCell({ cell }) {
      return <Text style={[styles.cellText]}>{cell || 0}</Text>;
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.props.app.dm.manualEntryUserBatch}
          searchable
          title={this.props.title || "User Batch Errors"}
          rowControlsRight={false}
          onRowPress={false}
          initialRowsPerPage={5}
          name='manualEntryUserBatches'
          columns={{
            createdAt: {
              label: "Created At",
              sortable: true,
              width: 128,
              renderer: ({ cell }) => {
                let date = new Date(cell);
                return (
                  <View>
                    <View>
                      <Text style={[styles.cellText]}>
                        {this.props.app.utils.date.toShortDate(date)}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.cellText, styles.cellTextSmall]}>
                        {this.props.app.utils.date.toTime(date)}
                      </Text>
                    </View>
                  </View>
                );
              },
            },
            rowNumber: {
              label: "Row",
              width: 80,
            },
            message: {
              label: "Message",
              sortable: true,
              renderer: ({ cell }) => (
                <ShowHideText text={cell} maxCharacters={48} />
              ),
            },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => (
            <Text style={[styles.cellText]}>{cell}</Text>
          )}
          rowBottom={this.rowBottomRenderer}
          pageLoader={this.pageLoader}
          initialSortColumnName='name'
          initialSortDirection='asc'
          perms={this.perms}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {},
  cellTextSmall: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  rowBottomSurface: {
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
    ...StyleSheet.margin(8, -8, 0, -8),
    ...StyleSheet.padding(8),
  },
});
