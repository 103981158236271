import React, { useLayoutEffect } from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  ColorInputField,
  SectionHeader,
  StyleSheet,
  View,
  SwitchField,
  IconButton,
  FadeView,
  Dialog,
  Form,
  SubmitButton,
  Button,
  Redirect,
} from "../core";
import EditForm from "../components/EditForm";
import BlockEditFormFields from "../components/BlockEditFormFields";
import FormValidators from "../config/FormValidators";

export default R14.connect(
  class ProjectPipelineBlockEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.blockItemLoader = this.blockItemLoader.bind(this);
      this.datasetItemLoader = this.datasetItemLoader.bind(this);
      this.cloudAccessKeyItemLoader = this.cloudAccessKeyItemLoader.bind(this);
      this.agentCloudAccessKeyItemLoader =
        this.agentCloudAccessKeyItemLoader.bind(this);
      this.handleBlockValueChange = this.handleBlockValueChange.bind(this);
      this.handleCreateBlockPress = this.handleCreateBlockPress.bind(this);
      this.handleCreateBlockDialogClosePress =
        this.handleCreateBlockDialogClosePress.bind(this);
      this.handleCreateBlockDialogOkPress =
        this.handleCreateBlockDialogOkPress.bind(this);
      this.handleCreateBlockSubmit = this.handleCreateBlockSubmit.bind(this);
      this.handleUseAgentValueChange =
        this.handleUseAgentValueChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCloudAccessKeyValueChange =
        this.handleCloudAccessKeyValueChange.bind(this);
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.appModuleResource = null;
      this.state = {
        createBlockDialogVisible: false,
        dataKeySelections: this.formData.dataKeySelections || [],
        optionFields: this.props.formData.optionFields || null,
        appModule:
          this.props.formData.block && this.props.formData.block.appModule
            ? this.props.formData.block.appModule
            : null,
        selectableCloudAccessKey:
          this.formData.block && this.formData.block.selectableCloudAccessKey
            ? true
            : false,
        useAgent:
          this.formData.values && this.formData.values.agentCloudAccessKeyUid
            ? true
            : false,
      };
      this.createBlockFormData = null;
    }
    componentDidMount() {
      this.updateResourceInstanceType(this.state.appModule);
    }
    componentDidUpdate(prevProps, prevState) {
      this.updateResourceInstanceType(this.state.appModule);
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("pipelineBlockForm");
    }
    get appModuleResourceExists() {
      return this.state.appModule &&
        this.state.appModule.appModuleResource &&
        this.state.appModule.appModuleResource.uid
        ? true
        : false;
    }
    async blockItemLoader(filters = {}) {
      filters.projectType = this.props.formData.values.projectType;
      return await this.props.app.dm.pipelineBlock.fetchBlockSelections(
        filters
      );
    }
    async datasetItemLoader(filters = {}) {
      return await this.props.app.dm.pipelineBlock.fetchDatasetSelections(
        this.props.projectUid,
        filters
      );
    }
    async cloudAccessKeyItemLoader(filters = {}) {
      return await this.props.app.dm.cloudAccessKey.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    async agentCloudAccessKeyItemLoader(filters = {}) {
      filters.type = this.props.app.dm.cloudAccessKey.TYPE_R14;
      return await this.props.app.dm.cloudAccessKey.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    async handleBlockValueChange(val, elmt, form) {
      this.props.app.ui.progressIndicator.show();
      let block = await this.props.app.dm.block.fetchPipelineBlockEditFormData(
        val.value,
        { appModuleResource: true }
      );
      console.log("CHECK GBLOCK!", block);
      let dataKeySelections = block.data
        ? block.data.map((data) => ({
            value: data.key,
            label: data.name,
          }))
        : [];

      this.form.elmts("dataKeys") && this.form.elmts("dataKeys").setItems();
      let blockOptions = block.options;
      this.setState({
        dataKeySelections,
        optionFields: blockOptions && blockOptions.length ? blockOptions : null,
        appModule: block.appModule || null,
      });
      this.updateResourceInstanceType(block.appModule);
      this.props.app.ui.progressIndicator.hide({ timeout: 500 });
    }
    // handleKeyValueChange(value, label, elmt, form) {
    //   let subtypeSelections = this.getSubtypeSelections(value);
    //   if (form.elmts.subtype) {
    //     form.elmts.subtype.setItems(subtypeSelections);
    //     form.elmts.subtype.setValue(null);
    //   }
    //   this.setState({
    //     hasSubtypeSelections: subtypeSelections ? true : false,
    //     type: value
    //   });
    // }
    updateResourceInstanceType(appModule) {
      let appModuleResource =
        appModule &&
        appModule.appModuleResource &&
        appModule.appModuleResource.uid
          ? appModule.appModuleResource
          : null;

      if (!appModuleResource) {
        this.appModuleResource = null;
        return false;
      }

      // Already in sync
      if (
        this.appModuleResource &&
        this.appModuleResource.uid &&
        appModuleResource.uid === this.appModuleResource.uid
      )
        return true;

      // Make sure the element exists
      if (!this.form.elmts.exists("appModuleResourceInstanceTypeKey")) {
        return false;
      }

      // Set current resource
      this.appModuleResource = appModuleResource;

      // Update instance types
      let instanceTypeMap = {};
      this.form.elmts.appModuleResourceInstanceTypeKey.setItems(
        appModuleResource.instanceTypes.map((i) => {
          instanceTypeMap[i.key] = i;
          return {
            label: `${i.name} (${i.description})`,
            value: i.key,
          };
        })
      );

      // Update the selected instance type
      let instanceTypeKey =
        this.props.formData.values.appModuleResourceInstanceTypeKey;
      if (!instanceTypeKey || !instanceTypeMap[instanceTypeKey])
        instanceTypeKey = appModule.appModuleResourceInstanceTypeKey;
      this.form.elmts.appModuleResourceInstanceTypeKey.setValue(
        instanceTypeKey
      );

      return;
      let instanceTypes = null;
      // TODO: Fix this super hacky code.
      for (let appModuleResource of this.formData.appModuleResources) {
        if (this.state.appModule.gpuAccelerated) {
          if (appModuleResource.gpu)
            instanceTypes = appModuleResource.instanceTypes;
        } else if (
          appModuleResource.resourceUid === this.state.appModule.resourceUid
        )
          instanceTypes = appModuleResource.instanceTypes;
      }
      if (instanceTypes) {
      }
    }
    async handleUseAgentValueChange(value, elmt) {
      this.setState({
        useAgent: value ? true : false,
      });
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      this.props.app.ui.progressIndicator.show();

      try {
        this.props.app.ui.progressIndicator.show();
        let res = await this.props.app.dm.pipelineBlock[editMethod](
          form.values
        );
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
        if (res.success) {
          if (this.props.app.ui.pipeline.exists(this.props.pipelineUid)) {
            let pipeline = await this.props.app.ui.pipeline.instance(
              this.props.pipelineUid
            );
            await pipeline.refresh();
          }
          let redirect = this.isEditMode
            ? this.props.redirect
            : {
                route: "projectPipelineBlockIoEdit",
                params: {
                  uid: this.props.projectUid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.projectType
                  ),
                  pipelineUid: this.props.pipelineUid,
                  pipelineBlockUid: res.pipelineBlock.uid,
                  mode: "setup",
                },
              };
          return <Redirect to={redirect} />;
        } else if (res.errors && res.errors.length) {
          form.addError(res.errors[0].message);
        } else form.addError("An unknown error has occurred.");
      } catch (err) {
        form.addError(err.message);
        console.error(err);
      }
    }
    async handleCloudAccessKeyValueChange(value) {
      let cloudAccessKey = null;
      for (let i in this.formData.cloudAccessKeySelections) {
        if (this.formData.cloudAccessKeySelections[i].value === value) {
          cloudAccessKey = this.formData.cloudAccessKeySelections[i];
          break;
        }
      }
      this.setState({ cloudAccessKey });
    }
    async handleCreateBlockSubmit(form) {
      try {
        this.props.app.ui.progressIndicator.show();
        let res = await this.props.app.dm.block.create(form.values);
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
        if (res.success) {
          let selectVal = {
            label: res.block.name,
            value: res.block.uid,
          };
          this.form.elmts("block").setValue(selectVal);
          this.handleBlockValueChange(selectVal);
          this.setState({ createBlockDialogVisible: false });
          this.props.app.ui.snackBar.show({
            message: `Created Block: ${selectVal.label}.`,
            variant: "success",
          });
        } else if (res.errors && res.errors.length) {
          form.addError(res.errors[0].message);
        } else form.addError("An unknown error has occurred.");
      } catch (err) {
        form.addError(err.message);
      }
    }
    async handleCreateBlockPress(value) {
      if (!this.createBlockFormData) {
        this.props.app.ui.progressIndicator.show();
        this.createBlockFormData =
          await this.props.app.dm.block.fetchEditFormData(null, {
            projectType: this.props.projectType,
          });
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
      }

      this.setState({ createBlockDialogVisible: true });
    }
    async handleCreateBlockDialogClosePress() {
      this.setState({ createBlockDialogVisible: false });
    }
    async handleCreateBlockDialogOkPress() {
      this.props.app.ui.form("createBlockForm").submit();
    }
    render() {
      return (
        <Form
          name='pipelineBlockForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          validators={this.props.validators || null}
          initialValues={this.formData.values}
          style={[styles.editForm, this.props.style]}
          controlsBottomRight={[
            <Button
              title='Cancel'
              key='cancel'
              variant='text'
              onPress={() =>
                this.props.redirect
                  ? this.props.app.nav.to(this.props.redirect)
                  : this.props.app.nav.back()
              }
            />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              style={styles.submitButton}
              key='submit'
            />,
          ]}

          // formData={this.props.formData}
          // domainName='pipelineBlock'
          // //redirect={this.props.redirect || "projectPipeline"}
          // refreshDataTable={this.props.refreshDataTable || "projectPipeline"}
          // onAfterSave={this.handleAfterSave}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='pipelineUid' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              validator='key'
              helper='Used as remote folder name'
            />
          </FieldGroup>
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
          />
          <View style={styles.blockField}>
            <SelectMenuField
              name='block'
              label='Block Template'
              searchable
              onValueChange={this.handleBlockValueChange}
              itemLoader={this.blockItemLoader}
              items={this.formData.blockSelections}
              required='Please select a block.'
            />
            <IconButton
              tooltip='Create Block Template'
              onPress={this.handleCreateBlockPress}
              icon='add'
              variant='circle'
              style={styles.blockCreateButton}
            />
          </View>

          <Dialog
            name='createBlockDialog'
            title='Create Block Template'
            visible={this.state.createBlockDialogVisible}
            titleControlVariant='back'
            onClosePress={this.handleCreateBlockDialogClosePress}
            onCancelPress={this.handleCreateBlockDialogClosePress}
            onBackdropPress={this.handleCreateBlockDialogClosePress}
            onBackPress={this.handleCreateBlockDialogClosePress}
            onOkPress={this.handleCreateBlockDialogOkPress}
            okButtonTitle={"Create"}
            // OkButtonComponent={<SubmitButton title='Create'}
          >
            <Form
              name='createBlockForm'
              style={styles.createBlockForm}
              onSubmit={this.handleCreateBlockSubmit}
              validateBeforeSubmit
              validators={FormValidators}
              initialValues={
                this.createBlockFormData && this.createBlockFormData.values
              }
            >
              <BlockEditFormFields formData={this.createBlockFormData} />
            </Form>
          </Dialog>
          {/* <SelectMenuField
            useValuesOnly
            name='type'
            label='Type'
            items={this.formData.typeSelections}
            required='Please select a type.'
          /> */}

          {this.state.selectableCloudAccessKey ? (
            <SelectMenuField
              key='cloudAccessKey'
              name='cloudAccessKey'
              label='Cloud Access Key'
              searchable
              autoLoad
              itemLoader={this.cloudAccessKeyItemLoader}
            />
          ) : null}

          <SelectMenuField
            name='datasets'
            label='Datasets'
            multiple
            searchable
            autoLoad
            itemLoader={this.datasetItemLoader}
          />
          {this.state.dataKeySelections.length ? (
            <SelectMenuField
              useValuesOnly
              name='dataKeys'
              label='Data'
              multiple
              itemLoader={this.dataKeyItemsLoader}
              items={this.state.dataKeySelections}
            />
          ) : null}

          <SwitchField
            key='autoRun'
            name='autoRun'
            label='Auto Run'
            helper='Run when input(s) have queued items'
          />

          <SwitchField
            key='useAgentSwitch'
            name='useAgent'
            label='Use R14 Os Agent'
            onValueChange={this.handleUseAgentValueChange}
            value={this.state.useAgent}
          />

          <FadeView visible={this.state.useAgent}>
            <SelectMenuField
              // useValuesOnly
              // name='agentCloudAccessKeyUid'
              // label='Agent Cloud Access Key'
              // items={this.formData.agentCloudAccessKeySelections}
              name='agentCloudAccessKeyUid'
              label='Agent Cloud Access Key'
              multiple
              searchable
              autoLoad
              itemLoader={this.agentCloudAccessKeyItemLoader}
              helper=''
            />
          </FadeView>

          <FadeView
            visible={
              this.state.appModule &&
              [
                this.props.app.dm.appModule.TYPE_PYTHON_APP,
                this.props.app.dm.appModule.TYPE_NODE_APP,
                this.props.app.dm.appModule.TYPE_TASK,
                this.props.app.dm.appModule.TYPE_EXECUTABLE_LINUX_64,
              ].includes(this.state.appModule.type)
            }
          >
            <SectionHeader level={4} style={styles.sectionHeader}>
              Instance
            </SectionHeader>
            {!this.appModuleResourceExists &&
              this.state.appModule &&
              this.state.appModule.gpuAccelerated !== true && (
                <FieldGroup>
                  <SelectMenuField
                    useValuesOnly
                    name='cpu'
                    label='CPU'
                    items={this.formData.cpuSelections}
                  />
                  <SelectMenuField
                    useValuesOnly
                    name='memory'
                    label='Memory'
                    items={this.formData.memorySelections}
                  />
                </FieldGroup>
              )}

            {this.appModuleResourceExists && (
              <SelectMenuField
                useValuesOnly
                name='appModuleResourceInstanceTypeKey'
                label='Instance Type'
                required='Please select an instance type'
              />
            )}

            <FieldGroup>
              <TextInputField
                name='maxInstances'
                label='Max Instances'
                helper='Optional'
                validator={"positiveInt"}
              />
              <TextInputField
                name='inputItemsPerInstance'
                label='Input Items per Instance'
                helper='Optional'
                validator={"positiveInt"}
              />
            </FieldGroup>
          </FadeView>

          <SectionHeader level={4} style={styles.sectionHeader}>
            Appearance
          </SectionHeader>
          <FieldGroup>
            <TextInputField
              name='height'
              label='Height'
              helper='Optional'
              validator={"positiveInt"}
            />
            <TextInputField
              name='width'
              label='Width'
              helper='Optional'
              validator={"positiveInt"}
            />
          </FieldGroup>
          <FieldGroup>
            <ColorInputField
              name='backgroundColor'
              label='Background Color'
              // required='Please enter a background color.'
              validator='hexColor'
              helper='Optional, Dark color suggested.'
            />
            <SelectMenuField
              name='icon'
              label='Icon'
              items={this.formData.iconSelections}
              helper='Optional'
            />
          </FieldGroup>
          {/* <SectionHeader level={4} style={styles.sectionHeader}>
            Statistics
          </SectionHeader>
          <FieldGroup>
            <FileInputField
              name='statConfusionMatrixImageFile'
              label='Confusion Matrix'
              helper='Optional'
            />
            <TextInputField
              name='statPercentageMatrix'
              label='Percentage Matrix'
              helper='Optional'
              validator={"number"}
            />
          </FieldGroup> */}
          {this.state.optionFields && (
            <React.Fragment>
              <SectionHeader level={4} style={styles.sectionHeader}>
                Options
              </SectionHeader>
              <View>
                {this.state.optionFields.map((field) => {
                  let ret = null;
                  let fieldProps = {
                    name: `options_${field.key}`,
                    key: `options_${field.key}`,
                    label: field.label,
                    required: field.required,
                    helper: field.required ? field.helperText : `Optional`,
                  };
                  switch (field.type) {
                    case this.props.app.dm.block.OPTION_TYPE_TEXT_MULTI:
                      ret = (
                        <TextInputField {...fieldProps} multiline autoSize />
                      );
                      break;
                    case this.props.app.dm.block.OPTION_TYPE_TEXT_SECURE:
                      ret = (
                        <TextInputField
                          autoComplete={false}
                          {...fieldProps}
                          secure
                        />
                      );
                      break;
                    case this.props.app.dm.block.OPTION_TYPE_SWITCH:
                      ret = <SwitchField {...fieldProps} />;
                      break;
                    default:
                      ret = <TextInputField {...fieldProps} />;
                  }
                  return ret;
                })}
              </View>
            </React.Fragment>
          )}
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
    paddingBottom: 16,
  },
  createBlockForm: {
    ...StyleSheet.padding(0),
  },
  blockField: {
    flex: 1,
    flexDirection: "row",
  },
  blockCreateButton: {
    height: 36,
    width: 36,
    ...StyleSheet.margin(10, 8, 0, 12),
  },
});
