import React from "react";
import R14, {
  Colors,
  StyleSheet,
  IconButton,
  Text,
  DataTable,
  View,
  Surface,
  LabelView,
} from "../core";

import StateIndicatorText from "../components/StateIndicatorText";

export default R14.connect(
  class ManualEntryUserDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.metricCellRenderer = this.metricCellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.state) {
        filter.state = { eq: this.props.state };
      }
      if (this.props.manualEntryPipeline) {
        if (!this.props.manualEntryPipeline.manualEntryConfigs)
          throw new Error("Missing config data.");
        filter.manualEntryConfigUid = {
          in: this.props.manualEntryPipeline.manualEntryConfigs.nodes.map(
            (val) => val.uid
          ),
        };
      }
      if (this.props.manualEntryUserBatchUid) {
        filter.manualEntryUserBatchUids = {
          eq: this.props.manualEntryUserBatchUid,
        };
      }
      let signupDetailFields = this.props.signupDetails
        ? `
          email
          phone
          paymentInfo {
            firstName
            lastName
            dateOfBirth
            address {
              address1
              address2
              city
              state
              country
              country
              postalCode
            }
          }
`
        : "";
      let res = await this.props.app.dm.manualEntryUser.find(
        `
          uid
          name
          username
          lastCharacterSetCompleteAt
          metrics {
            mode
            type
            value
            rate
          }
          ${signupDetailFields}
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryUsers");
    }
    cellRenderer({ cell, columnName, row, columns }) {
      let renderedCell = null;
      switch (columnName) {
        case "lastCharacterSetCompleteAt":
          let lastCharacterSetCompleteAt = new Date(cell);
          let active = this.props.app.dm.manualEntryUser.isActive(
            lastCharacterSetCompleteAt
          );
          if (cell && this.props.activity) {
            renderedCell = (
              <StateIndicatorText
                label={`${this.props.app.utils.date.toShortDate(
                  lastCharacterSetCompleteAt
                )} ${this.props.app.utils.date.toTime(
                  lastCharacterSetCompleteAt
                )}`}
                color={active ? "green" : null}
              />
            );
          }

          break;
        case "paymentInfo":
          renderedCell = (
            <View style={styles.labelWrapper}>
              <View style={styles.labelRow}>
                <LabelView style={styles.labelView} key='name' label='Name'>
                  <Text>
                    {cell.firstName} {cell.lastName}
                  </Text>
                </LabelView>
                <LabelView style={styles.labelView} key='dob' label='DOB'>
                  <Text>{cell.dateOfBirth}</Text>
                </LabelView>
              </View>
              {cell.address && (
                <View style={styles.labelRow}>
                  <LabelView
                    style={styles.labelView}
                    key='address'
                    label='Address'
                  >
                    <Text>
                      {this.props.app.dm.manualEntryUser.formatPaymentInfoAddress(
                        cell.address
                      )}
                    </Text>
                  </LabelView>
                </View>
              )}
            </View>
          );
          break;
      }
      return renderedCell || <Text style={[styles.cellText]}>{cell}</Text>;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    metricCellRenderer(metrics, mode, type) {
      if (!metrics || !metrics.length) return null;
      let metric = null;
      for (let n of metrics) {
        if (n.mode === mode && n.type === type) {
          metric = n;
          break;
        }
      }
      if (!metric) return null;
      return <Text style={[styles.cellText]}>{metric.value.toFixed(2)}</Text>;
    }
    // handleRowPress(row) {
    //   this.props.app.nav.to("user", { uid: row.uid });
    // }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.manualEntryUser.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleSearchPress() {}

    render() {
      let columns = {
        username: {
          label: "Username",
          sortable: true,
        },
        name: {
          label: "Name",
          sortable: true,
        },
      };
      if (this.props.activity) {
        columns = {
          ...columns,
          metricCharactersPerMinute: {
            label: "CPM",
            width: 112,
            sortable: false,
            renderer: ({ row }) =>
              this.metricCellRenderer(
                row.metrics,
                this.props.app.dm.manualEntryUser.MODE_CHARACTERS,
                this.props.app.dm.manualEntryUser.METRIC_TYPE_SUBMISSIONS
              ),
          },
          // metricCharacterFieldsPerMinute: {
          //   label: "FCPM",
          //   sortable: false,
          //   width: 112,
          //   renderer: ({ row }) =>
          //     this.metricCellRenderer(
          //       row.metrics,
          //       this.props.app.dm.manualEntryUser.MODE_FIELD_CHARACTERS,
          //       this.props.app.dm.manualEntryUser.METRIC_TYPE_SUBMISSIONS
          //     ),
          // },
          metricFieldsPerMinute: {
            label: "FPM",
            sortable: false,
            width: 112,
            renderer: ({ row }) =>
              this.metricCellRenderer(
                row.metrics,
                this.props.app.dm.manualEntryUser.MODE_FIELD,
                this.props.app.dm.manualEntryUser.METRIC_TYPE_SUBMISSIONS
              ),
          },
        };
      }
      columns.lastCharacterSetCompleteAt = {
        label: "Active",
        sortable: true,
      };

      if (this.props.signupDetails) {
        columns.paymentInfo = {
          label: "Details",
          sortable: false,
        };
      }

      this.props.excludeColumns &&
        this.props.excludeColumns.forEach((col) => {
          delete columns[col];
        });

      let t = new Date();
      let startDate = new Date();
      let endDate = new Date();
      return (
        <DataTable
          name={this.props.name || "manualEntryUsers"}
          columns={columns}
          searchable
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={this.props.initialRowsPerPage || 10}
          headerTitle={this.props.title || "Manual Entry Users"}
          paddingBottom={72} // Make space for the FAB
          checkboxes={this.props.checkboxes || false}
          headerControlsRight={this.props.headerControlsRight || null}
          initialSortColumnName={
            this.props.initialSortColumnName
              ? this.props.initialSortColumnName
              : this.props.activity
              ? "lastCharacterSetCompleteAt"
              : "name"
          }
          initialSortDirection={
            this.props.initialSortDirection
              ? this.props.initialSortDirection
              : this.props.activity
              ? "desc"
              : "asc"
          }
          // headerControlsRight={[
          //   <PopUpMenu
          //     controlIcon='dotsVertical'
          //     key='adminMenu'
          //     direction='downLeft'
          //     onSelect={(value, label) => {}}
          //   >
          //     <PopUpMenuItem
          //       icon='add'
          //       iconSize='small'
          //       label='Add Manual Entry User'
          //       value='test1'
          //       to='userAdd'
          //       key='add'
          //     />
          //   </PopUpMenu>,
          // ]}
          rowControlsRight={
            this.props.rowControlsRight ||
            (!this.props.activity && this.perms.edit
              ? (row, dataTable) => {
                  return [
                    <IconButton
                      icon='edit'
                      size='small'
                      key='edit'
                      onPress={() =>
                        this.props.app.nav.to("manualEntryUserEdit", {
                          uid: row.uid,
                        })
                      }
                    />,
                  ];
                }
              : null)
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  labelWrapper: {
    marginTop: -16,
  },
  labelView: {
    // paddingBottom: 16,
  },
  labelRow: {
    flex: 1,
    flexDirection: "row",
    // flexWrap: "wrap",
    marginTop: 16,
    // paddingBottom: 16,
  },
  labelSurface: {
    // marginTop: -16,
  },
});
