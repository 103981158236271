import React from "react";
import R14, { SelectMenuField } from "../core";
export default R14.connect(
  class EntitySelectMenuField extends React.Component {
    constructor(props) {
      super(props);
      this.itemLoader = this.itemLoader.bind(this);
    }
    get labelField() {
      return this.props.labelField || "name";
    }
    get valueField() {
      return this.props.valueField || "uid";
    }
    async itemLoader(filter = {}) {
      if (this.props.filter) filter = { ...filter, ...this.props.filter };
      let res = await this.props.entityDomain.find(
        `
      ${this.labelField}
      ${this.valueField}
  `,
        {
          page: 1,
          resultsPerPage: 20,
          filter: filter,
          sort: [
            {
              field: "name",
              order: "ASC",
            },
          ],
          totalCount: false,
        }
      );
      console.log("CHECK CHECK", this.props.entityDomain.entityPluralName, this.props.entityDomain.entityName);
      return res[this.props.entityDomainPluralName] &&
        res[this.props.entityDomainPluralName].nodes
        ? res[this.props.entityDomainPluralName].nodes.map((val) => ({
            label: val[this.labelField],
            value: val[this.valueField],
          }))
        : [];
      console.log("CHECK RES?", res);
    }
    render() {
      return (
        <SelectMenuField
          key={this.props.name}
          name={this.props.name}
          label={this.props.label}
          searchable={this.props.searchable}
          autoLoad
          itemLoader={this.itemLoader}
          required={`Please select a ${this.props.label}.`}
        />
      );
    }
  }
);
