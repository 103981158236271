import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import ManualEntryErrorDataTable from '../components/ManualEntryErrorDataTable';
export default R14.connect(
  class ManualEntryErrorsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryError"
      );
    }
    render() {
      return (
        <ScreenLayout
          scrollEnabled
        >
          <ManualEntryErrorDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({

});