import R14 from "../core";
export default class ManualEntryDocumentDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryDocument",
    });
    this.state = {};
  }
  // async get(uid, fields) {
  //   if (!fields) fields = [("uid", "name", "description")];
  //   return await super.get(uid, fields);
  // }
}