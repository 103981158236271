import R14NavigationBase from "./base/R14NavigationBase";
import R14Portal from "./R14Portal";
import R14Route from "./R14Route";
export default class R14Navigation extends R14NavigationBase {
  constructor(r14) {
    super();
    this.PORTAL_TYPE_ROOT = "ROOT";
    this.PORTAL_TYPE_MODAL = "MODAL";
    this.PORTAL_TYPE_CONTAINER = "CONTAINER";
    this._r14 = r14;
    this._routesConfig = null;
    this._routes = {};
    this._screens = {};
    // url / deep link paths
    this._paths = {};
    this._navigators = {};
    this._portals = {};
    this._initialScreen = null;
    this._history = null;
    this._location = null;
    this._match = null;
    this._activeRouteParams = {};
    // this._route = {};
    this.state = {
      activeRoute: null,
    };
    this._currRoute = null;
    this._prevRoute = null;
  }
  get routesConfig() {
    return this._routesConfig;
  }
  routeForwarder(route, params = {}) {
    return this._routesConfig.routeForwarder
      ? this._routesConfig.routeForwarder(route, params)
      : route;
  }
  createPath(pathTemplate, data) {
    let ret = pathTemplate;
    for (let param in data) {
      let key = `:${param}`;
      if (ret.indexOf(key) !== -1) {
        ret = ret.replace(key, data[param]);
      }
    }
    return ret;
  }
  setActiveRoute(route) {
    if (route !== null && route instanceof R14Route === false)
      throw new Error("Active Route Error. Bad route type.");

    if (route && this.currRoute) {
      if (route.path === this._currRoute.path) return;
    }
    // if (route && this._currRoute && this._currRoute.name === route.name) return;
    // if (route && this._currRoute && this._currRoute.name === route.name) return;
    this._prevRoute = this._currRoute;
    this._currRoute = route;
    this.setState({
      activeRouteName: route.name || null,
      activeRoutePath: route.path || null,
    });
  }
  get currRoute() {
    return this._currRoute;
  }
  get prevRoute() {
    return this._prevRoute;
  }
  /** @todo Should this require the app context? */
  async handleBeforeLoad(appContext) {
    let ret = true;
    let shouldActionLoadMethod =
      appContext._metadata.actions.shouldActionLoad ||
      this._navigators["r14NavRoot"].shouldActionLoad;
    let actionWillLoadMethod =
      appContext._metadata.actions.actionWillLoad ||
      this._navigators["r14NavRoot"].actionWillLoad;
    if (shouldActionLoadMethod) {
      ret = await shouldActionLoadMethod({
        to: this.currRoute,
        from: this.prevRoute,
        app: appContext,
      });
    }
    if (ret === true && actionWillLoadMethod)
      await actionWillLoadMethod({
        to: this.currRoute,
        from: this.prevRoute,
        app: appContext,
      });
    return ret;
  }
  /** TODO Change to active route name. */
  get activeRouteName() {
    return this.state.activeRouteName;
  }
  get activeRoutePath() {
    return this.state.activeRoutePath;
  }
  screens(key) {
    return this._screens[key] || null;
  }
  portals(name) {
    return this._portals[name] || null;
  }
  addPortal(name, type, options = {}) {
    this._portals[name] = new R14Portal(name, type, options);
    return this._portals[name];
  }
  removePortal(name) {
    if (this._portals[name]) delete this._portals[name];
    if (this._activePortals[name]) delete this._activePortals[name];
  }
  navigators(key) {
    return this._navigators[key] || null;
  }
  initializeRoutes(routes) {
    this._routesConfig =
      typeof routes === "function" ? routes(this._r14.app) : routes;
    this._initNavigation(this._routesConfig);
    // let out = this._printRoutes("root", this._routesConfig);
    // console.log(out);
  }
  findInitialRoute(routesConfig, initialRoute = null) {
    if (
      routesConfig.initialRoute &&
      routesConfig.routes &&
      routesConfig.routes[routesConfig.initialRoute]
    ) {
      return this.findInitialRoute(
        routesConfig.routes[routesConfig.initialRoute],
        routesConfig.initialRoute
      );
    } else return initialRoute;
  }

  _printProps(props) {
    let out = "";
    for (let prop in props) {
      if (prop === "props") {
        console.log(prop, props[prop]);
        throw new Error("Error: Navigation prop error");
      }
      if (prop === "type" || prop === "routes") continue;
      else if (prop.endsWith("Navigator")) {
        out += this._printProps(props[prop]);
        continue;
      }
      out += `${prop}=`;
      switch (typeof props[prop]) {
        case "string":
          out += `'${props[prop]}'\n`;
          break;
        case "object":
          out += `{${JSON.stringify(props[prop])}}\n`;
          break;
        case "boolean":
          out += `{${props[prop] ? "true" : "false"}}\n`;
          break;
        case "function":
          console.log("FUNCTION", props[prop].toString());
          out += `{${props[prop].toString()}}\n`;
          break;
        default:
          console.log("add type prop", prop, typeof props[prop]);
          throw new Error("sldkjf lsdkjf lksdjf");
      }
    }
    return out;
  }
  _printRoute(name, route) {
    let out = `<Route\nname='${name}'\n`;
    out += this._printProps(route);
    out += `/>\n`;
    return out;
  }
  _printRoutes(name, navigator = null) {
    let out = "";
    let nav = {
      name: name,
      type: null,
      props: {},
    };
    if (!navigator) {
      navigator = this._routesConfig;
      nav.type = "root";
    }
    for (let prop in navigator) {
      if (prop === "routes") continue;
      nav.props[prop] = navigator[prop];
    }

    let navTag = null;
    switch (navigator.type || "stack") {
      case "stack":
      case "container":
        navTag = "StackNavigator";
        break;
      case "tab":
        navTag = "TabNavigator";
        break;
      case "drawer":
        navTag = "DrawerNavigator";
        break;
      case "modal":
        navTag = "ModalNavigator";
        break;
      default:
        throw new Error("UNKNOWN TYPE");
    }

    out += `<${navTag}\n`;
    out += this._printProps(navigator);
    out += `>\n`;

    if (navigator.routes) {
      for (let routeName in navigator.routes) {
        let route = navigator.routes[routeName];
        if (route.type && route.routes && Object.keys(route.routes).length) {
          console.log(
            "route.type, route.routes",
            route.type,
            route.routes,
            Object.keys(route.routes).length
          );
          out += this._printRoutes(routeName, route);
          continue;
        }
        out += this._printRoute(routeName, route);
        console.log("ROUTE", routeName);
      }
    }

    console.log("NAVIGATOR END", nav.name);
    out += `</${navTag}>\n`;
    return out;
  }

  addNavigator(navigator, name, portal, parent, routePath) {
    this._navigators[name] = {
      type: navigator.type,
      portal: portal,
      routePath: [...routePath],
      parent: parent,
      routes: navigator.routes,
      initialRoute: navigator.initialRoute || null,
    };
    if (navigator.shouldActionLoad) {
      this._navigators[name].shouldActionLoad = navigator.shouldActionLoad;
    }
    if (navigator.actionWillLoad) {
      this._navigators[name].actionWillLoad = navigator.actionWillLoad;
    }
    switch (navigator.type) {
      case "tab":
        if (navigator.tabNavigator) {
          this._navigators[name].tabNavigator = navigator.tabNavigator || {};
        }
        break;
      case "drawer":
        if (navigator.drawerNavigator) {
          this._navigators[name].drawerNavigator =
            navigator.drawerNavigator || {};
        }
        break;
      case "modal":
        if (navigator.header) {
          this._navigators[name].header = navigator.header;
        }
        break;
      case "container":
      case "stack":
      default:
        if (navigator.header) this._navigators[name].header = navigator.header;
        break;
    }
  }
  _initNavigation(routesConfig, portal = null, parent = null, routePath = []) {
    if (!routesConfig.routes) return null;
    if (parent === null) {
      parent = "r14NavRoot";
      this.addNavigator(routesConfig, "r14NavRoot", null, null, [...routePath]);
      routePath.push(parent);
    }

    if (routesConfig.path) {
      this._paths[parent] = routesConfig.path;
    }

    for (let name in routesConfig.routes) {
      if (routesConfig.routes[name].routes) {
        let childRoutePath = [...routePath];
        childRoutePath.push(name);
        if (parent === "r14NavRoot") {
          switch (routesConfig.routes[name].type) {
            case "modal":
              portal = "modal";
              break;
            case "container":
              portal = name;
              break;
            default:
              portal = "root";
          }
        }
        this.addNavigator(
          routesConfig.routes[name],
          name,
          portal,
          parent,
          childRoutePath
        );
        this._initNavigation(
          routesConfig.routes[name],
          portal,
          name,
          childRoutePath
        );
        continue;
      }

      if (routesConfig.routes[name].path) {
        this._paths[name] = routesConfig.routes[name].path;
      }

      this._screens[name] = {
        routePath: [...routePath],
        portal: portal,
        parent: parent,
        ...routesConfig.routes[name],
      };
    }
  }
  getPortalByRouteName(routeName) {
    let portalName = this.getPortalNameByRouteName(routeName);
    return this.portals(portalName);
  }

  getPortalNameByRouteName(routeName) {
    let portal = null;
    if (this._screens[routeName]) {
      return this._navigators[this._screens[routeName].parent].portal;
    } else if (this._navigators[routeName])
      return this._navigators[routeName].portal;
    else return null;
  }

  getRouteByRouteName(routeName) {
    let portal = null;
    if (this._screens[routeName]) return this._screens[routeName];
    else if (this._navigators[routeName]) return this._navigators[routeName];
  }

  isScreenInNavigator(screen, navigator) {
    if (!this._navigators[navigator]) throw "No navigator found";
    if (!this._screens[screen]) throw "No screen found";
    return this._navigators[navigator].routes[screen] ? true : false;
  }
  getNextNavigatorName(screen, navigator) {
    if (!this._navigators[navigator]) throw "No navigator found Err 2";
    if (!this._screens[screen]) throw "No screen found Err 2";
    let nextNav = null;
    let foundCurr = false;
    for (let name of this._screens[screen].routePath) {
      if (name === navigator) foundCurr = true;
      else if (foundCurr) return name;
    }
    return null;
  }

  parseNavigationProps(props) {
    let routeProps = {
      route: props.route || null,
      //portal: props.portal || "default",
      delay: props.delay || 0,
    };
    if (!props.to) throw "Navigation props Error: No to.";
    if (typeof props.to === "object") {
      if (props.to.pathname) routeProps.route = props.to.pathname;
      else if (props.to.route) routeProps.route = props.to.route;

      if (props.to.portal) routeProps.portal = props.to.portal;
      //if(props.to.delay) routeProps.delay = props.to.delay;
      if (props.to.state) routeProps.state = props.to.state;
    } else routeProps.route = props.to;
    if (!routeProps.route) throw "Route Link Error: No pathname.";
    return routeProps;
  }
  isActiveRoute(route) {
    // if(typeof route === 'string'){
    //   console.error('IS ACTIVE ROUTE GOT STRING!');
    //   return false;
    // }
    if (typeof route === "string") route = { route, params: {} };

    let routeName = route.route;
    let routeInfo = this.getRouteByRouteName(route.route);
    let routePath = null;

    // return true;
    if (this.activeRouteName === routeName) return true;
    // Check the active routes of the portals

    let portal = this.getPortalByRouteName(routeName);

    if (!portal || !portal.route) return false;

    let portalRouteName = portal.route.name;

    if (portalRouteName === routeName) return true;
    // Check if it is a parent of the routePath
    let portalPathName = portal.route.path;
    let portalRoute = this.getRouteByRouteName(portalRouteName);
    if (
      portalRoute &&
      portalRoute.routePath &&
      portalRoute.routePath.indexOf(routeName) !== -1
    ) {
      let portalRouteParams = portal.route.params;
      if (!route.params || Object.keys(route.params).length === 0) return true;
      for (let i in route.params) {
        if (!portalRouteParams[i] || portalRouteParams[i] !== route.params[i])
          return false;
      }
      return true;
    }
    return false;
  }
}
