import R14 from "../core";
export default class R14LicenseDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "r14Deployment",
    });
    this.PRODUCT_INVENTR = "INVENTR";
    this.PRODUCT_TELETYPE = "TELETYPE";
    this.STATE_OUT_OF_SYNC = "OUT_OF_SYNC";
    this.STATE_ERROR = "ERROR";
    this.STATE_ACTIVE = "ACTIVE";
    this.STATE_INACTIVE = "INACTIVE";
  }
  parseSubmitValues(values) {
    if (values.r14License) {
      values.r14LicenseUid = values.r14License.value;
      delete values.r14License;
    }
    values.clientUid = this.dm.userSession.clientUid;
    return values;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let formDataValues = {};
    if (uid) {
      let res = await this.get(
        uid,
        `
        uid
        name
        url
        description
        product
        active
        master
        r14License {
          name
          uid
        }
    `
      );
      formDataValues = res;
      if(res.r14License){
        formDataValues.r14License = {
          value: res.r14License.uid,
          label: res.r14License.name,
        };
      }
    } else {
      formDataValues = {
        active: true,
      };
    }
    return {
      values: formDataValues,
      productSelections: [
        { value: this.PRODUCT_INVENTR, label: "Inventr" },
        { value: this.PRODUCT_TELETYPE, label: "Teletype" },
      ],
    };
  }
}
