import React from "react";
import R14, { Colors, StyleSheet, Text, View } from "../core";
import EntityDataTable from "./EntityDataTable";
export default R14.connect(
  class ManualEntryUsageMetricDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.usageMetricsDomain =
        this.props.app.dm.manualEntryPipelineUsageMetric;
      this.pipelineDomain = this.props.app.dm.manualEntryPipeline;
      this.pageLoader = this.pageLoader.bind(this);
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    get perms() {
      return this.props.perms;
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        manualEntryPipelineUid: { eq: this.props.manualEntryPipelineUid },
      };
      // if (search) filter.search = { like: `%${search}%` };
      // if (this.props.type) filter.type = { eq: this.props.type };

      let res = await this.usageMetricsDomain.find(
        [
          "uid",
          "startAt",
          "endAt",
          ...this.usageMetricsDomain.getMetricFields(),
        ],
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      // let res = await this.manualEntryDocumentDomain.find(
      //   `
      //   uid
      //   startAt
      //   endAt
      //   `,
      //   {
      //     page: page,
      //     resultsPerPage: rowsPerPage,
      //     filter: filter,
      //     sort: [
      //       {
      //         field: sortColumnName,
      //         order: sortDirection.toUpperCase(),
      //       },
      //     ],
      //     totalCount: options.totalCount || false,
      //   }
      // );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    // cellRenderer({ cell, columnName, row }) {
    //   let content = cell;
    //   let ret = null;
    //   let style = [styles.cellText];
    //   switch (columnName) {
    //     case "name":
    //       ret = (
    //         <React.Fragment>
    //           <Text style={[styles.cellText, styles.nameCellNameText]}>
    //             {row.name}
    //           </Text>
    //           {row.description && (
    //             <Text style={[styles.cellText, styles.nameCellDescriptionText]}>
    //               {row.description}
    //             </Text>
    //           )}
    //         </React.Fragment>
    //       );
    //       break;
    //     default:
    //       ret = <Text style={style}>{content}</Text>;
    //   }
    //   return ret;
    // }
    // handleRowPress(row) {
    //   this.props.app.nav.to("projectDocumentTemplate", {
    //     uid: this.props.projectUid,
    //     key: this.props.app.dm.project.getKeyByType(this.props.projectType),
    //     documentTemplateUid: row.uid,
    //   });
    // }
    cellToMinutesRenderer({ cell }) {
      if (isNaN(cell)) return null;
      cell = parseInt(cell);
      return <Text style={[styles.cellText]}>{Math.ceil(cell / 60)}</Text>;
    }
    renderMetricCell(row, type) {
      return (
        <View>
          {[
            this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
            this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
            this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
          ].map((parentType) => (
            <View key={parentType}>
              <Text style={[styles.cellText]}>
                {
                  row[
                    this.usageMetricsDomain.getMetricFieldName(parentType, type)
                  ]
                }
              </Text>
            </View>
          ))}
        </View>
      );
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.usageMetricsDomain}
          //searchable
          title={this.props.title || "Usage"}
          name={this.props.name || "manualEntryPipelineUsageMetrics"}
          columns={{
            startAt: {
              label: "Period",
              sortable: true,
              renderer: ({ row }) => {
                let startAt = new Date(row.startAt);
                let endAt = new Date(row.endAt);
                return (
                  <View>
                    <View>
                      <Text style={[styles.cellText]}>
                        {this.props.app.utils.date.toShortDate(startAt)}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.cellTextSmall]} noWrap>
                        {this.props.app.utils.date.toTime(startAt)} -{" "}
                        {this.props.app.utils.date.toTime(endAt)}
                      </Text>
                    </View>
                  </View>
                );
              },
            },
            modes: {
              label: "Modes",
              width: 72,
              renderer: ({ cell }) => {
                return (
                  <View>
                    <View>
                      <Text style={[styles.cellText]}>CLC</Text>
                    </View>
                    <View>
                      <Text style={[styles.cellText]}>FLC</Text>
                    </View>
                    <View>
                      <Text style={[styles.cellText]}>PLC</Text>
                    </View>
                  </View>
                );
              },
            },
            modeTotals: {
              label: "Total",
              width: 96,
              renderer: ({ row }) =>
                this.renderMetricCell(
                  row,
                  this.pipelineDomain.METRIC_TYPE_TOTAL
                ),
            },
            modeTimeout: {
              label: "Timeout",
              width: 96,
              renderer: ({ row }) =>
                this.renderMetricCell(
                  row,
                  this.pipelineDomain.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS
                ),
            },
            modeUsers: {
              label: "Rec. Users (mode)",
              // width:96,
              renderer: ({ row }) =>
                this.renderMetricCell(
                  row,
                  this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED
                ),
            },

            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL
            // )]: {
            //   label: "C",
            //   sortable: true,
            // },
            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS
            // )]: {
            //   label: "C TO",
            //   sortable: true,
            //   renderer: this.cellToMinutesRenderer,
            // },
            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED
            // )]: {
            //   label: "C UR",
            //   sortable: true,
            // },

            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL
            // )]: {
            //   label: "F",
            //   sortable: true,
            // },
            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS
            // )]: {
            //   label: "F TO",
            //   sortable: true,
            //   renderer: this.cellToMinutesRenderer,
            // },
            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_FIELD,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED
            // )]: {
            //   label: "F UR",
            //   sortable: true,
            // },

            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL
            // )]: {
            //   label: "FG",
            //   sortable: true,
            // },
            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL_TIMEOUT_SECONDS
            // )]: {
            //   label: "FG TO",
            //   sortable: true,
            //   renderer: this.cellToMinutesRenderer,
            // },
            // [this.usageMetricsDomain.getMetricFieldName(
            //   this.pipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
            //   this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED
            // )]: {
            //   label: "FG UR",
            //   sortable: true,
            // },

            [this.usageMetricsDomain.getMetricFieldName(
              this.pipelineDomain.METRIC_PARENT_TYPE_USER,
              this.pipelineDomain.METRIC_TYPE_TOTAL
            )]: {
              label: "Users",
              width: 96,
            },
            [this.usageMetricsDomain.getMetricFieldName(
              this.pipelineDomain.METRIC_PARENT_TYPE_USER,
              this.pipelineDomain.METRIC_TYPE_TOTAL_USERS_RECOMMENDED
            )]: {
              label: "Rec. Users",
            },
          }}
          pageLoader={this.pageLoader}
          // onRowPress={this.handleRowPress}
          initialSortColumnName='startAt'
          initialSortDirection='desc'
          // projectType={this.props.project.type}
          // projectUid={this.props.project.uid}
          perms={this.perms}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellTextSmall: {
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    fontSize: 14,
  },
});
