import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  View,
  Surface,
  SectionHeader,
  Text,
  Button,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Colors,
} from "../core";
import Dashboard from "../components/Dashboard";
import DashboardWidget from "../components/DashboardWidget";
import DashboardCard from "../components/DashboardCard";
import DashboardCardSection from "../components/DashboardCardSection";
import DashboardCardSectionRow from "../components/DashboardCardSectionRow";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import ManualEntryPipelineMetricsDataTable from "../components/ManualEntryPipelineMetricsDataTable";
export default R14.connect(
  class ProjectManualEntryPipelineScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleRefreshPress = this.handleRefreshPress.bind(this);
      this.userDomain = this.props.app.dm.user;
      this.state = {
        metrics: this.props.manualEntryPipeline.metrics,
        lastMetricsSyncAt: this.props.manualEntryPipeline.lastMetricsSyncAt,
      };
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.project.type),
        "manualEntryPipeline"
      );
    }
    get defaultQueueNamespaceKey() {
      return "__r14__default__NONAMESPACE__";
    }
    async handleRefreshPress() {
      this.props.app.ui.progressIndicator.show();
      // Auto-batch the fetch by resolving asynchronously
      let promiseRes = await Promise.all([
        this.props.app.dm.manualEntryPipeline.initDashboard(
          this.props.manualEntryPipeline.uid
        ),
        this.props.app.ui.dataTable("manualEntryDocumentMetrics").refresh(),
      ]);
      let manualEntryPipeline = promiseRes.length ? promiseRes[0] : null;
      this.props.app.ui.snackBar.show({
        message: manualEntryPipeline
          ? "Refreshed metrics."
          : "Unable to refresh metrics.",
        variant: manualEntryPipeline ? "success" : "error",
      });
      manualEntryPipeline &&
        this.setState({
          metrics: manualEntryPipeline.metrics,
          lastMetricsSyncAt: manualEntryPipeline.lastMetricsSyncAt,
        });
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    get manualEntryPipelineDomain() {
      return this.props.app.dm.manualEntryPipeline;
    }
    getMetric(parentType, type) {
      if (!this.state.metrics) return 0;
      return (
        this.state.metrics
          .filter(
            (metric) => metric.parentType === parentType && metric.type === type
          )
          .map((metric) => metric.value || 0)
          .at(0) || 0
      );
    }
    get dashboardSectionRows() {
      let defaultSections = [
        {
          title: "Namespace",
          queueNamespace: true,
        },
        {
          title: "Queued",
          metricType:
            this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL_AVAILABLE,
        },
        {
          title: "In Progress",
          metricType: this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL_QUEUED,
        },
        {
          title: "Total",
          metricType: this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL,
        },
      ];
      return [
        {
          title: "Document Sets",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_DOCUMENT_SET,
          sections: defaultSections,
        },
        {
          title: "Documents",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_DOCUMENT,
          sections: defaultSections,
        },
        {
          title: "Characters",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
          sections: defaultSections,
        },
        {
          title: "Fields",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_FIELD,
          sections: defaultSections,
        },
        {
          title: "Field Groups",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
          sections: defaultSections,
        },
        {
          title: "Users",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_USER,
          sections: [
            {
              title: "Active",
              metricType:
                this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL_ACTIVE,
            },
          ],
        },
      ];
    }
    parseDashboardMetrics() {
      let sectionMap = {};
      this.state.metrics.forEach((metric) => {
        if (!sectionMap[metric.parentType]) sectionMap[metric.parentType] = {};
        let namespaceKey =
          metric.queueNamespace || this.defaultQueueNamespaceKey;
        if (!sectionMap[metric.parentType][namespaceKey])
          sectionMap[metric.parentType][namespaceKey] = {};
        sectionMap[metric.parentType][namespaceKey][metric.type] = metric.value;
      });

      return sectionMap;
    }
    renderDashboardMetrics() {
      // parse metrics by namespace
      let sectionMap = this.parseDashboardMetrics();
      let rowContent = null;
      // let showQueueNamespace = sectionMap.find(
      //   (section) => section.queueNamespace !== this.defaultQueueNamespaceKey
      // );
      return this.dashboardSectionRows.map((sectionRow) => {
        let section = sectionMap[sectionRow.metricParentType];
        if (!section) return null;
        // make sure there is a value
        // let showSection = Object.keys(section).find((queueNamespace) =>
        //   Object.keys(section[queueNamespace]).find(
        //     (metricType) => section[queueNamespace][metricType] > 0
        //   )
        // );
        // if (!showSection) return null;
        const metricsContent = [];
        // render the titles
        metricsContent.push(
          <View
            key={`${sectionRow.metricParentType}_titles`}
            style={styles.dashboardCardSectionRow}
          >
            {sectionRow.sections.map((section) => {
              return (
                <View
                  key={`${sectionRow.metricParentType}_${section.metricType}_title`}
                  style={[
                    styles.dashboardCardSectionTitle,
                    section.queueNamespace &&
                      styles.dashboardCardSectionQueueNamespaceTitle,
                  ]}
                >
                  <Text
                    style={styles.dashboardCardSectionTitleText}
                    ellipsisMode='tail'
                    numberOfLines={1}
                  >
                    {section.title}
                  </Text>
                </View>
              );
            })}
          </View>
        );
        // Sort by the namespace key
        Object.keys(section)
          .sort()
          .forEach((queueNamespace) => {
            let metrics = section[queueNamespace];
            metricsContent.push(
              <View
                key={`${sectionRow.metricParentType}_${queueNamespace}`}
                style={styles.dashboardCardSectionRow}
              >
                {sectionRow.sections.map((section) => {
                  let sectionText = "n/a";
                  if (section.queueNamespace)
                    sectionText =
                      queueNamespace === this.defaultQueueNamespaceKey
                        ? "Default"
                        : queueNamespace;
                  else if (metrics)
                    sectionText = metrics[section.metricType] || 0;
                  return (
                    <View
                      key={`${sectionRow.metricParentType}_${queueNamespace}_${
                        section.queueNamespace
                          ? "queueNamespace"
                          : section.metricType
                      }`}
                      // title={section.title}
                      style={
                        section.queueNamespace
                          ? styles.dashboardCardQueueNamespaceSection
                          : styles.dashboardCardSection
                      }
                    >
                      <Text
                        style={[
                          styles.dashboardCardSectionText,
                          section.queueNamespace &&
                            styles.dashboardCardSectionQueueNamespaceText,
                        ]}
                        noWrap={true}
                        ellipsisMode='tail'
                        numberOfLines={1}
                        tooltip={sectionText}
                      >
                        {sectionText}
                      </Text>
                    </View>
                  );
                })}
              </View>
            );
          });
        return (
          <DashboardCardSectionRow
            key={sectionRow.metricParentType}
            title={sectionRow.title}
            contentStyle={styles.dashboardCardSectionRowContent}
          >
            {metricsContent}
          </DashboardCardSectionRow>
        );
      });
    }
    renderLastMetricsSyncAt() {
      let formattedDate = "N/A";
      if (this.state.lastMetricsSyncAt) {
        let lastMetricsSyncAt = new Date(this.state.lastMetricsSyncAt);
        formattedDate = `${this.props.app.utils.date.toShortDate(
          lastMetricsSyncAt
        )} ${this.props.app.utils.date.toTime(lastMetricsSyncAt)}`;
      }
      return formattedDate;
    }
    render() {
      if (!this.perms.read) return null;
      return (
        <ProjectScreenLayout
          headerTitle={this.props.manualEntryPipeline.name}
          scrollEnabled
          project={this.props.project}
          headerControlsRight={[
            <View key='lastMetricsSyncAt' style={styles.lastMetricsSyncAt}>
              <Text style={styles.lastMetricsSyncAtText}>
                Last Sync: {this.renderLastMetricsSyncAt()}
              </Text>
            </View>,
            <IconButton
              icon='refresh'
              key='refresh'
              onPress={this.handleRefreshPress}
            />,
          ]}
          breadCrumbs={[
            {
              label: "Manual Entry",
              to: {
                route: "projectManualEntryPipelines",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                },
              },
            },
          ]}
        >
          <Dashboard name='manualEntryPipeline' minWidth={352}>
            <DashboardCard title='Stats' style={styles.dashboardStatsCard}>
              {this.renderDashboardMetrics()}
            </DashboardCard>

            <DashboardWidget>
              <ManualEntryPipelineMetricsDataTable
                title='Daily Stats'
                name='manualEntryDocumentMetrics'
                manualEntryPipeline={this.props.manualEntryPipeline}
              />
            </DashboardWidget>
          </Dashboard>
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  dashboardCardSection: {
    // width: "33.3333%",
    width: "22%",
    maxWidth: "22%",
    overflow: "hidden",
  },
  dashboardCardQueueNamespaceSection: {
    width: "36%",
  },
  dashboardCardSectionTitle: {
    width: "22%",
  },
  dashboardCardSectionQueueNamespaceTitle: {
    width: "36%",
  },
  dashboardCardSectionTitleText: {
    fontSize: 13,
    paddingRight: 4,
    overflow: "hidden",
    display: "block",
  },
  dashboardCardSectionRow: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 4,
    alignItems: "center",
  },
  dashboardCardSectionRowContent: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 20,
  },
  dashboardCardSectionText: {
    fontSize: 20,
    fontWeight: 500,
  },
  dashboardCardSectionQueueNamespaceText: {
    fontSize: 15,
    fontWeight: 500,
    overflow: "hidden",
    display: "block",
    lineHeight: 20,
    paddingRight: 4,
  },
  lastMetricsSyncAt: {
    paddingTop: 4,
  },
  lastMetricsSyncAtText: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
});
