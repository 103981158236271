import R14 from "../core";
export default class ManualEntryUserBatchDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryUserBatch",
    });
    this.CSV_HEADER_COLUMN_NAME = "NAME";
    this.CSV_HEADER_COLUMN_USERNAME = "USERNAME";
    this.CSV_HEADER_COLUMN_EMAIL = "EMAIL";
    this.CSV_HEADER_COLUMN_PHONE = "PHONE";
    this.CSV_HEADER_COLUMN_PASSWORD = "PASSWORD";
    this.CSV_HEADER_COLUMN_MANUAL_ENTRY_CONFIG_KEY = "MANUAL_ENTRY_CONFIG_KEY";
    this.CSV_HEADER_COLUMN_MANUAL_ENTRY_MODES = "MANUAL_ENTRY_MODES";
    this.CSV_HEADER_COLUMN_VIRTUAL_WORKSPACE_CONFIG_KEY =
      "VIRTUAL_WORKSPACE_CONFIG_KEY";
    this.CSV_HEADER_COLUMN_COUNTRY_CODE = "COUNTRY_CODE";
    this.CSV_HEADER_COLUMN_CURRENCY_CODE = "CURRENCY_CODE";
    this.CSV_HEADER_COLUMN_MFA_TYPE = "MFA_TYPE";
    this.CSV_HEADER_COLUMN_ACTIVE = "ACTIVE";
    this.CSV_HEADER_COLUMN_SOURCE = "SOURCE";
    this.STATE_INIT = "INIT";
    this.STATE_RUNNING = "RUNNING";
    this.STATE_COMPLETE = "COMPLETE";
    this.STATE_ERROR = "ERROR";
  }
  //   async fetchEditFormData(uid = null, options = {}) {
  //     let fields = `
  //   uid
  //   name
  //   description
  // `;
  //     let formDataValues = {};
  //     if (uid) {
  //       let res = await this.get(uid, fields);
  //       formDataValues = {
  //         uid: res.uid,
  //         name: res.name,
  //       };
  //     }
  //     return {
  //       values: formDataValues,
  //     };
  //   }
  async fetchEditFormData(uid = null) {
    let qryVals = {};
    qryVals.r14DeploymentFilter = {
      clientUid: { eq: this.dm.userSession.clientUid },
      product: { eq: this.dm.r14Deployment.PRODUCT_TELETYPE },
    };
    qryVals.r14DeploymentInstanceInput = {
      // clientUid: { eq: this.dm.userSession.clientUid },
      product: this.dm.r14Deployment.PRODUCT_TELETYPE,
    };

    if (uid) {
      qryVals.uid = uid;

      // if (!this.dm.user.hasAdminRole)
      //   qryVals.pipelineBlockFilter = {
      //     userUids: { eq: this.dm.userSession.uid },
      //   };
    }
    let res = uid
      ? await this.api.qry(
          `
        query ManualEntryUserBatchCreateFormData(
            $uid: ID!,
            $r14DeploymentFilter: R14DeploymentFilter
            $r14DeploymentInstanceInput: R14DeploymentInstanceInput!
          ) {
          manualEntryUserBatch(uid: $uid){
            uid
            name
            description
            csvFile {
              name
              uid
            }
          }
          r14DeploymentInstance(input: $r14DeploymentInstanceInput){
            uuid
            master
          }
          r14Deployments(filter: $r14DeploymentFilter){
            nodes {
              uuid
              name
            }
          }
        }`,
          qryVals
        )
      : await this.api.qry(
          `
          query ManualEntryUserBatchCreateFormData(
              $r14DeploymentFilter: R14DeploymentFilter
              $r14DeploymentInstanceInput: R14DeploymentInstanceInput!
            ) {
            r14DeploymentInstance(input: $r14DeploymentInstanceInput){
              uuid
              master
            }
            r14Deployments(filter: $r14DeploymentFilter){
              nodes {
                uuid
                name
              }
            }
          }`,
          qryVals
        );
    let formData = {
      values: {},
      r14DeploymentSelections:
        res.data.r14Deployments && res.data.r14Deployments.nodes
          ? res.data.r14Deployments.nodes.map((val) => ({
              label: val.name,
              value: val.uuid,
            }))
          : [],
      r14DeploymentInstance: res.data.r14DeploymentInstance,
    };

    let formVals = {};
    if (uid && res.data.manualEntryUserBatch) {
      formVals.uid = res.data.manualEntryUserBatch.uid;
      formVals.name = res.data.manualEntryUserBatch.name;
      formVals.description = res.data.manualEntryUserBatch.description;
      formVals.csvFile = {
        label: res.data.manualEntryUserBatch.csvFile.name,
        value: res.data.manualEntryUserBatch.csvFile.uid,
      };
    }
    if (
      formData.r14DeploymentInstance &&
      formData.r14DeploymentSelections &&
      formData.r14DeploymentSelections.length > 0
    )
      formVals.r14DeploymentUuid = formData.r14DeploymentSelections
        .map((d) => d.value)
        .find((d) => d === formData.r14DeploymentInstance.uuid);

    formData.values = formVals;
    return formData;
  }
  parseSubmitValues(values) {
    values.clientUid = this.dm.userSession.clientUid;
    values.userUid = this.dm.userSession.uid;
    return values;
  }
  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_INIT:
        label = "Initializing";
        break;
      case this.STATE_RUNNING:
        label = "Running";
        break;
      case this.STATE_COMPLETE:
        label = "Complete";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_INIT:
      case this.STATE_RUNNING:
        color = "yellow";
        break;
      case this.STATE_COMPLETE:
        color = "green";
        break;
      case this.STATE_ERROR:
        color = "red";
        break;
    }
    return color;
  }
}
