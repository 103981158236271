import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  BackButton,
  Form,
  SubmitButton,
  Text,
  StyleSheet,
  Redirect,
  SwitchField,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ProjectPipelineManageDataScreen extends React.Component {
    constructor(props) {
      super(props);
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    async handleSubmit(form) {
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        console.log("Check input", this.props.pipelineUid, form.values);
        let res = await this.pipelineDomain.deleteData(this.props.pipelineUid, form.values);
        if (res.success) {
          ret = (
            <Redirect
              to={this.props.redirect || "projectPipeline"}
              params={{
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                pipelineUid: this.props.pipelineUid
              }}
            />
          );
        } else if (res.errors) {
          res.errors.forEach((err) => form.addError(err.message));
        } else form.addError("Unknown Error, please try again.");
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='deleteDataForm'
          style={styles.deleteDataForm}
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          // validators={FormValidators}
          initialValues={{}}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Delete' key='submit' />,
          ]}
        >
          <SwitchField
            key='deleteGlobalData'
            name='deleteGlobalData'
            label='Delete Global Data'
            value={true}
          />
          <SwitchField
            key='deletePipelineBlockData'
            name='deletePipelineBlockData'
            label='Delete Block Data'
            value={false}
          />
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  labelView: {
    marginBottom: 16,
  },
});
