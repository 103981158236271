import React from "react";
import R14, { ScreenLayout } from "../core";
import ProjectAppModuleBuildDataTable from "../components/ProjectAppModuleBuildDataTable";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
export default R14.connect(
  class ProjectsAppModuleBuildsScreen extends React.Component {
    render() {
      return (
        <ProjectScreenLayout
          project={this.props.project}
          floatingActionButton={
            this.props.app.dm.user.checkPermissions(
              this.props.app.dm.project.getKeyByType(
                this.props.project.type
              ),
              "appModule",
              "create"
            ) && {
              onPress: () =>
                this.props.app.nav.to("projectAppModuleBuildCreate", {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                }),
              icon: "add",
            }
          }
          scrollEnabled
          DataTableComponent={
            <ProjectAppModuleBuildDataTable
              projectUid={this.props.project.uid}
              projectType={this.props.project.type}
            />
          }
        />
      );
    }
  }
);
