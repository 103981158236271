import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  HiddenInputField,
  FieldGroup,
  Text,
  DatePickerField,
  TimePickerField,
  StyleSheet,
} from "../core";
import EditForm from "../components/EditForm";
// import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryUserPaymentBatchScreen extends React.Component {
    constructor(props) {
      super(props);
      this.manualEntryConfigItemLoader = this.manualEntryConfigItemLoader.bind(this);
    }
    get isEditMode() {
      return this.props.formData &&
        this.props.formData.values &&
        this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async manualEntryConfigItemLoader(filters = {}) {
      return await this.props.app.dm.manualEntryConfig.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }
    render() {
      return (
        <EditForm
          formData={this.props.formData}
          domainName='manualEntryUserPaymentBatch'
          redirect={this.props.redirect}
          refreshDataTable='manualEntryUserPaymentBatches'
        >
          {/* <SelectMenuField
            name='appModule'
            label='App Module'
            searchable
            itemLoader={this.appModuleItemLoader}
            items={this.formData.appModuleSelections}
            required='Please select an app module.'
            helper='Required'
            autoLoad
          /> */}
          <TextInputField
            name='name'
            label='Name'
            required='Please enter a name.'
            autoFocus
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
           <SelectMenuField
            multiple
            key='manualEntryConfigs'
            name='manualEntryConfigs'
            label='Manual Entry Configs'
            searchable
            autoLoad
            itemLoader={this.manualEntryConfigItemLoader}
            required='Please select at least one config.'
          />
          <FieldGroup>
            <DatePickerField
              name='processAtDate'
              label='Process Date'
              // textInputEditable
              required='Please select a Process date.'
            />
            <TimePickerField
              name='processAtTime'
              label='Process Time'
              visible='True'
              required='Please select a process time.'
            />
          </FieldGroup>
          <FieldGroup>
            <DatePickerField
              name='startAtDate'
              label='Start Date'
              // textInputEditable
              required='Please select a start date.'
            />
            <TimePickerField
              name='startAtTime'
              label='Start Time'
              visible='True'
              required='Please select a start time.'
            />
          </FieldGroup>
          <FieldGroup>
            <DatePickerField
              name='endAtDate'
              label='End Date'
              // textInputEditable
              required='Please select a end date.'
            />
            <TimePickerField
              name='endAtTime'
              label='End Time'
              visible='True'
              required='Please select a end time.'
            />
          </FieldGroup>
          {/* <TimePickerField
            name='endAt'
            label='End Time'
            required='Please select an end time.'
          /> */}
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  // labelView: {
  //   width: "25%",
  //   minWidth: 0,
  //   flex: 1,
  // },
});
